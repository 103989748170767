/* eslint-disable no-shadow */
const state = {
  loading: false,
  error: "",
  disable_error_messages: false,
  loadingMarkers: false,
};

const actions = {};

const mutations = {
  loading(state, value) {
    state.loading = value;
  },
  error(state, value) {
    state.error = value;
  },
  disable_error_messages(state, value) {
    state.disable_error_messages = value;
  },
  loadingMarkers(state, value) {
    state.loadingMarkers = value;
  },
};

const getters = {
  loading: (storeState) => storeState.loading,
  error: (storeState) => storeState.error,
  disable_error_messages: (storeState) => storeState.disable_error_messages,
  loadingMarkers: (storeState) => storeState.loadingMarkers,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

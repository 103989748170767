<template>
  <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 1.99999L11.5 11L21 1.99999" stroke="#6D6E71" stroke-width="3" />
  </svg>
</template>

<script>
export default {
  name: "ArrowDown",

  data() {
    return {};
  },
};
</script>

<template>
  <div class="register-code-container">
    <div v-if="loading">
      <v-progress-circular indeterminate />
    </div>
    <div v-if="!loading">
      <div>
        <h1
          v-if="
            !preCheckStatus.new_user &&
            !preCheckStatus.found &&
            preCheckStatus.is_verified &&
            preCheckStatus.same_password
          "
          class="title mb-5"
          v-t="'user_register.register_code.hasAccount'"
        />
        <h1
          class="title"
          :class="{ title__subtitle: preCheckStatus.found }"
          v-t="subTitleMessage()"
        />
      </div>
      <div class="code_container">
        <input
          v-if="preCheckStatus.new_user && !preCheckStatus.is_verified"
          :type="(showCode? 'text': 'password')"
          class="register__content__input__div__input code_input"
          :class="{
            'register__content__input__div__input--selected': code.length > 0,
            'register__content__input__div__input--error': codeErrors.length > 0,
          }"
          v-model="code"
          @input="$v.code.$touch()"
          @blur="$v.code.$touch()"
        />
        <v-btn
          v-if="!preCheckStatus.is_verified"
          @click="showCode = !showCode"
          class="code_btn"
          icon
          color="white"
          type="button"
        >
          <v-icon>
            {{ showCode ? "mdi-eye" : "mdi-eye-off" }}
          </v-icon>
        </v-btn>
      </div>
      <div class="mb-5">
        <p v-for="(error, index) in codeErrors" :key="index" class="text code_conditions">
          {{ error }}
        </p>
      </div>
      <div class="btn_container justify-end pt-3">
        <base-button
          class="btn-continue"
          :class="{ 'btn-continue--active': code }"
          @click="continueMethod"
        >
          {{ $t("user_register.register_code.btn") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import BaseButton from "@/components/explorer/base/BaseButton.vue";

export default {
  name: "RegisterCode",
  components: {
    BaseButton,
  },
  data() {
    return {
      code: "",
      showCode: false,
    };
  },
  validations: {
    code: {
      required,
    },
  },
  methods: {
    ...mapActions({
      setWantCreateAccount: "authentication/setWantCreateAccount",
      doLoginWithUUID: "authentication/doLoginWithUUID",
      verifyCode: "authentication/verifyCode",
      error: "snackbar/error",
      success: "snackbar/success",
    }),
    afterValidation() {
      if (this.validCode) {
        this.success(this.$t("views.user_register.code_sent.valid"));
        const { legalGuardianUUID, environment } = this;
        this.$mixpanel.track("click_step_enter_verify-code_in_register", {
          distinct_id: legalGuardianUUID,
          env: environment,
        });
        this.$emit("nextStep");
      } else {
        this.setWantCreateAccount({ wantCreateAccount: false });
        this.error(this.$t("views.user_register.code_sent.invalid"));
      }
    },
    async continueMethod() {
      if (this.preCheckStatus.is_verified) {
        if (this.preCheckStatus.is_verified
            && this.preCheckStatus.same_password
            && this.preCheckStatus.found) {
          this.doLoginWithUUID({
            uuid: this.preCheckStatus.lg_uuid,
          });
          this.$emit('closeModal');
        }
        if (this.preCheckStatus.found && !this.preCheckStatus.same_password) {
          return this.$emit('closeModal');
        }
        return this.$emit("nextStep");
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error(this.$t('views.register.error.code.required'));
      } else {
        const data = {
          code: this.code,
          email: this.email,
          authType: "email",
        };
        this.verifyCode(data);
        this.$emit("nextStep");
        return null;
      }
      return null;
    },
    backMethod() {
      this.$emit("backStep");
    },
    subTitleMessage() {
      if (!this.preCheckStatus.is_verified) {
        return "user_register.register_code.title";
      }
      if (this.legalGuardianAddress && this.preCheckStatus.is_verified) {
        if (
          this.legalGuardianAddress[0].address_lat &&
          this.legalGuardianAddress[0].address_lon &&
          this.preCheckStatus.same_password &&
          this.preCheckStatus.found
        ) {
          return 'user_register.register_code.setMessage';
        } else {
          return 'user_register.register_code.address_message';
        }
      }
      return '';
    },
  },
  computed: {
    ...mapGetters({
      currentStep: "userRegister/currentStep",
      validCode: "userRegister/validCode",
      getRegister: "userRegister/register",
      email: "authentication/email",
      preCheckStatus: "authentication/preCheckStatus",
      legalGuardianAddress: "authentication/legalGuardianAddress",
      loading: "authentication/loading",
    }),
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
    codeErrors() {
      const errors = [];
      if (!this.$v.code.$dirty) {
        return errors;
      }
      if (!this.$v.code.required) {
        errors.push(this.$t("views.register.error.code.required"));
      }
      return errors;
    },
  },
};
</script>

<template>
  <svg viewBox="0 0 49 46" xmlns="http://www.w3.org/2000/svg">
    <g style="mix-blend-mode: multiply">
      <path
        d="M39 23C39 30.1797 33.1797 36 26 36C18.8203 36 13 30.1797 13 23C13 15.8203 18.8203 10 26 10C33.1797 10 39 15.8203 39 23Z"
        fill="white"
        stroke="#BDBDBD"
        stroke-width="20"
      />
    </g>
    <circle cx="9" cy="10" r="9" fill="#180D81" />
    <path d="M9.01184 13.6526L9.01184 6.18227" stroke="white" stroke-width="0.5" />
    <path
      d="M12 8.36861C11.0045 8.40333 9.01361 7.91717 9.01361 5.69474"
      stroke="white"
      stroke-width="0.5"
    />
    <path
      d="M6.02368 8.36861C7.01914 8.40333 9.01007 7.91717 9.01007 5.69475"
      stroke="white"
      stroke-width="0.5"
    />
  </svg>
</template>

<script>
export default {
  name: "HasPriorityUnexplored",
};
</script>

<style scoped></style>

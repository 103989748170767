<template>
  <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M15.4607 2.1913H11.1461V0.730435C11.1461 0.365217 10.7865 0 10.427 0H5.57303C5.21348 0 4.85393 0.365217 4.85393 0.730435V2.1913H0.719101C0.359551 2.1913 0 2.37391 0 2.73913V5.11304C0 5.47826 0.359551 5.84348 0.719101 5.84348H1.07865L2.1573 20.4522C2.1573 20.8174 2.51685 21 2.8764 21H5.21348H11.1461H13.4831C13.8427 21 14.0225 20.8174 14.2022 20.4522L15.2809 5.84348H15.6405C16 5.84348 16.3596 5.47826 16.3596 5.11304V2.73913C16 2.37391 15.8202 2.1913 15.4607 2.1913Z"
        fill="#5FAABA"
      />
      <path
        d="M15.4607 2.1913H11.1461V0.730435C11.1461 0.365217 10.7865 0 10.427 0H5.57303C5.21348 0 4.85393 0.365217 4.85393 0.730435V2.1913H0.719101C0.359551 2.1913 0 2.37391 0 2.73913V5.11304C0 5.47826 0.359551 5.84348 0.719101 5.84348H1.07865L2.1573 20.4522C2.1573 20.8174 2.51685 21 2.8764 21H5.21348H11.1461H13.4831C13.8427 21 14.0225 20.8174 14.2022 20.4522L15.2809 5.84348H15.6405C16 5.84348 16.3596 5.47826 16.3596 5.11304V2.73913C16 2.37391 15.8202 2.1913 15.4607 2.1913ZM6.29214 1.27826H9.88764V2.0087H6.29214V1.27826ZM1.25843 3.46957H14.7416V4.56522H14.382H1.61798H1.25843V3.46957ZM7.37079 5.84348V19.7217H5.57303L5.21348 5.84348H7.37079ZM8.80899 5.84348H10.9663L10.427 19.7217H8.62921V5.84348H8.80899ZM2.33708 5.84348H3.95506L4.49438 19.7217H3.23596L2.33708 5.84348ZM12.764 19.7217H11.8652L12.4045 5.84348H13.8427L12.764 19.7217Z"
        fill="black"
      />
    </g>
    <defs></defs>
  </svg>
</template>

<script>
export default {
  name: "Trashcan",

  data() {
    return {};
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-code-container"},[(_vm.loading)?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),(!_vm.loading)?_c('div',[_c('div',[(
          !_vm.preCheckStatus.new_user &&
          !_vm.preCheckStatus.found &&
          _vm.preCheckStatus.is_verified &&
          _vm.preCheckStatus.same_password
        )?_c('h1',{directives:[{name:"t",rawName:"v-t",value:('user_register.register_code.hasAccount'),expression:"'user_register.register_code.hasAccount'"}],staticClass:"title mb-5"}):_vm._e(),_c('h1',{directives:[{name:"t",rawName:"v-t",value:(_vm.subTitleMessage()),expression:"subTitleMessage()"}],staticClass:"title",class:{ title__subtitle: _vm.preCheckStatus.found }})]),_c('div',{staticClass:"code_container"},[(((_vm.showCode? 'text': 'password'))==='checkbox'&&(_vm.preCheckStatus.new_user && !_vm.preCheckStatus.is_verified))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],staticClass:"register__content__input__div__input code_input",class:{
          'register__content__input__div__input--selected': _vm.code.length > 0,
          'register__content__input__div__input--error': _vm.codeErrors.length > 0,
        },attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.code)?_vm._i(_vm.code,null)>-1:(_vm.code)},on:{"input":function($event){return _vm.$v.code.$touch()},"blur":function($event){return _vm.$v.code.$touch()},"change":function($event){var $$a=_vm.code,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.code=$$a.concat([$$v]))}else{$$i>-1&&(_vm.code=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.code=$$c}}}}):(((_vm.showCode? 'text': 'password'))==='radio'&&(_vm.preCheckStatus.new_user && !_vm.preCheckStatus.is_verified))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],staticClass:"register__content__input__div__input code_input",class:{
          'register__content__input__div__input--selected': _vm.code.length > 0,
          'register__content__input__div__input--error': _vm.codeErrors.length > 0,
        },attrs:{"type":"radio"},domProps:{"checked":_vm._q(_vm.code,null)},on:{"input":function($event){return _vm.$v.code.$touch()},"blur":function($event){return _vm.$v.code.$touch()},"change":function($event){_vm.code=null}}}):(_vm.preCheckStatus.new_user && !_vm.preCheckStatus.is_verified)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],staticClass:"register__content__input__div__input code_input",class:{
          'register__content__input__div__input--selected': _vm.code.length > 0,
          'register__content__input__div__input--error': _vm.codeErrors.length > 0,
        },attrs:{"type":(_vm.showCode? 'text': 'password')},domProps:{"value":(_vm.code)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.code=$event.target.value},function($event){return _vm.$v.code.$touch()}],"blur":function($event){return _vm.$v.code.$touch()}}}):_vm._e(),(!_vm.preCheckStatus.is_verified)?_c('v-btn',{staticClass:"code_btn",attrs:{"icon":"","color":"white","type":"button"},on:{"click":function($event){_vm.showCode = !_vm.showCode}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.showCode ? "mdi-eye" : "mdi-eye-off")+" ")])],1):_vm._e()],1),_c('div',{staticClass:"mb-5"},_vm._l((_vm.codeErrors),function(error,index){return _c('p',{key:index,staticClass:"text code_conditions"},[_vm._v(" "+_vm._s(error)+" ")])}),0),_c('div',{staticClass:"btn_container justify-end pt-3"},[_c('base-button',{staticClass:"btn-continue",class:{ 'btn-continue--active': _vm.code },on:{"click":_vm.continueMethod}},[_vm._v(" "+_vm._s(_vm.$t("user_register.register_code.btn"))+" ")])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section class="login">
    <div class="login__container">
      <picture class="login__container__picture mb-4">
        <img
          class="mb-1"
          contain
          width="120px"
          :src="require('@/assets/iconsChile/header/logo.svg')"
        />
        <img src="@/assets/iconsChile/header/mime-side-logo.svg" width="120px" />
      </picture>
      <h1 class="login__title mt-10" v-t="'views.login.question'" />
      <v-btn v-if="false" @click="signInGoogle()" class="login__google mt-7" depressed>
        <img src="@/assets/iconsBase/logos/google.svg" alt="google" />
        <span class="absolute w-full" v-t="'views.login.google'" />
      </v-btn>
      <div class="login__radio--button px-5 mt-5 mb-10">
        <input
          type="radio"
          id="radioEmail"
          v-model="authMethod"
          value="email"
          :class="{ 'login__form--input--disabled' : loginLoading}"
          :disabled="loginLoading ? true : false"
        />
        <label
          for="radioEmail"
          v-t="'views.login.authentication_method.email'"
        />
        <input
          type="radio"
          id="radioPhone"
          name="radioPhone"
          v-model="authMethod"
          value="username"
          :class="{ 'login__form--input--disabled' : loginLoading}"
          :disabled="loginLoading ? true : false"
        />
        <label for="radioPhone" v-t="'views.login.authentication_method.username'" />
      </div>
      <h3 v-if="isResetPassword" class="login__container--recover-title mb-5" v-t="'views.login.login-recover-password'"/>
      <div class="login__form">
        <label class="login__form--label" for="register">
          {{
            $t(`${authMethod === "email" ? "views.login.name.email" : "views.login.name.phone"}`)
          }}
          <span class="login__form--dot">*</span>
        </label>
        <div
          v-if="authMethod === 'email'"
        >
          <input
            class="login__form--input"
            id="register"
            type="email"
            v-model="email"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
            @click="resetLoginAssistAction"
            :class="{ 'login__form--input--disabled' : loginLoading}"
            :disabled="loginLoading ? true : false"
          />
          <p v-for="(error, index) in emailErrors" :key="index" class="login__form--error">
            {{ error }}
          </p>
        </div>

        <div v-else>
          <div class="d-flex justify-space-between">
            <input disabled value="+56" class="login__form--input login__form--input--area" />
            <input
              class="login__form--input login__form--input--phone"
              id="register"
              type="tel"
              v-model="username"
              @input="$v.username.$touch()"
              @blur="$v.username.$touch()"
              @click="resetLoginAssistAction"
              :disabled="loginLoading ? true : false"
            />
          </div>
          <p v-for="(error, index) in usernameErrors" :key="index" class="login__form--error">
            {{ error }}
          </p>
        </div>

        <label v-if="!isResetPassword" class="login__form--label mt-7" for="password">
          {{ $t("views.login.name.password") }}
          <span class="login__form--dot">*</span>
        </label>

        <div v-if="!isResetPassword">
          <input
            class="login__form--input"
            id="password"
            :type="showPassword ? 'text' : 'password'"
            v-model="password"
            :class="{ 'login__form--input--disabled' : loginLoading}"
            :disabled="loginLoading ? true : false"
          />
          <v-btn
            v-if="!loginLoading"
            @click="showPassword = !showPassword"
            class="login__btn--password"
            icon
            color="#293895"
            type="button"
          >
            <v-icon>{{ showPassword ? "mdi-eye" : "mdi-eye-off" }} </v-icon>
          </v-btn>
        </div>

        <p v-for="(error, index) in passwordErrors" :key="index" class="login__form--error">
          {{ error }}
        </p>
      </div>

      <button
        v-if="!isResetPassword"
        class="login__submit mt-7"
        :class="{ 'login__submit__loading' : loginLoading }"
        type="button"
        @click="recaptcha"
      >
          <v-progress-circular v-if="loginLoading" indeterminate color="white" />
          <div v-if="!loginLoading" v-t="'views.login.btn-login'" />
      </button>
      <button
        @click="openRegister()"
        v-if="!isResetPassword"
        class="login__link mt-5"
        v-t="'views.login.to-register'"
      />
      <button
        v-if="!isResetPassword"
        @click="forgotPassword()"
        type="button"
        class="login__link mt-2"
        v-t="'views.login.to-forgot-password'"
      />
      <button
        v-if="!isResetPassword"
        class="login__guest mt-5"
        type="button"
        @click="backGuest"
        v-t="'views.login.back-guest'"
      />

      <div class="login__btn-reset">
        <button
          v-if="isResetPassword"
          class="login__submit mt-7"
          type="button"
          @click="isResetPassword = false"
          v-t="'views.login.back'"
        />
        <button
          v-if="isResetPassword"
          class="login__reset mt-7"
          type="button"
          @click="resetPassword()"
          v-t="'views.login.btn-login'"
        />
      </div>
    </div>
    <v-dialog
      persistent
      v-model="showUserRegister"
      max-width="360"
      transition="slide-x-transition"
    >
      <user-register :step="userRegisterStep" @closeModal="closeRegisterModal" @resetPassword="showResetPassword()" />
    </v-dialog>
  </section>
</template>

<script>
import { requiredIf, maxLength, minLength, email } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
// import CountryFlag from 'vue-country-flag';
import * as SentryBrowser from "@sentry/browser";
// import BaseButton from '@/components/explorer/base/BaseButton.vue';
// import ActionPopup from '@/components/authentication/auth/ActionPopup.vue';
import CONFIG from "@/config";
import phoneCodes from "@/assets/countryPhoneCodes.json";
import UserRegister from "../../components/dashboard/UserRegister.vue";
// import LoginAssistDialog from '@/components/dialogs/LoginAssistDialog.vue';

export default {
  name: "Login",
  components: {
    // ActionPopup,
    // BaseButton,
    // CountryFlag,
    // LoginAssistDialog,
    UserRegister,
  },
  data() {
    return {
      showPassword: false,
      email: "",
      username: "",
      password: "",
      isResetPassword: false,
      loginAssistDialog: false,
      loginAssistAction: "",
      authMethod: "email",
      actionEmail: "",
      actionUsername: "",
      resetPasswordDialog: false,
      siteKey: CONFIG.captchaKey,
      phoneCodes,
      currentPhoneCodeIndex: 0,
      showUserRegister: false,
      userRegisterStep: 1,
    };
  },
  validations: {
    email: {
      required: requiredIf(function useEmail() {
        return this.authMethod === "email" && this.loginAssistAction === "";
      }),
      email(emailToTest) {
        if (this.authMethod === "email" && this.loginAssistAction === "") {
          return email(emailToTest);
        }
        return true;
      },
    },
    actionEmail: {
      required: requiredIf(function useEmail() {
        return this.authMethod === "email" && this.loginAssistAction !== "";
      }),
      email(emailToTest) {
        if (this.authMethod === "email" && this.loginAssistAction !== "") {
          return email(emailToTest);
        }
        return true;
      },
    },
    username: {
      required: requiredIf(function usePhone() {
        return this.authMethod === "username" && this.loginAssistAction === "";
      }),
      minLength(username) {
        if (this.authMethod === "username" && this.loginAssistAction === "") {
          return minLength(9)(username);
        }
        return true;
      },
      maxLength(username) {
        if (this.authMethod === "username" && this.loginAssistAction === "") {
          return maxLength(9)(username);
        }
        return true;
      },
    },
    actionUsername: {
      required: requiredIf(function usePhone() {
        return this.authMethod === "username" && this.loginAssistAction !== "";
      }),
      minLength(username) {
        if (this.authMethod === "username" && this.loginAssistAction !== "") {
          return minLength(9)(username);
        }
        return true;
      },
      maxLength(username) {
        if (this.authMethod === "username" && this.loginAssistAction !== "") {
          return maxLength(9)(username);
        }
        return true;
      },
    },
    password: {
      required: requiredIf(function isLogin() {
        return this.loginAssistAction === "";
      }),
    },
  },
  mounted() {
    this.$recaptchaInstance.showBadge();
    this.setLoginLoading({ bool: false });
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
  computed: {
    ...mapGetters({
      wantCreateAccount: 'authentication/wantCreateAccount',
      legalGuardianUUID: 'authentication/legalGuardianUUID',
      preCheckStatus: 'authentication/preCheckStatus',
      environment: 'authentication/environment',
      rerenderCount: 'authentication/rerenderCount',
      loginLoading: 'authentication/loginLoading',
    }),
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) {
        return errors;
      }
      if (!this.$v.password.required) {
        errors.push(this.$t("views.login.error.password.required"));
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) {
        return errors;
      }
      if (!this.$v.email.required) {
        errors.push(this.$t("views.login.error.email.required"));
      }
      if (!this.$v.email.email) {
        errors.push(this.$t("views.login.error.email.invalid"));
      }
      return errors;
    },
    actionEmailErrors() {
      const errors = [];
      if (!this.$v.actionEmail.$dirty) {
        return errors;
      }
      if (!this.$v.actionEmail.required) {
        errors.push(this.$t("views.login.error.email.required"));
      }
      if (!this.$v.actionEmail.email) {
        errors.push(this.$t("views.login.error.email.invalid"));
      }
      return errors;
    },
    usernameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) {
        return errors;
      }
      if (!this.$v.username.required) {
        errors.push(this.$t("views.register.error.username.required"));
      }
      if (!this.$v.username.minLength || !this.$v.username.maxLength) {
        errors.push(this.$t("views.login.error.username.length"));
      }
      return errors;
    },
    actionUsernameErrors() {
      const errors = [];
      if (!this.$v.actionUsername.$dirty) {
        return errors;
      }
      if (!this.$v.actionUsername.required) {
        errors.push(this.$t("views.register.error.username.required"));
      }
      if (!this.$v.actionUsername.minLength || !this.$v.actionUsername.maxLength) {
        errors.push(this.$t("views.login.error.username.length"));
      }
      return errors;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    showGuestBtn() {
      return this.wantCreateAccount && CONFIG.guestOption;
    },
    getPhoneCode() {
      return this.phoneCodes[this.currentPhoneCodeIndex];
    },
    getPhoneNumber() {
      return `+56${this.username}`;
    },
    getActionPhoneNumber() {
      return `${this.getPhoneCode.dial_code}${this.actionUsername}`;
    },
  },
  methods: {
    ...mapActions({
      doLogin: 'authentication/doLogin',
      info: 'snackbar/info',
      warn: 'snackbar/warn',
      error: 'snackbar/error',
      success: 'snackbar/success',
      retrieveResetPasswordToken: 'authentication/retrieveResetPasswordToken',
      resendVerificationCode: 'authentication/resendVerificationCode',
      cleanFilters: 'institutions/cleanFilters',
      setAuthMethod: 'userRegister/setAuthMethod',
      setRegisterData: 'userRegister/setRegisterData',
      setWantCreateAccount: 'authentication/setWantCreateAccount',
      setRerenderCount: 'authentication/setRerenderCount',
      setLoading: 'authentication/setLoading',
      setGoogleAuth: 'authentication/setGoogleAuth',
      setLoginLoading: 'authentication/setLoginLoading',
      logoutSuccess: 'authentication/logoutSuccess',
      setGuestData: 'authentication/setGuestData',
    }),
    openRegister() {
      this.mixPanel("click_create_account");
      if (this.showUserRegister) {
        this.showUserRegister = false;
        return this.showUserRegister;
      }
      this.showUserRegister = true;
      return this.showUserRegister;
    },
    async signInGoogle() {
      const googleAuth = await this.$gAuth.signIn();
      this.setLoginLoading({ bool: true });
      this.setGoogleAuth({
        googleAuth,
      });
    },
    showResetPassword() {
      this.isResetPassword = true;
    },
    closeRegisterModal() {
      this.showUserRegister = false;
    },
    forgotPassword() {
      this.mixPanel("click_forgot_password");
      this.isResetPassword = true;
    },
    mixPanel(tag, data) {
      const { legalGuardianUUID, environment } = this;
      this.$mixpanel.track(tag, {
        distinct_id: legalGuardianUUID,
        env: environment,
        ...data,
      });
    },
    async recaptcha() {
      await this.$recaptchaLoaded();
      const captcha = await this.$recaptcha("login");
      await this.tryLogin(captcha);
    },
    async tryLogin(captcha) {
      this.setLoginLoading({ bool: true });
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.warn("invalid");
      } else {
        const payload = {
          password: this.password,
          callback: this.goToWelcome,
          hotjar: this.$hj,
          captcha,
        };
        if (this.authMethod === "email") {
          payload.email = this.email;
        }
        if (this.authMethod === "username") {
          payload.username = this.getPhoneNumber;
        }
        await this.cleanFilters();
        this.doLogin(payload);
      }
    },
    validateLogin() {
      return this.$refs.form.validate();
    },
    // eslint-disable-next-line camelcase
    goToWelcome() {
      const { legalGuardianUUID, environment } = this;
      this.$mixpanel.track("login", {
        distinct_id: legalGuardianUUID,
        env: environment,
      });
      this.$mixpanel.identify(legalGuardianUUID);
      this.$mixpanel.people.set_once({
        $email: this.email,
      });
      SentryBrowser.setUser({ id: legalGuardianUUID });
    },
    onLoginAssist(action) {
      if (this.loginAssistAction === action) {
        this.resetLoginAssistAction();
      } else {
        this.loginAssistAction = action;
        this.loginAssistDialog = true;
      }
    },
    resetLoginAssistAction() {
      this.loginAssistAction = "";
      this.loginAssistDialog = false;
    },
    openResetPasswordDialog() {
      this.resetPasswordDialog = true;
    },
    closeResetPasswordDialog() {
      this.resetPasswordDialog = false;
    },
    onActionClick() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.warn("invalid");
      } else if (this.loginAssistAction === "reset-password") {
        this.resetPassword();
      } else if (this.loginAssistAction === "resend-code") {
        this.setAuthMethod({ authMethod: this.authMethod });
        this.setRegisterData({
          registerData: {
            email: this.actionEmail,
            username: this.getActionPhoneNumber,
          },
        });
        this.resendCode();
      }
    },
    resetPassword() {
      this.retrieveResetPasswordToken({
        email: this.email,
        username: `+56${this.username}`,
        authMethod: this.authMethod,
        callback: () => {
          this.isResetPassword = false;
          this.success(
            this.authMethod === "email"
              ? this.$t("views.reset_password.sent.email")
              : this.$t("views.reset_password.sent.sms")
          );
        },
      });
      const data = {
        email: this.email,
        username: `+56${this.username}`,
        authMethod: this.authMethod,
      };
      this.mixPanel("click_send_link_reset_password", data);
    },
    resendCode() {
      this.resendVerificationCode({
        email: this.actionEmail,
        username: this.actionUsername,
        authMethod: this.authMethod,
        callback: this.goToVerifyCode,
      });
    },
    goToVerifyCode() {
      this.$router.push({ name: "User Register", params: { step: 2 } });
    },
    backGuest() {
      this.mixPanel("click_guest_to_explorer");
      this.setWantCreateAccount({ wantCreateAccount: false });
      const newVal = this.rerenderCount + 1;
      this.setRerenderCount({ rerenderCount: newVal });
      this.$router.push({ name: "Explorer", params: { rerender: newVal } });
    },
  },
  watch: {
    preCheckStatus: {
      handler(newVal) {
        if (newVal.found) {
          if (newVal.same_password) {
            if (newVal.has_addresses === false && newVal.has_applicants === false) {
              this.showUserRegister = true;
              this.setLoginLoading({ bool: false });
              this.setLoading(false);
              this.userRegisterStep = 3;
            }
            if (newVal.has_addresses && newVal.has_applicants) {
              this.setLoginLoading({ bool: false });
              this.setLoading(false);
              this.$router.push({ name: 'Explorer' });
            }
            if (newVal.has_addresses && !newVal.has_applicants) {
              this.showUserRegister = true;
              this.setLoginLoading({ bool: false });
              this.setLoading(false);
              this.userRegisterStep = 4;
            }
            if (!newVal.has_addresses && newVal.has_applicants) {
              this.showUserRegister = true;
              this.setLoginLoading({ bool: false });
              this.setLoading(false);
              this.userRegisterStep = 3;
            }
          }
        } else if (newVal.linkAccount) {
          this.showUserRegister = true;
          this.setLoginLoading({ bool: false });
          this.setLoading(false);
          this.userRegisterStep = 6;
        } else {
          if (newVal.linkAccount === false) {
            if (newVal.has_addresses === false && newVal.has_applicants === false) {
              this.showUserRegister = true;
              this.setLoginLoading({ bool: false });
              this.setLoading(false);
              this.userRegisterStep = 3;
            }
            if (newVal.has_addresses && newVal.has_applicants) {
              this.setLoginLoading({ bool: false });
              this.setLoading(false);
              this.$router.push({ name: 'Explorer' });
            }
            if (newVal.has_addresses && !newVal.has_applicants) {
              this.showUserRegister = true;
              this.setLoginLoading({ bool: false });
              this.setLoading(false);
              this.userRegisterStep = 4;
            }
            if (!newVal.has_addresses && newVal.has_applicants) {
              this.showUserRegister = true;
              this.setLoginLoading({ bool: false });
              this.setLoading(false);
              this.userRegisterStep = 3;
            }
          } else {
            this.showUserRegister = true;
            this.setLoginLoading({ bool: false });
            this.setLoading(false);
            this.userRegisterStep = 2;
          }
        }
      },
    },
  },
};

</script>
<style>
.welcome-dialog {
  width: auto !important;
  box-shadow: none;
}
.grecaptcha-badge {
  z-index: 100 !important;
}
</style>
<style scoped>
.captcha {
  justify-content: center;
}
</style>

<template>
  <svg width="23" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 12L11.5 3L2 12" stroke="#6D6E71" stroke-width="3" />
  </svg>
</template>

<script>
export default {
  name: "ArrowUp",

  data() {
    return {};
  },
};
</script>

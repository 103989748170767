<template>
  <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.16935 8.20247C4.56543 8.20247 4.94171 7.93088 5.07043 7.5587L5.47642 6.38181L5.8824 7.5587C6.01113 7.93088 6.3874 8.20247 6.78349 8.20247H7.97173L7.03104 8.91665C6.71417 9.15806 6.58545 9.61071 6.71417 9.99295L7.22908 11.5018L6.03093 10.5663C5.70416 10.3148 5.22887 10.3148 4.9021 10.5663L3.70395 11.5018L4.21886 9.99295C4.34758 9.61071 4.21886 9.15806 3.90199 8.91665L2.9811 8.20247H4.16935ZM1.0106 8.22258L3.01081 9.78171L1.96119 12.8195C1.82256 13.2017 1.981 13.4934 2.17904 13.6343C2.27806 13.7047 2.40678 13.755 2.55532 13.755C2.69394 13.755 2.85238 13.7047 3.01081 13.584L5.45661 11.6828L7.90242 13.584C8.22919 13.8354 8.54605 13.7751 8.74409 13.6343C8.94213 13.4934 9.10056 13.2017 8.96194 12.8094L7.93212 9.77165L9.93234 8.21252C10.2492 7.97111 10.2789 7.63917 10.1997 7.41787C10.1304 7.20664 9.90263 6.96522 9.50655 6.96522H6.96172L6.12005 4.53097C5.99132 4.15879 5.70416 4.02803 5.46652 4.02803C5.23877 4.02803 4.94171 4.15879 4.81298 4.54103L3.98121 6.96522H1.42648C1.0304 6.96522 0.812556 7.20664 0.73334 7.42793C0.664026 7.64923 0.693732 7.98117 1.0106 8.22258ZM12.1603 26.1173C8.95203 26.1173 5.98142 24.8902 3.74356 22.6973C4.70406 22.9186 5.69426 23.0293 6.68447 23.0293C14.0615 23.0293 20.0621 16.9336 20.0621 9.42965C20.0621 7.26699 19.5769 5.1848 18.6362 3.30379C22.1019 5.54692 24.32 9.52018 24.32 13.755C24.3299 20.5749 18.864 26.1173 12.1603 26.1173ZM17.1905 1.16124L15.1705 0.326355L16.4677 2.10678C18.0223 4.2292 18.8442 6.76404 18.8442 9.42965C18.8442 16.2395 13.3881 21.792 6.67456 21.792C5.10014 21.792 3.55542 21.4802 2.08002 20.8666L0.0996094 20.0418L1.37697 21.8121C3.91189 25.3328 7.85291 27.3546 12.1603 27.3546C19.5373 27.3546 25.5479 21.2589 25.5479 13.755C25.538 8.30305 22.1911 3.24343 17.1905 1.16124Z"
      fill="#454444"
    />
  </svg>
</template>

<script>
export default {
  name: "moon",
};
</script>

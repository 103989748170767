<template>
  <svg width="10" height="28" viewBox="0 0 10 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 0V21.9885H9M9 21.9885L7.01749 17.7202M9 21.9885L7.01749 27"
      stroke="#333333"
      stroke-width="0.25"
    />
  </svg>
</template>
<script>
export default {
  name: "LegendArrow",
};
</script>

import api, { feedback } from "../api";

const appUrl = "api/institutions";

export default {
  simulate1({ studentUUID }) {
    return api.get(`${appUrl}/favorite-schools/simulation_data/`, {
      params: { student_uuid: studentUUID },
    });
  },
  simulate(uuid) {
    return feedback.get("/reports/risk/risk", {
      params: {
        applicant_uuid: uuid.studentUUID,
        // year:2020
      },
    });
  },
  simulationSendingInstitutions(data) {
    return feedback.post("/reports/risk/risk_v2", {
      ...data,
    });
  },
};

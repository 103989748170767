import { geoLocatorApi } from "../api";

const geoFromAddress = "/geolocator/fromAddress";
const geoFromPoint = "/geolocator/fromPoint";
const travelTime = "/transport/traveltime/";
const busTravelTime = "/transport/public_traveltime";

export default {
  retrieveTimes(campusCode, campusLat, campusLon, lat, lon) {
    return geoLocatorApi.post("/travel", {
      campus_code: campusCode,
      campus_lat: campusLat,
      campus_lon: campusLon,
      lat,
      lon,
    });
  },

  fromAddress({ geoSearch }) {
    return geoLocatorApi.get(`${geoFromAddress}`, {
      params: {
        VP_cuadrante: geoSearch.VP_cuadrante,
        VP_tipo_via: geoSearch.VP_tipo_via,
        VP_numero: geoSearch.VP_numero,
        VP_letra: geoSearch.VP_letra,
        VP_prefijo: "",
        VG_numero_via: geoSearch.VG_numero_via,
        VG_letra: geoSearch.VG_letra,
        VG_placa: geoSearch.VG_placa,
        VG_cuadrante: geoSearch.VG_cuadrante,
        VG_prefijo: "",
        departamento: "76",
        municipio: "520",
      },
    });
  },
  fromPoint({ geoSearch }) {
    return geoLocatorApi.get(`${geoFromPoint}`, {
      params: {
        lat: geoSearch.lat,
        lon: geoSearch.lon,
      },
    });
  },
  retrieveTravelTimes(data) {
    return geoLocatorApi.post(`${travelTime}`, data);
  },
  retrieveBusTravelTimes(data) {
    return geoLocatorApi.post(`${busTravelTime}`, data);
  },
};

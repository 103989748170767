<template>
  <div class="confirm-applicant">
    <p
      v-if="false"
      class="confirm-applicant__subtitle"
      v-t="'user_register.confirm_applicant.subtitle'"
    />
    <div v-if="oneStudent === null">
      <div v-for="(student, index) in students" :key="index">
        <confirm-student
          v-if="isEditCurrentStudent(student)"
          :student="student"
          :index="index"
          :editStudents="editStudents"
          :editCurrentStudent="editCurrentStudent"
          :studentSet="studentSet"
        />
        <!--<div class="confirm-applicant__student">
          <div class="d-flex justify-space-between">
            <div class="confirm-applicant__student__input-div">
              <h6
                class="confirm-applicant__student__input-div__title"
                v-t="'user_register.confirm_applicant.first_name'"
              />
              <input
                v-model="student.first_name"
                class="confirm-applicant__student__input-div__input"
                type="text"
                :placeholder="$t('user_register.register_data.form.name')"
                @blur="changeFirstName(student.first_name, index)"
              />
            </div>
            <div class="confirm-applicant__student__input-div">
              <h6
                v-t="'user_register.confirm_applicant.last_name'"
                class="confirm-applicant__student__input-div__title"
              />
              <input
                v-model="student.first_lastname"
                class="confirm-applicant__student__input-div__input"
                type="text"
                :placeholder="$t('user_register.register_data.form.name')"
                @blur="changeLastName(student.first_lastname, index)"
              />
            </div>
          </div>
          <button
            v-if="!showGrades"
            v-t="student.grades.length > 0 ? student.grades : 'Select a grade'"
            class="confirm-applicant__student__btn mb-5"
            @click="showGradeList"
          />
          <register-grade
            v-if="showGrades"
            :confirmStudent="true"
            @gradeInfo="setGrades($event, index)"
          />
          <v-select
            class="confirm-applicant__student__select"
            hide-details
            :items="schoolList"
            v-model="student.current_enrollment.name"
            @change="setCurrentSchool(student.current_enrollment.name, index)"
            :placeholder="student.current_enrollment ? '' : 'Select your current school'"
            solo
            dark
            height="31"
            background-color="#293895"
            :menu-props="{ bottom: true, offsetY: true }"
          >
            <template slot="item" slot-scope="data">
              {{ data.item.name }}
            </template>
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
          </v-select>
          <div class="confirm-applicant__student__confirm d-flex">
            <img
              src="@/assets/iconsNewHaven/user_register/icons/delete.svg"
              style="cursor: pointer"
              v-if="!confirmApplicant"
              class="mr-2"
              @click="changeConfirm(index)"
            />
            <div class="d-flex">
              <img
                v-if="confirmApplicantFunc(index)"
                class="mr-2"
                @click="changeConfirm(index)"
                src="@/assets/iconsNewHaven/icons/confirm-on.svg"
                style="cursor: pointer"
              />
              <img
                v-else
                src="@/assets/iconsNewHaven/icons/confirm-off.svg"
                style="cursor: pointer"
                class="mr-2"
                @click="changeConfirm(index)"
              />

              <p
                v-t="'user_register.confirm_location.confirm_text'"
                class="confirm-applicant__student__confirm__text"
              />
              <p v-if="!confirmApplicantFunc(index)">Please fill all fields correctly.</p>
            </div>
          </div>
        </div>-->
      </div>
    </div>
    <div v-if="oneStudent !== null">
      <confirm-student
        :student="oneStudent"
        :editStudents="editStudents"
        :editCurrentStudent="editCurrentStudent"
        :cleanGrades="cleanGrades"
        :studentSet="studentSet"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmStudent from "./ConfirmStudent.vue";

export default {
  name: "ConfirmStudents",
  components: {
    ConfirmStudent,
  },
  data() {
    return {
      grade: null,
      confirmApplicant: false,
      showGrades: false,
      studentsForm: [],
      errors: {},
      finished: {},
    };
  },
  props: {
    editStudents: {
      type: Boolean,
      default: false,
    },
    editCurrentStudent: {
      type: Boolean,
      default: false,
    },
    editStudentInProfile: {
      type: Boolean,
      default: false,
    },
    idStudent: {
      type: Number,
      default: null,
    },
    oneStudent: {
      type: Object,
      default: null,
    },
    cleanGrades: {
      type: Boolean,
      default: false,
    },
    studentSet: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      students: "authentication/students",
      schoolList: "institutions/schoolList",
      currentStudent: "authentication/currentStudent",
      municipalities: "options/municipalities",
    }),
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
  },
  methods: {
    ...mapActions({
      error: "snackbar/error",
      success: "snackbar/success",
    }),
    confirmApplicantFunc(index) {
      return this.finished[index] === true;
    },
    isEditCurrentStudent({ id }) {
      const isEditStudentInProfile = this.editStudentInProfile && this.idStudent === id;

      if (this.editCurrentStudent) {
        if (this.currentStudent.id === id) {
          return true;
        }
        return false;
      }

      if (isEditStudentInProfile) {
        return true;
      }
      return false;
    },
    backMethod() {
      this.$emit("backStep");
    },
    hasErrors(index) {
      return this.errors[index] === true;
    },
    confirmApplicantStudent(index, data, confirmed) {
      if (confirmed) {
        this.getOrSetStudent(index, data);
        this.finished[index] = true;
      } else {
        this.finished[index] = false;
      }
      this.$emit("setStudents", this.studentsForm, true);
    },

    showGradeList() {
      if (!this.showGrades) {
        this.showGrades = true;
      }
      this.showGrades = false;
    },
    changeFirstName(firstName, index) {
      this.getOrSetStudent(index, { firstName });
    },
    changeLastName(lastName, index) {
      this.getOrSetStudent(index, { lastName });
    },
    setGrades(gradeIds, index) {
      this.getOrSetStudent(index, { gradeId: gradeIds[0] });
    },
    setCurrentSchool(item, index) {
      let id;
      this.schoolList.forEach((school) => {
        if (school.name === item) {
          id = school.id;
        }
      });
      this.getOrSetStudent(index, { currentSchool: id });
    },
    getOrSetStudent(index, items) {
      let found = false;
      for (let i = 0; i < this.studentsForm.length; i += 1) {
        if (this.studentsForm[i].index === index) {
          found = true;
          Object.assign(this.studentsForm[i], items);
        }
      }
      if (!found) {
        this.studentsForm.push({ index, ...items });
      }
    },
  },
};
</script>
<style>
.v-application .primary--text {
  font-size: 10px;
}
.theme--light.v-list {
  background: #eef5ff;
  color: #2b5bff;
  font-size: 10px;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #1976d2;
}
</style>

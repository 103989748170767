<template>
  <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.6799 25.94L19.6699 27.47C18.4199 28.11 17.9399 28.89 18.3799 29.76C18.8299 30.64 19.6799 30.75 20.9999 30.08L24.0999 28.5L25.2299 30.72L22.2699 32.23C19.7199 33.53 17.7799 32.9 16.6099 30.58C15.4699 28.32 16.0899 26.49 18.7399 25.15L21.5699 23.71L22.6799 25.94Z"
      fill="#424241"
    />
    <path
      d="M24.034 22.7019L22.8191 23.3349L23.7941 25.2061L25.009 24.573L24.034 22.7019Z"
      fill="#424241"
    />
    <path
      d="M26.2237 26.8092L25.0088 27.4423L25.9838 29.3134L27.1987 28.6804L26.2237 26.8092Z"
      fill="#424241"
    />
    <path
      d="M31.5697 22.18C31.4197 22.29 30.8197 22.38 31.1697 22.54C31.6497 22.76 32.0797 23.52 32.3797 24.2C32.5697 24.24 32.7497 24.29 32.8897 24.35C32.4897 23.44 31.6897 22.1 31.5697 22.18Z"
      fill="#424241"
    />
    <path
      d="M37.4698 26.49C37.3698 25.62 37.0298 24.9 36.4498 24.55C35.8298 24.17 35.2898 24.1 34.8298 24.15C34.0198 24.24 33.4498 24.78 33.3698 24.86L33.2698 24.97L33.1398 24.89C33.0398 24.83 32.3698 24.44 31.5598 24.54C31.0998 24.6 30.5898 24.8 30.0798 25.31C29.5898 25.8 29.4398 26.57 29.5398 27.44C29.6698 28.5 30.1798 29.69 30.8398 30.57C31.4998 31.46 32.3198 32.01 32.9698 31.92C33.0398 31.91 33.1098 31.9 33.1698 31.88C33.2198 31.85 33.3598 31.78 33.5398 31.76C33.6198 31.75 33.6998 31.75 33.7798 31.77C33.7898 31.77 33.7898 31.77 33.8098 31.77H33.8198H33.8298C33.8698 31.78 33.9698 31.79 34.0798 31.78C34.1798 31.77 34.2598 31.74 34.3098 31.72H34.3198H34.3298C34.3398 31.71 34.3598 31.71 34.3698 31.7C34.4598 31.65 34.5398 31.63 34.6198 31.62C34.7898 31.6 34.9298 31.64 34.9898 31.66C35.0598 31.66 35.1298 31.66 35.1898 31.65C35.8498 31.58 36.5098 30.85 36.9398 29.83C37.3798 28.83 37.5998 27.55 37.4698 26.49ZM31.2598 25.83C30.9298 25.93 30.7598 26.13 30.6398 26.34C30.5298 26.55 30.4998 26.77 30.4898 26.85C30.4898 26.87 30.4898 26.88 30.4898 26.88C30.4898 26.97 30.4198 27.05 30.3298 27.06C30.3198 27.06 30.3098 27.06 30.2998 27.06C30.1998 27.06 30.1198 26.97 30.1198 26.87C30.1198 26.86 30.1298 26.59 30.2598 26.28C30.3898 25.97 30.6598 25.61 31.1598 25.47C31.2598 25.44 31.3598 25.5 31.3898 25.6C31.4198 25.7 31.3598 25.8 31.2598 25.83Z"
      fill="#424241"
    />
    <path
      d="M35.1198 22.88C35.1998 21.89 35.8598 21.95 35.8598 21.95C35.8598 21.95 32.6298 20.68 33.0898 23.95C33.0898 23.95 33.5698 24.05 34.0598 23.96C34.2098 23.93 34.3698 23.89 34.5198 23.82C34.8398 23.66 35.0798 23.38 35.1198 22.88Z"
      fill="#424241"
    />
    <path
      d="M1.73993 32.07L7.26993 29.22L1.29993 27.47L1.17993 26.26L8.21993 25.59L8.30993 26.52L5.56993 26.78L2.59993 26.97L8.51993 28.74L8.58993 29.45L3.12993 32.3L6.06993 31.93L8.80993 31.67L8.89993 32.6L1.85993 33.27L1.73993 32.07Z"
      fill="#454444"
    />
    <path
      d="M9.33992 21.54C9.22992 21.57 9.03992 21.56 8.76992 21.53C9.08992 22.04 9.17992 22.58 9.02992 23.14C8.89992 23.64 8.65992 24.02 8.28992 24.27C7.91992 24.52 7.51992 24.59 7.08992 24.48C6.55992 24.35 6.19992 24.04 6.00992 23.56C5.81992 23.08 5.80992 22.48 5.99992 21.75L6.20992 20.9L5.80992 20.8C5.50992 20.72 5.23992 20.75 5.00992 20.89C4.77992 21.03 4.62992 21.27 4.53992 21.63C4.45992 21.94 4.46992 22.22 4.57992 22.47C4.67992 22.72 4.84992 22.87 5.06992 22.93L4.84992 23.8C4.59992 23.74 4.36992 23.58 4.17992 23.34C3.98992 23.1 3.85992 22.81 3.79992 22.46C3.73992 22.12 3.75992 21.76 3.84992 21.39C3.99992 20.8 4.25992 20.38 4.63992 20.12C5.01992 19.86 5.46992 19.79 5.97992 19.91L8.32992 20.5C8.79992 20.62 9.17992 20.65 9.48992 20.6L9.56992 20.62L9.33992 21.54ZM8.30992 22.82C8.37992 22.55 8.36992 22.27 8.28992 21.99C8.20992 21.71 8.06992 21.49 7.86992 21.32L6.82992 21.06L6.65992 21.74C6.38992 22.81 6.56992 23.42 7.18992 23.58C7.45992 23.65 7.69992 23.61 7.89992 23.47C8.09992 23.32 8.23992 23.11 8.30992 22.82Z"
      fill="#454444"
    />
    <path
      d="M7.44005 18.79C6.73005 18.38 6.25005 17.9 6.02005 17.33C5.79005 16.76 5.82005 16.21 6.13005 15.66C6.45005 15.1 6.89005 14.78 7.46005 14.69L6.97005 14.36L7.38005 13.65L11.84 16.19C12.43 16.53 12.8 16.97 12.94 17.51C13.08 18.05 12.98 18.62 12.65 19.21C12.46 19.54 12.21 19.82 11.89 20.06C11.57 20.3 11.24 20.43 10.9 20.45L10.66 19.78C11.26 19.68 11.7 19.39 11.98 18.9C12.2 18.52 12.26 18.16 12.16 17.83C12.06 17.5 11.83 17.22 11.44 17L11.05 16.78C11.23 17.3 11.17 17.83 10.86 18.37C10.56 18.91 10.09 19.22 9.48005 19.3C8.86005 19.38 8.18005 19.21 7.44005 18.79ZM7.97005 18.06C8.49005 18.35 8.95005 18.48 9.36005 18.43C9.77005 18.38 10.09 18.17 10.31 17.79C10.59 17.3 10.57 16.81 10.26 16.32L8.17005 15.13C7.60005 15.12 7.17005 15.36 6.90005 15.83C6.68005 16.21 6.66005 16.59 6.84005 16.97C7.01005 17.37 7.39005 17.73 7.97005 18.06Z"
      fill="#454444"
    />
    <path
      d="M9.41981 11.2L9.90981 11.65C9.83981 11.01 10.0298 10.46 10.4898 10C11.2798 9.22 12.1198 9.27 13.0098 10.15L15.4598 12.61L14.8198 13.24L12.3698 10.77C12.0998 10.5 11.8398 10.37 11.5898 10.36C11.3398 10.35 11.0898 10.47 10.8398 10.73C10.6298 10.94 10.5098 11.17 10.4598 11.44C10.4098 11.71 10.4298 11.97 10.5298 12.24L13.1698 14.9L12.5298 15.53L8.80981 11.8L9.41981 11.2Z"
      fill="#454444"
    />
    <path
      d="M18.12 11.08C17.5 11.43 16.88 11.52 16.26 11.33C15.64 11.15 15.14 10.71 14.75 10.03L14.66 9.9C14.4 9.45 14.26 8.99 14.23 8.54C14.2 8.09 14.28 7.67 14.48 7.29C14.68 6.91 14.95 6.62 15.32 6.41C15.9 6.06 16.47 6 17.03 6.2C17.59 6.4 18.07 6.87 18.49 7.6L18.68 7.92L15.59 9.69C15.86 10.13 16.2 10.42 16.61 10.55C17.02 10.68 17.42 10.64 17.81 10.42C18.09 10.26 18.29 10.07 18.41 9.85C18.53 9.63 18.62 9.38 18.66 9.12L19.35 9.22C19.29 10.02 18.88 10.64 18.12 11.08ZM15.68 7.04C15.37 7.22 15.17 7.49 15.09 7.84C15 8.19 15.05 8.59 15.24 9.03L17.52 7.72L17.49 7.66C17.24 7.28 16.96 7.03 16.64 6.92C16.32 6.82 16 6.86 15.68 7.04Z"
      fill="#454444"
    />
    <path
      d="M19.6901 3.24L20.08 4.45L21.01 4.15L21.22 4.81L20.2901 5.11L21.2901 8.22C21.3601 8.42 21.45 8.56 21.56 8.63C21.67 8.7 21.8301 8.71 22.0301 8.64C22.1301 8.61 22.26 8.55 22.42 8.45L22.64 9.14C22.43 9.27 22.22 9.37 22.01 9.44C21.63 9.56 21.3001 9.54 21.0301 9.37C20.7601 9.2 20.56 8.9 20.42 8.48L19.42 5.38L18.51 5.67L18.3 5.01L19.21 4.72L18.82 3.51L19.6901 3.24Z"
      fill="#454444"
    />
    <path
      d="M25.32 6.67C25.33 6.33 25.44 6.02 25.64 5.74C25.84 5.46 26.21 5.14 26.76 4.79C26.46 4.4 26.26 4.08 26.16 3.84C26.06 3.6 26.02 3.37 26.03 3.14C26.05 2.6 26.22 2.19 26.55 1.9C26.88 1.61 27.31 1.47 27.86 1.49C28.35 1.51 28.74 1.66 29.05 1.96C29.36 2.26 29.5 2.62 29.49 3.05C29.48 3.34 29.4 3.59 29.24 3.83C29.09 4.07 28.83 4.31 28.46 4.56L27.93 4.92L29.43 6.86C29.67 6.45 29.79 5.98 29.81 5.47L30.62 5.5C30.59 6.33 30.36 7 29.95 7.53L30.91 8.78L29.83 8.74L29.38 8.16C29.13 8.37 28.85 8.52 28.52 8.62C28.19 8.72 27.86 8.76 27.53 8.75C26.84 8.72 26.29 8.52 25.89 8.14C25.49 7.76 25.3 7.26 25.32 6.67ZM27.56 8.02C28.04 8.04 28.48 7.87 28.89 7.53L27.24 5.4L27.08 5.5C26.52 5.88 26.24 6.28 26.22 6.7C26.21 7.08 26.32 7.39 26.56 7.63C26.8 7.87 27.13 8 27.56 8.02ZM26.94 3.14C26.93 3.45 27.11 3.85 27.47 4.33L28.06 3.95C28.29 3.8 28.44 3.66 28.53 3.52C28.62 3.38 28.66 3.21 28.67 3.01C28.68 2.79 28.6 2.61 28.45 2.46C28.3 2.31 28.09 2.23 27.85 2.22C27.58 2.21 27.37 2.29 27.21 2.47C27.03 2.64 26.95 2.87 26.94 3.14Z"
      fill="#454444"
    />
    <path
      d="M39.08 9.93C38.66 10.56 38.16 10.95 37.56 11.1C36.96 11.25 36.32 11.15 35.63 10.8C34.88 10.42 34.42 9.85 34.25 9.09C34.06 8.32 34.2 7.5 34.66 6.6L34.97 5.99C35.27 5.4 35.64 4.94 36.07 4.6C36.5 4.26 36.98 4.07 37.48 4.03C37.98 3.99 38.49 4.09 39 4.35C39.67 4.69 40.11 5.15 40.33 5.73C40.54 6.31 40.52 6.95 40.25 7.64L39.4 7.22C39.58 6.68 39.61 6.23 39.49 5.89C39.37 5.55 39.08 5.26 38.65 5.04C38.12 4.77 37.6 4.75 37.1 4.99C36.6 5.23 36.16 5.71 35.79 6.44L35.48 7.05C35.13 7.73 35 8.35 35.08 8.9C35.16 9.45 35.46 9.86 35.97 10.12C36.43 10.36 36.84 10.43 37.19 10.35C37.54 10.27 37.89 9.99 38.24 9.51L39.08 9.93Z"
      fill="#454444"
    />
    <path
      d="M42.35 9.65C42.98 9.57 43.52 9.75 43.98 10.19C44.78 10.96 44.76 11.8 43.9 12.71L41.5 15.22L40.85 14.6L43.25 12.08C43.51 11.8 43.64 11.54 43.64 11.29C43.64 11.04 43.51 10.79 43.25 10.54C43.04 10.34 42.8 10.22 42.53 10.18C42.26 10.14 42 10.17 41.74 10.27L39.16 13L38.51 12.38L43.66 6.98L44.31 7.6L42.35 9.65Z"
      fill="#454444"
    />
    <path
      d="M43.7298 18.7C43.7898 18.6 43.9298 18.47 44.1398 18.3C43.5498 18.17 43.1098 17.85 42.8098 17.36C42.5398 16.92 42.4498 16.48 42.5298 16.04C42.6098 15.6 42.8398 15.27 43.2298 15.04C43.6998 14.76 44.1698 14.71 44.6398 14.91C45.1098 15.11 45.5498 15.53 45.9398 16.18L46.3898 16.93L46.7398 16.72C47.0098 16.56 47.1698 16.35 47.2398 16.09C47.2998 15.83 47.2398 15.55 47.0498 15.23C46.8798 14.96 46.6698 14.77 46.4198 14.67C46.1698 14.57 45.9498 14.58 45.7498 14.7L45.2798 13.93C45.4998 13.79 45.7698 13.74 46.0798 13.77C46.3898 13.8 46.6798 13.92 46.9698 14.12C47.2598 14.32 47.4998 14.58 47.6998 14.91C48.0098 15.43 48.1298 15.91 48.0498 16.36C47.9698 16.81 47.6998 17.18 47.2598 17.46L45.1898 18.71C44.7798 18.96 44.4798 19.21 44.2998 19.46L44.2298 19.5L43.7298 18.7ZM43.5498 17.07C43.6998 17.31 43.8998 17.5 44.1498 17.64C44.3998 17.78 44.6598 17.84 44.9198 17.81L45.8398 17.25L45.4798 16.65C44.9098 15.71 44.3498 15.4 43.7998 15.74C43.5598 15.89 43.4198 16.08 43.3798 16.32C43.3398 16.57 43.3898 16.82 43.5498 17.07Z"
      fill="#454444"
    />
    <path
      d="M49.5 21.32C49.48 21.18 49.44 21.04 49.39 20.89C49.2 20.33 48.83 20.03 48.29 20L44.76 21.2L44.47 20.35L49.45 18.66L49.73 19.49L49.16 19.7C49.7 19.83 50.06 20.15 50.23 20.66C50.29 20.83 50.31 20.96 50.29 21.06L49.5 21.32Z"
      fill="#454444"
    />
    <path
      d="M52.0099 23.39L50.7499 23.6L50.9099 24.57L50.2199 24.69L50.0599 23.72L46.8399 24.26C46.6299 24.3 46.4799 24.36 46.3899 24.47C46.2999 24.58 46.2699 24.73 46.3099 24.94C46.3299 25.04 46.3699 25.18 46.4399 25.35L45.7299 25.47C45.6299 25.25 45.5599 25.03 45.5199 24.81C45.4499 24.41 45.5199 24.1 45.7299 23.85C45.9399 23.6 46.2599 23.45 46.6999 23.38L49.9099 22.84L49.7499 21.9L50.4299 21.79L50.5899 22.73L51.8499 22.52L52.0099 23.39Z"
      fill="#454444"
    />
    <path
      d="M45.6599 28.31C45.6899 27.6 45.9499 27.03 46.4299 26.6C46.9199 26.17 47.5499 25.98 48.3299 26.01L48.4999 26.02C49.0199 26.04 49.4799 26.16 49.8799 26.38C50.2799 26.6 50.5899 26.89 50.8099 27.25C51.0299 27.62 51.1299 28.01 51.1099 28.43C51.0799 29.11 50.8399 29.64 50.3699 29.99C49.9099 30.35 49.2499 30.51 48.4099 30.48L48.0399 30.46L48.1899 26.9C47.6699 26.89 47.2499 27.03 46.9199 27.3C46.5899 27.58 46.4199 27.94 46.3999 28.38C46.3899 28.7 46.4399 28.97 46.5599 29.19C46.6799 29.42 46.8399 29.62 47.0499 29.79L46.5999 30.32C45.9399 29.86 45.6299 29.19 45.6599 28.31ZM50.3799 28.39C50.3899 28.03 50.2799 27.72 50.0199 27.46C49.7599 27.2 49.3999 27.04 48.9299 26.96L48.8199 29.59H48.8899C49.3499 29.58 49.7099 29.47 49.9699 29.26C50.2299 29.05 50.3599 28.76 50.3799 28.39Z"
      fill="#454444"
    />
    <path
      d="M49.13 34.56C49.19 34.43 49.23 34.29 49.27 34.14C49.42 33.57 49.27 33.12 48.84 32.79L45.23 31.86L45.45 30.99L50.54 32.3L50.32 33.15L49.73 33.01C50.11 33.41 50.23 33.88 50.1 34.4C50.06 34.57 50 34.69 49.93 34.77L49.13 34.56Z"
      fill="#454444"
    />
    <path
      d="M13.31 44.13C12.84 43.45 12.57 42.86 12.5 42.37C12.43 41.88 12.55 41.44 12.86 41.06C13.21 40.63 13.68 40.41 14.25 40.41C14.83 40.41 15.39 40.64 15.94 41.09C16.32 41.4 16.59 41.75 16.77 42.13C16.95 42.52 17.01 42.9 16.96 43.29C16.91 43.68 16.76 44.02 16.52 44.33L15.79 43.74C16.06 43.41 16.17 43.06 16.12 42.7C16.07 42.34 15.85 42 15.46 41.69C15.1 41.4 14.76 41.25 14.43 41.24C14.1 41.23 13.82 41.37 13.6 41.65C13.42 41.87 13.36 42.14 13.42 42.45C13.48 42.76 13.69 43.16 14.04 43.66C14.39 44.16 14.63 44.59 14.76 44.96C14.89 45.32 14.93 45.66 14.89 45.97C14.85 46.28 14.71 46.57 14.48 46.85C14.12 47.29 13.65 47.51 13.09 47.49C12.53 47.47 11.95 47.23 11.38 46.75C11 46.44 10.71 46.08 10.5 45.67C10.29 45.26 10.2 44.86 10.23 44.46C10.26 44.06 10.4 43.71 10.66 43.39L11.39 43.98C11.12 44.31 11.03 44.67 11.11 45.06C11.19 45.45 11.44 45.81 11.85 46.15C12.23 46.46 12.59 46.62 12.92 46.63C13.25 46.64 13.52 46.51 13.74 46.24C13.96 45.97 14.04 45.69 13.97 45.39C13.91 45.1 13.68 44.68 13.31 44.13Z"
      fill="#454444"
    />
    <path
      d="M17.57 49.65C17.86 49.78 18.16 49.8 18.46 49.72C18.76 49.64 18.98 49.47 19.12 49.21L19.9 49.55C19.76 49.82 19.55 50.04 19.27 50.21C18.99 50.38 18.67 50.48 18.31 50.5C17.95 50.52 17.61 50.46 17.28 50.32C16.62 50.03 16.2 49.59 16 48.98C15.8 48.37 15.87 47.69 16.21 46.93L16.27 46.79C16.48 46.32 16.74 45.94 17.07 45.65C17.4 45.36 17.77 45.19 18.18 45.12C18.59 45.06 19.01 45.12 19.45 45.32C19.99 45.56 20.37 45.91 20.58 46.39C20.8 46.87 20.8 47.37 20.6 47.9L19.82 47.56C19.93 47.24 19.93 46.93 19.8 46.64C19.68 46.35 19.46 46.13 19.15 46C18.73 45.82 18.34 45.83 17.98 46.03C17.62 46.23 17.31 46.62 17.06 47.19L16.99 47.35C16.75 47.9 16.67 48.38 16.77 48.78C16.89 49.17 17.15 49.46 17.57 49.65Z"
      fill="#454444"
    />
    <path
      d="M22.9799 47.23C23.4499 46.8 23.9899 46.64 24.6299 46.73C25.7299 46.89 26.1899 47.6 26.0199 48.84L25.5099 52.28L24.6199 52.15L25.1299 48.71C25.1799 48.33 25.1399 48.04 24.9999 47.84C24.8599 47.64 24.6099 47.51 24.2499 47.45C23.9599 47.41 23.6999 47.45 23.4599 47.57C23.2199 47.69 23.0199 47.87 22.8599 48.1L22.2999 51.8L21.4099 51.67L22.4999 44.29L23.3899 44.42L22.9799 47.23Z"
      fill="#454444"
    />
    <path
      d="M27.5599 49.68C27.4999 49.17 27.5399 48.7 27.6899 48.26C27.8399 47.83 28.0799 47.48 28.4099 47.21C28.7399 46.94 29.1399 46.78 29.5999 46.72C30.3099 46.63 30.9199 46.8 31.4199 47.24C31.9199 47.68 32.2199 48.3 32.3199 49.12L32.3299 49.18C32.3899 49.69 32.3499 50.16 32.2099 50.58C32.0699 51.01 31.8299 51.36 31.4899 51.63C31.1599 51.9 30.7599 52.07 30.2899 52.12C29.5799 52.21 28.9799 52.04 28.4799 51.6C27.9799 51.16 27.6799 50.54 27.5799 49.73L27.5599 49.68ZM28.4699 49.68C28.5399 50.26 28.7399 50.71 29.0499 51.02C29.3599 51.33 29.7399 51.47 30.1899 51.41C30.6399 51.35 30.9799 51.13 31.1999 50.74C31.4199 50.35 31.4899 49.84 31.4099 49.2C31.3399 48.63 31.1399 48.18 30.8299 47.86C30.5099 47.54 30.1299 47.41 29.6799 47.46C29.2399 47.52 28.9099 47.74 28.6799 48.12C28.4599 48.5 28.3899 49.02 28.4699 49.68Z"
      fill="#454444"
    />
    <path
      d="M33.72 48.7C33.52 48.23 33.43 47.76 33.45 47.3C33.47 46.84 33.61 46.44 33.85 46.09C34.09 45.74 34.43 45.48 34.85 45.29C35.51 45.01 36.14 45.01 36.74 45.29C37.34 45.57 37.81 46.09 38.13 46.84L38.15 46.9C38.35 47.37 38.44 47.83 38.42 48.28C38.4 48.73 38.27 49.13 38.02 49.48C37.77 49.83 37.44 50.1 37 50.29C36.35 50.57 35.72 50.57 35.12 50.29C34.52 50.01 34.06 49.49 33.73 48.74L33.72 48.7ZM34.59 48.45C34.82 48.99 35.13 49.36 35.52 49.58C35.91 49.8 36.31 49.82 36.73 49.64C37.15 49.46 37.41 49.15 37.52 48.72C37.63 48.28 37.55 47.77 37.3 47.18C37.07 46.65 36.76 46.28 36.37 46.06C35.98 45.84 35.57 45.82 35.16 46C34.75 46.18 34.49 46.48 34.38 46.91C34.27 47.34 34.33 47.84 34.59 48.45Z"
      fill="#454444"
    />
    <path
      d="M41.6699 47.56L40.9299 48.07L36.6799 41.93L37.4199 41.42L41.6699 47.56Z"
      fill="#454444"
    />
  </svg>
</template>

<script>
export default {
  name: "MagnetCharterSchool",
};
</script>

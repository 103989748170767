<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M0.125 6.5C0.125 2.9799 2.97991 0.125 6.5 0.125C10.0201 0.125 12.875 2.97991 12.875 6.5C12.875 10.0201 10.0201 12.875 6.5 12.875C2.9799 12.875 0.125 10.0201 0.125 6.5ZM7.47283 6.5C7.47283 5.95053 7.04947 5.52717 6.5 5.52717C5.95053 5.52717 5.52717 5.95053 5.52717 6.5C5.52717 7.04947 5.95053 7.47283 6.5 7.47283C7.04947 7.47283 7.47283 7.04947 7.47283 6.5Z"
        fill="white"
        stroke="#333333"
        stroke-width="0.25"
      />
      <path
        d="M3.10899 6.49999C3.10899 4.6274 4.62771 3.10869 6.5003 3.10869C8.37289 3.10869 9.8916 4.6274 9.8916 6.49999C9.8916 8.37258 8.37289 9.8913 6.5003 9.8913C4.62771 9.8913 3.10899 8.37258 3.10899 6.49999ZM6.94264 6.49999C6.94264 6.24933 6.75096 6.05765 6.5003 6.05765C6.24964 6.05765 6.05795 6.24933 6.05795 6.49999C6.05795 6.75065 6.24964 6.94234 6.5003 6.94234C6.75096 6.94234 6.94264 6.75065 6.94264 6.49999Z"
        fill="#0F69B4"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="13" height="13" fill="white" transform="translate(13) rotate(90)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "PrioritySibling",
};
</script>

import api, { userApi } from "../api";

const registerUrl = "registration";
const guestRegister = "/token/guest/save_user/";
const tokenUrl = "token";
const authUrl = "authentication";
export default {
  /**
   * @response {
   *  name: String,
   *  token: String,
   *  username: String,
   *  register_complete: Boolean
   *  ...
   * }
   */
  loginWithUUID({ uuid }) {
    return userApi.get(`${tokenUrl}/fast_login/${uuid}/`);
  },
  /**
   * @response {
   *  name: String,
   *  token: String,
   *  username: String,
   *  register_complete: Boolean
   *  ...
   * }
   */
  login({ email, password, username, captcha }) {
    return userApi.post(`${authUrl}/login/`, {
      email,
      password,
      username,
      captcha,
    });
  },
  /**
   * Call to API to logout current user
   * @returns {Promise<AxiosResponse<any>>}
   */
  logout() {
    return userApi.post(`${authUrl}/logout/`);
  },

  retrieveUserDetails() {
    return userApi.get("/authentication/user/");
  },
  /**
   * Retrieve the student list of the logged legal guardian
   * @returns {Promise<AxiosResponse<any>>}
   */
  retrieveStudents() {
    return api.get(`${registerUrl}/applicant/`);
  },
  /**
   * Register a new user
   * @param username
   * @param email
   * @param password1
   * @param password2
   * @returns {Promise<AxiosResponse<any>>}
   */
  register({
    // eslint-disable-next-line
    username,
    email,
    password1,
    password2,
    token,
  }) {
    if (username) {
      return userApi.post(`${guestRegister}/`, {
        username,
        password1,
        password2,
        token,
      });
    }
    return userApi.post(`${guestRegister}/`, {
      email,
      password1,
      password2,
      token,
    });
  },
  /**
   * Checks if the code is valid
   * @param email
   * @param username
   * @param code
   * @param authMethod
   * @returns {Promise<AxiosResponse<any>>}
   */
  verifyCode({ email, username, code, authMethod }) {
    if (authMethod === "email") {
      return userApi.post(`${registerUrl}/verify_code/`, {
        code,
        email,
        username,
      });
    }
    return userApi.post(`${registerUrl}/verify_code/`, {
      code,
      email: "",
      username: email,
    });
  },
  /**
   * Checks if the code is valid
   * @param username
   * @returns {Promise<AxiosResponse<any>>}
   */
  verifyAccount({ username }) {
    return userApi.post(`/prechecks/check_user/`, {
      username,
    });
  },

  resendVerificationCode({ email, username, authMethod }) {
    if (authMethod === "email") {
      return userApi.post(`${registerUrl}/resend_verification_code/`, {
        email,
      });
    }
    return userApi.post(`${registerUrl}/resend_verification_code/`, {
      username,
    });
  },
  /**
   * Send email for password's reset
   * @param email
   * @returns {Promise<AxiosResponse<any>>}
   */
  resetPasswordToken({ email, username }) {
    if (email) {
      return userApi.post(`${authUrl}/password_reset/`, { email });
    }
    return api.post(`${registerUrl}/phone_number/password_reset/`, { username });
  },
  /**
   * Confirms token and reset password
   * @param token
   * @param password
   * @returns {Promise<AxiosResponse<any>>}
   */
  resetPassword({ token, password }) {
    return userApi.post(`${authUrl}/password_reset/confirm/`, {
      token,
      password,
    });
  },
  /**
   * Delete student in database
   * @param uuid
   * @returns {Promise<AxiosResponse<any>>}
   */
  deleteStudent({ uuid }) {
    return api.delete(`${registerUrl}/applicant/${uuid}/`);
  },
  createLegalGuardian() {
    return api.post(`${registerUrl}/legal_guardian/step_0/`);
  },
  updateGuest(data, uuid) {
    return api.put(`${registerUrl}/guest/${uuid}/`, data);
  },
  changePassword({ password1, password2 }) {
    return api.post(`${registerUrl}/password/change/`, {
      new_password1: password1,
      new_password2: password2,
    });
  },
  validateDate(year, month, day) {
    return api.get(`${registerUrl}/validate_date/?year=${year}&month=${month}&day=${day}`);
  },
  refreshToken({ token }) {
    return userApi.post(`/token/refresh/`, {
      token,
    });
  },
  fakeToken() {
    return userApi.get(`/token/guest/generate/`);
  },
  googleAuth({ authResponse, guestToken }) {
    return userApi.post(`/socials/google/`, {
      access_token: authResponse.access_token,
      id_token: authResponse.id_token,
      guest_token: guestToken,
    });
  },
  linkAccounts({ gauth }) {
    return userApi.post(`/socials/google/connect/`, {
      access_token: gauth.access_token,
      id_token: gauth.id_token,
    });
  },
};

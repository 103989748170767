<template>
  <svg width="39" height="20" viewBox="0 0 39 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.876 12.6733C21.1916 14.8071 18.1238 16.2495 16.659 16.7501C13.4352 17.8528 10.5356 18.0197 7.92409 17.5428C6.1651 17.823 4.70627 18.5561 3.39754 19.5217C3.26546 19.617 3.08536 19.5217 3.08536 19.3607L3.1634 15.8144C1.19429 14.4674 0.383836 12.0773 0.870111 8.50711C1.53649 4.10249 4.12995 1.4323 8.85461 0.669386C10.3254 0.430976 11.8263 0.419056 13.3031 0.609784C13.6093 0.645545 13.9155 0.687267 14.2216 0.728989C19.9369 1.50382 23.6289 7.2197 21.876 12.6733Z"
      fill="#939598"
    />
    <path
      d="M17.7655 12.6737C18.4499 14.8074 21.5176 16.2498 22.9825 16.7505C26.2063 17.8531 29.1059 18.02 31.7174 17.5432C33.4764 17.8233 34.9352 18.5564 36.2439 19.522C36.376 19.6174 36.5561 19.522 36.5561 19.3611L36.4781 15.8147C38.4472 14.4677 39.2576 12.0776 38.7713 8.50745C38.099 4.10283 35.5055 1.43263 30.7868 0.669722C29.316 0.431312 27.8152 0.419392 26.3383 0.61012C26.0322 0.645881 25.726 0.687603 25.4198 0.729324C19.6986 1.50416 16.0065 7.22004 17.7655 12.6737Z"
      fill="#6D6E71"
    />
    <path
      d="M2.9552 7.05914C2.9492 6.84457 3.16532 6.72536 3.39945 6.7194L4.93632 6.66576C5.76478 6.63596 6.54522 6.88033 6.58124 8.00086C6.60526 8.65052 6.36512 9.08562 5.95089 9.28231C6.44317 9.45516 6.78536 9.75317 6.81538 10.5399L6.82138 10.7068C6.8634 11.9406 6.18502 12.4055 5.29052 12.4353L3.60357 12.4949C3.32141 12.5008 3.14131 12.3578 3.1353 12.1909L2.9552 7.05914ZM4.04782 8.94258L4.93632 8.91278C5.36856 8.90085 5.56067 8.61476 5.54866 8.20947C5.53666 7.79821 5.28451 7.55384 4.88229 7.56576L3.99979 7.59556L4.04782 8.94258ZM5.03838 11.5412C5.52465 11.5234 5.80081 11.2552 5.77679 10.6532L5.77079 10.528C5.74677 9.9141 5.45261 9.69953 4.96633 9.71741L4.06583 9.74721L4.13186 11.571L5.03838 11.5412Z"
      fill="white"
    />
    <path
      d="M7.38487 7.00552C7.37887 6.78499 7.63101 6.68366 7.88916 6.67174C8.1413 6.66578 8.41145 6.74923 8.41745 6.96976L8.59755 12.1015C8.60356 12.3161 8.34541 12.4353 8.09327 12.4413C7.82912 12.4472 7.57098 12.3459 7.56497 12.1373L7.38487 7.00552Z"
      fill="white"
    />
    <path
      d="M9.97855 12.483C9.75042 12.4889 9.52229 12.3876 9.51629 12.173L9.33019 7.0353C9.32418 6.81477 9.57032 6.70749 9.83447 6.70153C10.0926 6.69557 10.3568 6.77901 10.3628 6.99954L10.5249 11.5591L12.464 11.4936C12.6741 11.4876 12.7821 11.7022 12.7881 11.9346C12.7941 12.1552 12.6981 12.3876 12.494 12.3936L9.97855 12.483Z"
      fill="white"
    />
    <path
      d="M13.1427 7.00552C13.1367 6.78499 13.3888 6.68366 13.647 6.67174C13.8991 6.66578 14.1693 6.74923 14.1753 6.96976L14.3554 12.1015C14.3614 12.3161 14.1032 12.4353 13.8511 12.4413C13.5869 12.4472 13.3288 12.3459 13.3228 12.1373L13.1427 7.00552Z"
      fill="white"
    />
    <path
      d="M18.0175 12.0956L16.1925 8.97835L16.3065 12.1552C16.3125 12.3697 16.0544 12.4889 15.8023 12.4949C15.5381 12.5068 15.28 12.3995 15.274 12.1909L15.0939 7.06511C15.0879 6.84458 15.334 6.73134 15.5981 6.72538C15.9704 6.71346 16.1264 6.78498 16.3906 7.24392L18.0295 10.1406L17.9154 6.96379C17.9094 6.74326 18.1616 6.64193 18.4197 6.63001C18.6779 6.61809 18.942 6.7075 18.948 6.92803L19.1281 12.0598C19.1341 12.2744 18.876 12.3936 18.6238 12.3995C18.3777 12.4055 18.1616 12.334 18.0175 12.0956Z"
      fill="white"
    />
    <path
      d="M21.6908 7.57171C21.1565 7.58959 20.8623 7.89357 20.8863 8.50747L20.9644 10.7128C20.9884 11.3267 21.3006 11.6128 21.8229 11.5949C22.3392 11.577 22.6453 11.273 22.6273 10.6591L22.6093 10.1942L21.9669 10.2181C21.7628 10.224 21.6428 10.0333 21.6368 9.82469C21.6308 9.628 21.7328 9.41939 21.9429 9.41343L23.2937 9.36575C23.5038 9.35979 23.6239 9.51476 23.6299 9.6876L23.6599 10.6293C23.7079 11.9465 22.9035 12.4651 21.8529 12.5008C20.8023 12.5366 19.9738 12.0777 19.9258 10.7545L19.8477 8.54919C19.7997 7.23198 20.6042 6.71344 21.6127 6.67768C22.7114 6.64191 23.5338 7.17834 23.5698 8.12602C23.5819 8.51939 23.4138 8.62072 23.0716 8.63264C22.7954 8.64456 22.5613 8.57304 22.5433 8.32867C22.5072 7.95317 22.3632 7.54787 21.6908 7.57171Z"
      fill="white"
    />
    <path
      d="M27.0708 6.95782C27.0648 6.73729 27.317 6.63597 27.5751 6.62405C27.8273 6.61808 28.0974 6.70153 28.1034 6.92206L28.2355 10.6174C28.2835 11.9346 27.455 12.4532 26.3924 12.4949C25.3178 12.5307 24.4654 12.0717 24.4173 10.7545L24.2853 7.05914C24.2793 6.83861 24.5314 6.73133 24.7895 6.72537C25.0477 6.71941 25.3118 6.80285 25.3178 7.02338L25.4499 10.7187C25.4739 11.3326 25.8101 11.6187 26.3564 11.5949C26.9087 11.577 27.2209 11.273 27.1969 10.6591L27.0708 6.95782Z"
      fill="white"
    />
    <path
      d="M28.7637 12.0955C28.7637 12.0776 28.7697 12.0478 28.7757 12.018L30.1384 6.99947C30.2045 6.76106 30.4926 6.64186 30.7868 6.62994C31.087 6.61802 31.3872 6.71934 31.4652 6.95179L33.1762 11.869C33.1822 11.8988 33.1942 11.9226 33.1942 11.9465C33.2002 12.1909 32.834 12.3875 32.5578 12.3935C32.3837 12.3995 32.2456 12.3458 32.1976 12.2028L31.8614 11.1657L30.0364 11.2313L29.7722 12.2922C29.7362 12.4412 29.6041 12.5008 29.43 12.5127C29.1539 12.5067 28.7757 12.3458 28.7637 12.0955ZM31.6093 10.3611L30.8288 7.95907L30.2225 10.4087L31.6093 10.3611Z"
      fill="white"
    />
    <path
      d="M34.1718 12.483C33.9436 12.4889 33.7155 12.3876 33.7095 12.173L33.5294 7.0353C33.5234 6.81477 33.7695 6.70749 34.0337 6.70153C34.2858 6.69557 34.556 6.77901 34.562 6.99954L34.7241 11.5591L36.6632 11.4936C36.8733 11.4876 36.9814 11.7022 36.9874 11.9346C36.9934 12.1552 36.8973 12.3876 36.6932 12.3936L34.1718 12.483Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "bilingual",
};
</script>

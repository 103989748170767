<template>
  <div class="confirm-applicant__student">
    <div class="d-flex mb-5 justify-space-between">
      <div class="confirm-applicant__student__input-div">
        <h6 class="confirm-applicant__student__input-div__title">
          {{ $t("user_register.confirm_applicant.first_name") }}
          <span class="text-red">*</span>
        </h6>
        <input
          v-model="student.first_name"
          class="confirm-applicant__student__input-div__input"
          type="text"
          :placeholder="
            student.first_name
              ? student.first_name
              : $t('user_register.confirm_applicant.first_name')
          "
          @blur="changeFirstName(student.first_name)"
        />
      </div>
      <div class="confirm-applicant__student__input-div">
        <h6 class="confirm-applicant__student__input-div__title">
          {{ $t("user_register.confirm_applicant.last_name") }}
          <span class="text-red">*</span>
        </h6>
        <input
          v-model="student.first_lastname"
          class="confirm-applicant__student__input-div__input"
          type="text"
          :placeholder="$t('user_register.confirm_applicant.last_name')"
          @blur="changeLastName(student.first_lastname)"
        />
      </div>
    </div>

    <p
      v-if="editCurrentStudent"
      class="confirm-applicant__subtitle"
      v-t="'user_register.register_student.gradesOfInteres'"
    />

    <register-grade
      :confirmStudent="editCurrentStudent ? false : true"
      @selected-grade="setGrades"
      :editCurrentStudent="editCurrentStudent"
      :gradesOfStudent="student.current_enrollment.grade_label"
      :userRegister="true"
      :oneGrade="true"
      :isInProfile="isInProfile"
      :userRegisterInDashboard="true"
      :cleanGrades="cleanGrades"
      :student="student"
    />

    <register-grade
      :confirmStudent="editCurrentStudent ? false : true"
      @selected-grade="setGradeInterest"
      :editCurrentStudent="editCurrentStudent"
      :gradesOfStudent="student.grades[0]"
      :userRegister="true"
      :isInProfile="isInProfile"
      :userRegisterInDashboard="true"
      :oneGrade="true"
      :gradesOfInterest="true"
      :cleanGrades="cleanGrades"
      :student="student"
    />

    <p
      v-if="editCurrentStudent"
      class="confirm-applicant__current-institution mt-3 mb-2"
      v-t="'user_register.register_student.current_institution'"
    />

    <v-autocomplete
      class="confirm-applicant__student__select mb-5 mt-2"
      hide-details
      :items="municipalities"
      v-model="municipalityName"
      item-text="name"
      item-value="name"
      placeholder="Selecciona la comuna"
      solo
      dark
      height="31"
      :no-data-text="$t('errors.no-data')"
      background-color="#2b5bff"
      :menu-props="{ bottom: true, offsetY: true }"
    >
    </v-autocomplete>

    <v-autocomplete
      :disabled="schoolList.length === 0"
      class="confirm-applicant__student__select"
      hide-details
      :items="schoolList"
      v-model="schoolId"
      item-text="campus_name"
      item-value="id"
      placeholder="Selecciona tu establecimiento actual"
      solo
      dark
      height="31"
      :no-data-text="$t('errors.no-data')"
      background-color="#2b5bff"
      :menu-props="{ bottom: true, offsetY: true }"
    >
    </v-autocomplete>

    <div class="confirm-applicant__student__confirm d-flex">
      <img
        src="@/assets/iconsNewHaven/user_register/icons/delete.svg"
        style="cursor: pointer"
        v-if="!confirmApplicant && !editStudents"
        class="mr-2"
        @click="changeConfirm()"
      />
      <div class="d-flex">
        <img
          v-if="confirmApplicant"
          class="mr-2"
          @click="changeConfirm()"
          src="@/assets/iconsNewHaven/icons/confirm-on.svg"
          style="cursor: pointer"
        />
        <img
          v-else
          src="@/assets/iconsNewHaven/icons/confirm-off.svg"
          style="cursor: pointer"
          class="mr-2"
          @click="changeConfirm()"
        />

        <p
          v-t="'user_register.register_student.confirm_text'"
          class="confirm-applicant__student__confirm__text"
        />
        <!--<p v-if="!confirmApplicant">Please fill all fields correctly.</p>-->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import RegisterGrade from "../../../shared/Grade.vue";
import elasticSearchsService from "@/services/elasticSearchs.services";

export default {
  name: "ConfirmStudent",
  components: { RegisterGrade },
  data() {
    return {
      elasticSearchsService,
      grade: null,
      gradeInterest: null,
      selectedGrades: [],
      selectedStages: null,
      confirmApplicant: false,
      schoolId: null,
      municipalityName: null,
      schoolToSearch: null,
      schoolList: [],
    };
  },
  props: {
    student: {
      required: true,
      type: Object,
    },
    index: {
      required: false,
      type: Number,
    },
    editStudents: {
      type: Boolean,
      default: false,
    },
    editCurrentStudent: {
      type: Boolean,
      default: false,
    },
    isInProfile: {
      type: Boolean,
      default: false,
    },
    cleanGrades: {
      type: Boolean,
      default: false,
    },
    studentSet: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.confirmApplicant = false;
  },
  computed: {
    ...mapGetters({
      municipalities: "options/municipalities",
    }),
  },
  methods: {
    async searchSchools() {
      const filter = [
        {
          fieldname: "commune",
          fieldvalue: this.municipalityName,
        },
      ];
      const from = 0;
      await this.elasticSearchsService.elasticSchoolList(from, filter).then((response) => {
        this.schoolList = response.data.results;
      });
    },
    changeConfirm() {
      if (!this.confirmApplicant) {
        if (!this.editCurrentStudent) {
          if (
            !this.valid(this.student.first_name) ||
            !this.valid(this.student.first_lastname) ||
            !this.valid(this.grade) ||
            !this.valid(this.gradeInterest)
          ) {
            return false;
          }
        }
        this.confirmApplicant = true;
        this.$parent.confirmApplicantStudent(
          this.index,
          {
            id: this.student.id,
            firstName: this.student.first_name,
            lastName: this.student.first_lastname,
            gradeId: this.grade[0],
            currentSchool: this.schoolId,
            uuid: this.student.uuid,
            gradeInterest: this.gradeInterest,
            stagesInteres: this.selectedStages,
          },
          true
        );
        return true;
        // do logic to see if its complete
      }
      this.confirmApplicant = false;
      this.$parent.confirmApplicantStudent(this.index, {}, false);
      return null;
    },
    setGrades(gradeId, stage) {
      this.selectedStages = stage;
      this.grade = gradeId;
    },
    setGradeInterest(gradeId, stage) {
      this.selectedStages = stage;
      if (this.editCurrentStudent) {
        this.gradeInterest = gradeId;
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.gradeInterest = gradeId;
      }
    },
    valid(element) {
      if (element === "" || element === null || element === undefined) {
        return false;
      }
      return true;
    },
    changeFirstName(name) {
      this.student.first_name = name;
      if (!this.valid(this.student.first_name)) {
        this.confirmApplicant = false;
      }
    },
    changeLastName(lastName) {
      this.student.first_lastname = lastName;
      if (!this.valid(this.student.first_lastname)) {
        this.confirmApplicant = false;
      }
    },
  },
  watch: {
    cleanGrades: {
      handler(newVal) {
        if (newVal === true) {
          this.confirmApplicant = false;
        }
      },
    },
    municipalityName: {
      handler() {
        this.searchSchools();
      },
    },
  },
};
</script>

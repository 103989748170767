<template>
  <div>
    <div
      v-if="!loading"
      class="user_register_container"
      :class="{
        user_register_container__gradient: stepAux === 2 || stepAux === 5,
        user_register_container__white:
          ( stepAux === 3 ) ||
          (stepAux === 4 && students.length > 0),
      }"
    >
      <register-data
        v-if="stepAux === 1"
        @nextStep="nextStep"
        @backStep="backStep"
        @setLocationModal="setLocationModal()"
        @closeModal="closeModals"
      />
      <register-code
        v-if="stepAux === 2"
        @nextStep="nextStep"
        @backStep="backStep"
        @closeModal="closeModals"
      />
      <!--<register-zone
        v-if="stepAux === 3"
        @nextStep="nextStep"
        @backStep="backStep"
      />-->
      <div v-if="stepAux === 3  && (!preCheckStatus || !preCheckStatus.has_addresses)">
        <register-location
          @nextStep="nextStep"
          @backStep="backStep"
          :userRegister="true"
          @locationInfo="setFastLocation"
          :gradesOfInterest="true"
        />
        <div class="btn_container justify-end pt-3">
          <base-button
            class="btn-continue"
            :class="{ 'btn-continue--active': location }"
            @click="nextStep"
          >
            {{ $t("user_register.register_code.btn") }}
          </base-button>
        </div>
      </div>
      <div v-if="stepAux === 3 && preCheckStatus.has_addresses">
        <confirm-location @nextStep="nextStep" @backStep="backStep" @locationInfo="setFastLocation" />
        <div class="btn_container pt-3">
          <v-btn icon @click="backStep">
            <img width="8" src="@/assets/iconsNewHaven/user_register/icons/blue-back.svg" />
          </v-btn>
          <base-button
            class="btn-continue"
            :class="{ 'btn-continue--active': location }"
            @click="nextStep"
            :disabled="!locationDone"
          >
            {{ $t("user_register.register_code.btn") }}
          </base-button>
        </div>
      </div>
      <div v-if="stepAux === 4 && students.length === 0">
        <div class="user_register_container__grade-title">
          <h1
            v-t="'user_register.register_grade.title'"
            class="user_register_container__grade-title__text"
          />
          <div class="d-flex">
            <img
              class="mr-1"
              src="@/assets/iconsNewHaven/user_register/icons/face.svg"
            />
            <img
              src="@/assets/iconsChile/registration/2-2.svg"
            />
                      
          </div>
        </div>
        <register-grade
          @nextStep="nextStep"
          @backStep="backStep"
          :userRegister="true"
          @gradeInfo="setFastGrade"
          :gradesOfInterest="true"
        />
        <div class="btn_container pt-3">
          <v-btn icon @click="backStep">
            <img width="8" src="@/assets/iconsNewHaven/user_register/icons/blue-back.svg" />
          </v-btn>
          <base-button
            class="btn-continue"
            @click="nextStep"
            :disabled="grades === null"
            :class="{ 'btn-continue--active': grades }"
          >
            {{ $t("user_register.register_code.btn") }}
          </base-button>
        </div>
      </div>
      <div v-if="stepAux === 4 && students.length > 0">
        <div class="user_register_container__grade-title">
          <h1
            v-t="'user_register.register_grade.title'"
            class="user_register_container__grade-title__text"
          />
          <img src="@/assets/iconsNewHaven/user_register/icons/face.svg" />
        </div>
        <confirm-students
          :oneStudent="students[0]"
          @nextStep="nextStep"
          @backStep="backStep"
          @setStudents="setStudent"
        />
        <div class="btn_container pt-3">
          <v-btn icon @click="backStep">
            <img width="8" src="@/assets/iconsNewHaven/user_register/icons/blue-back.svg" />
          </v-btn>
          <base-button
            class="btn-continue"
            @click="nextStep"
            :disabled="!studentsDone"
            :class="{ 'btn-continue--active': grades }"
          >
            {{ $t("user_register.register_code.btn") }}
          </base-button>
        </div>
      </div>
      <RegisterFinalModal
        v-if="stepAux === 5"
        @closeModal="closeModals"
      />
      <LinkAccount v-if="stepAux === 6" @closeModal="closeModals" @resetPassword="resetPassword" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseButton from '@/components/explorer/base/BaseButton.vue';
import RegisterGrade from '@/components/shared/Grade.vue';
import RegisterData from '../authentication/user_register/steps/RegisterData.vue';
import RegisterCode from '../authentication/user_register/steps/RegisterCode.vue';
import RegisterLocation from '../shared/SearchLocation.vue';
import ConfirmLocation from '../authentication/user_register/steps/ConfirmLocation.vue';
import ConfirmStudents from '../authentication/user_register/steps/ConfirmStudents.vue';
import RegisterFinalModal from '../authentication/user_register/steps/RegisterFinalModal.vue';
import LinkAccount from '@/components/authentication/user_register/steps/LinkAccount.vue';

export default {
  name: "UserRegister",
  components: {
    RegisterData,
    RegisterCode,
    RegisterLocation,
    BaseButton,
    RegisterGrade,
    ConfirmLocation,
    RegisterFinalModal,
    ConfirmStudents,
    LinkAccount,
  },
  data() {
    return {
      stepAux: null,
      location: null,
      grades: null,
      stage: null,
      studentsForm: null,
      locationDone: false,
      studentsDone: false,
      showVerifyCode: false,
    };
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    this.stepAux = this.step;
  },
  methods: {
    ...mapActions({
      setFastRegisterLocation: 'userRegister/setFastRegisterLocation',
      setFastRegisterGrade: 'userRegister/setFastRegisterGrade',
      setStudents: 'newStudentRegister/setStudents',
      setInRegisterFlow: 'userRegister/setInRegisterFlow',
    }),
    /* eslint-disable */
    nextStep() {
      if (this.stepAux < 7) {
        if (this.stepAux === 3) {
          const { location } = this;
          this.stepAux += 1;
          this.setFastRegisterLocation({ location });
        } else {
          if (this.stepAux === 4 && this.grades !== null && this.students.length === 0) {
            const gradesData = {
              grades: this.grades,
              stage: this.stage
            }
            this.setFastRegisterGrade({gradesData: gradesData});
            this.stepAux += 1;
            return this.stepAux;
          }
          if (this.stepAux === 4 && this.students.length !== 0) {
            const { studentsForm } = this;
            this.setStudents(studentsForm);
          }
          this.stepAux += 1;
        }
      } else {
        this.$emit("closeModal");
      }
    },
    /* eslint-enable */
    backStep() {
      if (this.stepAux === 1) {
        this.$emit("closeModal");
      } else {
        this.stepAux -= 1;
      }
    },
    resetPassword() {
      this.$emit('resetPassword');
      this.$emit('closeModal');
    },
    setLocationModal() {
      this.stepAux = 3;
    },
    closeModals() {
      this.$emit('closeModal');
      this.setInRegisterFlow(false);
      this.stepAux = 1;
    },
    setFastLocation(location) {
      this.location = location;
      this.locationDone = true;
    },
    setFastGrade(grades, stage) {
      this.grades = grades;
      this.stage = stage;
    },
    setStudent(studentForm, confirmed) {
      this.studentsForm = studentForm;
      if (confirmed) {
        this.studentsDone = true;
      } else {
        this.studentsDone = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      loading: "loading/loading",
      legalGuardianAddress: "authentication/legalGuardianAddress",
      preCheckStatus: "authentication/preCheckStatus",
      students: "authentication/students",
    }),
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
  },
  watch: {
    step: {
      handler(newVal) {
        this.stepAux = newVal
      },
    },
  },
};
</script>

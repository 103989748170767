var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"grade-container pb-4"},[(_vm.mapFilter)?_c('h6',{directives:[{name:"t",rawName:"v-t",value:('user_register.register_grade.guest_title'),expression:"'user_register.register_grade.guest_title'"}],staticClass:"grade-container__title"}):_vm._e(),(!_vm.gradesOfInterest && !_vm.isInProfile)?_c('p',{directives:[{name:"t",rawName:"v-t",value:('shared.grade.subtitle'),expression:"'shared.grade.subtitle'"}],staticClass:"grade-container__subtitle"}):_vm._e(),(_vm.userRegister && !_vm.isInProfile && _vm.gradesOfInterest)?_c('p',{directives:[{name:"t",rawName:"v-t",value:('shared.grade.subtitle-grades-interest'),expression:"'shared.grade.subtitle-grades-interest'"}],staticClass:"grade-container__subtitle"}):_vm._e(),(_vm.isShowGrades)?_c('p',{directives:[{name:"t",rawName:"v-t",value:('shared.grade.stage_title'),expression:"'shared.grade.stage_title'"}],staticClass:"grade-container__title"}):_vm._e(),(_vm.isShowGrades)?_c('div',{staticClass:"grade__checkbox pb-8",style:(_vm.selectedStage.index === -1 ? '' : 'border-bottom: 1px solid #2b5bff;')},[_vm._l((_vm.selectStageLabels()),function(label,index){return [_c('label',{staticClass:"grade__checkbox__label mr-1",class:{
          grade__checkbox__label__selected: _vm.selectedStage.name === label,
          'grade-desktop': !_vm.isMobile,
        },on:{"click":function($event){return _vm.setStage(label, index)}}},[_vm._v(" "+_vm._s(label)+" ")])]})],2):_vm._e(),(!_vm.isShowGrades)?_c('button',{staticClass:"confirm-applicant__student__btn",on:{"click":function($event){_vm.isShowGrades = !_vm.isShowGrades}}},[_vm._v(" "+_vm._s(_vm.getTextButton)+" "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]):_vm._e(),(_vm.showTitle && _vm.selectedStage.index !== -1 && !_vm.noCurrentSchool)?_c('p',{staticClass:"grade-container__title mt-5"},[_vm._v(" "+_vm._s(_vm.getTitle)+" ")]):_vm._e(),[(_vm.selectedStage.name === 'Parvularia')?_c('div',{staticClass:"grade__checkbox mt-3"},[_vm._l((_vm.preschoolLabels),function(gradeName){return [_c('label',{staticClass:"grade__checkbox__label mr-1",class:{
            'grade__checkbox__label__grade-selected': _vm.selectedGradesNames.includes(gradeName),
          },on:{"click":function($event){return _vm.selectGrades(gradeName)}}},[_vm._v(" "+_vm._s(gradeName)+" ")])]})],2):_vm._e()],[(_vm.selectedStage.name === 'Básica')?_c('div',{staticClass:"grade__checkbox mt-5"},[_vm._l((_vm.elementarySchoolLabels),function(gradeName){return [_c('label',{staticClass:"grade__checkbox__label mr-1",class:{
            'grade__checkbox__label__grade-selected': _vm.selectedGradesNames.includes(gradeName),
          },on:{"click":function($event){return _vm.selectGrades(gradeName)}}},[_vm._v(" "+_vm._s(gradeName)+" ")])]})],2):_vm._e()],[(_vm.selectedStage.name === 'Media')?_c('div',{staticClass:"grade__checkbox mt-5"},[_vm._l((_vm.highSchoolLabels),function(gradeName){return [_c('label',{staticClass:"grade__checkbox__label mr-1",class:{
            'grade__checkbox__label__grade-selected': _vm.selectedGradesNames.includes(gradeName),
          },on:{"click":function($event){return _vm.selectGrades(gradeName)}}},[_vm._v(" "+_vm._s(gradeName)+" ")])]})],2):_vm._e()],[(_vm.selectedStage.name === 'Adultos')?_c('div',{staticClass:"grade__checkbox mt-5"},[_vm._l((_vm.adultSchoolLabels),function(gradeName){return [_c('label',{staticClass:"grade__checkbox__label mr-1",class:{
            'grade__checkbox__label__grade-selected': _vm.selectedGradesNames.includes(gradeName),
          },on:{"click":function($event){return _vm.selectGrades(gradeName)}}},[_vm._v(" "+_vm._s(gradeName)+" ")])]})],2):_vm._e()],[(_vm.selectedStage.name === 'Especial')?_c('div',{staticClass:"grade__checkbox mt-5"},[_vm._l((_vm.specialitySchools),function(gradeName){return [_c('label',{staticClass:"grade__checkbox__label mr-1",class:{
            'grade__checkbox__label__grade-selected': _vm.selectedGradesNames.includes(gradeName),
          },on:{"click":function($event){return _vm.selectGrades(gradeName)}}},[_vm._v(" "+_vm._s(gradeName)+" ")])]})],2):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }
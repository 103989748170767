<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M0.125 6.5C0.125 2.9799 2.97991 0.125 6.5 0.125C10.0201 0.125 12.875 2.97991 12.875 6.5C12.875 10.0201 10.0201 12.875 6.5 12.875C2.9799 12.875 0.125 10.0201 0.125 6.5ZM7.47283 6.5C7.47283 5.95053 7.04947 5.52717 6.5 5.52717C5.95053 5.52717 5.52717 5.95053 5.52717 6.5C5.52717 7.04947 5.95053 7.47283 6.5 7.47283C7.04947 7.47283 7.47283 7.04947 7.47283 6.5Z"
        fill="white"
        stroke="#333333"
        stroke-width="0.25"
      />
      <path
        d="M3.10899 6.50001C3.10899 4.62742 4.62771 3.1087 6.5003 3.1087C8.37289 3.1087 9.8916 4.62742 9.8916 6.50001C9.8916 8.3726 8.37289 9.89131 6.5003 9.89131C4.62771 9.89131 3.10899 8.3726 3.10899 6.50001ZM6.94264 6.50001C6.94264 6.24935 6.75096 6.05766 6.5003 6.05766C6.24964 6.05766 6.05795 6.24935 6.05795 6.50001C6.05795 6.75067 6.24964 6.94235 6.5003 6.94235C6.75096 6.94235 6.94264 6.75067 6.94264 6.50001Z"
        fill="#11D3D9"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="13" height="13" fill="white" transform="translate(13) rotate(90)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "PrioritySEP",
};
</script>

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import school from "@/assets/icons/school.vue";
import sun from "@/assets/icons/sun.vue";
import moon from "@/assets/icons/moon.vue";
import bus from "@/assets/icons/bus.vue";
import bilingual from "@/assets/icons/bilingual.vue";
import uniform from "@/assets/icons/uniform.vue";
import neighborhood from "@/assets/icons/neighborhood.vue";
import checkmark from "@/assets/icons/checkmark.vue";
import NeighborhoodSchool from "@/assets/icons/NeighborhoodSchool.vue";
import CharterSchool from "@/assets/icons/CharterSchool.vue";
import ComprehensiveHighSchool from "@/assets/icons/ComprehensiveHighSchool.vue";
import InterdistrictMagnetSchool from "@/assets/icons/InterdistrictMagnetSchool.vue";
import MagnetCharterSchool from "@/assets/icons/MagnetCharterSchool.vue";
import MagnetSchool from "@/assets/icons/MagnetSchool.vue";
import Cross from "@/assets/icons/Cross.vue";
import HeartOutline from "@/assets/icons/HeartOutline.vue";
import Trashcan from "@/assets/icons/Trashcan.vue";
import logout from "@/assets/icons/logout.vue";
import ConsiliumWhite from "@/assets/icons/ConsiliumWhite.vue";
import ConsiliumBots from "@/assets/icons/ConsiliumBots.vue";
import ConsiliumBotsWithIcon from "@/assets/icons/ConsiliumBotsWithIcon.vue";
import ArrowUp from "@/assets/icons/ArrowUp.vue";
import ArrowUp2 from "@/assets/icons/ArrowUp2.vue";
import ArrowDown from "@/assets/icons/ArrowDown.vue";
import ArrowLeft from "@/assets/icons/ArrowLeft.vue";
import ArrowDown2 from "@/assets/icons/ArrowDown2.vue";
import ArtifitialSchoolRemarked from "@/assets/icons/ArtifitialSchoolRemarked.vue";
import Consilium from "@/assets/icons/Consilium.vue";
import OutlinedHeart from "@/assets/icons/OutlinedHeart.vue";
import NoPriority from "@/assets/mapMarkers/NoPriority.vue";
import OnePriority from "@/assets/mapMarkers/OnePriority.vue";
import BothPriorities from "@/assets/mapMarkers/BothPriorities.vue";
import Info from "@/assets/icons/Info.vue";
import Close from "@/assets/icons/Close.vue";
import HeartFilled from "@/assets/icons/HeartFilled.vue";
import CloseMapCard from "@/assets/icons/CloseMapCard.vue";
import Walking from "@/assets/icons/Walking.vue";
import Driving from "@/assets/icons/Driving.vue";

import HasPriorityExplored from "@/assets/iconsChile/HasPriorityExplored.vue";
import NoPriorityExplored from "@/assets/iconsChile/NoPriorityExplored.vue";
import NoPriorityUnexplored from "@/assets/iconsChile/NoPriorityUnexplored.vue";
import HasPriorityUnexplored from "@/assets/iconsChile/HasPriorityUnexplored.vue";
import PerformanceLevelBars from "@/assets/icons/PerformanceLevelBars.vue";
import CloseFilter from "@/assets/icons/CloseFilter.vue";
import pdf from "@/assets/iconsChile/pdf.vue";
import Language from "@/assets/iconsChile/Language.vue";
import Classroom from "@/assets/iconsChile/Classroom.vue";
import Sport from "@/assets/iconsChile/Sport.vue";
import Activities from "@/assets/iconsChile/Activities.vue";
import InfoSchool from "@/assets/iconsChile/InfoSchool.vue";
import Wifi from "@/assets/iconsChile/Wifi.vue";
import AddStudent from "@/assets/iconsChile/AddStudent.vue";
import Email from "@/assets/icons/Email.vue";
import Sms from "@/assets/icons/Sms.vue";

import ChairWhite from "@/assets/iconsChile/simulation/ChairWhite.vue";
import ChairGray from "@/assets/iconsChile/simulation/ChairGray.vue";
import ChairRed from "@/assets/iconsChile/simulation/ChairRed.vue";
import ChairBlue from "@/assets/iconsChile/simulation/ChairBlue.vue";
import Person01 from "@/assets/iconsChile/simulation/Person01.vue";
import Person02 from "@/assets/iconsChile/simulation/Person02.vue";
import Person03 from "@/assets/iconsChile/simulation/Person03.vue";
import Person04 from "@/assets/iconsChile/simulation/Person04.vue";
import LegendArrow from "@/assets/iconsChile/simulation/LegendArrow.vue";

import PriorityOther from "@/assets/iconsChile/priority/priorityOther.vue";
import PrioritySEP from "@/assets/iconsChile/priority/prioritySEP.vue";
import PrioritySibling from "@/assets/iconsChile/priority/prioritySibling.vue";

Vue.use(Vuetify);

// Custom NH icons:
const tour = [true, false];
const explored = [true, false];
const selected = [true, false];
const components = [
  { component: NoPriority, sibling: false, name: "nopriority" },
  { component: OnePriority, sibling: false, name: "neighborhoodpriority" },
  { component: OnePriority, sibling: true, name: "siblingpriority" },
  { component: BothPriorities, sibling: false, name: "bothpriorities" },
];
const temp = {};
for (let i = 0; i < tour.length; i += 1) {
  for (let j = 0; j < selected.length; j += 1) {
    for (let k = 0; k < explored.length; k += 1) {
      for (let l = 0; l < components.length; l += 1) {
        const hastour = `${tour[i] ? "tour" : "notour"}`;
        const hasselected = `${selected[j] ? "selected" : "unselected"}`;
        const hasexplored = `${explored[k] ? "explored" : "unexplored"}`;
        const name = `${hastour}-${hasselected}-${hasexplored}-${components[l].name}`;
        temp[name] = {
          component: components[l].component,
          props: {
            selected: selected[j],
            hasTour: tour[i],
            explored: explored[k],
            sibling: components[l].sibling,
          },
        };
      }
    }
  }
}

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: "md",
  },
  icons: {
    values: {
      ...temp,
      school: {
        component: school,
      },
      sun: {
        component: sun,
      },
      moon: {
        component: moon,
      },
      bus: {
        component: bus,
      },
      bilingual: {
        component: bilingual,
      },
      neighborhood: {
        component: neighborhood,
      },
      uniform: {
        component: uniform,
      },
      checkmark: {
        component: checkmark,
      },
      neighborhoodSchool: {
        component: NeighborhoodSchool,
      },
      charterSchool: {
        component: CharterSchool,
      },
      comprehensiveHighSchool: {
        component: ComprehensiveHighSchool,
      },
      interdistrictMagnetSchool: {
        component: InterdistrictMagnetSchool,
      },
      magnetCharterSchool: {
        component: MagnetCharterSchool,
      },
      magnetSchool: {
        component: MagnetSchool,
      },
      cross: {
        component: Cross,
      },
      heartoutline: {
        component: HeartOutline,
      },
      trashcan: {
        component: Trashcan,
      },
      logout: {
        component: logout,
      },
      consiliumWhite: {
        component: ConsiliumWhite,
      },
      consiliumBots: {
        component: ConsiliumBots,
      },
      consiliumBotsWithIcon: {
        component: ConsiliumBotsWithIcon,
      },
      arrowUp: {
        component: ArrowUp,
      },
      arrowUp2: {
        component: ArrowUp2,
      },
      arrowDown: {
        component: ArrowDown,
      },
      arrowLeft: {
        component: ArrowLeft,
      },
      arrowDown2: {
        component: ArrowDown2,
      },
      artifitialSchoolRemarked: {
        component: ArtifitialSchoolRemarked,
      },
      consilium: {
        component: Consilium,
      },
      outlinedHeart: {
        component: OutlinedHeart,
      },
      info: {
        component: Info,
      },
      close: {
        component: Close,
      },
      heartFilled: {
        component: HeartFilled,
      },
      closeMapCard: {
        component: CloseMapCard,
      },
      walking: {
        component: Walking,
      },
      driving: {
        component: Driving,
      },
      hasPriorityExplored: {
        component: HasPriorityExplored,
      },
      hasPriorityUnexplored: {
        component: HasPriorityUnexplored,
      },
      noPriorityExplored: {
        component: NoPriorityExplored,
      },
      noPriorityUnexplored: {
        component: NoPriorityUnexplored,
      },
      performanceLevelBars: {
        component: PerformanceLevelBars,
      },
      closeFilter: {
        component: CloseFilter,
      },
      pdf: {
        component: pdf,
      },
      Language: {
        component: Language,
      },
      Classroom: {
        component: Classroom,
      },
      Sport: {
        component: Sport,
      },
      Activities: {
        component: Activities,
      },
      InfoSchool: {
        component: InfoSchool,
      },
      Wifi: {
        component: Wifi,
      },
      email: {
        component: Email,
      },
      sms: {
        component: Sms,
      },
      chairWhite: {
        component: ChairWhite,
      },
      chairGray: {
        component: ChairGray,
      },
      chairRed: {
        component: ChairRed,
      },
      chairBlue: {
        component: ChairBlue,
      },
      person01: {
        component: Person01,
      },
      person02: {
        component: Person02,
      },
      person03: {
        component: Person03,
      },
      person04: {
        component: Person04,
      },
      legendArrow: {
        component: LegendArrow,
      },
      priorityOther: {
        component: PriorityOther,
      },
      prioritySEP: {
        component: PrioritySEP,
      },
      prioritySibling: {
        component: PrioritySibling,
      },
      addStudent: {
        component: AddStudent,
      },
    },
  },
});

<template>
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.37499 0H11.4646L16.8125 5.60752V18.75C16.8125 19.4406 16.2805 20 15.625 20H1.37499C0.719471 20 0.1875 19.4406 0.1875 18.75V1.24999C0.1875 0.559387 0.719532 0 1.37499 0Z"
      fill="#F90E1F"
    />
    <path
      d="M16.7953 5.62492H12.6562C12.0007 5.62492 11.4688 5.06495 11.4688 4.37493V0.0124207L16.7953 5.62492Z"
      fill="#D30221"
    />
    <path
      d="M12.3581 9.4764C12.557 9.4764 12.6544 9.2939 12.6544 9.11703C12.6544 8.93389 12.5529 8.75702 12.3581 8.75702H11.2252C11.0038 8.75702 10.8803 8.95012 10.8803 9.16327V12.0939C10.8803 12.3551 11.0216 12.5001 11.2128 12.5001C11.4028 12.5001 11.5447 12.3551 11.5447 12.0939V11.2895H12.2299C12.4425 11.2895 12.5487 11.1064 12.5487 10.9245C12.5487 10.7464 12.4425 10.5695 12.2299 10.5695H11.5447V9.4764H12.3581ZM8.52901 8.75702H7.70012C7.47508 8.75702 7.31535 8.91953 7.31535 9.16075V12.0964C7.31535 12.3958 7.54512 12.4895 7.70958 12.4895H8.57945C9.60899 12.4895 10.2888 11.7764 10.2888 10.6757C10.2883 9.51203 9.64824 8.75702 8.52901 8.75702ZM8.56882 11.7658H8.06351V9.4808H8.51893C9.2083 9.4808 9.50811 9.96769 9.50811 10.6395C9.50811 11.2683 9.21359 11.7658 8.56882 11.7658ZM5.53237 8.75702H4.71122C4.47905 8.75702 4.34961 8.91824 4.34961 9.16327V12.0939C4.34961 12.3551 4.49804 12.5001 4.69752 12.5001C4.897 12.5001 5.04543 12.3551 5.04543 12.0939V11.2382H5.5602C6.1955 11.2382 6.71979 10.7645 6.71979 10.0026C6.71986 9.25704 6.21399 8.75702 5.53237 8.75702ZM5.51873 10.5508H5.04549V9.44516H5.51873C5.81085 9.44516 5.9967 9.68515 5.9967 9.99828C5.99608 10.3108 5.81085 10.5508 5.51873 10.5508Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "pdf",
};
</script>

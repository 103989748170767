import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import authentication from "./authentication.module";
import loading from "./loading.module";
import locale from "./locale.module";
import institutions from "./institutions.module";
import snackbar from "./snackbar.module";
import simulation from "./simulation.module";
import userRegister from "./userRegister.module";
import options from "./options.module";
import filters from "./filters.module";
import elasticSearch from "./elasticSearch.module";
import newStudentRegister from "./studentRegister.module";
import feedback from "./feedback.module";
import contactForm from "./contactForm.module";
import roles from "./roles.module";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    authentication,
    institutions,
    loading,
    locale,
    simulation,
    snackbar,
    userRegister,
    options,
    filters,
    elasticSearch,
    newStudentRegister,
    feedback,
    contactForm,
    roles,
  },
});

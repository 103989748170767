<template>
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.637 6.213 12.813 1.94a.997.997 0 0 0-.918-.607H4.106a1 1 0 0 0-.918.607L1.361 6.213A2.337 2.337 0 0 0 0 8.334V11c0 .55.45 1 1 1h.334v1.666c0 .55.45 1 1 1H3.67c.55 0 1-.45 1-1V12h6.665v1.666c0 .55.45 1 1 1h1.335c.55 0 1-.45 1-1V12H15c.55 0 1-.45 1-1V8.334c0-.94-.56-1.753-1.363-2.121zM3.8 2.203A.335.335 0 0 1 4.106 2h7.788c.134 0 .253.078.306.2l1.625 3.803c-.053-.003-.106-.006-.156-.006H2.334c-.053 0-.106.003-.156.006l1.622-3.8zM4 13.666c0 .184-.15.334-.334.334H2.334A.335.335 0 0 1 2 13.666V12h2v1.666zm9.666.334H12.33a.335.335 0 0 1-.334-.334V12h2v1.666H14c0 .184-.15.334-.334.334zM15 11.334H1A.335.335 0 0 1 .666 11V8.334c0-.918.747-1.665 1.665-1.665h11.335c.918 0 1.665.747 1.665 1.665V11h.003c0 .184-.15.334-.334.334z"
      fill="#FFFFFF"
    />
    <path
      d="M3 7.334c-.919 0-1.666.747-1.666 1.666 0 .919.747 1.666 1.666 1.666.919 0 1.666-.747 1.666-1.666 0-.919-.747-1.666-1.666-1.666zM3 10c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zM13 7.334c-.919 0-1.666.747-1.666 1.666a1.667 1.667 0 0 0 3.331 0c0-.919-.746-1.666-1.665-1.666zM13 10c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zM10.334 9.334H5.666a.335.335 0 0 0 0 .67h4.665a.335.335 0 0 0 .003-.67zM10.334 8H5.666a.335.335 0 0 0 0 .669h4.665A.335.335 0 0 0 10.334 8z"
      fill="#FFFFFF"
    />
  </svg>
</template>

<script>
export default {
  name: "Driving",
};
</script>

<style scoped></style>

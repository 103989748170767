import services from "../services";

/* eslint-disable no-shadow */
const getDefaultState = () => ({
  simulationData: [],
  simulated: false,
  showSimulateInfo: false,
  loading: false,
  institutionsList: [],
  institutionsDontSimulate: [],
  // messages: '',
});

const state = getDefaultState();

const getters = {
  loading: ({ loading }) => loading,
  simulated: ({ simulated }) => simulated,
  institutionsList: ({ institutionsList }) => {
    institutionsList.sort((a, b) => a.program_ranking - b.program_ranking);
    return institutionsList;
  },
  institutionsDontSimulate: ({ institutionsDontSimulate }) => institutionsDontSimulate,
  // eslint-disable-next-line no-unused-vars
  simulationData(storeState) {
    const { simulationData, institutionsList } = storeState;
    const favorites = institutionsList;
    const simulationDataUnited = JSON.parse(JSON.stringify(simulationData));
    /* eslint-disable no-param-reassign  */
    for (const simulation of simulationDataUnited) {
      simulation.portfolio.forEach((choice) => {
        const searchFavorite = favorites.find(({ uuid }) => uuid === choice.campus.uuid);
        /* eslint-disable camelcase */
        if (searchFavorite) {
          choice.school = searchFavorite;
          choice.campus = searchFavorite;
          choice.programUUID = searchFavorite.uuid;
        }
      });
    }
    /* eslint-enable no-param-reassign */
    return [simulationDataUnited];
  },
  messages: ({ messages }) => messages,
  recommendedSchools: ({ recommendedSchools }) => recommendedSchools,
  showSimulateInfo: ({ showSimulateInfo }) => showSimulateInfo,
};

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState());
  },
  setInstitutionsList(state, data) {
    const { institutions, interestedGrade } = data;

    function searchProgram(institution) {
      const filter = institution.programs.filter(({ grade }) => grade.id === interestedGrade);
      return filter.length > 0;
    }
    const finalInstitutions = [];
    institutions.forEach((institution) => {
      const availableSimulation = searchProgram(institution);
      institution.availableSimulation = availableSimulation;
      finalInstitutions.push(institution);
    });
    // eslint-disable-next-line no-restricted-syntax
    /*
    for (const iterator in institutions) {
      const availableSimulation = searchProgram(institutions[iterator]);
      institutions[iterator].availableSimulation = availableSimulation;
     // console.log(institutions);
    }
    */
    const institutionsSimulate = finalInstitutions.filter(
      ({ availableSimulation }) => availableSimulation === true
    );
    const institutionsDontSimulate = finalInstitutions.filter(
      ({ availableSimulation }) => availableSimulation === false
    );
    state.institutionsList = institutionsSimulate;
    state.institutionsDontSimulate = institutionsDontSimulate;
  },
  setSimulationData(state, data) {
    state.simulationData = data;
  },
  setSimulated(state, status) {
    state.simulated = status;
  },
  messages(state, results) {
    state.messages = results;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setRecommendedSchools(state, newVal) {
    state.recommendedSchools = newVal;
  },
  setShowSimulateInfo(state, newVal) {
    state.showSimulateInfo = newVal;
  },
};

const actions = {
  async simulate({ rootState, commit }, { callback }) {
    commit("setLoading", true);
    const { currentStudent } = rootState.authentication;
    await services.simulationService
      .simulate({ studentUUID: currentStudent.uuid })
      .then((response) => {
        commit("setSimulationData", response.data[0].api_response);
        commit("setSimulated", true);
        if (callback) {
          callback();
        }
        commit("setLoading", false);
        f;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async simulationSendingInstitutions({ rootState, state, commit }, { callback }) {
    commit("setLoading", true);
    const { currentStudent } = rootState.authentication;
    const { id, grades } = currentStudent;
    const campuses = state.institutionsList.map((institution) => institution.campus_code);

    const data = {
      interest_grade: grades[0],
      applicant_id: id,
      campuses,
    };

    const dataSimulation = [];
    await services.simulationService
      .simulationSendingInstitutions({ ...data, year: 2019 })
      .then((response) => {
        if (response.data[0].api_response !== null) {
          dataSimulation.push(response.data[0].api_response);
        }
        // commit('setSimulationData', response.data[0].api_response);
        // commit('setSimulated', true);
        // if (callback) {
        //   callback();
        // }
        // commit('setLoading', false);
      })
      .catch((error) => {
        console.error(error);
        commit("setLoading", false);
      });

    await services.simulationService
      .simulationSendingInstitutions({ ...data, year: 2020 })
      .then((response) => {
        if (response.data[0].api_response !== null) {
          dataSimulation.push(response.data[0].api_response);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    await services.simulationService
      .simulationSendingInstitutions({ ...data, year: 2021 })
      .then((response) => {
        if (response.data[0].api_response !== null) {
          dataSimulation.push(response.data[0].api_response);
        }
        // commit('setSimulationData', dataSimulation);
        // commit('setSimulated', true);
        // if (callback) {
        //   callback();
        // }
        // commit('setLoading', false);
      })
      .catch((error) => {
        console.error(error);
      });

    commit("setSimulationData", dataSimulation);
    commit("setSimulated", true);
    if (callback) {
      callback();
    }
    commit("setLoading", false);
  },
  resetSimulation({ commit }) {
    commit("setSimulationData", []);
    commit("setSimulated", false);
  },
  setRecommendedSchools({ commit }, newVal) {
    commit("setRecommendedSchools", newVal);
  },
  setShowSimulateInfo({ commit }, newVal) {
    commit("setShowSimulateInfo", newVal);
  },
  setInstitutionsList({ commit, rootGetters }, list) {
    const favorites = rootGetters["institutions/favoriteSchools"];
    const instiutions = list.institutions;
    const instiutionArray = [];
    instiutions.forEach((institution) => {
      const favoriteProgram = favorites.find((favorite) => favorite.campus === institution.uuid);
      institution.program_ranking = favoriteProgram.rank;
      instiutionArray.push(institution);
    });
    const newList = {
      institutions: instiutionArray,
      interestedGrade: list.interestedGrade,
    };
    commit("setInstitutionsList", newList);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

/* eslint-disable no-param-reassign */
import axios from 'axios';
import jwtDecode from "jwt-decode";
import moment from 'moment';
import CONFIG from '@/config';
import i18n from '@/i18n';
import router from '@/router';
import services from '@/services';

const api = axios.create({ baseURL: CONFIG.baseURL });
const userApi = axios.create({ 
  baseURL: CONFIG.userUrl,
  headers: {
    'Content-Type': 'application/json',
    'x-tenant': 'chile',
  },
});
const apiWithoutLoading = axios.create({ baseURL: CONFIG.baseURL });
/* NGROCK TEST
const preCheckTest = axios.create({
  baseURL: 'http://c27c-191-112-178-220.ngrok.io',
  headers: {
    'x-tenant': 'chile',
  },
});
*/
const searchApi = axios.create({
  baseURL: CONFIG.elasticSearchURL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': CONFIG.apigatewayKey,
    'x-index': 'chile-campuses-master',
  },
});

const searchGradeTrackApi = axios.create({
  baseURL: CONFIG.elasticSearchURL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": CONFIG.apigatewayKey,
    "x-index": "chile-gradetrack-master",
  },
});

const feedback = axios.create({
  baseURL: CONFIG.baseURL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": CONFIG.apigatewayKey,
  },
});

const geoLocatorApi = axios.create({
  baseURL: CONFIG.geolocatorBaseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const apiSendMail = axios.create({
  baseURL: CONFIG.contactForm,
  baseTable: CONFIG.messageApiTableName,
  headers: {
    'Content-Type': 'application/json',
  },
});

const analyticsApi = axios.create({
  baseURL: CONFIG.analyticsApiURL,
  headers: {
    "Content-Type": "application/json",
  },
});

let storeAPI = null;

api.setStore = (store) => {
  storeAPI = store;
};
apiWithoutLoading.setStore = (store) => {
  storeAPI = store;
};

searchApi.setStore = (store) => {
  storeAPI = store;
};

geoLocatorApi.setStore = (store) => {
  storeAPI = store;
};

feedback.setStore = (store) => {
  storeAPI = store;
};

apiSendMail.setStore = (store) => {
  storeAPI = store;
};

userApi.setStore = (store) => {
  storeAPI = store;
};
// Login shenanigans
api.interceptors.request.use((config) => {
  config.params = { ...config.params, language_code: i18n.locale || 'es' };
  const isGuest = storeAPI.getters['authentication/isGuest'];
  const token = storeAPI.getters['authentication/token'];
  if (!isGuest) {
    const today = moment();
    const decoded = jwtDecode(token);
    let expirationDate = new Date(0);
    expirationDate = expirationDate.setUTCSeconds(decoded.exp);
    expirationDate = moment(expirationDate);
    const dateDiff = today.diff(expirationDate)
    const expDateDiff = dateDiff + 100000;
    if(expDateDiff > 0) {
      services.authenticationService
        .refreshToken({token})
        .then((response) => {
          const newtoken = response.data.token;
          storeAPI.commit('authentication/setUserToken', newtoken);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            storeAPI.dispatch('authentication/logoutSuccess',  {
              callback: () => {
                const location = {
                  coordinate: {
                    lat: this.CONFIG.defaultLocation.lat,
                    lng: this.CONFIG.defaultLocation.lng,
                  },
                  regionId: 24,
                  municipalityName: 'Santiago',
                };
                storeAPI.dispatch('authentication/setGuestData', {
                  location,
                  grades: [5],
                  stage: 1,
                  level: 'PreKinder',
                });
              },
            })
          };
          console.error(error);
        });
    }
  }
  // eslint-disable-next-line no-param-reassign
  config.headers = {
    ...config.headers,
    Authorization: `${token ? 'Bearer '.concat(token) : ''}`, // TODO: check backend login
  };
  return config;
});

// Login shenanigans
apiWithoutLoading.interceptors.request.use((config) => {
  const isGuest = storeAPI.getters['authentication/isGuest'];
  let token = storeAPI.getters['authentication/token'];
  if (isGuest) {
    token = storeAPI.getters['authentication/legalGuardian'].guestToken;
  }
  // eslint-disable-next-line no-param-reassign
  config.headers = {
    ...config.headers,
    'Content-Type': 'application/json',
    Authorization: `${token ? 'Bearer '.concat(token) : ''}`, // TODO: check backend login
  };
  if (CONFIG.apigatewayKey) {
    config.headers['x-api-key'] = CONFIG.apigatewayKey;
  }
  config.params = { ...config.params, language_code: i18n.locale || 'es' };
  return config;
});
searchApi.interceptors.request.use((config) => {
  config.params = { ...config.params, language_code: i18n.locale || "es" };
  return config;
});

userApi.interceptors.request.use((config) => {
  const token = storeAPI.getters['authentication/token'];
  // eslint-disable-next-line no-param-reassign
  if (token !== '') {
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json',
      Authorization: `${token ? 'Bearer '.concat(token) : ''}`, 
    };
  } else {
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json',
    };
  }
  if (CONFIG.apigatewayKey) {
    config.headers['x-api-key'] = CONFIG.apigatewayKey;
  }
  config.params = { ...config.params, language_code: i18n.locale || 'es' };
  return config;
});

geoLocatorApi.interceptors.request.use((config) => {
  config.params = { ...config.params, language_code: i18n.locale || 'es' };
  return config;
});

feedback.interceptors.request.use((config) => {
  config.params = { ...config.params, language_code: i18n.locale || "es" };
  const isGuest = storeAPI.getters["authentication/isGuest"];
  let token = storeAPI.getters["authentication/token"];
  if (isGuest) {
    token = storeAPI.getters["authentication/legalGuardian"].guestToken;
  }
  // eslint-disable-next-line no-param-reassign
  config.headers = {
    ...config.headers,
    Authorization: `${token ? "Bearer ".concat(token) : ""}`, // TODO: check backend login
  };
  return config;
});
/* TOKEN INTERCEPTOR TEST
preCheckTest.interceptors.request.use((config) => {
  config.params = { ...config.params, language_code: i18n.locale || 'es' };
  const isGuest = storeAPI.getters['authentication/isGuest'];
  let token = storeAPI.getters['authentication/token'];
  if (!token && isGuest) {
    token = storeAPI.getters['authentication/legalGuardian'].guestToken;
  } else if (!isGuest) {
    const today = moment();
    const decoded = jwtDecode(token);
    let expirationDate = new Date(0);
    expirationDate = expirationDate.setUTCSeconds(decoded.exp);
    expirationDate = moment(expirationDate);
    const dateDiff = today.diff(expirationDate)
    const expDateDiff = dateDiff + 100000;
    if(expDateDiff > 0) {
        services.authenticationService
          .refreshToken({token})
          .then((response) => {
            const newtoken = response.data.token;
            storeAPI.commit('authentication/setUserToken', newtoken);
          })
          .catch((error) => {
            if (error.response.status === 400) {
              storeAPI.dispatch('authentication/logoutSuccess',  {
                callback: () => {
                  const location = {
                    coordinate: {
                      lat: this.CONFIG.defaultLocation.lat,
                      lng: this.CONFIG.defaultLocation.lng,
                    },
                    regionId: 24,
                    municipalityName: 'Santiago',
                  };
                  storeAPI.dispatch('authentication/setGuestData'), {
                    location,
                    grades: [5],
                    stage: 1,
                    level: 'PreKinder',
                  };
                },
              })
            };
            console.error(error);
          });
    }
  }
  // eslint-disable-next-line no-param-reassign
  config.headers = {
    ...config.headers,
    Authorization: `${token ? 'Bearer '.concat(token) : ''}`, // TODO: check backend login
  };
  return config;
});
*/
apiSendMail.interceptors.request.use((config) => {
  config.params = { ...config.params, language_code: i18n.locale || "es" };
  return config;
});

api.interceptors.response.use(
  (response) => {
    storeAPI.commit("loading/loading", false);
    return response;
  },
  (error) => {
    storeAPI.commit("loading/loading", false);
    if (storeAPI.getters["loading/disable_error_messages"]) {
      return Promise.reject(error);
    }
    if (error.response && error.response.status) {
      const { status } = error.response;
      if (status === 500) {
        storeAPI.dispatch("snackbar/error", i18n.t("errors.500"));
        // TODO: on-api-snackbar
        router.push({ name: "Login" });
      }
      if (status === 400) {
        let errors = "";
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(error.response.data)) {
          if (key === "non_field_errors") {
            errors = `${value}\n`;
          } else {
            errors = `${key}: ${value}\n`;
          }
        }
        storeAPI.dispatch("snackbar/error", errors);
        // TODO: 'on-api-snackbar'
      }
      if (status === 401) {
        storeAPI.dispatch("snackbar/error", i18n.t("errors.token_expired"));
        // TODO: 'on-api-snackbar'
        storeAPI.commit("authentication/reset", {}, { root: true });
        storeAPI.commit("institutions/reset", {}, { root: true });
        router.push({ name: "Login" });
      }
    }
    return Promise.reject(error);
  }
);
userApi.interceptors.response.use(
  (response) => {
    storeAPI.commit('loading/loading', false);
    return response;
  },
  (error) => {
    storeAPI.commit('loading/loading', false);
    if (storeAPI.getters['loading/disable_error_messages']) {
      return Promise.reject(error);
    }
    if (error.response && error.response.status) {
      const { status } = error.response;
      if (status === 500) {
        storeAPI.dispatch('snackbar/error', i18n.t('errors.500'));
        // TODO: on-api-snackbar
        router.push({ name: 'Login' });
      }
      if (status === 400) {
        // eslint-disable-next-line no-restricted-syntax
        if (error.response.data.non_field_errors[0] !== 'User is already registered with this e-mail address.') {
          storeAPI.dispatch('snackbar/error', 'No se puede iniciar sesión con las credenciales proporcionadas');
        }
        storeAPI.commit('authentication/setLoginLoading', {bool: false});
        storeAPI.commit('authentication/setLinkLoading', {bool: false});
        // TODO: 'on-api-snackbar'
      }
      if (status === 401) {
        storeAPI.dispatch('snackbar/error', i18n.t('errors.token_expired'));
        // TODO: 'on-api-snackbar'
        storeAPI.commit('authentication/reset', {}, { root: true });
        storeAPI.commit('institutions/reset', {}, { root: true });
        router.push({ name: 'Login' });
      }
    }
    return Promise.reject(error);
  },
);
apiWithoutLoading.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status) {
      const { status } = error.response;
      if (status === 500) {
        storeAPI.dispatch("snackbar/error", i18n.t("errors.500"));
        // TODO: on-api-snackbar
        router.push({ name: "Login" });
      }
      if (status === 400) {
        let errors = "";
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(error.response.data)) {
          errors = `${key}: ${value}\n`;
        }
        storeAPI.dispatch("snackbar/error", errors);
        // TODO: 'on-api-snackbar'
      }
      if (status === 401) {
        storeAPI.dispatch("snackbar/error", i18n.t("errors.token_expired"));
        // TODO: 'on-api-snackbar'
        storeAPI.dispatch("authentication/logoutSuccess", {}, { root: true });
        storeAPI.commit("authentication/reset", {}, { root: true });
        storeAPI.commit("institutions/reset", {}, { root: true });
        router.push({ name: "Login" });
      }
    }
    return Promise.reject(error);
  }
);

export {
  api as default,
  apiWithoutLoading,
  searchApi,
  geoLocatorApi,
  feedback,
  apiSendMail,
  searchGradeTrackApi,
  analyticsApi,
  userApi,
};

import i18n from "@/i18n";

const getDefaultState = () => ({
  content: "",
  level: null,
  show: false,
  timeout: 5000,
});
export const state = {
  ...getDefaultState(),
};

export const [SUCCESS, INFO, WARN, ERROR] = [...Array(4).keys()];

const getters = {
  content: ({ content }) => content,
  level: ({ level }) => level,
  show: ({ show }) => show,
  timeout: ({ timeout }) => timeout,
};

const actions = {
  /** Success log */
  success({ commit }, content) {
    // eslint-disable-next-line no-use-before-define
    commit("setMessage", { content, level: SUCCESS });
  },
  info({ commit }, content) {
    // eslint-disable-next-line no-use-before-define
    commit("setMessage", { content, level: INFO });
  },
  warn({ commit }, content) {
    // eslint-disable-next-line no-use-before-define
    commit("setMessage", { content, level: WARN });
  },
  error({ commit }, content) {
    // eslint-disable-next-line no-use-before-define
    commit("setMessage", { content, level: ERROR });
  },
  reset({ commit }) {
    commit("resetState");
  },
  infoWithTimeout({ commit }, { content, timeout }) {
    commit("setMessage", { content, level: INFO, timeout });
  },
};

const mutations = {
  // eslint-disable-next-line no-shadow
  setMessage(
    state,
    {
      content = i18n.t("snackbar.generic-error"),
      level = getDefaultState().level,
      timeout = getDefaultState().timeout,
    }
  ) {
    let message = content;
    if (content === false || content === undefined || content === null) {
      message = i18n.t("snackbar.generic-error");
    }

    Object.assign(state, getDefaultState());
    state.content = message;
    state.level = level;
    state.timeout = timeout;
    state.show = true;
  },
  // eslint-disable-next-line no-shadow
  resetState(state) {
    state.show = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

<template>
  <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.44043 7.5203L2.85435 9.66602L8.48688 0.278458" stroke="#13BB56" />
  </svg>
</template>

<script>
export default {
  name: "checkmark",
};
</script>

import api from "../api";

const appUrl = "/registration/legal_guardian";
const applicantUrl = "/registration/applicant/";

export default {
  identificationStep1({ uuid, nationality, idType }) {
    return api.patch(`${appUrl}/${uuid}/step_1/`, {
      nationality,
      identification_type: idType,
    });
  },
  identificationStep2({ uuid, idNumber }) {
    return api.patch(`${appUrl}/${uuid}/step_2_1/`, {
      identification_number: idNumber,
    });
  },
  identificationCommonStep2({ uuid, interestGrades }) {
    return api.patch(`${applicantUrl}c_step_2/`, {
      uuid,
      interestGrades,
    });
  },
  // identificationStep3({ uuid, imageB64, imageName }) {
  //   return api.patch(`${appUrl}/${uuid}/step_2_2/`, {
  //     data_b64: imageB64,
  //     filename: imageName,
  //   });
  // },
  identificationStep3({ uuid, imageB64, imageName, imageNumber }) {
    return api.patch(`${appUrl}/${uuid}/step_2_2/`, {
      data_b64: imageB64,
      filename: imageName,
      image_number: imageNumber,
    });
  },
  identificationStep4({ uuid, personalInfo }) {
    return api.patch(`${appUrl}/${uuid}/step_3/`, {
      first_name: personalInfo.firstName,
      other_name: personalInfo.secondName,
      first_lastname: personalInfo.surname,
      other_lastname: personalInfo.secondSurname,
      gender: personalInfo.gender,
      birth_date: personalInfo.birthDate,
      education: null,
      marital_status: null,
      ethnicity: null,
    });
  },
  identificationStep5({ uuid, fullAddressPost }) {
    return api.patch(`${appUrl}/${uuid}/step_4/`, {
      department: fullAddressPost.department,
      municipality: fullAddressPost.municipality,
      main_address: fullAddressPost.main_address,
      address_details: fullAddressPost.address_details,
      vp_tipo_via: fullAddressPost.vp_tipo_via,
      area_label: fullAddressPost.area_label,
      vp_number: fullAddressPost.vp_number,
      vp_letra: fullAddressPost.vp_letra,
      vp_prefijo: fullAddressPost.vp_prefijo,
      vp_cuadrante: fullAddressPost.vp_cuadrante,
      vg_numero_via: fullAddressPost.vg_numero_via,
      vg_letra: fullAddressPost.vg_letra,
      vg_placa: fullAddressPost.vg_placa,
      vg_prefijo: fullAddressPost.vg_prefijo,
      vg_cuadrante: fullAddressPost.vg_cuadrante,
      address_lat: fullAddressPost.address_lat,
      address_lon: fullAddressPost.address_lon,
    });
  },
  identificationStep6({ uuid, contactConfig, phoneNumber, email }) {
    return api.patch(`${appUrl}/${uuid}/step_5/`, {
      phone: phoneNumber,
      email,
      contact_preference_call: contactConfig.calls,
      contact_preference_whatsapp: contactConfig.whatsapp,
      contact_preference_sms: contactConfig.sms,
      contact_preference_email: true,
    });
  },
  identificationStep7({ uuid, notifications }) {
    return api.patch(`${appUrl}/${uuid}/step_6/`, {
      notify_important_dates: notifications.dates,
      notify_interest_schools: notifications.schools,
    });
  },
  communRegisterStep1({ username }) {
    return api.patch(`${appUrl}/step_1/`, {
      personalized_name: username,
    });
  },
  fastRegisterStep2({ location }) {
    return api.patch(`${appUrl}/f_step_2/`, {
      address_details: location.address_details,
      address_lat: location.coordinates.lat,
      address_lon: location.coordinates.lng,
      address_street: location.address_street,
      address_number: location.address_number,
      address_zipcode: location.zipcode,
      location: location.location,
    });
  },
  fastRegisterStep3({ gradesData }) {
    return api.patch(`${appUrl}/f_step_3/`, {
      interest_grades: gradesData.grades,
      interest_stages: [gradesData.stage],
    });
  },
  retrieveRegistrationData(uuid, key) {
    return api.request({
      url: `${appUrl}/${uuid}/`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    });
  },
  retrieveRegistrationApplicants() {
    return api.get(`${applicantUrl}`);
  },
};

<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9969 1.86921H15.0574C14.9642 1.86921 14.8749 1.83219 14.809 1.7663C14.7431 1.70041 14.7061 1.61104 14.7061 1.51786C14.7061 1.42467 14.7431 1.3353 14.809 1.26941C14.8749 1.20352 14.9642 1.1665 15.0574 1.1665H16.9969C17.09 1.1665 17.1794 1.20352 17.2453 1.26941C17.3112 1.3353 17.3482 1.42467 17.3482 1.51786C17.3482 1.61104 17.3112 1.70041 17.2453 1.7663C17.1794 1.83219 17.09 1.86921 16.9969 1.86921Z"
      fill="#333333"
    />
    <path
      d="M22.6868 14.0681H3.31327C3.22008 14.0681 3.13071 14.0311 3.06482 13.9652C2.99893 13.8993 2.96191 13.81 2.96191 13.7168V1.51786C2.96191 1.42467 2.99893 1.3353 3.06482 1.26941C3.13071 1.20352 3.22008 1.1665 3.31327 1.1665H9.23002C9.32321 1.1665 9.41257 1.20352 9.47847 1.26941C9.54436 1.3353 9.58137 1.42467 9.58137 1.51786C9.58137 1.61104 9.54436 1.70041 9.47847 1.7663C9.41257 1.83219 9.32321 1.86921 9.23002 1.86921H3.66462V13.3654H22.3354V1.86921H18.8781C18.7849 1.86921 18.6956 1.83219 18.6297 1.7663C18.5638 1.70041 18.5268 1.61104 18.5268 1.51786C18.5268 1.42467 18.5638 1.3353 18.6297 1.26941C18.6956 1.20352 18.7849 1.1665 18.8781 1.1665H22.6868C22.78 1.1665 22.8693 1.20352 22.9352 1.26941C23.0011 1.3353 23.0381 1.42467 23.0381 1.51786V13.7168C23.0381 13.81 23.0011 13.8993 22.9352 13.9652C22.8693 14.0311 22.78 14.0681 22.6868 14.0681Z"
      fill="#333333"
    />
    <path
      d="M12.9995 1.86921H11.1619C11.0687 1.86921 10.9793 1.83219 10.9135 1.7663C10.8476 1.70041 10.8105 1.61104 10.8105 1.51786C10.8105 1.42467 10.8476 1.3353 10.9135 1.26941C10.9793 1.20352 11.0687 1.1665 11.1619 1.1665H12.9995C13.0927 1.1665 13.182 1.20352 13.2479 1.26941C13.3138 1.3353 13.3508 1.42467 13.3508 1.51786C13.3508 1.61104 13.3138 1.70041 13.2479 1.7663C13.182 1.83219 13.0927 1.86921 12.9995 1.86921Z"
      fill="#333333"
    />
    <path
      d="M24.9459 24.8322H18.2752C18.182 24.8322 18.0926 24.7951 18.0267 24.7292C17.9608 24.6634 17.9238 24.574 17.9238 24.4808V22.8129C17.9245 22.2777 18.1374 21.7646 18.5159 21.3861C18.8943 21.0076 19.4075 20.7947 19.9427 20.7941H23.2781C23.8134 20.7946 24.3266 21.0075 24.7051 21.386C25.0837 21.7645 25.2966 22.2776 25.2973 22.8129V24.4808C25.2973 24.574 25.2603 24.6634 25.1944 24.7292C25.1285 24.7951 25.0391 24.8322 24.9459 24.8322ZM18.6265 24.1294H24.5946V22.8129C24.5941 22.4639 24.4553 22.1294 24.2085 21.8827C23.9616 21.6359 23.6271 21.4971 23.2781 21.4968H19.9427C19.5937 21.4971 19.2592 21.6359 19.0124 21.8827C18.7657 22.1294 18.6269 22.464 18.6265 22.8129V24.1294Z"
      fill="#333333"
    />
    <path
      d="M21.611 20.1008C21.1924 20.1009 20.7831 19.9768 20.435 19.7443C20.0869 19.5117 19.8156 19.1812 19.6553 18.7944C19.4951 18.4076 19.4531 17.9821 19.5348 17.5714C19.6164 17.1608 19.818 16.7837 20.1141 16.4877C20.4101 16.1916 20.7873 15.99 21.1979 15.9084C21.6085 15.8267 22.0341 15.8687 22.4208 16.0289C22.8076 16.1892 23.1381 16.4605 23.3707 16.8086C23.6032 17.1567 23.7273 17.566 23.7272 17.9846C23.7265 18.5457 23.5033 19.0835 23.1066 19.4802C22.7099 19.8769 22.1721 20.1001 21.611 20.1008ZM21.611 16.5708C21.3314 16.5707 21.058 16.6536 20.8255 16.8089C20.5929 16.9642 20.4116 17.185 20.3046 17.4433C20.1975 17.7017 20.1695 17.986 20.224 18.2602C20.2785 18.5345 20.4131 18.7865 20.6108 18.9842C20.8085 19.182 21.0605 19.3167 21.3347 19.3713C21.609 19.4259 21.8933 19.3979 22.1517 19.2909C22.41 19.1839 22.6309 19.0027 22.7862 18.7702C22.9416 18.5376 23.0245 18.2643 23.0245 17.9846C23.0242 17.6098 22.8751 17.2505 22.6101 16.9854C22.3451 16.7204 21.9858 16.5713 21.611 16.5708Z"
      fill="#333333"
    />
    <path
      d="M23.2099 24.8321H20.0105C19.9173 24.8321 19.828 24.7951 19.7621 24.7292C19.6962 24.6633 19.6592 24.574 19.6592 24.4808V23.2243C19.6592 23.1312 19.6962 23.0418 19.7621 22.9759C19.828 22.91 19.9173 22.873 20.0105 22.873C20.1037 22.873 20.1931 22.91 20.259 22.9759C20.3249 23.0418 20.3619 23.1312 20.3619 23.2243V24.128H22.8586V23.2243C22.8586 23.1312 22.8956 23.0418 22.9615 22.9759C23.0274 22.91 23.1168 22.873 23.2099 22.873C23.3031 22.873 23.3925 22.91 23.4584 22.9759C23.5243 23.0418 23.5613 23.1312 23.5613 23.2243V24.4794C23.5615 24.5256 23.5525 24.5715 23.535 24.6143C23.5174 24.657 23.4915 24.6959 23.4589 24.7287C23.4262 24.7615 23.3874 24.7875 23.3447 24.8052C23.302 24.823 23.2562 24.8321 23.2099 24.8321Z"
      fill="#333333"
    />
    <path
      d="M16.3356 24.8322H9.66483C9.57164 24.8322 9.48228 24.7951 9.41638 24.7292C9.35049 24.6634 9.31348 24.574 9.31348 24.4808V22.8129C9.31413 22.2777 9.52704 21.7646 9.90551 21.3861C10.284 21.0076 10.7971 20.7947 11.3323 20.7941H14.6681C15.2033 20.7947 15.7164 21.0076 16.0949 21.3861C16.4734 21.7646 16.6863 22.2777 16.6869 22.8129V24.4808C16.6869 24.574 16.6499 24.6634 16.584 24.7292C16.5181 24.7951 16.4288 24.8322 16.3356 24.8322ZM10.0162 24.1294H15.9842V22.8129C15.9839 22.464 15.8451 22.1294 15.5983 21.8827C15.3516 21.6359 15.017 21.4971 14.6681 21.4968H11.3323C10.9834 21.4971 10.6488 21.6359 10.4021 21.8827C10.1553 22.1294 10.0166 22.464 10.0162 22.8129V24.1294Z"
      fill="#333333"
    />
    <path
      d="M12.9997 20.1008C12.5811 20.1009 12.1718 19.9768 11.8237 19.7443C11.4756 19.5117 11.2042 19.1812 11.044 18.7944C10.8838 18.4076 10.8418 17.9821 10.9235 17.5714C11.0051 17.1608 11.2067 16.7837 11.5027 16.4877C11.7988 16.1916 12.1759 15.99 12.5865 15.9084C12.9971 15.8267 13.4227 15.8687 13.8095 16.0289C14.1962 16.1892 14.5268 16.4605 14.7593 16.8086C14.9919 17.1567 15.116 17.566 15.1159 17.9846C15.1152 18.5457 14.8921 19.0836 14.4954 19.4803C14.0986 19.877 13.5608 20.1002 12.9997 20.1008ZM12.9997 16.5708C12.7201 16.5707 12.4467 16.6536 12.2141 16.8089C11.9816 16.9642 11.8003 17.185 11.6932 17.4433C11.5862 17.7017 11.5581 17.986 11.6126 18.2602C11.6672 18.5345 11.8018 18.7865 11.9995 18.9842C12.1972 19.182 12.4491 19.3167 12.7234 19.3713C12.9977 19.4259 13.282 19.3979 13.5403 19.2909C13.7987 19.1839 14.0195 19.0027 14.1749 18.7702C14.3303 18.5376 14.4132 18.2643 14.4132 17.9846C14.4129 17.6098 14.2639 17.2504 13.9989 16.9853C13.7339 16.7203 13.3745 16.5712 12.9997 16.5708Z"
      fill="#333333"
    />
    <path
      d="M14.5996 24.8321H11.4002C11.307 24.8321 11.2176 24.7951 11.1517 24.7292C11.0858 24.6633 11.0488 24.574 11.0488 24.4808V23.2243C11.0488 23.1312 11.0858 23.0418 11.1517 22.9759C11.2176 22.91 11.307 22.873 11.4002 22.873C11.4934 22.873 11.5827 22.91 11.6486 22.9759C11.7145 23.0418 11.7515 23.1312 11.7515 23.2243V24.128H14.2482V23.2243C14.2482 23.1312 14.2852 23.0418 14.3511 22.9759C14.417 22.91 14.5064 22.873 14.5996 22.873C14.6928 22.873 14.7821 22.91 14.848 22.9759C14.9139 23.0418 14.9509 23.1312 14.9509 23.2243V24.4794C14.9511 24.5256 14.9422 24.5715 14.9246 24.6143C14.907 24.657 14.8812 24.6959 14.8485 24.7287C14.8159 24.7615 14.7771 24.7875 14.7344 24.8052C14.6916 24.823 14.6458 24.8321 14.5996 24.8321Z"
      fill="#333333"
    />
    <path
      d="M7.72426 24.8322H1.0535C0.960316 24.8322 0.870948 24.7951 0.805057 24.7292C0.739166 24.6634 0.702148 24.574 0.702148 24.4808V22.8129C0.702799 22.2776 0.915758 21.7645 1.2943 21.386C1.67285 21.0075 2.18607 20.7946 2.72136 20.7941H6.05674C6.59198 20.7947 7.10511 21.0076 7.48358 21.3861C7.86205 21.7646 8.07496 22.2777 8.07561 22.8129V24.4808C8.07561 24.574 8.03859 24.6634 7.9727 24.7292C7.90681 24.7951 7.81744 24.8322 7.72426 24.8322ZM1.40485 24.1294H7.3729V22.8129C7.37253 22.464 7.23375 22.1294 6.987 21.8827C6.74025 21.6359 6.4057 21.4971 6.05674 21.4968H2.72136C2.37238 21.4971 2.03779 21.6359 1.79098 21.8827C1.54418 22.1294 1.40532 22.4639 1.40485 22.8129V24.1294Z"
      fill="#333333"
    />
    <path
      d="M4.389 20.1008C3.97037 20.1008 3.56115 19.9767 3.21308 19.7441C2.86501 19.5115 2.59373 19.1809 2.43354 18.7942C2.27335 18.4074 2.23146 17.9818 2.31316 17.5712C2.39485 17.1607 2.59647 16.7835 2.89251 16.4875C3.18854 16.1915 3.56571 15.99 3.9763 15.9084C4.38689 15.8267 4.81247 15.8687 5.19921 16.029C5.58595 16.1892 5.91648 16.4605 6.14901 16.8086C6.38154 17.1568 6.50561 17.566 6.50554 17.9846C6.50489 18.5457 6.28168 19.0837 5.88488 19.4804C5.48808 19.8771 4.95011 20.1003 4.389 20.1008ZM4.389 16.5708C4.10937 16.5708 3.83602 16.6537 3.60352 16.8091C3.37101 16.9644 3.1898 17.1852 3.08279 17.4436C2.97578 17.7019 2.94778 17.9862 3.00233 18.2605C3.05688 18.5347 3.19154 18.7866 3.38927 18.9844C3.587 19.1821 3.83892 19.3167 4.11318 19.3713C4.38743 19.4258 4.67171 19.3979 4.93005 19.2908C5.1884 19.1838 5.40921 19.0026 5.56456 18.7701C5.71992 18.5376 5.80284 18.2643 5.80284 17.9846C5.80256 17.6097 5.65351 17.2503 5.38843 16.9852C5.12334 16.7201 4.76389 16.5711 4.389 16.5708Z"
      fill="#333333"
    />
    <path
      d="M5.98826 24.8321H2.78885C2.69567 24.8321 2.6063 24.7951 2.54041 24.7292C2.47452 24.6633 2.4375 24.574 2.4375 24.4808V23.2243C2.4375 23.1312 2.47452 23.0418 2.54041 22.9759C2.6063 22.91 2.69567 22.873 2.78885 22.873C2.88204 22.873 2.9714 22.91 3.03729 22.9759C3.10319 23.0418 3.1402 23.1312 3.1402 23.2243V24.128H5.6369V23.2243C5.6369 23.1312 5.67392 23.0418 5.73981 22.9759C5.8057 22.91 5.89507 22.873 5.98826 22.873C6.08144 22.873 6.17081 22.91 6.2367 22.9759C6.30259 23.0418 6.33961 23.1312 6.33961 23.2243V24.4794C6.33979 24.5256 6.33084 24.5715 6.31327 24.6143C6.29569 24.657 6.26984 24.6959 6.2372 24.7287C6.20455 24.7615 6.16576 24.7875 6.12304 24.8052C6.08032 24.823 6.03451 24.8321 5.98826 24.8321Z"
      fill="#333333"
    />
    <path
      d="M19.6378 4.74079H6.36307C6.26989 4.74079 6.18052 4.70377 6.11463 4.63788C6.04874 4.57199 6.01172 4.48262 6.01172 4.38944C6.01172 4.29625 6.04874 4.20689 6.11463 4.14099C6.18052 4.0751 6.26989 4.03809 6.36307 4.03809H19.6378C19.731 4.03809 19.8204 4.0751 19.8863 4.14099C19.9522 4.20689 19.9892 4.29625 19.9892 4.38944C19.9892 4.48262 19.9522 4.57199 19.8863 4.63788C19.8204 4.70377 19.731 4.74079 19.6378 4.74079Z"
      fill="#333333"
    />
    <path
      d="M19.6378 7.96973H6.36307C6.26989 7.96973 6.18052 7.93271 6.11463 7.86682C6.04874 7.80093 6.01172 7.71156 6.01172 7.61838C6.01172 7.52519 6.04874 7.43583 6.11463 7.36994C6.18052 7.30405 6.26989 7.26703 6.36307 7.26703H19.6378C19.731 7.26703 19.8204 7.30405 19.8863 7.36994C19.9522 7.43583 19.9892 7.52519 19.9892 7.61838C19.9892 7.71156 19.9522 7.80093 19.8863 7.86682C19.8204 7.93271 19.731 7.96973 19.6378 7.96973Z"
      fill="#333333"
    />
    <path
      d="M19.6378 11.199H12.283C12.1898 11.199 12.1004 11.162 12.0345 11.0961C11.9687 11.0302 11.9316 10.9408 11.9316 10.8476C11.9316 10.7544 11.9687 10.6651 12.0345 10.5992C12.1004 10.5333 12.1898 10.4963 12.283 10.4963H19.6378C19.731 10.4963 19.8204 10.5333 19.8863 10.5992C19.9522 10.6651 19.9892 10.7544 19.9892 10.8476C19.9892 10.9408 19.9522 11.0302 19.8863 11.0961C19.8204 11.162 19.731 11.199 19.6378 11.199Z"
      fill="#333333"
    />
  </svg>
</template>
<script>
export default {
  name: "Classroom",
};
</script>

<template>
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5" clip-path="url(#clip0)">
      <path
        d="M13.4473 24.6006C14.4084 24.6006 15.1875 23.8215 15.1875 22.8603C15.1875 21.8992 14.4084 21.1201 13.4473 21.1201C12.4862 21.1201 11.707 21.8992 11.707 22.8603C11.707 23.8215 12.4862 24.6006 13.4473 24.6006Z"
        fill="#333333"
      />
      <path
        d="M26.7169 8.16664C23.2506 4.44767 18.5569 2.39947 13.4998 2.39941C8.44311 2.39947 3.74939 4.44767 0.283114 8.16664C-0.113975 8.59274 -0.0905079 9.26014 0.335585 9.65728C0.761731 10.0544 1.42908 10.031 1.82622 9.60481C4.88903 6.31862 9.03479 4.50884 13.5002 4.50878C17.9652 4.50884 22.1109 6.31862 25.1737 9.60481C25.3815 9.82767 25.6631 9.94041 25.9455 9.94041C26.203 9.94041 26.4611 9.84665 26.6644 9.65728C27.0905 9.26009 27.114 8.59274 26.7169 8.16664Z"
        fill="#333333"
      />
      <path
        d="M22.8443 12.5222C20.2821 9.9406 16.9636 8.51894 13.5 8.51904C10.0356 8.5191 6.71704 9.94081 4.15563 12.5223C3.74536 12.9358 3.74794 13.6035 4.16143 14.0138C4.57492 14.4241 5.24269 14.4215 5.65296 14.008C7.81533 11.8287 10.6021 10.6285 13.5 10.6284C16.3971 10.6283 19.1839 11.8286 21.3471 14.0081C21.5532 14.2158 21.8244 14.3198 22.0957 14.3198C22.3642 14.3198 22.6329 14.2178 22.8387 14.0137C23.2521 13.6034 23.2546 12.9356 22.8443 12.5222Z"
        fill="#333333"
      />
      <path
        d="M18.6231 16.8904C17.2509 15.531 15.2841 14.6865 13.49 14.6865C13.4898 14.6865 13.4894 14.6865 13.4892 14.6865H13.4132C13.4129 14.6865 13.4127 14.6865 13.4124 14.6865C11.6182 14.6865 9.65125 15.531 8.27915 16.8904C7.8654 17.3003 7.86229 17.9681 8.27225 18.3819C8.47849 18.5901 8.74997 18.6943 9.02149 18.6943C9.28975 18.6943 9.55812 18.5926 9.76373 18.3889C10.7405 17.4211 12.1726 16.7959 13.4125 16.7959C13.4127 16.7959 13.4129 16.7959 13.413 16.7959H13.4894C13.4896 16.7959 13.4896 16.7959 13.4898 16.7959C14.7296 16.7959 16.1618 17.4212 17.1385 18.3889C17.5522 18.7988 18.2201 18.7958 18.63 18.3819C19.04 17.9682 19.0369 17.3003 18.6231 16.8904Z"
        fill="#333333"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="27" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "Wifi",
};
</script>

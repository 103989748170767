import api, { userApi } from '../api';

const prechecksUrl = '/prechecks/check_user';
const prechecksRoles = 'registration/user_roles';

export default {
  preChekUser({ preCheck }) {
    return userApi.post(`${prechecksUrl}/`, {
      username: preCheck.username,
      password: preCheck.password,
    });
  },
  preChekRoles() {
    return api.get(`${prechecksRoles}/`);
  },
  preChekLegalGuardian() {
    return api.post('/registration/prechecks/check_user/');
  },
};

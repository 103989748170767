<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "@/api";

export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'MIME - Explorador Escolar',
    // all titles will be injected into this template
    titleTemplate: '%s',
    meta: [
      {name: "description", content: "Explorador de Colegios, encuentra todos los colegios" +
          "de chile en un solo lugar"},
      {name: "url", content: "https://mime.mineduc.cl"},
      {name: "keywords", content: "MIME," +
          "mime," +
          "Mime," +
          "MIME mineduc," +
          "Mineduc," +
          "info escuela," +
          "RBD," +
          "rbd," +
          "Ministerio Educación," +
          "ministerio educación," +
          "SAE," +
          "Sistema Admisión Escolar, " +
          "sistema admisión escolar"},
      {name: "keywords", content: "SEP Subvención Escolar Preferencial," +
          "PIE," +
          "Subvención Escolar Preferencial," +
          "Programa de Integración Escolar"},
      {name: "keywords", content: "admisión, " +
          "admision," +
          "matrícula," +
          "matricula," +
          "postulación," +
          "postulacion," +
          "proceso de postulación," +
          "proceso de postulacion," +
          "proceso de admisión," +
          "proceso de admision," +
          "vacante," +
          "vacantes," +
          "cupo," +
          "cupos," +
          "admisión 2022," +
          "admisión 2022," +
          "admisión prekinder 2022," +
          "admision 2022," +
          "admision 2022," +
          "admision prekinder 2022," +
          "admisión 2023," +
          "admisión 2023," +
          "admisión prekinder 2023," +
          "admision 2023," +
          "admision 2023," +
          "admision prekinder 2023," +
          "vacantes 2022," +
          "vacantes 2023," +
          "con vacantes," +
          "con cupos," +
          "vacantes año académico 2022," +
          "sistema de admisión escolar," +
          "cuándo son las postulaciones para," +
          "cómo saber si quedan vacantes," +
          "quedan vacantes," +
          "matrículas 2022," +
          "matrículas 2023," +
          "matrículas segundo semestre 2022," +
          "matrícula 2022," +
          "matrícula 2023," +
          "matrícula segundo semestre 2022"}
    ],
  },
  mounted() {
    api.setStore(this.$store);
    if (this.language === null) {
      this.setLanguage("en");
    }
  },
  methods: {
    ...mapActions({
      setLanguage: "authentication/setLanguage",
    }),
  },
  computed: {
    ...mapGetters({
      language: "authentication/language",
    }),
  },
};
</script>

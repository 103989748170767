<template>
  <svg
    width="43px"
    height="42px"
    viewBox="0 0 43 42"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon id="path-1" points="0 0.476190476 43 0.476190476 43 41.4761905 0 41.4761905" />
    </defs>
    <g id="Symbols" stroke="none" stroke-width="1">
      <g id="Icon-/-School-/-selected" transform="translate(-16.000000, -14.000000)">
        <g id="Group-3" transform="translate(16.000000, 14.000000)">
          <mask id="mask-2" fill="white">
            <use xlink:href="#path-1"></use>
          </mask>
          <g id="Clip-2"></g>
          <path
            d="M12.823764,39.9768601 L16.970259,39.9768601 L16.970259,33.184439
          C16.970259,31.9659749 17.4578389,30.8507705 18.2594532,30.0494755 L18.2945755,29.9792589
          C19.1313122,29.1779639 20.244895,28.6575351 21.501033,28.6575351 C22.755105,28.6575351
          23.8686878,29.1779639 24.7054245,29.9792589 C25.5070389,30.8156622 26.029741,31.9308667
          26.029741,33.184439 L26.029741,39.9768601 L30.176236,39.9768601 L30.176236,21.9002224
          L30.176236,18.4864577 L21.501033,11.5205604 L12.823764,18.4864577 L12.823764,21.9002224
          L12.823764,39.9768601 Z M1.49992793,39.9768601 L11.3259021,39.9768601
          L11.3259021,22.6313008 L1.49992793,22.6313008 L1.49992793,39.9768601 Z
          M8.29505597,35.832017 L4.53077404,35.832017 C4.11343872,35.832017 3.76428194,35.4829993
          3.76428194,35.0658302 L3.76428194,27.5423307 C3.76428194,27.1230965 4.11343872,26.776144
          4.53077404,26.776144 L8.29505597,26.776144 C8.71239129,26.776144 9.06154807,27.1230965
          9.06154807,27.5423307 L9.06154807,35.0658302 C9.06154807,35.4829993 8.71239129,35.832017
          8.29505597,35.832017 L8.29505597,35.832017 Z M5.29726613,34.3347518
          L7.52856388,34.3347518 L7.52856388,28.3085175 L5.29726613,28.3085175
          L5.29726613,34.3347518 Z M31.6761639,39.9768601 L41.4670158,39.9768601
          L41.4670158,22.6313008 L31.6761639,22.6313008 L31.6761639,39.9768601 Z
          M38.469226,35.832017 L34.704944,35.832017 C34.2876087,35.832017 33.9384519,35.4829993
          33.9384519,35.0658302 L33.9384519,27.5423307 C33.9384519,27.1230965 34.2876087,26.776144
          34.704944,26.776144 L38.469226,26.776144 C38.8886273,26.776144 39.2357181,27.1230965
          39.2357181,27.5423307 L39.2357181,35.0658302 C39.2357181,35.4829993 38.8886273,35.832017
          38.469226,35.832017 L38.469226,35.832017 Z M35.4383799,34.3347518 L37.7047999,34.3347518
          L37.7047999,28.3085175 L35.4383799,28.3085175 L35.4383799,34.3347518 Z
          M22.9637726,19.6698135 C22.5794936,19.322861 22.0567914,19.0791682 21.501033,19.0791682
          C20.9080863,19.0791682 20.4205064,19.322861 20.0362274,19.6698135 C19.6540143,20.0539395
          19.4102244,20.5764334 19.4102244,21.1691439 C19.4102244,21.7267461 19.6540143,22.2492401
          20.0362274,22.6313008 C20.4205064,22.9803185 20.9080863,23.2240113 21.501033,23.2240113
          C22.0567914,23.2240113 22.5794936,22.9803185 22.9637726,22.6313008
          C23.3459857,22.2492401 23.5567194,21.7267461 23.5567194,21.1691439
          C23.5567194,20.5764334 23.3459857,20.0539395 22.9637726,19.6698135
          L22.9637726,19.6698135 Z M21.501033,17.5798378 C22.4761928,17.5798378
          23.381108,17.9639638 24.0442992,18.6268909 C24.6703022,19.2526445 25.0897036,20.1571992
          25.0897036,21.1691439 C25.0897036,22.1439152 24.6703022,23.0505351 24.0442992,23.6742235
          L24.009177,23.6742235 C23.381108,24.3392158 22.4761928,24.7212765 21.501033,24.7212765
          C20.5238072,24.7212765 19.618892,24.3392158 18.9557008,23.6742235 C18.3296978,23.0505351
          17.9102964,22.1439152 17.9102964,21.1691439 C17.9102964,20.1571992 18.3296978,19.2877527
          18.9557008,18.6268909 C19.618892,17.9639638 20.5238072,17.5798378 21.501033,17.5798378
          L21.501033,17.5798378 Z M29.7919569,1.38459129 L29.7919569,6.95648305
          C29.7919569,7.34060902 29.4799885,7.6896267 29.0957094,7.72266979 C29.0254649,7.72266979
          28.922164,7.72266979 28.8188632,7.6896267 C27.8767597,7.4087604 26.9016,7.6896267
          25.9594965,7.93331952 C24.7756691,8.24516372 23.5897756,8.5941814 22.2675251,8.14190405
          L22.2675251,10.2318798 L31.3270072,17.4765781 C31.5356748,17.6149461
          31.6761639,17.8607041 31.6761639,18.1023317 L31.6761639,21.1340356
          L42.2335079,21.1340356 C42.6508432,21.1340356 43,21.4809881 43,21.9002224 L43,40.7430468
          C43,41.1602159 42.6508432,41.4761905 42.2335079,41.4761905 L30.9076058,41.4761905
          L25.2632489,41.4761905 L17.7367511,41.4761905 L12.0572719,41.4761905
          L0.768558113,41.4761905 C0.349156777,41.4761905 0,41.1602159 0,40.7430468 L0,21.9002224
          C0,21.4809881 0.349156777,21.1340356 0.768558113,21.1340356 L11.3259021,21.1340356
          L11.3259021,18.1023317 C11.3259021,17.8937472 11.4292029,17.6851627
          11.6048143,17.5467947 L20.7345409,10.2318798 L20.7345409,6.99159133
          L20.7345409,6.95648305 L20.7345409,3.02022445 L20.7345409,1.38459129
          C20.7345409,1.24622334 20.7675972,1.10579019 20.872964,0.965357036
          C21.0816317,0.618404547 21.5340893,0.548187972 21.9183683,0.756772504
          C23.1724403,1.55806754 24.3583337,1.24622334 25.5752174,0.897205654
          C26.7259886,0.58329626 27.8767597,0.269386865 29.2010763,0.653512835
          C29.548167,0.72372941 29.7919569,1.0376388 29.7919569,1.38459129 L29.7919569,1.38459129
          Z M28.2961611,6.08703663 L28.2961611,2.01241008 C27.529669,1.94219351
          26.7259886,2.15077804 25.9594965,2.35936257 C24.7756691,2.67327197 23.5897756,3.02022445
          22.2675251,2.5679471 L22.2675251,3.02022445 L22.2675251,6.53931398 C23.381108,7.0969162
          24.4616346,6.7830068 25.5752174,6.46909741 C26.4470763,6.26051287 27.3189353,6.01682006
          28.2961611,6.08703663 L28.2961611,6.08703663 Z M18.4681209,39.9768601
          L24.4967568,39.9768601 L24.4967568,33.184439 C24.4967568,32.3501009
          24.1827223,31.6169573 23.6269639,31.0614202 C23.0670735,30.5017528 22.3357036,30.1899086
          21.501033,30.1899086 C20.6642964,30.1899086 19.8978043,30.5017528 19.3751021,31.0614202
          L19.3399798,31.0944633 C18.8172777,31.6520655 18.4681209,32.383144 18.4681209,33.184439
          L18.4681209,39.9768601 Z"
            id="Fill-1"
            fill="#000000"
            mask="url(#mask-2)"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "school",
};
</script>

<template>
  <section class="search-location">
    <p
      class="search-location__no-info"
      v-if="isInMainMap"
      v-t="'shared.location.no-location-message'"
    />
    <div class="search-location__div">
      <div class="d-flex" v-if="userRegister && !isInMapFilter">
        <h3
          class="search-location__div__title"
          :class="{ 'search-location__div__title__confirm': confirmLocation }"
          v-t="'shared.location.title'"
        />
        <img
          v-if="!confirmLocation || isGuest"
          class="mr-1"
          src="@/assets/iconsNewHaven/user_register/icons/location-pin.svg"
        />
        <img v-if="!isInMainMap && !isGuest" src="@/assets/iconsChile/registration/1-2.svg" />
      </div>
    </div>
    <p class="search-location__subtitle">
      {{ selectSubtitle() }}
    </p>
    <div
      v-if="commune === ''"
      class="w-full d-flex gap-2"
      :class="{ 'flex-column': isMobile, 'flex-row': !isMobile }"
    >
      <button
        @click="displayMap"
        class="search-location__select-btn mt-3"
        :class="{ 'search-location__select-btn__selected': showMap }"
      >
        {{ $t("shared.location.set-location-btn") }}
        <div class="search-location__select-btn__bold">
          {{ $t("shared.location.set-location-bold") }}
        </div>
      </button>
      <button
        @click="dislpayInputs"
        class="search-location__select-btn mt-3"
        :class="{ 'search-location__select-btn__selected': showInputs }"
      >
        {{ $t("shared.location.write-location-bold") }}
        <div class="search-location__select-btn__bold">
          {{ $t("shared.location.write-location-btn") }}
        </div>
      </button>
    </div>
    <div
      class="w-full"
      :class="showMap && commune !== '' ? '' : 'mt-5'"
      v-if="showMap || showInputs"
    >
      <div class="search-location__input-title">
        {{ $t("shared.location.commune-title") }}
        <p class="search-location__input-title__dot">*</p>
      </div>
      <v-autocomplete
        class="registration-input w-full"
        :items="municipalityLabels"
        item-text="name"
        v-model="commune"
        @change="setMapCenter"
        :placeholder="$t('registrationFlow.location.municipality')"
        dense
        outlined
        :dark="commune !== '' ? true : false"
        :background-color="commune !== '' ? '#2b5bff' : '#FFFFFF'"
        color="#2b5bff"
        :disabled="municipalityLabels.length === 0 && !commune"
      />
    </div>
    <div class="search-location__write-div w-full" v-if="showInputs">
      <div v-if="showInputs">
        <div class="search-location__input-title">
          {{ $t("shared.location.address-title") }}
          <p class="search-location__input-title__dot">*</p>
        </div>
        <gmap-autocomplete
          :options="autocompleteOptions"
          v-if="!showMap"
          class="search-location__search"
          :placeholder="$t('shared.location.write-address')"
          @place_changed="setPlace($event, false)"
        >
        </gmap-autocomplete>
      </div>
    </div>
    <div class="w-full" style="margin-top: -15px">
      <div class="search-location__instructions" v-if="showMap && commune !== ''">
        <div class="search-location__instructions--bold">
          {{ $t("shared.location.map-instructions-bold") }}
        </div>
        {{ $t("shared.location.map-instruction") }}
      </div>
      <GmapMap
        v-if="showMap && commune !== ''"
        style="height: 500px"
        class="mt-2 w-full"
        ref="locationMapRef"
        :center="mapCenter"
        :options="{
          zoom: 15,
          disableDefaultUI: true,
          clickableIcons: false,
          draggableIcons: false,
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          scaleControl: false,
          rotateControl: false,
          styles: mapStyle,
        }"
      >
        <GmapMarker
          :position="mapCenter"
          :clickable="true"
          :draggable="true"
          @dragend="setPlace($event, true)"
        />
      </GmapMap>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CONFIG from "@/config";
import mapStyle from "@/assets/mapStyle.json";

export default {
  name: "SearchLocation",
  data() {
    return {
      showMap: false,
      showInputs: false,
      mapStyle,
      homeLocation: null,
      zoom: 13,
      addressDetail: null,
      CONFIG,
      commune: "",
      mapCenter: CONFIG.defaultLocation,
      autocompleteOptions: {
        componentRestrictions: {
          country: ["cl"],
        },
      },
    };
  },
  props: {
    userRegister: {
      type: Boolean,
      default: false,
    },
    confirmLocation: {
      type: Boolean,
      default: false,
    },
    isInMapFilter: {
      type: Boolean,
      default: false,
    },
    isInMainMap: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.userHomeLocation !== null) {
      this.homeLocation = {
        lat: this.userHomeLocation.lat,
        lng: this.userHomeLocation.lng,
      };
    }
  },
  computed: {
    ...mapGetters({
      userHomeLocation: "userRegister/userHomeLocation",
      municipalityLabels: "options/municipalityLabels",
      municipalities: "options/municipalities",
      isGuest: "authentication/isGuest",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({
      setFastRegisterLocation: "userRegister/setFastRegisterLocation",
    }),
    /* eslint-disable */
    async setPlace(place, isSetInDrag) {
      let location;
      let country;
      let addressDetail;

      if (isSetInDrag) {
        this.homeLocation = {
          lat: place.latLng.lat(),
          lng: place.latLng.lng(),
        };
        const geocoder = new google.maps.Geocoder();
        await geocoder
          .geocode({ location: this.homeLocation }, (results, status) => {
            if (status === "OK") {
              if (!results[0]) {
                // window.alert('No results found');
                return null;
              }
            }
          })
          .then((response) => {
            let zipcode = null;
            const address = response.results[0];
            let adrressStreet;
            let addressNumber;
            address.address_components.forEach((component) => {
              if (component.types[0] === "route") {
                adrressStreet = component.long_name;
              }
              if (component.types[0] === "street_number") {
                addressNumber = component.long_name;
              }
              if (component.types[0] === "postal_code") {
                zipcode = component.long_name;
              }
            });

            if (zipcode == null) {
              // generic zipcode, middle of newHaven
              zipcode = "06510";
            }
            const coordinates = {
              lat: address.geometry.location.lat(),
              lng: address.geometry.location.lng(),
            };
            addressDetail = address.formatted_address;
            location = {
              coordinates,
              address_details: addressDetail,
              zipcode,
              address_name: { id: 1, address_name: "home" },
              address_street: adrressStreet,
              address_number: addressNumber,
              location: this.searchLocality(address.address_components),
            };
            country = this.searchLocality(address.address_components);
            this.$emit("locationInfo", location);
            this.$emit("selected-country", country);
          });
      } else {
        this.homeLocation = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.addressDetail = place.formatted_address;
        location = {
          coordinates: this.homeLocation,
          address_details: this.addressDetail,
          address_name: { id: 1, address_name: "home" },
          zipcode: null,
          location: this.searchLocality(place.address_components),
        };
        country = this.searchLocality(place.address_components);
        place.address_components.forEach((component) => {
          if (component.types[0] === "route") {
            location.address_street = component.long_name;
          }
          if (component.types[0] === "street_number") {
            location.address_number = component.long_name;
          }
          if (component.types[0] == "postal_code") {
            location.zipcode = component.long_name;
          }
        });
        if (!this.userRegister) {
          this.setFastRegisterLocation({ location });
          this.addressDetail = "";
        } else {
          this.$emit("locationInfo", location);
        }
        this.$emit("selected-country", country);
      }
      /*
       */
      // *send location
      this.$emit("selected-addressDetail", this.addressDetail);
      this.$emit("selected-location", this.homeLocation);
    },
    searchLocality(addresses) {
      // eslint-disable-next-line no-restricted-syntax
      for (const { types, long_name } of addresses) {
        const isLocality = types.find((type) => type === "locality") || null;

        if (isLocality) {
          // eslint-disable-next-line camelcase
          return long_name;
        }
      }

      return null;
    },
    displayMap() {
      this.showInputs = false;
      if (!this.showMap) {
        this.showMap = true;
        return;
      }
      this.showMap = false;
      return;
    },
    dislpayInputs() {
      this.showMap = false;
      if (!this.showInputs) {
        this.showInputs = true;
        return;
      }
      this.showInputs = false;
      return;
    },
    selectSubtitle() {
      if (this.isGuest) {
        return this.$t("shared.location.sub-title-guest");
      }
      if (this.showMap && this.commune !== "") {
        return this.$t("shared.location.sub-title-map");
      }
      if (this.isInMapFilter) {
        return this.$t("shared.location.sub-title-student");
      }
      return this.$t("shared.location.sub-title");
    },
    setMapCenter() {
      const commune = this.municipalities.find(
        (municipality) => municipality.name === this.commune
      );
      this.mapCenter = {
        lat: commune.default_coordinate.lat,
        lng: commune.default_coordinate.lon,
      };
      document.activeElement.blur();
    },
    /* eslint-disable */
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #2b5bff;
}
.v-text-field--placeholder {
  color: #2b5bff !important;
}
</style>

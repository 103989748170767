import services from "../services";

/* eslint-disable no-shadow */
const getDefaultState = () => ({
  loadingFeedback: true,
  feedbackApplication: {},
});
const state = getDefaultState();
const getters = {
  loadingFeedback: (state) => state.loadingFeedback,
  feedbackApplication: (state) => state.feedbackApplication,
};

const mutations = {
  setFeedbackApplication(state, data) {
    state.feedbackApplication = data;
  },
  setLoadingFeedback(state, data) {
    state.loadingFeedback = data;
  },
};
const actions = {
  async retriveFeedbackApplications({ commit, dispatch }, uuid) {
    commit("setLoadingFeedback", true);
    let dataFeedback = {};
    
    const userData = await dispatch("retrieveUserDetails", uuid);
    const applicationsData = await dispatch("retrieveApplications", userData.students);
    // const sendDataRecommendation = {
    //   students: applicationsData,
    //   uuid,
    // };
    // const recommendationsWithStudents = await dispatch(
    //   "retrieveRecommendations",
    //   sendDataRecommendation
    // );

    dataFeedback = {
      ...userData,
      students: applicationsData,
    };

    commit("setFeedbackApplication", dataFeedback);
    commit("setLoadingFeedback", false);
  },
  async retrieveUserDetails() {
    const { data: userDetails } = await services.authenticationService.retrieveUserDetails();
    let { data: listStudents } = await services.authenticationService.retrieveStudents();

    // eslint-disable-next-line camelcase
    listStudents = listStudents.map(({ uuid, address, location, grades }) => {
      return {
        uuid,
        address,
        location,
        grades,
        // eslint-disable-next-line camelcase
        // isInSae: is_in_sae ?? false,
      };
    });

    // listStudents = listStudents.filter((student) => student.isInSae === true);
    return {
      ...userDetails,
      students: listStudents,
    };
  },
  async retrieveApplications({ dispatch }, students) {
    const applicantsRequest = [];

    async function getApplications(student, counter) {
      const { data: results } = await services.feedbackService.results(student.uuid);
      const round = results[0]?.intervention?.treatment_id === 2 ? 1 : 2;
      const { data: applications } = await services.feedbackService.applications(student.uuid, round);
      applications[0].api_response.intervention = results[0].intervention;
      applications[0].api_response.noAdmission = results[0].no_admission;
      applications[0].api_response.admission = results[0].admission;

      const applicationsWithLocation = await dispatch("getLocationsSchools", applications[0]);
      applicantsRequest.push({ ...applicationsWithLocation, ...student });

      if (applicantsRequest.length !== students.length) {
        await getApplications(students[counter + 1], counter + 1);
      }
    }
    await getApplications(students[0], 0);

    return applicantsRequest;
  },
  async getLocationsSchools({}, listSchools) {
    const newListSchools = { ...listSchools };
    // const totalSchools = listSchools.api_response.portfolio.length;

    const uuids = listSchools.api_response.portfolio.map((school) => {
      return {
        fieldname: "campus_code",
        fieldvalue: school.campus.campus_code,
      };
    });

    const { data: listLocation } = await services.elasticSearchsService.elasticSearchCampuses(
      uuids
    );

    newListSchools.api_response.portfolio.forEach((school) => {
      school.campus.location = listLocation.results.find(
        (schoolLocation) => schoolLocation.campus_code === school.campus.campus_code
      ).location;
    });

    // async function getLocation(school, counter) {
    //   const newCounter = counter + 1;
    //   const { data } = await services.elasticSearchsService.elasticMainSerach({
    //     mainSearch: school.campus.campus_code,
    //   });
    //   newListSchools.api_response.portfolio[counter].campus.location = data.results[0].location;
    //   if (totalSchools !== newCounter) {
    //     await getLocation(newListSchools.api_response.portfolio[newCounter], newCounter);
    //   }
    // }

    // await getLocation(newListSchools.api_response.portfolio[0], 0);

    return newListSchools;
  },
  async retrieveRecommendations({}, dataStudents) {
    const { students } = dataStudents;
    let applicantsRequest = [...students];

    const { data: recommendationsApplicants } = await services.feedbackService.recommendations();
    // eslint-disable-next-line array-callback-return
    recommendationsApplicants.forEach((applicant) => {
      applicant.totalRecommendations = applicant.recommendations;
      applicant.locationDefault = applicant.def_location;
    });

    applicantsRequest = applicantsRequest.map((student) => {
      const findRecommendations = recommendationsApplicants.find(
        // eslint-disable-next-line camelcase
        ({ applicant_uuid }) => student.applicant_uuid === applicant_uuid
      );

      if (findRecommendations !== undefined) {
        return {
          ...student,
          recommendation: findRecommendations.recommendations,
          schoolsAtLocations: findRecommendations.schools_at_location,
          totalRecommendations: findRecommendations.totalRecommendations,
          locationDefault: findRecommendations.locationDefault,
        };
      }
    });

    return applicantsRequest.filter((applicant) => applicant !== undefined);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

/* eslint-disable no-shadow */
const getDefaultState = () => ({
  filters: {
    typeFilter: "",
    multimediaFilter: [],
    sepFilter: null,
    pieFilter: null,
    loginFilter: "",
    paymentFilter: null,
    performanceFilter: null,
    distanceFilter: null,
    vacanciesFilter: null,
    dependencyFilter: null,
  },
  cleanedFilters: null,
  filterLoading: false
});

const state = {
  ...getDefaultState(),
};

const getters = {
  typeFilter: ({ filters }) => filters.typeFilter,
  multimediaFilter: ({ filters }) => filters.multimediaFilter,
  sepFilter: ({ filters }) => filters.sepFilter,
  pieFilter: ({ filters }) => filters.pieFilter,
  loginFilter: ({ filters }) => filters.loginFilter,
  paymentFilter: ({ filters }) => filters.paymentFilter,
  performanceFilter: ({ filters }) => filters.performanceFilter,
  distanceFilter: ({ filters }) => filters.distanceFilter,
  distanceFilter: ({ filters }) => filters.distanceFilter,
  vacanciesFilter: ({ filters }) => filters.vacanciesFilter,
  dependencyFilter: ({ filters }) => filters.dependencyFilter,
  cleanedFilters: ({ cleanedFilters }) => cleanedFilters,
  filterLoading: ({ filterLoading }) => filterLoading,
};

const mutations = {
  resetStore(state) {
    Object.assign(state, getDefaultState());
  },
  setTypeFilter(state, { type }) {
    state.filters.typeFilter = type;
  },
  setMultimediaFilter(state, { multimedia }) {
    state.filters.multimediaFilter = multimedia;
  },
  setSepFilter(state, { priority }) {
    state.filters.sepFilter = priority;
  },
  setPieFilter(state, { priority }) {
    state.filters.pieFilter = priority;
  },
  setPaymentFilter(state, { payment }) {
    state.filters.paymentFilter = payment;
  },
  setPerformanceFilter(state, { performance }) {
    state.filters.performanceFilter = performance;
  },
  setLoginFilter(state, { login }) {
    state.filters.loginFilter = login;
  },
  setVacnaciesFilter(state, { vacancies }) {
    state.filters.vacanciesFilter = vacancies;
  },
  setDistanceFilter(state, { distance }) {
    state.filters.distanceFilter = distance;
  },
  setDependencyFilter(state, { dependency }) {
    state.filters.dependencyFilter = dependency;
  },
  setCleanedFilters(state, { bool }) {
    state.cleanedFilters = bool;
  },
  setFilterLoading(state, { bool }) {
    state.filterLoading = bool;
  },
};

const actions = {
  resetStores({ commit }, {}) {
    commit("resetStore");
  },
  setTypeFilter({ commit }, { type }) {
    commit("setTypeFilter", { type });
  },
  setMultimediaFilter({ commit }, { multimedia }) {
    commit("setMultimediaFilter", { multimedia });
  },
  setSepFilter({ commit }, { priority }) {
    commit("setSepFilter", { priority });
  },
  setPieFilter({ commit }, { priority }) {
    commit("setPieFilter", { priority });
  },
  setPaymentFilter({ commit }, { payment }) {
    commit("setPaymentFilter", { payment });
  },
  setPerformanceFilter({ commit }, { performance }) {
    commit("setPerformanceFilter", { performance });
  },
  setLoginFilter({ commit }, { login }) {
    commit("setLoginFilter", { login });
  },
  setDistanceFilter({ commit }, { distance }) {
    commit("setDistanceFilter", { distance: distance });
  },
  setVacnaciesFilter({ commit }, { vacancies }) {
    commit("setVacnaciesFilter", { vacancies: vacancies });
  },
  setDependencyFilter({ commit }, { dependency }) {
    commit("setDependencyFilter", { dependency: dependency });
  },
  setCleanedFilters({ commit }, { bool }) {
    commit("setCleanedFilters", { bool: bool });
  },
  setFilterLoading({ commit}, { bool }) {
    commit('setFilterLoading', { bool });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

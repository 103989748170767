<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.56137 3.65988L7.99999 4.46147L8.43862 3.6599C8.68343 3.21253 9.09122 2.61388 9.69004 2.15077L9.38416 1.75525L9.69004 2.15077C10.3061 1.6743 10.9951 1.4375 11.75 1.4375C13.8593 1.4375 15.5 3.15639 15.5 5.54731C15.5 6.8131 14.9987 7.89931 14.044 9.05137C13.0766 10.2187 11.6825 11.4088 9.94579 12.8889L9.94578 12.8889L9.94553 12.8891C9.35949 13.3885 8.69308 13.9565 8 14.5626L7.99997 14.5626L8.00027 14.5628L8.00002 14.5626C8.00001 14.5625 8.00001 14.5625 8 14.5625C7.99999 14.5625 7.99999 14.5625 7.99998 14.5625C7.30742 13.9569 6.6414 13.3893 6.05598 12.8904L6.0546 12.8892L6.05459 12.8892C4.31765 11.409 2.92353 10.2188 1.95606 9.05141C1.00127 7.89931 0.5 6.81309 0.5 5.54731C0.5 3.15639 2.14075 1.4375 4.25 1.4375C5.00495 1.4375 5.69387 1.6743 6.30996 2.15077L6.61584 1.75525L6.30996 2.15077C6.90878 2.61389 7.31657 3.21251 7.56137 3.65988Z"
      stroke="#F90E1F"
    />
  </svg>
</template>

<script>
export default {
  name: "HeartOutline",
};
</script>

<template>
  <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39 23c0 7.18-5.82 13-13 13s-13-5.82-13-13 5.82-13 13-13 13 5.82 13 13z"
      fill="#fff"
      stroke="#F655AB"
      stroke-width="20"
    />
  </svg>
</template>

<script>
export default {
  name: "NoPriorityExplored",
};
</script>

<style scoped></style>

<template>
  <div class="register-finale">
    <h1 class="register-finale__title">{{ $t("user_register.register_finale.final_message") }}</h1>
    <div class="btn_container pt-3">
      <base-button class="btn-continue" @click="toExplorer">
        {{ $t("user_register.register_finale.explore_btn") }}
      </base-button>
      <base-button class="btn-continue" @click="toDashboard">
        {{ $t("user_register.register_finale.dashboard_btn") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";

export default {
  name: "RegisterFinaleModal",
  components: {
    BaseButton,
  },
  data() {
    return {
      code: "",
      showCode: false,
    };
  },
  methods: {
    ...mapActions({
      getStudents: "authentication/getStudents",
      verifyCode: "authentication/verifyCode",
      error: "snackbar/error",
      success: "snackbar/success",
      getUserDetails: "authentication/getUserDetails",
      setCurrentStep: "userRegister/setCurrentStep",
    }),
    toDashboard() {
      this.setCurrentStep({
        nextStep: 5,
      }).then(() => {
        this.getStudents({
          callback: () => {
            this.getUserDetails({
              legalGuardianUUID: this.legalGuardianUUID,
              token: this.token,
            });
            this.$router.push({ name: "Dashboard" });
          },
          selectFirst: false,
          updateMarkers: true,
        });
      });
    },
    toExplorer() {
      this.setCurrentStep({
        nextStep: 5,
      }).then(() => {
        this.$emit("closeModal");
        this.getStudents({
          callback: () => {
            this.getUserDetails({
              legalGuardianUUID: this.legalGuardianUUID,
              token: this.token,
            });
            if (this.currentRoute !== "Explorer") {
              this.$router.push({ name: "Explorer" });
            }
          },
          selectFirst: false,
          updateMarkers: true,
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      currentStep: "userRegister/currentStep",
      validCode: "userRegister/validCode",
      getRegister: "userRegister/register",
      email: "authentication/email",
      preCheckStatus: "authentication/preCheckStatus",
      legalGuardianUUID: "authentication/legalGuardianUUID",
      token: "authentication/token",
    }),
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
    currentRoute() {
      return this.$route.name;
    },
  },
};
</script>

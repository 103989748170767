<template>
  <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39 23c0 7.18-5.82 13-13 13s-13-5.82-13-13 5.82-13 13-13 13 5.82 13 13z"
      fill="#fff"
      stroke="#F655AB"
      stroke-width="20"
    />
    <circle cx="9" cy="10" r="9" fill="#180D81" />
    <path
      d="M9.012 13.653v-7.47M12 8.369c-.995.034-2.986-.452-2.986-2.674M6.024 8.369c.995.034 2.986-.452 2.986-2.674"
      stroke="#fff"
      stroke-width=".5"
    />
  </svg>
</template>

<script>
export default {
  name: "HasPriorityExplored",
};
</script>

<style scoped></style>

<template>
  <div class="confirm-location">
    <div class="d-flex justify-space-between align-center mb-4">
      <h1 class="title" v-t="selectTitle()" />
      <img src="@/assets/iconsNewHaven/user_register/icons/location-pin.svg" />
    </div>
    <div v-if="!isInMapFilter && !isInMainMap">
      <!-- {{ legalGuardianAddress }} -->
      <div v-for="(address, index) in legalGuardianAddress" :key="index">
        <div class="confirm-location__div" v-if="!deletedAddress.includes(address.address_details)">
          <div class="confirm-location__div__title">
            <h3 class="confirm-location__div__title__text">
              {{ nameAddress(address) }}
            </h3>
            <img
              style="cursor: pointer"
              src="@/assets/iconsNewHaven/user_register/icons/delete.svg"
              @click="deleteLocation(address.address_details)"
            />
          </div>
          <p class="confirm-location__div__address">
            {{ formatedAddress(address) }}
          </p>
        </div>
      </div>
    </div>
    <div class="confirm-location__confirm d-flex" v-if="!editModal">
      <img
        src="@/assets/iconsNewHaven/icons/confirm-off.svg"
        style="cursor: pointer"
        v-if="!confirmLocation"
        class="mr-2"
        @click="changeConfirm(legalGuardianAddress[0].address_details)"
      />
      <img
        v-else
        class="mr-2"
        @click="changeConfirm(legalGuardianAddress[0].address_details)"
        src="@/assets/iconsNewHaven/icons/confirm-on.svg"
        style="cursor: pointer"
      />
      <p
        v-t="'user_register.confirm_location.confirm_text'"
        class="confirm-location__confirm__text"
      />
    </div>
    <register-location
      v-if="!confirmLocation"
      :userRegister="true"
      @locationInfo="setFastLocation"
      :confirmLocation="true"
      :isInMapFilter="isInMapFilter"
      :isInMainMap="isInMainMap"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RegisterLocation from "../../../shared/SearchLocation.vue";

export default {
  name: "ConfirmLocation",
  components: {
    RegisterLocation,
  },
  data() {
    return {
      confirmLocation: false,
      deletedAddress: [],
      confirmedLocation: null,
    };
  },
  props: {
    editModal: {
      type: Boolean,
      default: false,
    },
    isInMapFilter: {
      type: Boolean,
      default: false,
    },
    isInMainMap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentStep: "userRegister/currentStep",
      legalGuardianAddress: "authentication/legalGuardianAddress",
    }),
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
  },
  methods: {
    ...mapActions({
      error: "snackbar/error",
      success: "snackbar/success",
    }),
    selectTitle() {
      if (this.editModal && !this.isInMapFilter) {
        return "user_register.confirm_location.edit_title";
      }
      if (this.editModal && this.isInMapFilter) {
        return "user_register.confirm_location.edit_student";
      }
      return "user_register.confirm_location.title";
    },
    nameAddress(address) {
      // eslint-disable-next-line no-prototype-builtins
      const existsName = address.hasOwnProperty("address_name");
      if (existsName) {
        return address.address_name.address_name ?? "Home";
      }
      return "Home";
    },
    formatedAddress(address) {
      const addressDetails = address.address_details;

      let indexComma = addressDetails.indexOf(",");
      const commaList = [];
      // eslint-disable-next-line no-unused-vars
      let count = 0;

      while (indexComma !== -1) {
        count += 1;
        indexComma = address.address_details.indexOf(",", (indexComma += 1));
        commaList.push(indexComma);
      }
      return addressDetails.slice(0, commaList[0]);
    },
    backMethod() {
      this.$emit("backStep");
    },
    changeConfirm(addressDetail) {
      if (!this.confirmLocation && this.legalGuardianAddress.length > 0) {
        this.confirmLocation = true;
        // eslint-disable-next-line
        const geocoder = new google.maps.Geocoder();
        // eslint-disable-next-line
        geocoder
          .geocode({ address: addressDetail }, (results, status) => {
            if (status === "OK") {
              if (!results[0]) {
                window.alert("No results found"); // eslint-disable-line no-alert
                return null;
              }
            }
          })
          .then((response) => {
            let zipcode = null;
            const address = response.results[0];
            let adrressStreet;
            let addressNumber;
            let location;
            address.address_components.forEach((component) => {
              if (component.types[0] === "route") {
                adrressStreet = component.long_name;
              }
              if (component.types[0] === "street_number") {
                addressNumber = component.long_name;
              }
              if (component.types[0] === "postal_code") {
                zipcode = component.long_name;
              }
              if (component.types[0] === "administrative_area_level_2") {
                location = component.long_name;
              }
            });
            const coordinates = {
              lat: address.geometry.location.lat(),
              lng: address.geometry.location.lng(),
            };
            addressDetail = address.formatted_address;
            location = {
              coordinates,
              address_details: addressDetail,
              zipcode,
              address_name: { id: 1, address_name: "home" },
              address_street: adrressStreet,
              address_number: addressNumber,
              location: location,
            };
            this.$emit("locationInfo", location);
          });
        return this.confirmLocation;
      }
      this.confirmLocation = false;
      return this.confirmLocation;
    },
    deleteLocation(location) {
      if (!this.deletedAddress.includes(location)) {
        this.deletedAddress.push(location);
      }
    },
    setFastLocation(location) {
      return this.$emit("locationInfo", location);
    },
  },
};
</script>

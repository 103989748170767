<template>
  <svg width="76" height="87" viewBox="0 0 76 87" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.2186 57.8739C20.3914 57.8739 21.3421 56.9394 21.3421 55.7867C21.3421 54.634 20.3914 53.6996 19.2186 53.6996C18.0458 53.6996 17.0951 54.634 17.0951 55.7867C17.0951 56.9394 18.0458 57.8739 19.2186 57.8739Z"
      fill="#EF9620"
    />
    <path
      d="M65.5789 40.3239C66.7976 40.3239 67.7856 39.3528 67.7856 38.155C67.7856 36.9571 66.7976 35.9861 65.5789 35.9861C64.3602 35.9861 63.3722 36.9571 63.3722 38.155C63.3722 39.3528 64.3602 40.3239 65.5789 40.3239Z"
      fill="#19CAD0"
    />
    <path
      d="M46.8478 22.2717C48.0162 22.2717 48.9634 21.3408 48.9634 20.1924C48.9634 19.044 48.0162 18.113 46.8478 18.113C45.6794 18.113 44.7322 19.044 44.7322 20.1924C44.7322 21.3408 45.6794 22.2717 46.8478 22.2717Z"
      fill="#CF82AB"
    />
    <path
      d="M20.1775 4.51558C21.3394 4.51558 22.2812 3.58986 22.2812 2.44792C22.2812 1.30597 21.3394 0.380249 20.1775 0.380249C19.0157 0.380249 18.0738 1.30597 18.0738 2.44792C18.0738 3.58986 19.0157 4.51558 20.1775 4.51558Z"
      fill="#FC010A"
    />
    <path
      d="M2.01258 83.745C2.28198 84.2239 2.65439 84.6055 3.12187 84.882C3.58936 85.1623 4.12024 85.3025 4.71451 85.3025C5.2018 85.3025 5.66137 85.2285 6.0932 85.0767C6.52107 84.9287 6.89348 84.7262 7.21438 84.4731C7.53132 84.22 7.75715 83.9513 7.88788 83.6632V85.6101C7.57094 85.9723 7.1193 86.2565 6.53296 86.4551C5.94662 86.6576 5.34047 86.7588 4.71847 86.7588C3.83499 86.7588 3.03472 86.5564 2.3216 86.1553C1.60848 85.7542 1.04195 85.1974 0.629921 84.4887C0.206012 83.7878 0 82.9856 0 82.09C0 81.1828 0.206012 80.3767 0.62596 79.6758C1.04195 78.9749 1.60452 78.422 2.31764 78.0209C3.03075 77.6198 3.83103 77.4174 4.71451 77.4174C5.3365 77.4174 5.93869 77.5186 6.529 77.7211C7.11534 77.9236 7.56698 78.2039 7.88392 78.5661V80.513C7.75318 80.2132 7.52736 79.9406 7.21042 79.6953C6.89348 79.45 6.52107 79.2514 6.08924 79.1034C5.66137 78.9554 5.2018 78.8776 4.71054 78.8776C4.11628 78.8776 3.5854 79.0139 3.11791 79.2864C2.65042 79.559 2.27802 79.9367 2.00862 80.4235C1.73922 80.9102 1.60056 81.467 1.60056 82.09C1.60056 82.7131 1.73922 83.266 2.01258 83.745Z"
      fill="#170083"
    />
    <path
      d="M9.99536 79.6758C10.4113 78.9749 10.9739 78.422 11.687 78.0209C12.4002 77.6198 13.2004 77.4174 14.0839 77.4174C14.9832 77.4174 15.7835 77.6198 16.4927 78.0209C17.1979 78.422 17.7604 78.9749 18.1764 79.6758C18.5924 80.3767 18.7984 81.1828 18.7984 82.09C18.7984 82.9856 18.5924 83.7878 18.1764 84.4926C17.7604 85.2013 17.1979 85.7542 16.4927 86.1592C15.7875 86.5603 14.9832 86.7627 14.0839 86.7627C13.2004 86.7627 12.4002 86.5603 11.687 86.1592C10.9739 85.7581 10.4074 85.2013 9.99536 84.4926C9.57938 83.7839 9.3694 82.9818 9.3694 82.09C9.3694 81.1828 9.57542 80.3767 9.99536 79.6758ZM11.382 83.745C11.6514 84.2239 12.0238 84.6055 12.4913 84.882C12.9588 85.1623 13.4896 85.3025 14.0839 85.3025C14.6782 85.3025 15.2091 85.1623 15.6765 84.882C16.144 84.6016 16.5164 84.2239 16.7858 83.745C17.0552 83.266 17.1899 82.7131 17.1899 82.09C17.1899 81.467 17.0552 80.9102 16.7858 80.4235C16.5164 79.9367 16.144 79.559 15.6765 79.2864C15.2091 79.0139 14.6782 78.8776 14.0839 78.8776C13.4896 78.8776 12.9588 79.0139 12.4913 79.2864C12.0238 79.559 11.6514 79.9367 11.382 80.4235C11.1126 80.9102 10.9739 81.467 10.9739 82.09C10.9739 82.7131 11.1086 83.266 11.382 83.745Z"
      fill="#170083"
    />
    <path
      d="M26.128 79.4422C25.7991 79.0645 25.3158 78.8776 24.6819 78.8776C24.2184 78.8776 23.8143 78.971 23.4617 79.1579C23.1131 79.3448 22.8397 79.6096 22.6495 79.9484C22.4594 80.2872 22.3603 80.6766 22.3603 81.1166V86.568H20.7756V77.6121H22.3603V79.0139C22.6654 78.4687 23.0457 78.0676 23.5013 77.8067C23.9569 77.5459 24.4838 77.4174 25.0781 77.4174C26.0408 77.4174 26.8054 77.7328 27.364 78.3597C27.9266 78.9905 28.2039 79.8433 28.2039 80.9219V86.568H26.6192V81.1166C26.6232 80.3767 26.4568 79.8199 26.128 79.4422Z"
      fill="#170083"
    />
    <path
      d="M31.9914 84.5823C32.2291 84.8159 32.5025 84.9989 32.8154 85.1352C33.1245 85.2715 33.4652 85.3377 33.8376 85.3377C34.2853 85.3377 34.6379 85.2364 34.8954 85.0378C35.1529 84.8393 35.2836 84.5589 35.2836 84.2123C35.2836 83.9008 35.1806 83.636 34.9786 83.4219C34.7726 83.2077 34.5111 83.0247 34.1941 82.8767C33.8772 82.7288 33.5404 82.5886 33.1839 82.4562C32.7877 82.3004 32.3876 82.1135 31.9874 81.9033C31.5833 81.6891 31.2466 81.4087 30.9772 81.0661C30.7078 80.7234 30.5731 80.2912 30.5731 79.7694C30.5731 79.2359 30.7157 78.7959 30.997 78.4455C31.2822 78.095 31.6507 77.8341 32.1063 77.6667C32.5619 77.4992 33.0333 77.4136 33.5206 77.4136C34.0079 77.4136 34.4675 77.4915 34.8993 77.6472C35.3272 77.803 35.7075 78.0093 36.0403 78.2702C36.3692 78.5311 36.6267 78.8271 36.8129 79.1658L35.5451 79.9641C35.2955 79.6253 34.9825 79.3489 34.6022 79.1269C34.2219 78.9049 33.8019 78.7959 33.3226 78.7959C32.9937 78.7959 32.7085 78.866 32.4708 79.0101C32.2331 79.1542 32.1142 79.3722 32.1142 79.672C32.1142 79.9057 32.2053 80.1121 32.3915 80.2951C32.5777 80.4781 32.8154 80.6377 33.1047 80.7818C33.3939 80.9259 33.6989 81.0622 34.0159 81.1907C34.5428 81.397 35.0261 81.6229 35.4619 81.8604C35.8977 82.1018 36.2463 82.39 36.4999 82.7288C36.7574 83.0675 36.8881 83.4998 36.8881 84.0332C36.8881 84.812 36.5989 85.4623 36.0284 85.9802C35.454 86.4981 34.6973 86.759 33.7583 86.759C33.1522 86.759 32.6015 86.6499 32.1142 86.4358C31.6269 86.2216 31.2149 85.9451 30.8781 85.6103C30.5414 85.2715 30.2878 84.9288 30.1135 84.5784L31.3615 83.8191C31.5437 84.0955 31.7537 84.3486 31.9914 84.5823Z"
      fill="#170083"
    />
    <path
      d="M39.0909 73.1535C39.3088 72.9472 39.5624 72.842 39.8516 72.842C40.1566 72.842 40.4141 72.9472 40.6241 73.1535C40.8341 73.3599 40.9411 73.613 40.9411 73.9129C40.9411 74.1971 40.8341 74.4502 40.6241 74.6605C40.4141 74.8747 40.1566 74.9837 39.8516 74.9837C39.5624 74.9837 39.3048 74.8747 39.0909 74.6605C38.873 74.4463 38.7621 74.1971 38.7621 73.9129C38.766 73.613 38.873 73.3599 39.0909 73.1535ZM40.6479 77.6121V86.5681H39.0632V77.6121H40.6479Z"
      fill="#170083"
    />
    <path d="M45.3028 71.3818V86.5681H43.7181V71.3818H45.3028Z" fill="#170083" />
    <path
      d="M48.4008 73.1535C48.6187 72.9472 48.8723 72.842 49.1615 72.842C49.4666 72.842 49.7241 72.9472 49.934 73.1535C50.144 73.3599 50.251 73.613 50.251 73.9129C50.251 74.1971 50.144 74.4502 49.934 74.6605C49.7241 74.8708 49.4666 74.9837 49.1615 74.9837C48.8723 74.9837 48.6148 74.8747 48.4008 74.6605C48.183 74.4463 48.072 74.1971 48.072 73.9129C48.076 73.613 48.183 73.3599 48.4008 73.1535ZM49.9578 77.6121V86.5681H48.3731V77.6121H49.9578Z"
      fill="#170083"
    />
    <path
      d="M55.1083 84.7184C55.4371 85.1078 55.9205 85.3025 56.5544 85.3025C57.0179 85.3025 57.422 85.2091 57.7746 85.0222C58.1232 84.8353 58.3966 84.5705 58.5867 84.2317C58.7769 83.8929 58.876 83.5035 58.876 83.0635V77.6121H60.4607V86.5681H58.876V85.1662C58.5709 85.6997 58.1945 86.0969 57.7468 86.3617C57.2992 86.6265 56.7683 86.7627 56.1621 86.7627C55.2113 86.7627 54.4507 86.4434 53.8841 85.8087C53.3176 85.174 53.0323 84.3213 53.0323 83.2582V77.6121H54.6171V83.0635C54.6131 83.7761 54.7755 84.3291 55.1083 84.7184Z"
      fill="#170083"
    />
    <path
      d="M75.7094 86.5682H74.1247V81.1167C74.1247 80.3496 73.9821 79.785 73.6968 79.4228C73.4116 79.0607 72.9758 78.8777 72.3815 78.8777C71.9457 78.8777 71.5694 78.9634 71.2524 79.1386C70.9355 79.3138 70.6898 79.5708 70.5195 79.9096C70.3491 80.2483 70.262 80.6494 70.262 81.1167V86.5682H68.6773V81.1167C68.6773 80.3496 68.5346 79.785 68.2494 79.4228C67.9641 79.0607 67.5283 78.8777 66.9341 78.8777C66.4983 78.8777 66.1219 78.9634 65.805 79.1386C65.488 79.3138 65.2424 79.5708 65.072 79.9096C64.9017 80.2483 64.8145 80.6494 64.8145 81.1167V86.5682H63.2298V77.6122H64.8145V78.9166C65.0919 78.4104 65.4405 78.0327 65.8525 77.7874C66.2685 77.5421 66.7598 77.4175 67.3263 77.4175C67.9602 77.4175 68.5029 77.5654 68.9585 77.8536C69.4141 78.1417 69.7469 78.5584 69.9609 79.088C70.2778 78.5311 70.674 78.1106 71.1494 77.8341C71.6248 77.5538 72.1676 77.4136 72.7737 77.4136C73.3957 77.4136 73.9226 77.5499 74.3584 77.8224C74.7942 78.095 75.127 78.4961 75.3608 79.0218C75.5905 79.5474 75.7094 80.1783 75.7094 80.922V86.5682Z"
      fill="#170083"
    />
    <path
      d="M54.6842 4.65964H28.0057C26.8013 4.65964 25.8267 3.70174 25.8267 2.51799C25.8267 1.33424 26.8013 0.376343 28.0057 0.376343H54.6842C55.8886 0.376343 56.8632 1.33424 56.8632 2.51799C56.8632 3.70174 55.8846 4.65964 54.6842 4.65964Z"
      fill="#170083"
    />
    <path
      d="M63.285 13.5649H36.1073C34.9029 13.5649 33.9283 12.607 33.9283 11.4233C33.9283 10.2395 34.9029 9.28162 36.1073 9.28162H63.289C64.4934 9.28162 65.468 10.2395 65.468 11.4233C65.468 12.607 64.4894 13.5649 63.285 13.5649Z"
      fill="#170083"
    />
    <path
      d="M28.5088 13.5649H12.0159C10.8115 13.5649 9.83695 12.607 9.83695 11.4233C9.83695 10.2395 10.8115 9.28162 12.0159 9.28162H28.5048C29.7092 9.28162 30.6838 10.2395 30.6838 11.4233C30.6838 12.607 29.7092 13.5649 28.5088 13.5649Z"
      fill="#170083"
    />
    <path
      d="M27.1578 22.2992H8.14126C6.93688 22.2992 5.96229 21.3413 5.96229 20.1575C5.96229 18.9738 6.93688 18.0159 8.14126 18.0159H27.1578C28.3621 18.0159 29.3367 18.9738 29.3367 20.1575C29.3367 21.3413 28.3621 22.2992 27.1578 22.2992Z"
      fill="#170083"
    />
    <path
      d="M26.1873 31.3719H7.17081C5.96643 31.3719 4.99183 30.414 4.99183 29.2303C4.99183 28.0465 5.96643 27.0886 7.17081 27.0886H26.1873C27.3917 27.0886 28.3663 28.0465 28.3663 29.2303C28.3663 30.414 27.3877 31.3719 26.1873 31.3719Z"
      fill="#170083"
    />
    <path
      d="M27.3877 40.0669H8.10577C6.90139 40.0669 5.9268 39.109 5.9268 37.9252C5.9268 36.7415 6.90139 35.7836 8.10577 35.7836H27.3877C28.5921 35.7836 29.5667 36.7415 29.5667 37.9252C29.5667 39.109 28.5921 40.0669 27.3877 40.0669Z"
      fill="#170083"
    />
    <path
      d="M47.4344 49.1086H11.9251C10.7207 49.1086 9.74609 48.1507 9.74609 46.967C9.74609 45.7832 10.7207 44.8253 11.9251 44.8253H47.4344C48.6388 44.8253 49.6134 45.7832 49.6134 46.967C49.6134 48.1507 48.6348 49.1086 47.4344 49.1086Z"
      fill="#170083"
    />
    <path
      d="M56.5938 57.9322H27.3203C26.1159 57.9322 25.1413 56.9743 25.1413 55.7906C25.1413 54.6068 26.1159 53.6489 27.3203 53.6489H56.5938C57.7982 53.6489 58.7728 54.6068 58.7728 55.7906C58.7728 56.9743 57.7942 57.9322 56.5938 57.9322Z"
      fill="#170083"
    />
    <path
      d="M63.7924 49.1086H55.1359C53.9316 49.1086 52.957 48.1507 52.957 46.967C52.957 45.7832 53.9316 44.8253 55.1359 44.8253H63.7924C64.9968 44.8253 65.9714 45.7832 65.9714 46.967C65.9714 48.1507 64.9968 49.1086 63.7924 49.1086Z"
      fill="#170083"
    />
    <path
      d="M58.674 40.176H49.0627C47.8584 40.176 46.8838 39.2181 46.8838 38.0344C46.8838 36.8506 47.8584 35.8927 49.0627 35.8927H58.674C59.8784 35.8927 60.853 36.8506 60.853 38.0344C60.853 39.2181 59.8784 40.176 58.674 40.176Z"
      fill="#170083"
    />
    <path
      d="M66.039 22.3652H54.7836C53.5792 22.3652 52.6046 21.4073 52.6046 20.2236C52.6046 19.0398 53.5792 18.0819 54.7836 18.0819H66.039C67.2433 18.0819 68.2179 19.0398 68.2179 20.2236C68.2179 21.4073 67.2433 22.3652 66.039 22.3652Z"
      fill="#170083"
    />
    <defs></defs>
  </svg>
</template>

<script>
export default {
  name: "Consilium",
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/authentication/Login.vue";
import CONFIG from "../config";

Vue.use(VueRouter);

let storeRouter = null;

const isLogged = () => storeRouter.getters["authentication/token"] !== "";
const showPrimer = () => storeRouter.getters["authentication/primerShow"];
const isGuest = () => storeRouter.getters["authentication/isGuest"];
const thereAreStudents = () => storeRouter.getters["authentication/students"].length > 0;

const routes = [
  {
    path: "/",
    name: "Mantainance",
    component: () =>
      import(/* webpackChunkName: "schools" */ "../views/Main.vue"),
    props: true,
  },
  // authentication
  {
    path: "/login",
    name: "LoginApp",
    component: () => import(/* webpackChunkName: "schools" */ "../views/Main.vue"),
    beforeEnter: (to, from, next) => {
      next("/map");
    },
    props: true,
    children: [
      {
        path: "/",
        component: Login,
        name: "Login",
        props: true,
      },
    ],
  },
  {
    path: "/new_password",
    name: "Reset Password",
    component: () => import(/* webpackChunkName: "schools" */ "../views/Main.vue"),
    props: (route) => ({ token: route.query.token }),
    children: [
      {
        path: "/",
        name: "Reset Password",
        component: () =>
          import(/* webpackChunkName: "schools" */ "../views/authentication/ResetPassword.vue"),
      },
    ],
  },
  {
    path: "/pilot_login/:uuid",
    name: "Pilot Login",
    component: () =>
      import(/* webpackChunkName: "schools" */ "../views/authentication/PilotLogin.vue"),
    props: true,
  },
  // feedback
  {
    path: "/feedback/:uuid",
    name: "Feedback",
    component: () =>
      import(/* webpackChunkName: "schools" */ "../views/feedback/FeedbackApplication.vue"),
    meta: { title: "Home page" },
  },
  // explorer
  {
    path: "/explorer",
    name: "ExplorerApp",
    component: () => import(/* webpackChunkName: "schools" */ "../views/Main.vue"),
    children: [
      {
        path: "school/:code",
        name: "Explorer",
        component: () => import(/* webpackChunkName: "schools" */ "../views/explorer/Explorer.vue"),
        beforeEnter: (to, from, next) => {
          if (!isLogged() && !isGuest()) {
            next("/login");
          } else if (isLogged() && !thereAreStudents()) {
            next("/dashboard");
          } else {
            next();
          }
        },
        props: true,
      },
      {
        path: "",
        name: "Explorer",
        component: () => import(/* webpackChunkName: "schools" */ "../views/explorer/Explorer.vue"),
        beforeEnter: (to, from, next) => {
          if (!isLogged() && !isGuest()) {
            next("/login");
          } else if (isLogged() && !thereAreStudents()) {
            next("/dashboard");
          } else {
            next();
          }
        },
        props: true,
      },
      {
        path: "/favorites",
        name: "Favorites",
        component: () =>
          import(/* webpackChunkName: "schools" */ "../views/explorer/Favorites.vue"),
        beforeEnter: (to, from, next) => {
          if (!isLogged() && !isGuest()) {
            next("/login");
          } else {
            next();
          }
        },
      },
      {
        path: "/postulate",
        name: "Postulate",
        component: () => import(/* webpackChunkName: "schools" */ "../views/explorer/Simulate.vue"),
        beforeEnter: (to, from, next) => {
          if (!CONFIG.simulationOn || (!isLogged() && !isGuest())) {
            next("/login");
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    path: "/schools-details/:uuid",
    name: "SchoolDetails",
    component: () =>
      import(/* webpackChunkName: "schools" */ "../views/explorer/SchoolDetails.vue"),
    props: true,
    meta: { noHeader: true, noFooter: true },
  },
  {
    /* uuid corresponde al campus */
    path: "/schools-details/:uuid/:legalguardian/:student",
    name: "SchoolDetails",
    component: () =>
      import(/* webpackChunkName: "schools" */ "../views/explorer/SchoolDetails.vue"),
    props: true,
    meta: { noHeader: true, noFooter: true },
  },
  // dashboard
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import(/* webpackChunkName: "schools" */ "../views/dashboard/Dashboard.vue"),
    beforeEnter: (to, from, next) => {
      if (isGuest()) {
        next("/login");
      } else {
        next();
      }
    },
  },
  //{
  //  path: '/admissions_and_postulations',
  //  name: 'AdmissionsandPostulations',
  //  component: () => import(/* webpackChunkName: "schools" */ '../views/dashboard/Administrateadmission.vue'),
  //  props: true,
  //},
  //{
  //  path: '/messages',
  //  name: 'Messages',
  //  component: () => import(/* webpackChunkName: "schools" */ '../views/dashboard/Administratemessages.vue'),
  //  props: true,
  //},
  {
    path: "*",
    redirect: "/explorer",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.setStore = (store) => {
  storeRouter = store;
};

export default router;

import services from '@/services';
/* eslint-disable no-shadow */
const getDefaultState = () => ({
  currentRole: {
    roleInfo: null,
    rolePermissions: null,
  },
  headMaster: {
    roleInfo: null,
    rolePermissions: null,
    schools: null,
    mainSchool: null,
    firstLogin: null,
  },
  legalGuardian: {
    roleInfo: null,
    rolePermissions: null,
  },
});

const state = {
  ...getDefaultState(),
};

const getters = {
  currentRole: ({ currentRole }) => currentRole,
  isLegalGuardian: ({ currentRole }) => {
    if (currentRole.roleInfo) {
      return currentRole.roleInfo.id === 2;
    }
    return false;
  },
  isHeadMaster: ({ currentRole }) => {
    if (currentRole.roleInfo) {
      return currentRole.roleInfo.id === 3;
    }
    return false;
  },
  currentHeadMasterMainSchool: ({ headMaster }) => headMaster.mainSchool,
  currentHeadMasterSchools: ({ headMaster }) => headMaster.schools,
  headMasterRole: ({ headMaster }) => headMaster.roleInfo,
  legalGuardianRole: ({ legalGuardian }) => legalGuardian.roleInfo,
  headMasterFirstLogin: ({ headMaster }) => headMaster.firstLogin,
  headMasterPermissions: ({ headMaster }) => headMaster.rolePermissions,
  legalGuardianPermissions: ({ legalGuardian }) => legalGuardian.rolePermissions,
  rolePermissions: ({ legalGuardian, headMaster }) => [
    { legalGuardian: legalGuardian.rolePermissions },
    { headMaster: headMaster.rolePermissions },
  ],
};

const mutations = {
  resetStore(state) {
    Object.assign(state, getDefaultState());
  },
  setHeadMasterSchools(state, { schools }) {
    state.headMaster.schools = schools;
  },
  setHeadMasterMainSchool(state, { mainSchool }) {
    state.headMaster.mainSchool = mainSchool;
  },
  setLegalGuardianRoleInfo(state, { info }) {
    state.legalGuardian.roleInfo = info;
  },
  setLegalGuardianRolePermissions(state, { info }) {
    state.legalGuardian.rolePermissions = info;
  },
  setHeadMasterRoleInfo(state, { info }) {
    state.headMaster.roleInfo = info;
  },
  setHeadMasterRolePermissions(state, { info }) {
    state.headMaster.rolePermissions = info;
  },
  setCurrentRole(state, { info }) {
    state.currentRole.rolePermissions = info.rolePermissions;
    state.currentRole.roleInfo = info.roleInfo;
  },
  setHeadMasterFirstLogin(state, { bool }) {
    state.headMaster.firstLogin = bool;
  },
};

const actions = {
  resetStores({ commit }, {}) {
    commit("resetStore");
  },
  async getRoleGraphs({ graphName, campusUuid }) {
    return services.analyticsService
      .getGraph({ graphName, campusUuid })
      .then((response) => response)
      .catch((err) => err);
  },
  setMainSchool({ commit }, { school }) {
    commit("setMainSchool", { school });
  },
  setLegalGuardianRoleInfo({ commit }, { info }) {
    commit('setLegalGuardianRoleInfo', { info });
  },
  setLegalGuardianRolePermissions({ commit }, { info }) {
    commit('setLegalGuardianRolePermissions', { info });
  },
  setHeadMasterRoleInfo({ commit }, { info }) {
    const schools = [];
    if (info !== null) {
      if (info.roleOptions) {
        const getSchoolsPromise = new Promise((resolve) => {
          info.roleOptions.campus_allowed.forEach((campus, index, array) => {
            services.institutionsService
              .retrieveSchoolDetail({
                uuid: campus,
                studentUuid: null,
                grade: null,
              })
              .then((campusDetails) => {
                if (index === 0) {
                  commit('setHeadMasterMainSchool', { mainSchool: campusDetails.data });
                }
                schools.push(campusDetails.data);
              })
              .catch((error) => {
                console.error(error);
                throw error;
              })
              .finally(() => {
                if (index === array.length - 1) resolve(campus);
              });
          });
        });
        getSchoolsPromise.then(() => {
          commit('setHeadMasterSchools', { schools });
        });
      }
    }
    commit('setHeadMasterRoleInfo', { info });
  },
  setHeadMasterRolePermissions({ commit }, { info }) {
    commit('setHeadMasterRolePermissions', { info });
  },
  setCurrentRole({ commit }, { info }) {
    commit('setCurrentRole', { info });
  },
  setHeadMasterFirstLogin({ commit }, { bool }) {
    commit('setHeadMasterFirstLogin', { bool });
  },
  setHeadMasterMainSchool({ commit }, { mainSchool }) {
    commit('setHeadMasterMainSchool', { mainSchool });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

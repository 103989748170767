import moment from "moment";
import utils from "@/utils";
import services from "../services";

const schoolsLiteFromFile = require("../assets/schoolsLite.json");

/* eslint-disable no-shadow */
const getDefaultState = () => ({
  mapCenter: {
    lat: -33.447487,
    lng: -70.673676,
  },
  mapZoom: null,
  home: {
    lat: -33.447487,
    lng: -70.673676,
  },
  favoriteSchoolUUIDs: [], // Ordered array of UUIDs
  liteAllSchoolUUIDs: [],
  loadingLite: false,
  loadingProfile: false,
  loadingFavorites: false,
  loadingSchoolCard: false,
  liteLastUpdate: null,
  schools: [],
  schoolList: [],
  institutionList: [],
  onlyFavorites: false,
  searchedSchoolUuid: "",
  currentSchool: {},
  detailModal: false,
  chooseProgramModal: false,
  contactModal: false,
  currentSchoolTravelDurations: {},
  recommendedSchools: [],
  showFavoriteStatuses: [],
  favorites: [],
  campusDetails: {},
  distanceRadius: 0,
  cornerBounds: null,
  allFavoriteCampuses: [],
  filters: null,
  showInfoMarker: false,
  programsOptions: [],
  isInCampus: null,
  showCampusDetail: false,
  exploredCampuses: [],
  exploredSchoolCards: [],
  applications: [],
  favoriteSchoolsDetails: [],
});

const state = getDefaultState();

const getters = {
  searchedSchoolUuid(storeState) {
    return storeState.searchedSchoolUuid;
  },
  distance(storeState) {
    return storeState.filters.distance;
  },
  favoriteSchools: ({ favorites }) => favorites,
  allFavoriteSchools: ({ allFavoriteCampuses }) => allFavoriteCampuses,
  favoriteSchoolsDetails: ({ favoriteSchoolsDetails }) => favoriteSchoolsDetails,
  favoriteSchoolUUIDs(storeState) {
    return storeState.favoriteSchoolUUIDs;
  },
  /** Map favorite schools to 'overload' them with risk data */
  favoriteSchoolsWithRisks(storeState, getters, rootState, rootGetters) {
    const risksByYear = rootGetters["simulation/risksByYear"];
    if (!("2019" in risksByYear && "portfolio" in risksByYear["2019"])) {
      return storeState.favorites;
    }
    if (!("2020" in risksByYear && "portfolio" in risksByYear["2020"])) {
      return storeState.favorites;
    }
    const risks = {
      2019: risksByYear["2019"].portfolio,
      2020: risksByYear["2020"].portfolio,
    };
    if (
      risks["2019"].length !== storeState.favorites.length &&
      risks["2020"].length !== storeState.favorites.length
    ) {
      console.error("Risks differ from favorites!");
      return storeState.favorites;
    }
    return storeState.favorites.map((favorite, index) => {
      const { ratex: ratex19, academyid, rank } = risks["2019"][index];
      const ratex20 = risks["2020"][index].ratex;
      return {
        ...favorite,
        ratex: ratex19 * 100,
        ratex20: ratex20 * 100,
        academyid,
        rank,
      };
    });
  },
  /** Favorite schools for current student */
  favoriteSchoolsByStudent(storeState, rootGetters) {
    if (rootGetters["authentication/currentStudent"].uuid) {
      return storeState.favoriteSchools.filter(
        (s) => s.student === rootGetters["authentication/currentStudent"].uuid
      );
    }
    return storeState.favoriteSchools.filter((s) => s.student === null);
  },
  mapCenter(storeState) {
    return storeState.mapCenter;
  },
  /*
  if (storeState.onlyFavorites) {
      let favorites = storeState.favoriteSchools.filter(
        (s) => s.student === rootGetters['authentication/currentStudent'].uuid,
      );
      favorites = favorites.map((favorite) => favorite.uuid);
      return schools.filter((school) => favorites.includes(school.uuid));
    }
  */
  schools: ({ schools }) => schools,
  schoolList: ({ schoolList }) => schoolList,
  institutionList: ({ institutionList }) => institutionList,
  retrieveSchoolDetail(storeState) {
    return (uuid) => storeState.schools.find((s) => s.uuid === uuid);
  },
  next(storeState) {
    return storeState.next;
  },
  originalShifts(storeState) {
    return storeState.originalShifts;
  },
  filtersExist(storeState) {
    return (
      Object.keys(storeState.filters).length !== 0 && storeState.filters.constructor === Object
    );
  },
  currentSchoolLite(storeState) {
    return storeState.currentSchool;
  },
  detailModal(storeState) {
    return storeState.detailModal;
  },
  chooseProgramModal(storeState) {
    return storeState.chooseProgramModal;
  },
  contactModal(storeState) {
    return storeState.contactModal;
  },
  postulationStats(storeState) {
    return storeState.postulationStats;
  },
  showFavoriteStatuses(storeState) {
    return storeState.showFavoriteStatuses;
  },
  recommendedSchools(storeState) {
    return storeState.recommendedSchools;
  },
  campusDetails: (storeState) => {
    const { campusDetails } = storeState;
    return campusDetails;
  },
  home: ({ home }) => home,
  favorites: ({ favorites }) => favorites,
  distanceRadius: ({ distanceRadius }) => distanceRadius,
  liteAllSchoolUUIDs: ({ liteAllSchoolUUIDs }) => liteAllSchoolUUIDs,
  cornerBounds: ({ cornerBounds }) => cornerBounds,
  filters: ({ filters, distanceRadius }) => {
    if (distanceRadius > 0) {
      return { ...filters, distance_radius: distanceRadius };
    }
    return filters;
  },
  thereAreFiltersApplied: ({ filters }) => !!filters,
  showInfoMarker: ({ showInfoMarker }) => showInfoMarker,
  programsOptions: ({ programsOptions }) => programsOptions,
  showCampusDetail: ({ showCampusDetail }) => showCampusDetail,
  loadingLite: ({ loadingLite }) => loadingLite,
  loadingProfile: ({ loadingProfile }) => loadingProfile,
  loadingFavorites: ({ loadingFavorites }) => loadingFavorites,
  loadingSchoolCard: ({ loadingSchoolCard }) => loadingSchoolCard,
  exploredCampuses: ({ exploredCampuses }) => exploredCampuses,
  exploredSchoolCards: ({ exploredSchoolCards }) => exploredSchoolCards,
  applications: ({ applications }) => applications,
  isInCampus: ({ isInCampus }) => isInCampus,
  communeInfoList: ({ communeInfoList }) => communeInfoList,
};

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState());
  },
  setApplications(state, data) {
    state.applications = data;
  },
  setGuestExploredCards(state, campus) {
    if (!state.exploredSchoolCards.includes(campus.uuid)) {
      state.exploredSchoolCards.push(campus.uuid);
    }
  },
  setGuestExploredCampuses(state, campus) {
    if (!state.exploredCampuses.includes(campus.uuid)) {
      state.exploredCampuses.push(campus.uuid);
    }
  },
  setFavoriteSchoolUUIDs(state, schoolUUIDList) {
    const oldFavoriteUUIDs = state.favoriteSchoolUUIDs.map((oldFav) => oldFav.uuid);
    const newFavoriteUUIDs = schoolUUIDList.map((newFav) => newFav.uuid);
    // reset postulation risks only if list differs
    if (
      oldFavoriteUUIDs.length === newFavoriteUUIDs.length &&
      oldFavoriteUUIDs.every((oldFav) => newFavoriteUUIDs.includes(oldFav))
    ) {
      console.debug("no favorite changes");
    } else {
      // TODO: Restart risk
      console.debug("Favorites changed. Resetting risks");
    }
    // Initializes favorite's show statuses
    state.showFavoriteStatuses = [];
    const len = schoolUUIDList.length;
    for (let i = 0; i < len; i += 1) {
      state.showFavoriteStatuses.push(false);
    }
    state.favoriteSchoolUUIDs = schoolUUIDList;
  },
  clearFavoriteSchoolList(state) {
    state.favoriteSchoolUUIDs = [];
    // TODO: Restart risk
  },
  clearFavoriteSchoolListFromStudent(state, studentUUID) {
    if (studentUUID === null) {
      state.favoriteSchoolUUIDs = [];
    } else {
      state.favoriteSchoolUUIDs = state.favoriteSchools.filter((s) => !(s.student === studentUUID));
    }
    // TODO: Restart risk
  },
  setMapCenter(state, { lat, lng }) {
    state.mapCenter = { lat: parseFloat(lat), lng: parseFloat(lng) };
  },
  setSchools(state, schoolList) {
    state.schools = [...schoolList];
  },
  setSchoolList(state, schoolList) {
    state.schoolList = schoolList;
  },
  setInstitutionList(state, institutionList) {
    state.institutionList = institutionList;
  },
  setNext(state, nextURL) {
    const pairs = nextURL.slice(nextURL.indexOf("?") + 1).split("&");
    state.next = {};
    pairs.forEach((p) => {
      const [key, value] = p.split("=");
      state.next[key] = Number(value);
    });
  },
  addFilters(state, newFilterObject) {
    state.filters = { ...state.filters, ...newFilterObject };
  },
  removeFilter(state, filterName) {
    /* eslint-disable no-unused-vars */
    const { [filterName]: deleted, ...otherFilters } = state.filters;
    state.filters = otherFilters;
    /* eslint-enable no-unused-vars */
  },
  clearSchools(state) {
    state.schools = [];
  },
  clearNext(state) {
    state.next = "";
  },
  clearFilters(state) {
    state.filters = {};
  },
  setOnlyFavorites(state, newVal) {
    state.onlyFavorites = newVal;
  },
  addExploredToSchool(state, schooluuid) {
    let i;
    state.schools.forEach((school, index) => {
      if (school.uuid === schooluuid) {
        i = index;
        state.schools[i].explored = true;
      }
    });
  },
  addExploredMapCardToSchool(state, schooluuid) {
    let i;
    state.schools.forEach((school, index) => {
      if (school.uuid === schooluuid) {
        i = index;
        state.schools[i].exploredMapCard = true;
      }
    });
  },
  saveSearch(state, uuid) {
    state.searchedSchoolUuid = uuid;
  },
  setOriginalShifts(state, shifts) {
    state.originalShifts = shifts;
  },
  updateCurrentSchool(state, newVal) {
    state.currentSchool = newVal;
  },
  updateDetailModal(state, newVal) {
    state.detailModal = newVal;
  },
  updateChooseProgramModal(state, { val, programsOptions, isInCampus }) {
    state.programsOptions = programsOptions;
    state.chooseProgramModal = val;
    state.isInCampus = isInCampus;
  },
  updateContactModal(state, newVal) {
    state.contactModal = newVal;
  },
  updateCampusDetailFavorite(state, isFavorite) {
    state.campusDetails.isFavorite = isFavorite;
    state.currentSchool.isFavorite = isFavorite;
  },
  exploreCampus(state, campusUuid) {
    const index = state.schools.findIndex((element) => element.uuid === campusUuid);
    state.schools[index].explored = true;
  },
  exploreSchoolCard(state, campusUuid) {
    const index = state.schools.findIndex((element) => element.uuid === campusUuid);
    state.schools[index].exploredSchoolCard = true;
  },
  updateFavoriteSchool(state, { campusUuid, newVal }) {
    const index = state.schools.findIndex((element) => element.uuid === campusUuid);
    state.schools[index].isFavorite = newVal;
  },
  removeFavoriteByIndex(state, index) {
    state.favoriteSchoolUUIDs.splice(index, 1);
  },
  addFavoriteByUUID(state, schoolUUID) {
    state.favoriteSchoolUUIDs.push(schoolUUID);
  },
  setShowFavoriteStatuses(state, { favoriteIndex, newVal }) {
    state.showFavoriteStatuses = state.showFavoriteStatuses.map(() => false);
    state.showFavoriteStatuses[favoriteIndex] = newVal;
  },
  setRecommendedSchools(state, newVal) {
    state.recommendedSchools = newVal;
  },
  setFavorites(state, favorites) {
    state.favorites = favorites;
  },
  setCampusDetails(state, campusDetails) {
    state.campusDetails = { ...campusDetails };
  },
  setDistanceRadius(state, distanceRadius) {
    state.distanceRadius = distanceRadius;
  },
  setLiteAllSchoolUUIDs(state, liteAllSchoolUUIDs) {
    state.liteAllSchoolUUIDs = liteAllSchoolUUIDs;
  },
  setLiteLastUpdate(state, moment) {
    state.liteLastUpdate = moment;
  },
  setCornerBounds(state, cornerBounds) {
    state.cornerBounds = cornerBounds;
  },
  setAllFavoriteCampuses(state, allFavoriteCampuses) {
    state.allFavoriteCampuses = allFavoriteCampuses;
  },
  cleanAllFavorites(state) {
    state.allFavoriteCampuses = [];
  },
  setFilters(state, filters) {
    state.filters = { ...filters };
  },
  cleanFilters(state) {
    state.filters = null;
  },
  showInfoMarker(state, newVal) {
    state.showInfoMarker = newVal;
  },
  setShowCampusDetail(state, newVal) {
    state.showCampusDetail = newVal;
  },
  setFavoriteSchoolsDetails(state, school) {
    school.isFavorite = true;
    state.favoriteSchoolsDetails.push(school);
  },
  DeleteFavoriteSchoolsDetails(state) {
    state.favoriteSchoolsDetails = [];
  },
  setLoadingLite(state, loadingLite) {
    state.loadingLite = loadingLite;
  },
  setLoadingProfile(state, loadingProfile) {
    state.loadingProfile = loadingProfile;
  },
  setLoadingFavorites(state, loadingFavorites) {
    state.loadingFavorites = loadingFavorites;
  },
  setLoadingSchoolCard(state, loadingSchoolCard) {
    state.loadingSchoolCard = loadingSchoolCard;
  },
  setExploredCampuses(state, exploredCampuses) {
    state.exploredCampuses = [...exploredCampuses];
  },
  setExploredSchoolCards(state, exploredSchoolCards) {
    state.exploredSchoolCards = [...exploredSchoolCards];
  },
  setCommuneList(state, list) {
    state.communeInfoList = list;
  },
};

const actions = {
  getApplications({ commit, rootGetters }) {
    const legalGuardian = rootGetters["authentication/legalGuardian"];
    if (!legalGuardian.isGuest) {
      services.institutionsService.retrieveLegalGuardianApplications().then((response) => {
        commit("setApplications", response.data);
      });
    }
  },
  async setApplications({ commit, rootGetters }, { callback = null }) {
    let studentId = rootGetters["authentication/currentStudent"];
    studentId = studentId.id;
    return services.institutionsService
      .applyWithFavorites({ studentId })
      .then(({ data }) => {
        commit("setApplications", data);
        if (callback) {
          callback({ success: true });
        }
      })
      .catch((error) => {
        console.error(error);
        if (callback) {
          callback({ success: false });
        }
      });
  },
  setMapCenter({ commit }, { lat, lng }) {
    commit("setMapCenter", { lat, lng });
  },
  setCornerBounds({ commit }, { cornerBounds }) {
    commit("setCornerBounds", cornerBounds);
  },
  async getFavoriteSchoolsDetails({ commit, rootGetters, dispatch }, mainSearch) {
    const { grades } = rootGetters["authentication/currentStudent"];
    commit("setLoadingFavorites", true);
    commit("DeleteFavoriteSchoolsDetails");
    const schooldetails = [];
    const loadingPromise = new Promise((resolve, reject) => {
      mainSearch.forEach((campusUuid, index, array) => {
        services.elasticSearchsService
          .elasticMainSerach({ mainSearch: campusUuid })
          .then((response) => {
            commit("setFavoriteSchoolsDetails", response.data.results[0]);
            schooldetails.push(response.data.results[0]);
          })
          .finally(() => {
            if (index === array.length - 1) resolve(schooldetails);
          });
      });
    });

    loadingPromise.then(() => {
      setTimeout(() => {
        const institutionsDetails = schooldetails;
        const simulateInstitutions = {
          institutions: institutionsDetails,
          interestedGrade: grades[0],
        };
        dispatch("simulation/setInstitutionsList", simulateInstitutions, { root: true });
      }, 500);
      commit("setLoadingFavorites", false);
    });
  },
  recalculateSchoolsHighlights({ dispatch, state, rootState }) {
    const { schools } = state;
    const activityFilter = state.filters && state.filters.activities;
    const shiftFilter = state.filters && state.filters.shifts;

    function shouldHighlight(school) {
      let hasActivity = false;
      if (activityFilter) {
        hasActivity = activityFilter.reduce(
          (hasAct, activityUUID) =>
            hasAct ||
            school.activities.some((schoolActivity) => schoolActivity.uuid === activityUUID),
          false
        );
      }
      const d1 = utils.getDistanceInM(school.location, rootState.authentication.home.coordinates);
      const d2 = state.filters.distance * 1000;
      const distance = d1 <= d2;
      const inShift =
        shiftFilter &&
        shiftFilter.reduce((p, n) => [...p, ...n], []).some((f) => school.shift.includes(f));
      const shouldHighlight =
        distance && (!activityFilter || hasActivity) && (!shiftFilter || inShift);
      const newSchool = school;
      newSchool.shouldHighlight = shouldHighlight;
      return newSchool;
    }
    const schoolsHightlightered = schools.map((school) => shouldHighlight(school));
    dispatch("orderedAndSetSchools", schoolsHightlightered);
  },
  addFilters({ commit, dispatch }, newFilterObject) {
    commit("addFilters", newFilterObject);
    dispatch("recalculateSchoolsHighlights");
  },
  removeFilter({ commit, dispatch }, filterName) {
    commit("removeFilter", filterName);
    dispatch("recalculateSchoolsHighlights");
  },
  setSchools({ commit }, schoolList) {
    commit("setSchools", schoolList);
  },
  setInstitutionList({ commit }, institutionList) {
    commit("setInstitutionList", institutionList);
  },
  updateCampusDetailFavorite({ commit }, isFavorite) {
    commit("updateCampusDetailFavorite", isFavorite);
  },
  retrieveSchoolNames({ commit }) {
    const schoolFinalList = [];
    let searchFrom = 0;
    services.elasticSearchsService
      .elasticSchoolList(searchFrom)
      .then((response) => {
        response.data.results.forEach((school) => {
          if (school.campus_name !== "Sin Información") {
            schoolFinalList.push({
              name: school.campus_name,
              id: school.id,
            });
          }
        });
        response.data.results_nonincluded.forEach((school) => {
          if (school.campus_name !== "Sin Información") {
            schoolFinalList.push({
              name: school.campus_name,
              id: school.id,
            });
          }
        });
      })
      .then(() => {
        searchFrom = 5000;
        services.elasticSearchsService
          .elasticSchoolList(searchFrom)
          .then((response) => {
            response.data.results.forEach((school) => {
              if (school.campus_name !== "Sin Información") {
                schoolFinalList.push({
                  name: school.campus_name,
                  id: school.id,
                });
              }
            });
            response.data.results_nonincluded.forEach((school) => {
              if (school.campus_name !== "Sin Información") {
                schoolFinalList.push({
                  name: school.campus_name,
                  id: school.id,
                });
              }
            });
          })
          .then(() => {
            searchFrom = 10000;
            services.elasticSearchsService.elasticSchoolList(searchFrom).then((response) => {
              response.data.results.forEach((school) => {
                if (school.campus_name !== "Sin Información") {
                  schoolFinalList.push({
                    name: school.campus_name,
                    id: school.id,
                  });
                }
              });
              response.data.results_nonincluded.forEach((school) => {
                if (school.campus_name !== "Sin Información") {
                  schoolFinalList.push({
                    name: school.campus_name,
                    id: school.id,
                  });
                }
              });
            });
          })
          .then(() => {
            searchFrom = 15000;
            services.elasticSearchsService.elasticSchoolList(searchFrom).then((response) => {
              response.data.results.forEach((school) => {
                if (school.campus_name !== "Sin Información") {
                  schoolFinalList.push({
                    name: school.campus_name,
                    id: school.id,
                  });
                }
              });
              response.data.results_nonincluded.forEach((school) => {
                if (school.campus_name !== "Sin Información") {
                  schoolFinalList.push({
                    name: school.campus_name,
                    id: school.id,
                  });
                }
              });
            });
          });
        // .then(() => {
        //   console.log(schoolFinalList);
        //   commit('setSchoolList', schoolFinalList);
        // });
      });
  },
  retrieveInstitutionsWithIncluded({ commit }) {
    services.institutionsService.retrieveInstitutions(true).then((response) => {
      const { data } = response;
      commit("setInstitutionList", data);
    });
  },
  orderedAndSetSchools({ commit, rootState }, schoolList) {
    const homeLocation = rootState.authentication.home.coordinates;
    const distances = schoolList.map((school) => {
      const distance = utils.getDistanceInM(school.location, homeLocation);
      return { ...school, distance };
    });
    const ordererList = distances.sort((a, b) => a.distance - b.distance);

    const schoolsTrue = ordererList.filter((school) => school.shouldHighlight);
    const schoolsFalse = ordererList.filter((school) => !school.shouldHighlight);
    commit("setSchools", schoolsTrue.concat(schoolsFalse));
  },
  updateSchoolsLocation({ commit, rootState, state }) {
    const homeLocation = rootState.authentication.home.coordinates;
    const schoolList = state.schools;
    const distances = schoolList.map((school) => {
      const distance = utils.getDistanceInM(school.location, homeLocation);
      return { ...school, distance };
    });
    const ordererList = distances.sort((a, b) => a.distance - b.distance);
    commit("setSchools", ordererList);
  },
  selectSchoolFromSearchBar({ commit, dispatch, rootGetters }, { uuid, callback }) {
    commit("showInfoMarker", false);
    dispatch("updateCurrentSchool", { uuid }).then(() => {
      const campus = rootGetters["institutions/campusDetails"];
      const location = {
        lat: campus.campus_location[0].latitud,
        lng: campus.campus_location[0].longitud,
      };
      commit("setMapCenter", location);
      if (!rootGetters["authentication/isGuest"]) {
        dispatch("exploreSchoolCard", { campus: campus });
        dispatch("setShowMapCard", uuid);
      }
      if (callback) {
        callback();
      }
    });
  },
  updateCurrentSchool({ dispatch, commit }, { uuid, fromUrl }) {
    if (fromUrl) {
      commit("setShowCampusDetail", true);
    }
    commit("showInfoMarker", true);
    dispatch("retrieveCampusDetails", { campusUuid: uuid, fromUrl })
      .then(() => {
        dispatch("setLoadingSchoolCard", false);
      })
      .catch((error) => {
        commit("setShowCampusDetail", false);
        console.error(error);
      });
    dispatch("retrieveCurrentSchool", { mainSearch: uuid });
  },
  updateDetailModal({ commit }, newVal) {
    commit("updateDetailModal", newVal);
  },
  updateChooseProgramModal({ commit }, { val, programsOptions }) {
    commit("updateChooseProgramModal", { val, programsOptions });
  },
  closeChooseProgramModal({ commit }) {
    commit("updateChooseProgramModal", { val: false });
  },
  openChooseProgramModal({ commit }, { programsOptions, inCampusDetail }) {
    commit("updateChooseProgramModal", {
      val: true,
      programsOptions,
      isInCampus: inCampusDetail,
    });
  },
  updateContactModal({ commit }, newVal) {
    commit("updateContactModal", newVal);
  },
  updateFavoriteSchool({ commit }, { campusUuid, newVal }) {
    commit("updateFavoriteSchool", { campusUuid, newVal });
  },
  setRecommendedSchools({ commit }, newVal) {
    commit("setRecommendedSchools", newVal);
  },
  setThisSchoolFavoriteStatus({ state, commit }, { schoolUUID, newVal }) {
    const indexToChange = state.favoriteSchools.findIndex((school) => {
      const finded = school.campus === schoolUUID;
      return finded;
    });
    commit("setShowFavoriteStatuses", { favoriteIndex: indexToChange, newVal });
  },
  // adapter to uuid school list
  setFavoriteSchools({ dispatch }, schoolList) {
    const schoolUUIDList = schoolList.map((school) => school.uuid);
    dispatch("setFavoriteSchoolUUIDs", schoolUUIDList);
  },
  setFavoriteSchoolUUIDs({ commit }, schoolUUIDList) {
    commit("setFavoriteSchoolUUIDs", schoolUUIDList);
  },
  removeFavoriteByUUID({ state, dispatch, commit }, { programId }) {
    services.institutionsService
      .removeFavorites({
        programId,
      })
      .then(() => {
        dispatch("simulation/resetSimulation", {}, { root: true });
        dispatch("retrieveFavorites", () => {
          const { currentSchool, schools } = state;
          currentSchool.isFavorite = false;
          dispatch("updateFavoritesMarkers", { schools }).then(() => {
            commit("updateCurrentSchool", currentSchool);
          });
        });
        dispatch("simulation/resetSimulation", {}, { root: true });
      })
      .catch((error) => console.error(error));
  },
  removeFavoriteByCampusUUID({ state, dispatch, commit }, { campusUUID }) {
    const { favorites } = state;
    const favoritesToRemove = favorites.filter((favorite) => favorite.campus === campusUUID);
    const favoritesToRemoveUUIDs = favoritesToRemove.map((favorite) => favorite.uuid);
    if (favoritesToRemoveUUIDs.length > 0) {
      services.institutionsService
        .removeFavorites({
          uuids: favoritesToRemoveUUIDs,
        })
        .then(() => {
          dispatch("simulation/resetSimulation", {}, { root: true });
        })
        .then(() => {
          dispatch("retrieveFavorites", () => {
            const { currentSchool, schools } = state;
            currentSchool.isFavorite = false;
            dispatch("updateFavoritesMarkers", { schools }).then(() => {
              commit("updateCurrentSchool", currentSchool);
            });
          });
          dispatch("simulation/resetSimulation", {}, { root: true });
        })
        .catch((error) => console.error(error));
    }
  },
  // TODO: remove for deprecated
  addFavoriteByUUID({ state, dispatch, rootGetters, commit }, { schoolUUID }) {
    const { favoriteSchoolUUIDs } = state;
    if (!favoriteSchoolUUIDs.includes(schoolUUID)) {
      services.institutionsService
        .addFavorite({
          campusUuid: schoolUUID,
          studentUuid: rootGetters["authentication/currentStudent"].uuid,
        })
        .then(() => {
          dispatch("retrieveFavorites", () => {
            const { currentSchool, schools } = state;
            currentSchool.isFavorite = true;
            dispatch("updateFavoritesMarkers", { schools }).then(() => {
              commit("updateCurrentSchool", currentSchool);
            });
          });
          dispatch("simulation/resetSimulation", {}, { root: true });
        })
        .catch((error) => console.error(error));
    }
  },
  addMultipleFavoriteByUUID(
    { state, dispatch, rootGetters, commit },
    { programsUUIDs, studentUuid = undefined, dashboardSchool = [] }
  ) {
    let currentStudentUuid = rootGetters["authentication/currentStudent"].id;
    let programsToAdd = [programsUUIDs[0]];
    if (studentUuid !== undefined) {
      currentStudentUuid = studentUuid;
    } else {
      const favoriteProgramsUuids = state.favorites.map((el) => el.program_uuid);
      programsToAdd = programsUUIDs.filter((elem) => !favoriteProgramsUuids.includes(elem));
    }
    if (programsToAdd.length > 0) {
      services.institutionsService
        .addFavorite({
          programs: programsToAdd,
          studentUuid: currentStudentUuid,
        })
        .then(() => {
          dispatch("retrieveFavorites").finally(() => {
            if (dashboardSchool.length === 0) {
              const { currentSchool, schools } = state;
              currentSchool.isFavorite = true;
              dispatch("updateFavoritesMarkers", { schools }).then(() => {
                commit("updateCurrentSchool", currentSchool);
              });
            }
          });
          dispatch("simulation/resetSimulation", {}, { root: true });
        })
        .catch((error) => console.error(error));
    }
  },
  upFavoriteRank({ rootGetters, dispatch, commit }, { program, ranking }) {
    commit("setLoadingFavorites", true);
    let applicant = rootGetters["authentication/currentStudent"];
    applicant = applicant.id;
    const auxRanking = ranking + 1;
    services.institutionsService
      .swapFavorites({
        program,
        auxRanking,
        applicant,
      })
      .then(() => {
        dispatch("simulation/resetSimulation", {}, { root: true });
        dispatch("retrieveFavorites");
      })
      .catch((error) => console.error(error));
  },
  downFavoriteRank({ rootGetters, dispatch, commit }, { program, ranking }) {
    commit("setLoadingFavorites", true);
    let applicant = rootGetters["authentication/currentStudent"];
    applicant = applicant.id;
    const auxRanking = ranking - 1;
    services.institutionsService
      .swapFavorites({
        program,
        auxRanking,
        applicant,
      })
      .then(() => {
        dispatch("simulation/resetSimulation", {}, { root: true });
        dispatch("retrieveFavorites");
      })
      .catch((error) => console.error(error));
  },
  async updateFavoritesMarkers({ dispatch, state }, { schools }) {
    const { favorites } = state;
    const newSchools = [];
    schools.forEach((school) => {
      const isFavorite = favorites.find((favorite) => favorite.campus === school.uuid);
      if (isFavorite !== undefined) {
        school.isFavorite = true; // eslint-disable-line no-param-reassign
        newSchools.push(school);
      } else {
        school.isFavorite = false; // eslint-disable-line no-param-reassign
        newSchools.push(school);
      }
    });
    dispatch("setSchools", newSchools);
    return newSchools;
  },
  updateFavoritesSchoolCard({ commit, state, dispatch }, { school }) {
    const { favorites } = state;
    let newSchools;
    const isFavorite = favorites.find((favorite) => favorite.campus === school.uuid);
    if (isFavorite !== undefined) {
      school.isFavorite = true; // eslint-disable-line no-param-reassign
      newSchools = school;
    } else {
      school.isFavorite = false; // eslint-disable-line no-param-reassign
      newSchools = school;
    }
    commit("updateCurrentSchool", newSchools);
    dispatch("setLoadingLite", false);
    return newSchools;
  },
  async updateExploredMarkers({ dispatch, state, rootGetters }, schools) {
    const newSchools = [];
    const { exploredCampuses } = state;
    if (schools.length === 1) {
      const rootSchools = rootGetters["institutions/schools"];
      if (rootSchools.length !== 0) {
        rootSchools.forEach((school) => {
          if (school.uuid === schools[0].uuid) {
            school.isExplored = true; // eslint-disable-line no-param-reassign
            newSchools.push(school);
          } else {
            newSchools.push(school);
          }
        });
        dispatch("setSchools", newSchools);
        return newSchools;
      }

      dispatch("setSchools", schools);

      return schools;
    }
    schools.forEach((school) => {
      const isExplored = exploredCampuses.find(
        (campus) => campus.campus_code === parseInt(school.campus_code, 10)
      );
      if (isExplored !== undefined) {
        school.isExplored = true; // eslint-disable-line no-param-reassign
        newSchools.push(school);
      } else {
        school.isExplored = false; // eslint-disable-line no-param-reassign
        newSchools.push(school);
      }
    });
    dispatch("setSchools", schools);
    return schools;
  },

  async updateExploredSchoolCards({ dispatch, state, rootGetters }, schools) {
    const newSchools = [];
    const { exploredSchoolCards } = state;
    if (schools.length === 1) {
      const rootSchools = rootGetters["institutions/schools"];
      if (rootSchools.length !== 0) {
        rootSchools.forEach((school) => {
          if (school.id === schools[0].id) {
            school.isExploredMapCard = true; // eslint-disable-line no-param-reassign
            newSchools.push(school);
          } else {
            newSchools.push(school);
          }
        });
        dispatch("setSchools", newSchools);
      } else {
        dispatch("setSchools", schools);
      }
    } else {
      schools.forEach((school) => {
        const isExploredMapCard = exploredSchoolCards.find(
          (campus) => campus.campus_code === parseInt(school.campus_code, 10)
        );
        if (isExploredMapCard !== undefined) {
          school.isExploredMapCard = true; // eslint-disable-line no-param-reassign
          newSchools.push(school);
        } else {
          school.isExploredMapCard = false; // eslint-disable-line no-param-reassign
          newSchools.push(school);
        }
      });
      dispatch("setSchools", schools);
    }
  },
  async retrieveCurrentSchool({ dispatch, commit }, { mainSearch }) {
    services.elasticSearchsService.elasticMainSerach({ mainSearch }).then((response) => {
      const school = response.data.results[0];
      dispatch("updateFavoritesSchoolCard", { school });
      return school;
    });
  },
  retrieveCampuses(
    { dispatch, rootGetters, getters },
    { callback, bounds, showFilters, smallIcons }
  ) {
    const { filters } = getters;
    const isGuest = rootGetters["authentication/isGuest"];
    let uuid;
    let grades;
    if (!isGuest) {
      uuid = rootGetters["authentication/currentStudent"].uuid;
      grades = rootGetters["authentication/currentStudent"].grades;
    }
    const guestLocationSet = rootGetters["authentication/guestLocationSet"];
    if (isGuest && !guestLocationSet) {
      return;
    }

    const guestGrades = rootGetters["authentication/guestGrades"];
    const guestSexTypes = rootGetters["authentication/guestSexTypes"];
    const artificial = rootGetters["authentication/artificial"];
    const requestCount = 1;
    const requests = [];
    const newBounds = [];
    if (bounds === undefined) {
      getters.cornerBounds.forEach((bound) => {
        newBounds.push({ lat: bound.lat, lon: bound.lng });
      });
    } else {
      bounds.forEach((bound) => {
        newBounds.push({ lat: bound.lat, lon: bound.lng });
      });
    }
    for (let index = 0; index < requestCount; index += 1) {
      const params = {
        search_bounds: newBounds,
        index,
        search_size: 500,
        fields_required: [
          "campus_code",
          "id",
          "campus_name",
          "location",
          "multimedia_options",
          "payment",
          "tuition",
          "sector",
          "uuid",
          "image_thumb",
          "has_sep",
          "has_pie",
          "programs",
          "performance_set",
        ],
      };
      if (isGuest && guestGrades !== null) {
        params.grade_ids = guestGrades;
        if (guestSexTypes !== []) {
          params.gender_ids = guestSexTypes;
        }
      } else {
        params.grade_ids = grades;
        params.applicant_uuid = uuid;
      }
      if (isGuest) {
        const { guestStudentUuid } = rootGetters["authentication/legalGuardian"];
        params.applicant_uuid = guestStudentUuid;
      }
      if (showFilters && filters) {
        params.filters = filters;
        params.filters.show_school_type = true;
      }
      if (artificial) {
        params.artificial = true;
      }
      if (index === 0) {
        requests.push(services.institutionsService.retrieveCampuses(params, true));
      } else {
        requests.push(services.institutionsService.retrieveCampuses(params));
      }
    }
    // Resolve all requests in parallel
    Promise.all(requests)
      .then((response) => {
        let schools = response[0].data.results;
        const isGuest = rootGetters["authentication/isGuest"];
        if (response[0] !== undefined) {
          // schools = response[0].data.results;
          /*
          if (isGuest) {
            const { municipalityName } = rootGetters['authentication/guestLocation'];
            schools = municipalityName !== 'Chile' ? filterSchoolsLocation(response[0].data.results) : response[0].data.results;
          }
          else {
            const { address } = rootGetters['userRegister/registerData'];
            console.log(address);
            const { name } = address[0].location;
            schools = name !== 'Chile' ? filterSchoolsLocation(response[0].data.results) : response[0].data.results;
          }
          */
        }
        // Mapping of favorites to markers
        schools = dispatch("updateFavoritesMarkers", { schools });
        let newSchools;
        schools.then((response) => {
          newSchools = response;
          newSchools = dispatch("updateExploredMarkers", newSchools);
          newSchools.then((response) => {
            dispatch("updateExploredSchoolCards", response);
          });
        });
        return schools;
        /*
        function filterSchoolsLocation(schools) {
          const filterSchools = [];
          for (const school of schools) {
            const { agreement_set } = school;
            const isInterdistrictMagnet = agreement_set.filter((agreement) => agreement.id === 6);
            if (isInterdistrictMagnet.length > 0) {
              filterSchools.push(school);
            }
          }
          return filterSchools;
        }
        */
      })
      .finally(() => {
        if (callback) {
          callback();
        }
      });
  },
  retrieveCampusesWithActualData({ dispatch, getters }, { callback }) {
    const bounds = getters.cornerBounds;
    dispatch("retrieveCampuses", { callback, bounds });
  },
  // eslint-disable-next-line
  async retrieveCampusDetails(
    { commit, state, rootGetters },
    { campusUuid = undefined, applicantUuid = undefined, fromUrl = false }
  ) {
    commit("setLoadingProfile", true);
    const { currentSchool } = state;
    let studentUuid;

    if (applicantUuid) {
      studentUuid = applicantUuid;
    } else {
      // eslint-disable-next-line
      studentUuid = rootGetters["authentication/currentStudent"].uuid;
    }
    // eslint-disable-next-line
    const isGuest = rootGetters["authentication/isGuest"];
    let studentGrade;
    if (isGuest) {
      studentGrade = null;
    } else {
      // eslint-disable-next-line
      studentGrade = rootGetters["authentication/currentStudent"].current_enrollment;
      if (studentGrade === null) {
        // eslint-disable-next-line
        studentGrade = rootGetters["authentication/currentStudent"].grades[0];
      } else {
        studentGrade = studentGrade.grade_label;
      }
    }
    return services.institutionsService
      .retrieveSchoolDetail({
        uuid: campusUuid || currentSchool.uuid,
        studentUuid,
        grade: studentGrade,
        fromUrl,
      })
      .then((campusDetails) => {
        const favorites = rootGetters["institutions/favorites"];
        const favoriteCampus = favorites.find((favorite) => favorite.campus === campusUuid);
        const campus = { ...campusDetails.data };
        if (favoriteCampus === undefined) {
          campus.isFavorite = false;
        } else {
          campus.isFavorite = true;
        }
        if (fromUrl) {
          const exitsInfoGuest = rootGetters["authentication/exitsInfoGuest"];
          const lat = campus.campus_location[0].latitud;
          const lng = campus.campus_location[0].longitud;
          commit("setMapCenter", { lat, lng });

          if (!exitsInfoGuest) {
            commit("authentication/setExitsInfoGuest", { exitsInfoGuest: true }, { root: true });
          }
        }
        commit("setCampusDetails", campus);
        commit("setLoadingProfile", false);
        return campus;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },
  setLiteAllSchoolUUIDs({ commit }, newVal) {
    commit("setLiteAllSchoolUUIDs", newVal);
    commit("setLiteLastUpdate", moment());
  },
  retrieveLiteAllSchoolUUIDs({ dispatch }) {
    dispatch("setLiteAllSchoolUUIDs", schoolsLiteFromFile);
    /*
    const maxDaysToRefreshAllSchools = parseInt(CONFIG.maxDaysToRefreshAllSchools, 10);
    // Update only if are old or empty
    const existList = state.liteAllSchoolUUIDs.length > 0;
    const daysSinceLastUpdate = state.liteLastUpdate
      ? moment(state.liteLastUpdate).diff(moment(), 'days') : maxDaysToRefreshAllSchools;
    if (!existList || daysSinceLastUpdate >= maxDaysToRefreshAllSchools) {
      dispatch('setLoadingLite', true);
      services.institutionsService.retrieveLiteAllSchoolUUIDs()
        .then((res) => {
          dispatch('setLoadingLite', false);
          dispatch('setLiteAllSchoolUUIDs', res.data);
        }).catch((error) => {
          console.error(error);
          dispatch('setLoadingLite', false);
        });
    }
    */
  },
  retrieveFavorites({ commit, rootGetters, dispatch }) {
    const { uuid } = rootGetters["authentication/currentStudent"];
    services.institutionsService
      .retrieveFavoriteSchools({
        applicant_uuid: uuid,
      })
      .then((favorites) => {
        commit("setFavorites", favorites.data);
        const campusUuids = [];
        favorites.data.forEach((favorite) => {
          campusUuids.push(favorite.campus);
        });
        dispatch("getFavoriteSchoolsDetails", campusUuids);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        const schools = rootGetters["institutions/schools"];
        dispatch("updateFavoritesMarkers", { schools });
      });
  },
  retrieveAllFavorites({ commit, rootGetters }, callback = undefined) {
    const legalGuardian = rootGetters["authentication/legalGuardian"];
    if (!legalGuardian.isGuest) {
      commit("setLoadingLite", true);
      services.institutionsService
        .retrieveAllFavoriteCampuses()
        .then((favoriteCampuses) => {
          const favoriteCampusArray = favoriteCampuses.data;
          if (favoriteCampusArray.length !== 0) {
            commit("setAllFavoriteCampuses", favoriteCampusArray);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          commit("setLoadingLite", false);
          if (callback) {
            callback();
          }
        });
    }
  },
  async retrieveExploredCampuses({ commit, rootGetters }) {
    const isGuest = rootGetters["authentication/isGuest"];
    let { uuid } = rootGetters["authentication/currentStudent"];
    if (isGuest) {
      uuid = rootGetters["authentication/legalGuardian"].guestStudentUuid;
    }
    services.institutionsService
      .retrieveExploredSchoolCards({
        uuid,
      })
      .then((exploredCampuses) => {
        const exploredCampusesList = [];
        exploredCampuses.data.forEach((campus) => {
          exploredCampusesList.push(campus.campus_uuid);
        });
        commit("setExploredCampuses", exploredCampusesList);
      })
      .catch((error) => console.error(error));
  },
  async retrieveExploredSchoolCard({ commit, rootGetters }) {
    const isGuest = rootGetters["authentication/isGuest"];
    let { uuid } = rootGetters["authentication/currentStudent"];
    if (isGuest) {
      uuid = rootGetters["authentication/legalGuardian"].guestStudentUuid;
    }
    services.institutionsService
      .retrieveExploredCampuses({
        uuid,
      })
      .then((exploredSchoolCards) => {
        const exploredSchoolCardsList = [];
        exploredSchoolCards.data.forEach((campus) => {
          exploredSchoolCardsList.push(campus.campus_uuid);
        });
        commit("setExploredSchoolCards", exploredSchoolCardsList);
      })
      .catch((error) => console.error(error));
  },
  reset({ commit, state }) {
    const { liteLastUpdate, liteAllSchoolUUIDs, cornerBounds } = state;
    commit("reset");
    commit("setLiteAllSchoolUUIDs", liteAllSchoolUUIDs);
    commit("setLiteLastUpdate", liteLastUpdate);
    commit("setCornerBounds", cornerBounds);
  },
  async exploreCampus({ dispatch, rootGetters, commit }, { campus }) {
    const isGuest = rootGetters["authentication/isGuest"];
    let currentStudent = rootGetters["authentication/currentStudent"];
    if (!isGuest) {
      const campusUuid = campus.uuid;
      services.institutionsService
        .exploreCampus({
          currentStudent,
          campus: campusUuid,
        })
        .then(() => {
          dispatch("retrieveExploredCampuses").then(() => {
            dispatch("updateExploredMarkers", [campus]);
          });
        });
    } else {
      commit("setGuestExploredCampuses", campus);
    }
  },
  async exploreSchoolCard({ dispatch, rootGetters, commit }, { campus }) {
    const isGuest = rootGetters["authentication/isGuest"];
    let currentStudent = rootGetters["authentication/currentStudent"];
    if (!isGuest) {
      const campusUuid = campus.uuid;
      services.institutionsService
        .exploreSchoolCard({
          currentStudent,
          campus: campusUuid,
        })
        .then(() => {
          dispatch("retrieveExploredSchoolCard").then(() => {
            dispatch("updateExploredSchoolCards", [campus]);
          });
        });
    } else {
      commit("setGuestExploredCards", campus);
    }
  },
  setFilters({ commit, dispatch }, { filters }) {
    const distanceRadius = filters.distance_radius;
    if (distanceRadius !== undefined) {
      const newFilters = filters;
      newFilters.distance_radius = undefined;
      dispatch("setDistanceRadius", {
        distanceRadius,
        callback: () => {
          commit("setFilters", newFilters);
        },
      });
    } else {
      commit("setFilters", filters);
    }
  },
  cleanFilters({ commit, dispatch }) {
    commit("cleanFilters");
    return dispatch("setDistanceRadius", { distanceRadius: 2 });
  },
  setDistanceRadius({ commit }, { distanceRadius, callback = undefined }) {
    commit("setDistanceRadius", distanceRadius);
    if (callback) {
      callback();
    }
  },
  changeShowInfoMarkerStatus({ commit }, { status }) {
    commit("showInfoMarker", status);
  },
  setShowCampusDetail({ commit }, newVal) {
    let confirm = false;
    if (newVal) {
      confirm = true;
    }
    commit("addExploredToSchool", newVal);
    commit("setShowCampusDetail", confirm);
  },
  setShowMapCard({ commit }, newVal) {
    commit("addExploredMapCardToSchool", newVal);
  },
  setLoadingLite({ commit }, newVal) {
    commit("setLoadingLite", newVal);
  },
  setLoadingProfile({ commit }, newVal) {
    commit("setLoadingProfile", newVal);
  },
  setLoadingSchoolCard({ commit }, newVal) {
    commit("setLoadingSchoolCard", newVal);
  },
  setUpdateCampusDetails({ commit, getters }, newVal) {
    const { campusDetails } = getters;
    campusDetails.simulation = newVal;
    commit("setCampusDetails", campusDetails);
  },
  async setCommuneList({ commit }) {
    services.elasticSearchsService.elasticCommuneList().then((response) => {
      const list = response.data.results;
      commit("setCommuneList", list);
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

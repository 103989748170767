/* eslint-disable no-shadow */
import services from "../services";

const getDefaultState = () => ({
  goToFormContactFrom: null,
});

const state = {
  ...getDefaultState(),
};

const getters = {
  goToFormContactFrom(storeState) {
    return storeState.goToFormContactFrom;
  },
};

const mutations = {
  setGoToFormContactFrom(state, from) {
    state.goToFormContactFrom = from;
  },
};

const actions = {
  /*
  getMessages() {
    return services.contactService.getMessages().then((data) => data);
  },
  */
  setGoToFormContactFrom({ commit }, { from }) {
    commit("setGoToFormContactFrom", { from });
  },
  getMessages({ rootGetters }) {
    const legalGuardianUUID = rootGetters["authentication/legalGuardianUUID"];
    return services.contactService.getMessages(legalGuardianUUID).then((data) => data);
  },
  async sendMail({ rootGetters }, { form, callback, callbackError }) {
    const legalGuardianUUID = rootGetters["authentication/legalGuardianUUID"];
    const legalGuardianID = rootGetters["authentication/legalGuardianID"];
    const data = {
      ...form,
      parent_id: String(legalGuardianID) || 99,
      parent_uuid: String(legalGuardianUUID) || 99,
    };
    await services.contactService
      .sendMail(data)
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.error(error);
        if (callbackError) {
          callbackError();
        }
      });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

/* eslint-disable no-shadow */
import services from "../services";

const getDefaultState = () => ({
  genders: [],
  genderLabels: [],
  grades: [],
  gradeLabels: [],
  gradeTracks: [],
  stages: [],
  stageLabels: [],
  preschoolLabels: [],
  elementarySchoolLabels: [],
  highSchoolLabels: [],
  adultSchoolLabels: [],
  specialitySchools: [],
  agreementsLabels: [],
  specialties: [],
  specialityLabels: [],
  regions: [],
  regionLabels: [],
  performanceCategories: [],
  monthlyPayments: [],
  sexTypes: [],
  actualVideo: null,
  documentTypes: {},
  relationshipTypes: [],
  relationshipLabels: [],
  disabilities: [],
  armedConflictTypes: [],
  nationalities: [],
  nationalityLabels: [],
  nationalIdentifications: [],
  municipalities: [],
  municipalityLabels: [],
  municipalityOptions: [],
  roadTypes: [],
  roadTypeLabels: [],
  nationalIdentificationLabels: [],
});

const state = {
  ...getDefaultState(),
};

const getters = {
  genders: ({ genders }) => genders,
  genderLabels: ({ genderLabels }) => genderLabels,
  grades: ({ grades }) => grades,
  gradeLabels: ({ gradeLabels }) => gradeLabels,
  gradeTracks: ({ gradeTracks }) => gradeTracks,
  stages: ({ stages }) => stages,
  stageLabels: ({ stageLabels }) => stageLabels,
  preschoolLabels: ({ preschoolLabels }) => preschoolLabels,
  elementarySchoolLabels: ({ elementarySchoolLabels }) => elementarySchoolLabels,
  highSchoolLabels: ({ highSchoolLabels }) => highSchoolLabels,
  adultSchoolLabels: ({ adultSchoolLabels }) => adultSchoolLabels,
  specialitySchools: ({ specialitySchools }) => specialitySchools,
  specialties: ({ specialties }) => specialties,
  specialityLabels: ({ specialityLabels }) => specialityLabels,
  nationalities: ({ nationalities }) => nationalities,
  nationalityLabels: ({ nationalityLabels }) => nationalityLabels,
  nationalIdentifications: ({ nationalIdentifications }) => nationalIdentifications,
  nationalIdentificationLabels: ({ nationalIdentificationLabels }) => nationalIdentificationLabels,
  regions: ({ regions }) => regions,
  regionLabels: ({ regionLabels }) => regionLabels,
  municipalities: ({ municipalities }) => municipalities,
  municipalityOptions: ({ municipalityOptions }) => municipalityOptions,
  municipalityLabels: ({ municipalityLabels }) => municipalityLabels,
  roadTypes: ({ roadTypes }) => roadTypes,
  roadTypeLabels: ({ roadTypeLabels }) => roadTypeLabels,
  performanceCategories: ({ performanceCategories }) => performanceCategories,
  monthlyPayments: ({ monthlyPayments }) => monthlyPayments,
  sexTypes: ({ sexTypes }) => sexTypes,
  actualVideo: ({ actualVideo }) => actualVideo,
  documentTypes: ({ documentTypes }) => documentTypes,
  relationshipTypes: ({ relationshipLabels }) => relationshipLabels,
  relationshipOptions: ({ relationshipTypes }) => relationshipTypes,
  disabilities: ({ disabilities }) => disabilities,
  armedConflictTypes: ({ armedConflictTypes }) => armedConflictTypes,
  agreementsLabels: ({ agreementsLabels }) => agreementsLabels,
};

const mutations = {
  resetStore(state) {
    Object.assign(state, getDefaultState());
  },
  setGenders(state, newGenders) {
    state.genders = newGenders;
  },
  setGendersLabel(state, genderLabels) {
    state.genderLabels = genderLabels;
  },
  setGrades(state, newGrades) {
    state.grades = newGrades;
  },
  setGradeLabels(state, newGrades) {
    state.gradeLabels = newGrades;
  },
  setGradeTracks(state, newGrades) {
    state.gradeTracks = newGrades;
  },
  setStages(state, newGrades) {
    state.stages = newGrades;
  },
  setStageLabels(state, newGrades) {
    state.stageLabels = newGrades;
  },
  setPreschoolLabels(state, newGrades) {
    state.preschoolLabels = newGrades;
  },
  setElementarySchoolLabels(state, newGrades) {
    state.elementarySchoolLabels = newGrades;
  },
  setHighSchoolLabels(state, newGrades) {
    state.highSchoolLabels = newGrades;
  },
  setAdultSchoolLabels(state, newGrades) {
    state.adultSchoolLabels = newGrades;
  },
  setSpecialitySchools(state, newGrades) {
    state.specialitySchools = newGrades;
  },
  setSpecialties(state, specialties) {
    state.specialties = specialties;
  },
  setSpecialityLabels(state, specialityLabels) {
    state.specialityLabels = specialityLabels;
  },
  setRegions(state, newRegions) {
    state.regions = newRegions;
  },
  setRegionLabels(state, newRegions) {
    state.regionLabels = newRegions;
  },
  setMunicipalities(state, municipalities) {
    state.municipalities = municipalities;
  },
  setMunicipalityLabels(state, municipalities) {
    state.municipalityLabels = municipalities;
  },
  setMunicipalityOptions(state, municipalityOptions) {
    state.municipalityOptions = municipalityOptions;
  },
  setRoadType(state, roadTypes) {
    state.roadTypes = roadTypes;
  },
  setRoadTypeLabels(state, roadTypeLabels) {
    state.roadTypeLabels = roadTypeLabels;
  },
  setPerformanceCategories(state, newCategories) {
    state.performanceCategories = newCategories;
  },
  setMonthlyPayments(state, newMonthlyPayments) {
    state.monthlyPayments = newMonthlyPayments;
  },
  setSexTypes(state, newSexTypes) {
    state.sexTypes = newSexTypes;
  },
  setActualVideo(state, newActualVideo) {
    state.actualVideo = newActualVideo;
  },
  setDocumentTypes(state, types) {
    state.documentTypes = types;
  },
  setRelationshipOptions(state, options) {
    state.relationshipTypes = options;
  },
  setRelationshipLabels(state, options) {
    state.relationshipLabels = options;
  },
  setDisabilityOptions(state, options) {
    state.disabilities = options;
  },
  setArmedConflictTypes(state, options) {
    state.armedConflictTypes = options;
  },
  setNationalities(state, nationalities) {
    state.nationalities = nationalities;
  },
  setNationalityLabels(state, nationalityLabels) {
    state.nationalityLabels = nationalityLabels;
  },
  setNationalIdentification(state, nationalIdentifications) {
    state.nationalIdentifications = nationalIdentifications;
  },
  setNationalIdentificationLabels(state, nationalIdentifications) {
    state.nationalIdentificationLabels = nationalIdentifications;
  },
  setAgreementLabels(state, agreementLabels) {
    state.agreementsLabels = agreementLabels;
  },
};

const actions = {
  resetStores({ commit }, {}) {
    commit("resetStore");
  },
  setGenders({ commit }, genders) {
    commit("setGenders", genders);
  },
  setGendersLabel({ commit }, genders) {
    commit("setGendersLabel", genders);
  },
  setGrades({ commit }, grades) {
    commit("setGrades", grades);
  },
  setGradeLabels({ commit }, grades) {
    commit("setGradeLabels", grades);
  },
  setAgreementLabels({ commit }, agreements) {
    commit("setAgreementLabels", agreements);
  },
  setRegions({ commit }, regions) {
    commit("setRegions", regions);
  },
  setRegionLabels({ commit }, regions) {
    commit("setRegionLabels", regions);
  },
  setPerformanceCategories({ commit }, performanceCategories) {
    commit("setPerformanceCategories", performanceCategories);
  },
  setMonthlyPayments({ commit }, monthlyPayments) {
    commit("setMonthlyPayments", monthlyPayments);
  },
  setSexTypes({ commit }, sexTypes) {
    commit("setSexTypes", sexTypes);
  },
  setRelationshipOptions({ commit }, options) {
    commit("setRelationshipOptions", options);
  },
  setDocumentTypes({ commit }, types) {
    commit("setDocumentTypes", types);
  },
  setDisabilityOptions({ commit }, types) {
    commit("setDisabilityOptions", types);
  },
  setArmedConflictTypes({ commit }, types) {
    commit("setArmedConflictTypes", types);
  },
  async retrieveOptions({ dispatch, commit }) {
    services.optionsService.retrieveRegions().then((response) => {
      const { data } = response;
      const departments = [];
      data.forEach((region) => {
        departments.push(region.name);
      });
      dispatch("setRegionLabels", departments);
      dispatch("setRegions", data);
    });
    services.optionsService.retrieveMunicipalities().then((response) => {
      const { data } = response;
      commit("setMunicipalities", data);
      const municipalities = [];
      data.forEach((municipality) => {
        municipalities.push(municipality.name);
      });
      commit("setMunicipalityLabels", municipalities);
    });
    services.optionsService.retrieveGenders().then((response) => {
      const { data } = response;
      const genders = [];
      data.forEach((gender) => {
        if (gender.gender_label !== "Sin Información") {
          genders.push(gender.gender_label);
        }
      });
      dispatch("setGendersLabel", genders);
      dispatch("setGenders", data);
    });
    services.optionsService.retrieveGrades().then((response) => {
      const dataSorted = response.data.sort((a, b) => a.id - b.id);
      const gradeLabels = [];
      const preschoolLabels = [];
      const elementarySchoolLabels = [];
      const highSchoolLabels = [];
      const adultSchoolLabels = [];
      const specialSchoolsLabels = [];
      dataSorted.forEach((grade) => {
        if (grade.id >= 2 && grade.id <= 4) {
          preschoolLabels.push(grade.grade_name_es.split("/")[0].trim());
        }
        if (grade.id >= 5 && grade.id <= 6) {
          preschoolLabels.push(grade.grade_name_es.split("/")[1].trim());
        }
        if (grade.id === 1) {
          specialSchoolsLabels.push(grade.grade_name_es.split("/")[1].trim());
          preschoolLabels.push(grade.grade_name_es.split("/")[0].trim());
        }
        if (grade.id >= 7 && grade.id <= 14) {
          elementarySchoolLabels.push(grade.grade_name_es.split("/")[0].trim());
          specialSchoolsLabels.push(grade.grade_name_es.split("/")[0].trim());
        }
        if (grade.id >= 15 && grade.id <= 18) {
          highSchoolLabels.push(grade.grade_name_es.split("/")[0].trim());
        }
        if (
          grade.id === 7 ||
          grade.id === 11 ||
          grade.id === 13 ||
          grade.id === 15 ||
          grade.id === 17 ||
          grade.id === 18
        ) {
          adultSchoolLabels.push(grade.grade_name_es.split("/")[1].trim());
        }
        if (grade.id >= 19 && grade.id <= 22) {
          specialSchoolsLabels.push(grade.grade_name_es.split("/")[0].trim());
        }
      });
      commit("setPreschoolLabels", preschoolLabels);
      commit("setElementarySchoolLabels", elementarySchoolLabels);
      commit("setHighSchoolLabels", highSchoolLabels);
      commit("setAdultSchoolLabels", adultSchoolLabels);
      commit("setSpecialitySchools", specialSchoolsLabels);
      dispatch("setGradeLabels", gradeLabels);
      dispatch("setGrades", response.data);
    });
    services.optionsService.retrieveGradeTracks().then((response) => {
      const stageLabels = [];
      const stages = [];
      const gradeTracks = response.data.results;
      gradeTracks.sort((a, b) => a.stage_label.id - b.stage_label.id);
      response.data.results.forEach((grade, index) => {
        if (!stageLabels.includes(grade.stage_label.stage_name)) {
          stageLabels.push(grade.stage_label.stage_name);
        }
      });
      stageLabels.forEach((stageLabel) => {
        const stage = gradeTracks.find(
          (stage) => stage.stage_label.stage_name === stageLabel
        ).stage_label;
        stages.push(stage);
      });
      stageLabels.push("No Aplica");
      stages.push({
        id: 6,
        stage_name: "No Aplica",
        stage_name_en: "",
        stage_name_es: "No Aplica",
      });
      stages.sort((a, b) => a.id - b.id);
      commit("setGradeTracks", gradeTracks);
      commit("setStageLabels", stageLabels);
      commit("setStages", stages);
    });
    services.optionsService.retrievePerformanceCategories().then((response) => {
      dispatch("setPerformanceCategories", response.data);
    });
    services.optionsService.retrieveMonthlyPayments().then((response) => {
      // Orders the array of monthly payments
      const monthlyPayments = response.data;
      /* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */
      dispatch("setMonthlyPayments", monthlyPayments);
    });
    services.optionsService.retrieveAgreements().then((response) => {
      const agreementLabels = response.data;
      dispatch("setAgreementLabels", agreementLabels);
    });
  },
  async retrieveRegistrationOptions({ commit, dispatch }) {
    services.optionsService.retrieveNatonialitys().then((response) => {
      const { data } = response;
      commit("setNationalities", data);
      const nationalities = [];
      data.forEach((nationality) => {
        if (nationality.nationality_name !== "Sin Información") {
          nationalities.push(nationality.nationality_name);
        }
      });
      commit("setNationalityLabels", nationalities);
    });
    services.optionsService.retrieveNationalidentification().then((response) => {
      const { data } = response;
      commit("setNationalIdentification", data);
      const nationalIdentifications = [];
      data.forEach((nationalIdentification) => {
        if (nationalIdentification.identification_type_name !== "Sin Información") {
          nationalIdentifications.push(nationalIdentification.identification_type_name);
        }
      });
      commit("setNationalIdentificationLabels", nationalIdentifications);
    });
    services.optionsService.retriveDocumentTypes().then((response) => {
      const documents = {};
      response.data.forEach((elem) => {
        if (Object.prototype.hasOwnProperty.call(documents, elem.nationality.id)) {
          documents[elem.nationality.id].push(elem);
        } else {
          documents[elem.nationality.id] = [elem];
        }
      });
      dispatch("setDocumentTypes", documents);
    });
    /*
    services.optionsService.retrieveRoadType()
      .then((response) => {
        const { data } = response;
        commit('setRoadType', data);
        const roadTypes = [];
        data.forEach((roadType) => {
          roadTypes.push(roadType.tipo_via);
        });
        commit('setRoadTypeLabels', roadTypes);
      });
    */
    services.optionsService.retrieveRelationshipTypes().then((response) => {
      const { data } = response;
      commit("setRelationshipOptions", data);
      const RelationshipLabels = [];
      data.forEach((relationship) => {
        RelationshipLabels.push(relationship.relationship_name);
      });
      commit("setRelationshipLabels", RelationshipLabels);
    });
    services.optionsService.retrieveDisabilities().then((response) => {
      commit("setDisabilityOptions", response.data);
    });
    /*
    services.optionsService.retrieveArmedConflictTypes()
      .then((response) => {
        commit('setArmedConflictTypes', response.data);
      });
    */
  },
  async retrieveSpecialityOptions({ commit }, { gradeId }) {
    services.institutionsService.retrieveSpecialityByGrade({ gradeId }).then((response) => {
      const { data } = response;
      const specialityLabels = [];
      data.forEach((speciality) => {
        specialityLabels.push(speciality.specialty_label.specialty_name);
      });
      commit("setSpecialties", data);
      commit("setSpecialityLabels", specialityLabels);
      return specialityLabels[0];
    });
  },
  setActualVideo({ commit }, actualVideo) {
    commit("setActualVideo", actualVideo);
  },
  stopActualVideo({ state }) {
    state.actualVideo.stopVideo();
  },
  setMunicipalityOptions({ commit }, municipalityOptions) {
    commit("setMunicipalityOptions", municipalityOptions);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

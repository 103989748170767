<template>
  <svg width="200" height="29" viewBox="0 0 200 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M3.70724 23.1928C4.20455 24.092 4.88773 24.8053 5.75175 25.3278C6.61576 25.8502 7.59532 26.1114 8.69041 26.1114C9.58959 26.1114 10.4385 25.9707 11.2272 25.6894C12.0159 25.4081 12.7041 25.0314 13.2918 24.5592C13.8745 24.082 14.2915 23.5796 14.5326 23.0421V26.6941C13.9499 27.3773 13.116 27.9047 12.0309 28.2815C10.9459 28.6582 9.83071 28.8491 8.69041 28.8491C7.05782 28.8491 5.58597 28.4724 4.26985 27.7189C2.95373 26.9654 1.9139 25.9255 1.14533 24.5943C0.381775 23.2732 0 21.7662 0 20.0884C0 18.3855 0.381775 16.8734 1.15035 15.5573C1.91892 14.2412 2.95876 13.2064 4.27488 12.4529C5.591 11.6994 7.06284 11.3226 8.69543 11.3226C9.84076 11.3226 10.9559 11.5135 12.036 11.8903C13.121 12.267 13.9549 12.7995 14.5376 13.4777V17.1296C14.2915 16.572 13.8795 16.0597 13.2968 15.5975C12.7141 15.1354 12.0259 14.7636 11.2322 14.4823C10.4385 14.201 9.59461 14.0604 8.69543 14.0604C7.60034 14.0604 6.62079 14.3166 5.75677 14.8289C4.89275 15.3413 4.20957 16.0546 3.71226 16.9639C3.21495 17.8781 2.96378 18.918 2.96378 20.0884C2.95876 21.2588 3.20993 22.2936 3.70724 23.1928Z"
        fill="#170083"
      />
      <path
        d="M18.4257 15.5573C19.1943 14.2412 20.2341 13.2064 21.5503 12.4529C22.8664 11.6994 24.3382 11.3226 25.9708 11.3226C27.6285 11.3226 29.1054 11.6994 30.4064 12.4529C31.7075 13.2064 32.7423 14.2412 33.5109 15.5573C34.2795 16.8734 34.6612 18.3855 34.6612 20.0884C34.6612 21.7662 34.2795 23.2732 33.5109 24.5994C32.7423 25.9255 31.7075 26.9654 30.4064 27.7239C29.1054 28.4774 27.6235 28.8542 25.9708 28.8542C24.3382 28.8542 22.8664 28.4774 21.5503 27.7239C20.2341 26.9704 19.1943 25.9306 18.4257 24.5994C17.6572 23.2732 17.2754 21.7712 17.2754 20.0884C17.2754 18.3855 17.6572 16.8734 18.4257 15.5573ZM20.9826 23.1928C21.4799 24.092 22.1631 24.8053 23.0271 25.3278C23.8912 25.8502 24.8707 26.1114 25.9658 26.1114C27.0609 26.1114 28.0404 25.8502 28.9045 25.3278C29.7685 24.8053 30.4517 24.092 30.949 23.1928C31.4463 22.2936 31.6975 21.2588 31.6975 20.0884C31.6975 18.918 31.4463 17.8781 30.949 16.9639C30.4517 16.0496 29.7685 15.3363 28.9045 14.8289C28.0404 14.3166 27.0609 14.0604 25.9658 14.0604C24.8707 14.0604 23.8912 14.3166 23.0271 14.8289C22.1631 15.3413 21.4799 16.0546 20.9826 16.9639C20.4853 17.8781 20.2341 18.918 20.2341 20.0884C20.2341 21.2588 20.4853 22.2936 20.9826 23.1928Z"
        fill="#170083"
      />
      <path
        d="M48.6867 15.9442C48.4205 15.3213 48.0186 14.8541 47.4811 14.5376C46.9436 14.2212 46.2856 14.0604 45.5069 14.0604C44.653 14.0604 43.9045 14.2362 43.2615 14.5879C42.6135 14.9395 42.1162 15.4318 41.7645 16.0647C41.4129 16.6977 41.2371 17.4311 41.2371 18.2549V28.4825H38.3135V11.6894H41.2371V14.3216C41.7947 13.2969 42.4979 12.5434 43.3368 12.0561C44.1757 11.5688 45.1453 11.3277 46.2403 11.3277C47.4309 11.3277 48.4556 11.5939 49.3096 12.1314C50.1636 12.6689 50.8267 13.4224 51.2989 14.397C51.7761 15.3715 52.0122 16.5419 52.0122 17.9033V28.4925H49.0886V18.2599C49.0886 17.3356 48.953 16.562 48.6867 15.9442Z"
        fill="#170083"
      />
      <path
        d="M58.9838 24.7651C59.4209 25.2022 59.9282 25.5488 60.5009 25.805C61.0736 26.0612 61.7015 26.1867 62.3796 26.1867C63.2085 26.1867 63.8615 25.9959 64.3337 25.6191C64.8109 25.2424 65.047 24.725 65.047 24.0669C65.047 23.4842 64.8561 22.9919 64.4794 22.59C64.1026 22.1882 63.6204 21.8466 63.0377 21.5653C62.455 21.284 61.8321 21.0227 61.174 20.7816C60.4456 20.4903 59.7072 20.1436 58.9637 19.7418C58.2203 19.3399 57.5974 18.8175 57.1001 18.1695C56.6028 17.5265 56.3516 16.7127 56.3516 15.7432C56.3516 14.7435 56.6128 13.9197 57.1352 13.2616C57.6577 12.6036 58.3408 12.1163 59.1797 11.7999C60.0186 11.4834 60.8877 11.3226 61.7919 11.3226C62.6911 11.3226 63.54 11.4683 64.3287 11.7597C65.1224 12.051 65.8206 12.4428 66.4284 12.9301C67.0363 13.4174 67.5135 13.975 67.8551 14.6079L65.5192 16.1049C65.0571 15.4719 64.4794 14.9495 63.7862 14.5326C63.0929 14.1206 62.3093 13.9097 61.4302 13.9097C60.8224 13.9097 60.3 14.0453 59.8579 14.3115C59.4158 14.5778 59.1998 14.9947 59.1998 15.5523C59.1998 15.9893 59.3706 16.3812 59.7122 16.7227C60.0538 17.0643 60.4908 17.3708 61.0283 17.637C61.5658 17.9032 62.1234 18.1594 62.7061 18.4056C63.6807 18.7974 64.5698 19.2143 65.3735 19.6664C66.1773 20.1185 66.8152 20.6611 67.2925 21.294C67.7697 21.9269 68.0058 22.7407 68.0058 23.7404C68.0058 24.7149 67.7596 25.5839 67.2724 26.3525C66.7851 27.1211 66.112 27.7289 65.2429 28.176C64.3789 28.6281 63.3742 28.8542 62.2289 28.8542C61.1087 28.8542 60.099 28.6532 59.1998 28.2514C58.3007 27.8495 57.5371 27.3321 56.9192 26.6991C56.2963 26.0662 55.8292 25.4232 55.5127 24.7651L57.8134 23.3385C58.155 23.8509 58.5468 24.3231 58.9838 24.7651Z"
        fill="#170083"
      />
      <path
        d="M72.0803 3.32545C72.4822 2.93865 72.9494 2.74274 73.4869 2.74274C74.0445 2.74274 74.5217 2.93865 74.9135 3.32545C75.3003 3.71727 75.4962 4.18946 75.4962 4.75208C75.4962 5.28958 75.3003 5.75675 74.9135 6.15862C74.5217 6.56049 74.0495 6.76142 73.4869 6.76142C72.9494 6.76142 72.4822 6.56049 72.0803 6.15862C71.6785 5.75675 71.4775 5.28958 71.4775 4.75208C71.4775 4.18946 71.6785 3.71224 72.0803 3.32545ZM74.9487 11.6893V28.4925H72.0251V11.6893H74.9487Z"
        fill="#170083"
      />
      <path d="M83.5281 0V28.4875H80.6045V0H83.5281Z" fill="#170083" />
      <path
        d="M89.2454 3.32545C89.6472 2.93865 90.1144 2.74274 90.6519 2.74274C91.2095 2.74274 91.6867 2.93865 92.0786 3.32545C92.4653 3.71727 92.6613 4.18946 92.6613 4.75208C92.6613 5.28958 92.4653 5.75675 92.0786 6.15862C91.6867 6.56049 91.2145 6.76142 90.6519 6.76142C90.1144 6.76142 89.6472 6.56049 89.2454 6.15862C88.8435 5.75675 88.6426 5.28958 88.6426 4.75208C88.6426 4.18946 88.8435 3.71224 89.2454 3.32545ZM92.1137 11.6893V28.4925H89.1901V11.6893H92.1137Z"
        fill="#170083"
      />
      <path
        d="M101.095 24.1975C101.361 24.8204 101.763 25.2926 102.301 25.6241C102.838 25.9506 103.491 26.1164 104.275 26.1164C105.129 26.1164 105.877 25.9406 106.52 25.589C107.163 25.2373 107.666 24.745 108.017 24.1121C108.369 23.4792 108.545 22.7508 108.545 21.9219V11.6893H111.468V28.4925H108.545V25.8653C107.982 26.8649 107.289 27.6134 106.465 28.1107C105.636 28.608 104.662 28.8592 103.541 28.8592C102.371 28.8592 101.356 28.5929 100.492 28.0554C99.6282 27.5179 98.9601 26.7594 98.4828 25.7748C98.0056 24.7903 97.7695 23.6248 97.7695 22.2886V11.6893H100.693V21.9169C100.693 22.8161 100.829 23.5746 101.095 24.1975Z"
        fill="#170083"
      />
      <path
        d="M139.594 28.4874H136.671V18.2599C136.671 17.2854 136.555 16.4967 136.324 15.8838C136.093 15.276 135.741 14.8189 135.264 14.5125C134.787 14.206 134.184 14.0553 133.456 14.0553C132.652 14.0553 131.959 14.2211 131.376 14.5476C130.793 14.8792 130.341 15.3564 130.025 15.9893C129.708 16.6223 129.547 17.3758 129.547 18.2549V28.4824H126.624V18.2599C126.624 17.2854 126.508 16.4967 126.277 15.8838C126.046 15.276 125.695 14.8189 125.217 14.5125C124.74 14.206 124.142 14.0553 123.409 14.0553C122.605 14.0553 121.912 14.2211 121.329 14.5476C120.747 14.8792 120.294 15.3564 119.978 15.9893C119.661 16.6223 119.501 17.3758 119.501 18.2549V28.4824H116.577V11.6893H119.501V14.1357C119.842 13.5028 120.239 12.9803 120.686 12.5634C121.138 12.1515 121.656 11.84 122.238 11.6341C122.821 11.4281 123.454 11.3226 124.137 11.3226C124.916 11.3226 125.624 11.4432 126.257 11.6893C126.89 11.9355 127.443 12.2871 127.92 12.7493C128.392 13.2114 128.754 13.7841 128.995 14.4673C129.578 13.4224 130.311 12.6337 131.185 12.1113C132.064 11.5889 133.059 11.3277 134.179 11.3277C135.324 11.3277 136.299 11.5838 137.103 12.0962C137.906 12.6086 138.519 13.3571 138.946 14.3417C139.373 15.3262 139.584 16.5168 139.584 17.9032V28.4874H139.594Z"
        fill="#170083"
      />
      <path
        d="M145.255 28.4875H144.889V0H145.255V28.4875ZM159.06 24.725C158.376 26.0411 157.447 27.0558 156.267 27.7741C155.086 28.4925 153.765 28.8542 152.303 28.8542C151.183 28.8542 150.178 28.603 149.289 28.1057C148.4 27.6084 147.641 26.9352 147.009 26.0963C146.376 25.2574 145.893 24.3181 145.567 23.2833C145.24 22.2485 145.075 21.1835 145.075 20.0884C145.075 18.9933 145.24 17.9284 145.567 16.8936C145.893 15.8587 146.376 14.9194 147.009 14.0805C147.641 13.2416 148.4 12.5735 149.289 12.0711C150.178 11.5738 151.183 11.3227 152.303 11.3227C153.765 11.3227 155.086 11.6843 156.267 12.4027C157.447 13.121 158.381 14.1307 159.06 15.4318C159.743 16.7328 160.084 18.285 160.084 20.0884C160.084 21.8667 159.743 23.4139 159.06 24.725ZM158.713 15.6126C158.045 14.3568 157.141 13.3923 156.01 12.7091C154.88 12.0259 153.604 11.6843 152.193 11.6843C151.193 11.6843 150.274 11.9204 149.435 12.3976C148.596 12.8749 147.857 13.5078 147.225 14.2965C146.592 15.0902 146.104 15.9843 145.763 16.979C145.421 17.9786 145.25 19.0134 145.25 20.0834C145.25 21.1282 145.421 22.158 145.763 23.1677C146.104 24.1774 146.592 25.0816 147.225 25.8703C147.857 26.664 148.596 27.2969 149.435 27.7691C150.274 28.2463 151.193 28.4824 152.193 28.4824C153.604 28.4824 154.875 28.1409 156.01 27.4577C157.141 26.7745 158.045 25.8 158.713 24.5341C159.381 23.2682 159.718 21.7813 159.718 20.0784C159.718 18.3604 159.381 16.8684 158.713 15.6126Z"
        fill="#170083"
      />
      <path
        d="M164.133 15.5573C164.887 14.2412 165.906 13.2064 167.182 12.4529C168.458 11.6994 169.905 11.3226 171.512 11.3226C173.12 11.3226 174.567 11.6994 175.858 12.4529C177.149 13.2064 178.163 14.2412 178.907 15.5573C179.65 16.8734 180.022 18.3805 180.022 20.0884C180.022 21.7662 179.65 23.2732 178.907 24.5994C178.163 25.9255 177.149 26.9654 175.858 27.7239C174.567 28.4774 173.12 28.8542 171.512 28.8542C169.905 28.8542 168.463 28.4774 167.182 27.7239C165.906 26.9704 164.887 25.9306 164.133 24.5994C163.38 23.2732 163.003 21.7712 163.003 20.0884C163.003 18.3855 163.38 16.8734 164.133 15.5573ZM164.445 24.4336C165.163 25.6995 166.143 26.6941 167.383 27.4124C168.624 28.1308 170.011 28.4874 171.548 28.4874C173.055 28.4874 174.426 28.1308 175.657 27.4124C176.887 26.6941 177.862 25.6995 178.58 24.4336C179.299 23.1677 179.655 21.721 179.655 20.0884C179.655 18.4558 179.294 17.0091 178.58 15.7432C177.862 14.4773 176.887 13.4827 175.657 12.7643C174.426 12.046 173.06 11.6893 171.548 11.6893C170.016 11.6893 168.624 12.051 167.383 12.7643C166.143 13.4827 165.163 14.4773 164.445 15.7432C163.726 17.0091 163.37 18.4608 163.37 20.0884C163.37 21.721 163.726 23.1677 164.445 24.4336Z"
        fill="#170083"
      />
      <path
        d="M187.693 11.6894V12.0561H181.851V11.6894H187.693ZM184.955 5.84216V28.4875H184.588V5.84216H184.955Z"
        fill="#170083"
      />
      <path
        d="M190.581 26.3174C190.968 26.9654 191.535 27.4878 192.279 27.8897C193.022 28.2915 193.967 28.4925 195.107 28.4925C196.423 28.4925 197.503 28.1208 198.357 27.3773C199.211 26.6338 199.633 25.584 199.633 24.2176C199.633 23.4139 199.427 22.7508 199.01 22.2284C198.598 21.7059 198.041 21.2739 197.347 20.9323C196.654 20.5907 195.891 20.2994 195.067 20.0583C194.188 19.792 193.394 19.4956 192.676 19.1792C191.957 18.8627 191.385 18.4106 190.958 17.8279C190.531 17.2452 190.32 16.4264 190.32 15.3815C190.32 14.5024 190.521 13.774 190.923 13.1913C191.324 12.6086 191.852 12.1615 192.51 11.8601C193.168 11.5537 193.896 11.403 194.7 11.403C195.745 11.403 196.619 11.5788 197.312 11.9305C198.006 12.2821 198.568 12.7342 198.99 13.2818C199.417 13.8293 199.739 14.4221 199.96 15.055L199.593 15.1655C199.276 14.2864 198.754 13.5028 198.021 12.8096C197.292 12.1163 196.182 11.7697 194.695 11.7697C193.575 11.7697 192.626 12.0761 191.847 12.684C191.068 13.2918 190.676 14.196 190.676 15.3865C190.676 16.3862 190.882 17.1598 191.299 17.7073C191.711 18.2549 192.269 18.6718 192.962 18.9481C193.655 19.2294 194.429 19.4906 195.283 19.7317C196.087 19.9528 196.845 20.2441 197.563 20.6058C198.282 20.9725 198.865 21.4447 199.317 22.0324C199.769 22.6152 199.99 23.3486 199.99 24.2226C199.99 25.172 199.784 25.9959 199.367 26.6891C198.95 27.3823 198.382 27.9198 197.649 28.2966C196.915 28.6733 196.067 28.8642 195.092 28.8642C193.851 28.8642 192.826 28.6432 192.023 28.2062C191.219 27.7691 190.616 27.2015 190.214 26.5083C189.812 25.815 189.561 25.0766 189.466 24.298L189.833 24.2628C189.943 24.9812 190.189 25.6694 190.581 26.3174Z"
        fill="#170083"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "ConsiliumBots",
};
</script>

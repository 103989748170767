/* eslint-disable no-shadow */
import services from '../services';
import router from '@/router'

const getDefaultState = () => ({
  currentStep: 1,
  userRegisteredData: null,
  registerData: {
    email: null,
    username: null,
    typeRegister: null,
    register: null,
    password: null,
    confirmPassword: null,
    nationality: null,
    idType: null,
    idNumber: null,
    idImages: {
      image1: "",
      image2: "",
    },
    personalInfo: {
      firstName: null,
      secondName: null,
      surname: null,
      secondSurname: null,
      gender: null,
      birthDate: null,
    },
    address: {
      mainAddress: null,
      addressDetails: null,
      department: null,
      municipality: null,
      roadType: null,
      roadNumber: null,
      roadlLetter: null,
      mainRoadBis: null,
      quadrant: null,
      generatingRoadNumber: null,
      generationRoadlLetter: null,
      generationRoadPrefix: null,
      homePlate: null,
      generatingQuadrant: null,
      lat: null,
      lon: null,
    },
    homeLocation: null,
    fullAddress: null,
    stringFullAddress: null,
    addressType: null,
    identificationComplete: false,
    locationComplete: false,
    contactComplete: false,
    phoneNumber: null,
    contactConfig: {
      calls: null,
      whatsapp: null,
      sms: null,
      notifications: {
        dates: null,
        schools: null,
      },
    },
  },
  loadingLite: false,
  authMethod: "email",
  validCode: false,
  validAccount: null,
  geoLocatorError: false,
  inRegisterFlow: false,
});

const state = {
  ...getDefaultState(),
};

const getters = {
  currentStep: ({ currentStep }) => currentStep,
  geoLocatorError: ({ geoLocatorError }) => geoLocatorError,
  registerData: ({ registerData }) => registerData,
  nationality: ({ registerData }) => registerData.nationality,
  idType: ({ registerData }) => registerData.idType,
  authMethod: ({ authMethod }) => authMethod,
  username: ({ registerData }) => registerData.username,
  typeRegister: ({ registerData }) => registerData.typeRegister,
  register: ({ registerData }) => registerData.register,
  password: ({ registerData }) => registerData.password,
  confirmPassword: ({ registerData }) => registerData.confirmPassword,
  grades: ({ registerData }) => registerData.grades,
  validCode: ({ validCode }) => validCode,
  identificationComplete: ({ registerData }) => registerData.identificationComplete,
  locationComplete: ({ registerData }) => registerData.locationComplete,
  contactComplete: ({ registerData }) => registerData.contactComplete,
  userHomeLocation: ({ registerData }) => registerData.homeLocation,
  userFullAddress: ({ registerData }) => registerData.fullAddress,
  userStringAddress: ({ registerData }) => registerData.stringFullAddress,
  address: ({ registerData }) => registerData.address,
  loadingLite: ({ loadingLite }) => loadingLite,
  email: ({ registerData }) => registerData.email,
  phoneNumber: ({ registerData }) => registerData.phoneNumber,
  userRegisteredData: ({ userRegisteredData }) => userRegisteredData,
  inRegisterFlow: ({ inRegisterFlow }) => inRegisterFlow,
};

const mutations = {
  setGeoLocatorError(state, { geoLocatorError }) {
    state.geoLocatorError = geoLocatorError;
  },
  setInRegisterFlow(state, bool ) {
    state.inRegisterFlow = bool;
  },
  SetUserRegisteredData(state, { userRegisteredData }) {
    state.userRegisteredData = userRegisteredData;
  },
  setCurrentStep(state, { nextStep }) {
    state.currentStep = nextStep;
  },
  setRegisterData(state, { registerData }) {
    state.registerData = { ...registerData };
  },
  setPersonalInfo(state, { personalInfo }) {
    state.registerData.personalInfo = { ...personalInfo };
  },
  setAddress(state, { address }) {
    state.registerData.address = { ...address };
  },
  setContactConfig(state, { contactConfig }) {
    state.registerData.contactConfig = { ...contactConfig };
  },
  setNotifications(state, { notifications }) {
    state.registerData.contactConfig.notifications = { ...notifications };
  },
  setFullAddress(state, { fullAddress }) {
    state.registerData.fullAddress = fullAddress;
  },
  stringFullAddress(state, { stringAddress }) {
    state.registerData.stringFullAddress = stringAddress;
  },
  setHomeLocation(state, location) {
    state.registerData.homeLocation = location;
  },
  setUsername(state, { username }) {
    state.registerData.username = username;
  },
  setTypeRegister(state, { typeRegister }) {
    state.registerData.typeRegister = typeRegister;
  },
  setRegister(state, { register }) {
    state.registerData.register = register;
  },
  setPassword(state, { password }) {
    state.registerData.password = password;
  },
  setConfirmPassword(state, { confirmPassword }) {
    state.registerData.confirmPassword = confirmPassword;
  },
  setNationality(state, { nationalityLabel }) {
    state.registerData.nationality = nationalityLabel;
  },
  setIdType(state, { idTypeLabel }) {
    state.registerData.idType = idTypeLabel;
  },
  setIdImage1(state, { imageName }) {
    state.registerData.idImages.image1 = imageName;
  },
  setIdImage2(state, { imageName }) {
    state.registerData.idImages.image2 = imageName;
  },
  setDefaultImages(state, { imageName }) {
    state.registerData.idImages.image1 = imageName;
    state.registerData.idImages.image2 = imageName;
  },
  setMainAddress(state, { mainAddress }) {
    state.registerData.address.addressDetails = mainAddress;
  },
  setAddressType(state, { addressType }) {
    state.registerData.addressType = addressType;
  },
  setIdNumber(state, { idNumber }) {
    state.registerData.idNumber = idNumber;
  },
  setGrades(state, { grades }) {
    state.registerData.grades = grades;
  },
  setPhoneNumber(state, { phoneNumber }) {
    state.registerData.phoneNumber = phoneNumber;
  },
  setIdentificationComplete(state, { identificationComplete }) {
    state.registerData.identificationComplete = identificationComplete;
  },
  setContactComplete(state, { contactComplete }) {
    state.registerData.contactComplete = contactComplete;
  },
  setLocationComplete(state, { locationComplete }) {
    state.registerData.locationComplete = locationComplete;
  },
  setAuthMethod(state, { authMethod }) {
    state.authMethod = authMethod;
  },
  setValidCode(state, { validCode }) {
    state.validCode = validCode;
  },
  setValidAccount(state, { dataAccount }) {
    state.validAccount = dataAccount;
  },
  setLoadingLite(state, loadingLite) {
    state.loadingLite = loadingLite;
  },
};

const actions = {
  setGeoLocatorError({ commit }, { setGeo }) {
    commit("setGeoLocatorError", { setGeo });
  },
  setCurrentStep({ commit }, { nextStep }) {
    commit("setCurrentStep", { nextStep });
  },
  setFullAddress({ commit }, { fullAddress }) {
    commit("setFullAddress", { fullAddress });
  },
  setUsername({ commit }, { username }) {
    services.userRegistrationService
      .communRegisterStep1({ username })
      .then((response) => {
        const { data } = response;
        commit("SetUserRegisteredData", { userRegisteredData: data });
      })
      .finally(() => {
        commit("setLoadingLite", false);
      });
    commit("setUsername", { username });
  },
  setTypeRegister({ commit }, { typeRegister }) {
    commit("setTypeRegister", { typeRegister });
  },
  setRegister({ commit }, { register }) {
    commit("setRegister", { register });
  },
  setPassword({ commit }, { password }) {
    commit("setPassword", { password });
  },
  setInRegisterFlow({ commit }, bool) {
    commit('setInRegisterFlow', bool);
  },
  setConfirmPassword({ commit }, { confirmPassword }) {
    commit("setConfirmPassword", { confirmPassword });
  },
  setRegisterData({ commit }, { registerData }) {
    commit("setRegisterData", { registerData });
  },
  getRegistarData({ commit }, { uuid }) {
    commit("setLoadingLite", true);
    services.userRegistrationService
      .retrieveRegistrationData({ uuid })
      .then((response) => {
        const { data } = response;
        commit("SetUserRegisteredData", { userRegisteredData: data });
      })
      .finally(() => {
        commit("setLoadingLite", false);
      });
  },
  setNationality({ commit }, { uuid, nationality, idType, nationalityLabel, idTypeLabel }) {
    services.userRegistrationService
      .identificationStep1({ uuid, nationality, idType })
      .then(() => {
        commit("setNationality", { nationalityLabel });
        commit("setIdType", { idTypeLabel });
      })
      .finally(() => {});
  },
  setGrades({ commit }, { uuid, interestGrades }) {
    services.userRegistrationService
      .identificationCommonStep2({ uuid, interestGrades })
      .then((response) => {
        commit("setGrades", { grades: response.data });
      })
      .catch((error) => console.error(error))
      .finally(() => {});
  },
  setIdNumber({ commit }, { uuid, idNumber }) {
    services.userRegistrationService
      .identificationStep2({ uuid, idNumber })
      .then(() => {
        commit("setIdNumber", { idNumber });
      })
      .finally(() => {});
  },
  setIdImage({ commit }, { uuid, imageB64, imageName, imageNumber }) {
    services.userRegistrationService
      .identificationStep3({
        uuid,
        imageB64,
        imageName,
        imageNumber,
      })
      .then(() => {
        if (imageNumber === 1) {
          commit("setIdImage1", { imageName });
        } else if (imageNumber === 2) {
          commit("setIdImage2", { imageName });
        }
      })
      .finally(() => {});
  },
  setDefaultImages({ commit }, { imageName }) {
    commit("setDefaultImages", { imageName });
  },
  setPersonalInfo({ commit }, { uuid, personalInfo }) {
    services.userRegistrationService
      .identificationStep4({ uuid, personalInfo })
      .then(() => {
        commit("setPersonalInfo", { personalInfo });
      })
      .finally(() => {});
  },
  setMainAddress({ commit, state }, { uuid, mainAddress, addressIds, location, roadTypeId }) {
    const { address } = state.registerData;
    let addressDetail = state.registerData.stringFullAddress;
    let fullAddressPost;
    if (state.registerData.fullAddress) {
      addressDetail = state.registerData.fullAddress;
      fullAddressPost = {
        department: addressIds.department,
        municipality: addressIds.municipality,
        main_address: true,
        address_details: addressDetail,
        vp_tipo_via: addressIds.roadType,
        area_label: 1,
        vp_number: address.roadNumber,
        vp_letra: address.roadlLetter,
        vp_prefijo: address.mainRoadBis,
        vp_cuadrante: address.quadrant,
        vg_numero_via: address.generatingRoadNumber,
        vg_letra: address.generationRoadlLetter,
        vg_placa: address.homePlate,
        vg_prefijo: address.generationRoadPrefix,
        vg_cuadrante: address.generatingQuadrant,
        address_lat: address.lat,
        address_lon: address.lon,
      };
    } else {
      let roadTypeSelect = roadTypeId;
      if (roadTypeSelect === undefined) {
        roadTypeSelect = null;
      }
      fullAddressPost = {
        department: address.department,
        municipality: address.municipality,
        main_address: true,
        address_details: mainAddress,
        vp_tipo_via: roadTypeSelect,
        area_label: 1,
        vp_number: address.roadNumber,
        vp_letra: address.roadlLetter,
        vp_prefijo: address.mainRoadBis,
        vp_cuadrante: address.quadrant,
        vg_numero_via: address.generatingRoadNumber,
        vg_letra: address.generationRoadlLetter,
        vg_placa: address.homePlate,
        vg_prefijo: address.generationRoadPrefix,
        vg_cuadrante: address.generatingQuadrant,
        address_lat: location.lat,
        address_lon: location.lng,
      };
    }
    services.userRegistrationService
      .identificationStep5({ uuid, fullAddressPost })
      .then(() => {
        commit("setMainAddress", { mainAddress });
      })
      .finally(() => {});
  },
  setLoadingLite({ commit }, { loadingLite }) {
    commit("setLoadingLite", { loadingLite });
  },
  setLoadingAddress({ commit }, { address }) {
    commit("setAddress", { address });
  },
  setContactConfig({ commit, state }, { uuid, email, contactConfig }) {
    const { phoneNumber } = state.registerData;
    services.userRegistrationService
      .identificationStep6({
        uuid,
        contactConfig,
        phoneNumber,
        email,
      })
      .then(() => {
        commit("setContactConfig", { contactConfig });
      })
      .finally(() => {});
  },
  setNotifications({ commit }, { uuid, notifications }) {
    services.userRegistrationService
      .identificationStep7({ uuid, notifications })
      .then(() => {
        commit("setNotifications", { notifications });
      })
      .finally(() => {});
  },
  setAddress({ commit, rootGetters }, { address, fullAddress }) {
    const userStringAddress = rootGetters["userRegister/userStringAddress"];
    if (userStringAddress === undefined || userStringAddress === null) {
      const geoSearch = {
        VP_cuadrante: address.quadrant,
        VP_tipo_via: address.roadType,
        VP_numero: address.roadNumber,
        VP_letra: address.roadlLetter,
        VP_prefijo: address.mainRoadBis,
        VG_numero_via: address.generatingRoadNumber,
        VG_letra: address.generationRoadlLetter,
        VG_placa: address.homePlate,
        VG_cuadrante: address.generatingQuadrant,
        VG_prefijo: address.generationRoadPrefix,
        departamento: address.department,
        municipio: address.municipality,
      };
      if (userStringAddress) {
        commit("stringFullAddress", { fullAddress });
      } else {
        commit("setFullAddress", { fullAddress });
      }
      commit("setLoadingLite", true);
      services.geoLocatorService
        .fromAddress({ geoSearch })
        .then((response) => {
          if (
            response.data.location.error_code === "LOCATION_MISSING" ||
            response.data.location.error_code === "LATLON_MISSING"
          ) {
            commit("setGeoLocatorError", { geoLocatorError: true });
            commit("setCurrentStep", { nextStep: 14 });
          } else {
            const location = {
              lat: response.data.location.lat,
              lng: response.data.location.lon,
            };
            if (response.data.location !== undefined) {
              commit("setHomeLocation", { location });
            }
            commit("setCurrentStep", { nextStep: 15 });
            /* eslint-disable no-param-reassign */
            address.lat = location.lat;
            /* eslint-disable no-param-reassign */
            address.lon = location.lng;
          }
        })
        .finally(() => {
          commit("setAddress", { address });
          commit("setLoadingLite", false);
        });
    }
  },
  setStringAddress({ commit }, { stringAddress }) {
    commit("stringFullAddress", { stringAddress });
  },
  setFastRegisterLocation({ commit, dispatch }, { location }) {
    commit("setLoadingLite", true);
    const fulladdress = location.addressDetail;
    const homeLocation = location.coordinates;
    services.userRegistrationService.fastRegisterStep2({ location }).then(() => {
      dispatch('authentication/setLegalGuardianLocation', location, { root: true });
      commit('setFullAddress', { fulladdress });
      commit('setHomeLocation', { homeLocation });
    }).finally(() => {
      commit('setLoadingLite', false);
    });
  },
  setFastRegisterGrade({rootGetters, dispatch}, {gradesData}) {
    services.userRegistrationService
      .fastRegisterStep3( {gradesData})
      .then(() => {
        if (router.currentRoute.name === 'Login') {
          const legalGuardianUUID = rootGetters['authentication/legalGuardianUUID'];
          dispatch('authentication/getUserDetails', { legalGuardianUUID }, { root: true }).then(() => {
            dispatch('authentication/getStudents', {}, { root: true });
          });
        }
        const inRegisterFlow = rootGetters['userRegister/inRegisterFlow']; 
        if (inRegisterFlow) {
          dispatch('authentication/getStudents', {}, { root: true }).then(() => {
            const currentStudent = rootGetters['authentication/currentStudent'];
            const exploredCampuses = rootGetters['institutions/exploredCampuses'];
            const exploredSchoolCards = rootGetters['institutions/exploredSchoolCards'];
            if (exploredSchoolCards.length > 0) {
              exploredSchoolCards.forEach(campus => {
                services.institutionsService
                .exploreSchoolCard({
                  currentStudent,
                  campus,
                })
                .then(() => {
                  dispatch('institutions/retrieveExploredSchoolCard', {}, { root: true }).then(() => {
                    dispatch('institutions/updateExploredSchoolCards', [campus], { root: true });
                  });
                });
              });
            }
            if (exploredCampuses.length > 0) {
              exploredCampuses.forEach(campus => {
                services.institutionsService
                .exploreCampus({
                  currentStudent,
                  campus,
                })
                .then(() => {
                  dispatch('institutions/retrieveExploredCampuses', {}, { root: true }).then(() => {
                    dispatch('institutions/updateExploredMarkers', [campus], { root: true });
                  });
                });
              });
            }
          });
        }
      })
      .finally(() => {});
  },
  setHomeLocationFromAuth({ commit }, homeLocation) {
    commit("setHomeLocation", homeLocation);
  },
  setHomeLocation({ dispatch, commit }, { location }) {
    const geoSearch = {
      lat: location.lat,
      lon: location.lng,
    };
    services.geoLocatorService
      .fromPoint({ geoSearch })
      .then((response) => {
        const fullAddress = `${response.data.address.VP_tipo_via} ${response.data.address.VP_numero} # ${response.data.address.VG_numero_via} - ${response.data.address.VG_placa}`;
        const address = {
          mainAddress: true,
          addressDetails: fullAddress,
          department: response.data.address.departamento,
          municipality: response.data.address.municipio,
          roadType: response.data.address.VP_tipo_via,
          roadNumber: response.data.address.VP_numero,
          roadlLetter: response.data.address.VP_letra,
          mainRoadBis: response.data.address.VP_prefijo,
          quadrant: response.data.address.VP_prefijo,
          generatingRoadNumber: response.data.address.VP_cuadrante,
          generationRoadlLetter: response.data.address.VG_letra,
          generationRoadPrefix: response.data.address.VG_prefijo,
          homePlate: response.data.address.VG_placa,
          generatingQuadrant: response.data.address.VG_cuadrante,
          lat: geoSearch.lat,
          lon: geoSearch.lon,
        };
        dispatch("setAddress", { address });
      })
      .finally(() => {});
    commit("setHomeLocation", { location });
  },
  setIdentificationComplete({ commit }, { identificationComplete }) {
    commit("setIdentificationComplete", { identificationComplete });
  },
  setLocationComplete({ commit }, { locationComplete }) {
    commit("setLocationComplete", { locationComplete });
  },
  setContactComplete({ commit }, { contactComplete }) {
    commit("setContactComplete", { contactComplete });
  },
  setAuthMethod({ commit }, { authMethod }) {
    commit("setAuthMethod", { authMethod });
  },
  restartAddress({ commit }, { nullState }) {
    commit("setHomeLocation", { nullState });
    commit("setFullAddress", { nullState });
    commit("stringFullAddress", { stringAddress: nullState });
  },
  setAddressType({ commit }, { addressType }) {
    commit("setAddressType", { addressType });
  },
  setPhoneNumber({ commit }, { phoneNumber }) {
    commit("setPhoneNumber", { phoneNumber });
  },
  verifyAccount({ state, commit }, { callback, errorCallback }) {
    services.authenticationService
      .verifyAccount({
        username:
          state.registerData.typeRegister === "phone"
            ? `+56${state.registerData.register}`
            : state.registerData.register,
      })
      .then(({ data }) => {
        commit("setValidAccount", { validCode: data });
        callback(data);
      })
      .catch(() => {
        errorCallback(false);
        commit("setValidAccount", false);
      });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

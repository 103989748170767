<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2061 9.20831C17.4164 9.20831 17.5869 9.0378 17.5869 8.82745C17.5869 8.61711 17.4164 8.44659 17.2061 8.44659C16.9957 8.44659 16.8252 8.61711 16.8252 8.82745C16.8252 9.0378 16.9957 9.20831 17.2061 9.20831Z"
      fill="#333333"
    />
    <path
      d="M18.7295 9.20831C18.9398 9.20831 19.1104 9.0378 19.1104 8.82745C19.1104 8.61711 18.9398 8.44659 18.7295 8.44659C18.5191 8.44659 18.3486 8.61711 18.3486 8.82745C18.3486 9.0378 18.5191 9.20831 18.7295 9.20831Z"
      fill="#333333"
    />
    <path
      d="M15.6826 9.20831C15.893 9.20831 16.0635 9.0378 16.0635 8.82745C16.0635 8.61711 15.893 8.44659 15.6826 8.44659C15.4723 8.44659 15.3018 8.61711 15.3018 8.82745C15.3018 9.0378 15.4723 9.20831 15.6826 9.20831Z"
      fill="#333333"
    />
    <path
      d="M25.7615 16.5577C24.8755 16.1991 24.1627 15.5062 23.7765 14.6372C25.2119 13.0251 25.9995 10.9597 25.9995 8.79364C25.9995 3.94484 22.0546 0 17.2058 0C12.9565 0 9.31875 3.02951 8.55607 7.20347C8.51829 7.41041 8.65535 7.60876 8.86228 7.64659C9.06886 7.68427 9.26751 7.54726 9.3054 7.34038C10.0019 3.52843 13.3245 0.761719 17.2058 0.761719C21.6347 0.761719 25.2378 4.36485 25.2378 8.79364C25.2378 10.8471 24.4628 12.8015 23.0557 14.2967C22.9565 14.4021 22.9256 14.5547 22.976 14.6903C23.2935 15.5451 23.8666 16.2767 24.6037 16.7908C24.4362 16.8139 24.2665 16.8255 24.0952 16.8255C23.1647 16.8255 22.2763 16.4773 21.5939 15.845C21.4724 15.7324 21.2921 15.7112 21.1478 15.7927C20.3728 16.2301 19.5355 16.5331 18.6591 16.6931C18.4522 16.7308 18.3151 16.9292 18.3528 17.1361C18.3906 17.3431 18.5892 17.48 18.7959 17.4424C19.667 17.2834 20.503 16.9954 21.2847 16.5853C22.0769 17.2333 23.0646 17.5872 24.0952 17.5872C24.6708 17.5872 25.2315 17.4783 25.7616 17.2637C25.9054 17.2055 25.9995 17.0659 25.9995 16.9107C25.9995 16.7556 25.9053 16.616 25.7615 16.5577Z"
      fill="#333333"
    />
    <path
      d="M9.62131 16.9716C9.73125 16.8023 9.79514 16.6005 9.79514 16.3841C9.79514 15.7875 9.30977 15.3021 8.71319 15.3021H7.82813C7.61774 15.3021 7.44727 15.4726 7.44727 15.683V18.7299C7.44727 18.8312 7.48764 18.9283 7.55939 18.9998C7.63079 19.0709 7.72743 19.1107 7.82813 19.1107C7.8298 19.1107 8.64347 19.1071 8.86772 19.1071C9.52996 19.1071 10.0687 18.5684 10.0687 17.9061C10.0687 17.529 9.89401 17.1919 9.62131 16.9716ZM8.20903 16.0638H8.71324C8.88981 16.0638 9.03347 16.2075 9.03347 16.3841C9.03347 16.5606 8.88981 16.7043 8.71324 16.7043C8.70481 16.7043 8.69638 16.7045 8.68811 16.7051H8.20903V16.0638ZM8.86772 18.3453C8.74645 18.3453 8.45345 18.3464 8.20898 18.3474V17.4668H8.86772C9.10995 17.4668 9.30698 17.6639 9.30698 17.9061C9.30703 18.1483 9.10995 18.3453 8.86772 18.3453Z"
      fill="#333333"
    />
    <path
      d="M5.54784 15.5904C5.54677 15.5875 5.54566 15.5847 5.54449 15.5818C5.47517 15.4122 5.31135 15.3024 5.12803 15.3021C4.94471 15.3023 4.78089 15.4122 4.71157 15.5818C4.7104 15.5847 4.70929 15.5875 4.70822 15.5904L3.56412 18.5943C3.48922 18.7909 3.58793 19.0109 3.78446 19.0858C3.98098 19.1605 4.20107 19.0621 4.27597 18.8654L4.46777 18.3626H5.78839L5.98019 18.8654C6.05509 19.0621 6.27518 19.1606 6.4717 19.0858C6.66823 19.0109 6.7669 18.7909 6.69204 18.5943L5.54784 15.5904ZM4.75824 17.6008L5.12798 16.6316L5.49772 17.6008H4.75824Z"
      fill="#333333"
    />
    <path
      d="M13.9117 18.008C13.7505 17.8728 13.5103 17.8938 13.3752 18.055C13.3486 18.0866 13.3199 18.1162 13.2899 18.1429C13.1364 18.2796 12.9403 18.3489 12.7071 18.3489C12.0771 18.3489 11.5645 17.8364 11.5645 17.2063C11.5645 16.5763 12.0771 16.0638 12.7071 16.0638C12.9361 16.0638 13.1569 16.1312 13.3457 16.2587C13.52 16.3764 13.7567 16.3306 13.8744 16.1563C13.9922 15.982 13.9464 15.7452 13.772 15.6275C13.4569 15.4146 13.0886 15.302 12.707 15.302C11.657 15.302 10.8027 16.1562 10.8027 17.2063C10.8027 18.2564 11.657 19.1106 12.707 19.1106C13.1267 19.1106 13.5035 18.9726 13.7965 18.7116C13.8539 18.6605 13.9084 18.6042 13.9585 18.5445C14.0939 18.3835 14.0728 18.1432 13.9117 18.008Z"
      fill="#333333"
    />
    <path
      d="M8.79364 8.41278C3.94484 8.41278 0 12.3576 0 17.2064C0 19.3725 0.787617 21.4379 2.22295 23.05C1.83681 23.919 1.12384 24.6118 0.23791 24.9705C0.0941484 25.0288 0 25.1684 0 25.3235C0 25.4787 0.0941484 25.6183 0.237961 25.6766C0.768016 25.8912 1.32869 26 1.9043 26C2.9349 26 3.92255 25.6461 4.71484 24.9981C5.96715 25.6543 7.37151 26 8.79364 26C13.6424 26 17.5872 22.0552 17.5872 17.2064C17.5872 12.3576 13.6424 8.41278 8.79364 8.41278ZM8.79364 25.2383C7.41178 25.2383 6.04861 24.8811 4.85164 24.2055C4.79314 24.1724 4.7286 24.1563 4.66451 24.1563C4.57072 24.1563 4.47774 24.1909 4.40558 24.2578C3.72308 24.8901 2.83476 25.2383 1.9043 25.2383C1.73296 25.2383 1.56325 25.2267 1.39577 25.2036C2.13291 24.6894 2.70598 23.9578 3.02346 23.1031C3.07389 22.9674 3.04302 22.8148 2.94384 22.7095C1.53669 21.2142 0.761719 19.2599 0.761719 17.2064C0.761719 12.7776 4.3648 9.17445 8.79364 9.17445C13.2224 9.17445 16.8255 12.7775 16.8255 17.2064C16.8255 21.6352 13.2224 25.2383 8.79364 25.2383Z"
      fill="#333333"
    />
  </svg>
</template>
<script>
export default {
  name: "Language",
};
</script>

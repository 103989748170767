/**
 *
 * @param {number} x
 * @returns radian value of x
 */
const rad = (x) => (x * Math.PI) / 180;

/**
 *
 * @param {*Position 1{lat, lng}} p1
 * @param {*Position 2{lat, lng}} p2
 * @returns distance between p1 and p2 in meters
 */
const getDistanceInMeters = (p1, p2) => {
  const R = 6378137; // Earth’s mean radius in meter
  /* Auxiliary functions from
  https://stackoverflow.com/questions/1502590/calculate-distance-between-two-points-in-google-maps-v3
  */
  const p1Lat = parseFloat(p1.lat);
  const p2Lat = parseFloat(p2.lat);
  const p1Lng = parseFloat(p1.lng);
  const p2Lng = parseFloat(p2.lng);

  const dLat = rad(p2Lat - p1Lat);
  const dLong = rad(p2Lng - p1Lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1Lat)) * Math.cos(rad(p2Lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // returns the distance in meter
};

/**
 *
 * @param {*Position 1{lat, lng}} p1
 * @param {*Position 2{lat, lng}} p2
 *  * @returns distance between p1 and p2 in feet
 */
const getDistanceInFeet = (p1, p2) => {
  const distance = getDistanceInMeters(p1, p2);
  return (distance * 3.28084).toFixed(1).toString();
};

/**
 *
 * @param {*Position 1{lat, lng}} p1
 * @param {*Position 2{lat, lng}} p2
 *  * @returns distance between p1 and p2 in milles
 */
const getDistanceInMilles = (p1, p2) => {
  const distance = getDistanceInMeters(p1, p2);
  return (distance * 0.000621371).toFixed(1).toString();
};

/**
 *
 * @param {*Position 1{lat, lng}} p1
 * @param {*Position 2{lat, lng}} p2
 *  * @returns distance between p1 and p2 in kilometers
 */
const getDistanceInKm = (p1, p2) => {
  const distance = getDistanceInMeters(p1, p2);
  return (distance / 1000).toFixed(1).toString();
};

/**
 * Verify if is a valid run without dots, with hyphen
 */
const checkIsRUN = (run) => {
  // eslint-disable-line func-names
  let rut = run.toLowerCase();
  if (rut.length < 5) {
    return false;
  }
  if (!/^[0-9]+[-|‐]{1}[0-9k]{1}$/.test(rut)) {
    return false;
  }
  const aux = rut.split("-");
  rut = aux[0]
    .split("")
    .map((element) => parseInt(element, 10))
    .reverse();
  const digv = aux[1] === "k" ? 10 : parseInt(aux[1], 10);
  let total = 0;
  let mult = 2;
  for (let index = 0; index < rut.length; index += 1) {
    total += rut[index] * mult;
    mult = mult < 7 ? mult + 1 : 2;
  }
  let result = 11 - (total % 11);
  result = result === 11 ? 0 : result;
  return result === digv;
};

const checkIsIntGreaterOrEqualTo0 = (value) => {
  const parsedValue = parseInt(value, 10);
  return value === 0 || (!!value && parsedValue > -1);
};

const checkAtLeastOneSpecialCharacter = (password) => {
  const specialCharsRegExpExists = /.*[/!@#$%^&*)({}+=,._-]+.*/; // /!@#$%^&*)({}+=,._-
  const test = specialCharsRegExpExists.test(password);
  return test;
};

const checkAtLeastOneLetter = (password) => {
  if (!password) {
    return false;
  }
  const passStringify = `${password}`;
  const notNullString = passStringify.length > 0;
  const hastAtLeastOneLetter = /^.*[a-zA-Z]+.*$/;
  return hastAtLeastOneLetter.test(password) && notNullString;
};

const checkNotOnlyLetters = (password) => {
  const onlyLettersRegExp = /^[A-z]+$/;
  const hasOnlyLetters = !onlyLettersRegExp.test(password);
  const hastAtLeastOneLetter = checkAtLeastOneLetter(password);
  return hasOnlyLetters && hastAtLeastOneLetter;
};

const checkMixCases = (password) => {
  const onlyLettersRegExp = /^.*([a-z]+[A-Z]+|[A-Z]+[a-z]+).*$/;
  return onlyLettersRegExp.test(password);
};

const checkUpperCases = (password) => {
  const onlyLettersRegExp = /^.*([A-Z]+|[A-Z]+).*$/;
  return onlyLettersRegExp.test(password);
};

const checkLowerCases = (password) => {
  const onlyLettersRegExp = /^.*([a-z]+|[a-z]+).*$/;
  return onlyLettersRegExp.test(password);
};

const checkAtLeastOneNumber = (password) => {
  if (!password || (!Number.isInteger(password) && !Number.isInteger(parseInt(password, 10)))) {
    // exception to 0 and string with numbers that is not a integer
    if (password !== 0 && `${password}`.length < 1) return false;
  }
  const atLeastOneNumberRegExp = /.*[0-9].*/;
  return atLeastOneNumberRegExp.test(password);
};

const checkNotOnlyNumbers = (password) => {
  const onlyNumbersRegExp = /^[0-9]*$/;
  const hasAtLeastOneNumber = checkAtLeastOneNumber(password);
  return !onlyNumbersRegExp.test(password) && hasAtLeastOneNumber;
};

const atLeastSizeX = (password, n) => {
  if (!password) {
    if (password !== "" && password !== 0) {
      return false;
    }
  }
  return `${password}`.length >= n;
};

const checkValidPassword = (password) =>
  checkNotOnlyNumbers(password) &&
  checkMixCases(password) &&
  checkNotOnlyLetters(password) &&
  checkAtLeastOneSpecialCharacter(password) &&
  atLeastSizeX(password, 8);

const scrollToElement = (element) => {
  element.scrollTo({ top: 0, behavior: "smooth" });
};

const goToTop = () => {
  const element = document.getElementById("body");
  scrollToElement(element);
};

const gradeEnglish = [
  "PreK-3",
  "PreK-4",
  "Kindergarten",
  "1st Grade",
  "2nd Grade",
  "3rd Grade",
  "4th Grade",
  "5th Grade",
  "6th Grade",
  "7th Grade",
  "8th Grade",
  "9th Grade",
  "10th Grade",
  "11th Grade",
  "12th Grade",
];

const getMonthInString = (value, lang) => {
  switch (value) {
    case '01':
      if (lang === 'en') {
        return 'JAN'
      }
      return 'ENERO'
    case '02':
      return 'FEB'
    case '03':
      if (lang === 'en') {
        return 'MAR'
      }
      return 'MARZO'
    case '04':
      if (lang === 'en') {
        return 'APR'
      }
      return 'ABRIL'
    case '05':
      if (lang === 'en') {
        return 'MAY'
      }
      return 'MAYO'
    case '06':
      if (lang === 'en') {
        return 'June'
      }
      return 'JUN'
    case '07':
      if (lang === 'en') {
        return 'JULY'
      }
      return 'JUL'
    case '08':
      if (lang === 'en') {
        return 'AUG'
      }
      return 'AGOSTO'
    case '09':
      return 'SEPT'
    case '10':
      return 'OCT'
    case '11':
      return 'NOV'
    case '12':
      if (lang === 'en') {
        return 'DEC'
      }
      return 'DIC'
    default:
      break;
  }
};

export default {
  getDistanceInMeters,
  getDistanceInKm,
  getDistanceInFeet,
  getDistanceInMilles,
  checkIsRUN,
  checkIsIntGreaterOrEqualTo0,
  checkAtLeastOneSpecialCharacter,
  checkNotOnlyLetters,
  checkNotOnlyNumbers,
  checkValidPassword,
  checkMixCases,
  checkAtLeastOneLetter,
  checkAtLeastOneNumber,
  atLeastSizeX,
  goToTop,
  scrollToElement,
  checkLowerCases,
  checkUpperCases,
  gradeEnglish,
  getMonthInString
};

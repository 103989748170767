<template>
  <svg width="31" height="29" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.375 14.5C0.375 6.69898 6.69898 0.375 14.5 0.375H15.5C23.8533 0.375 30.625 7.14669 30.625 15.5V28.625H14.5C6.69898 28.625 0.375 22.301 0.375 14.5Z"
      fill="#5FAABA"
      stroke="#3D3838"
      stroke-width="0.75"
    />
    <path
      d="M16.3887 17.9863H14.7578L14.6309 7.78125H16.5254L16.3887 17.9863ZM14.5625 21.0918C14.5625 20.7988 14.6504 20.5547 14.8262 20.3594C15.0085 20.1576 15.2754 20.0566 15.627 20.0566C15.9785 20.0566 16.2454 20.1576 16.4277 20.3594C16.61 20.5547 16.7012 20.7988 16.7012 21.0918C16.7012 21.3848 16.61 21.6289 16.4277 21.8242C16.2454 22.013 15.9785 22.1074 15.627 22.1074C15.2754 22.1074 15.0085 22.013 14.8262 21.8242C14.6504 21.6289 14.5625 21.3848 14.5625 21.0918Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "Info",
};
</script>

<template>
  <div class="link-account">
    <h1 class="link-account--title" v-t="'user_register.link_account.title'" />
    <p class="link-account--sub-title" v-t="'user_register.link_account.sub_title'" />
    <p class="link-account--sub-title mt-4" v-t="'user_register.link_account.instructions'" />
    <form style="margin-top: 30px">
      <label class="link-account--input-title">
        {{ $t('views.login.name.email') }}
        <span class="link-account--input-title__dot">*</span>
      </label>
      <input
        id="email"
        type="email"
        v-model="email"
        class="link-account--input"
        :class="{ 'link-account--input__filled': email }"
      />
    </form>
    <form>
      <label class="link-account--input-title">
        {{ $t('views.login.name.password') }}
        <span class="link-account--input-title__dot">*</span>
      </label>
      <div
        class="link-account--input"
        :class="{ 'link-account--input__filled': password }"
      >
        <input
          id="password"
          :type="showPassword ? 'text': 'password'"
          v-model="password"
        />
        <v-icon
          @click="showPassword = !showPassword"
          :color="password ? '#ffffff': '#2b5bff'"
        >{{ showPassword ? "mdi-eye" : "mdi-eye-off" }} </v-icon>
      </div>
    </form>
    <p @click="showResetPassword()" class="link-account--recover-password" v-t="'user_register.link_account.recover_password'" />
    <div class="link-account--btn-div">
      <button
        @click="closeModal()"
        class="link-account--btn-div__btn-cancel"
        v-t="'student_register.cancel'"
      />
      <button
        class="link-account--btn-div__btn-link"
        :disabled="!password && !email ? true : false"
        :class="{ 'link-account--btn-div__btn-link__filled': password && email }"
        @click="setLinkAccounts()"
      >
        <div v-if="!linkLoading" v-t="'user_register.link_account.link'" />
        <v-progress-circular v-if="linkLoading" indeterminate :size="24" color="white" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LinkAccount',
  data() {
    return {
      email: '',
      password: '',
      showPassword: false
    };
  },
  methods: {
    ...mapActions({
      unsetGoogleAuthData: 'authentication/unsetGoogleAuthData',
      doLogin: 'authentication/doLogin',
      linkAccounts: 'authentication/linkAccounts',
      setLinkLoading: 'authentication/setLinkLoading',
    }),
    closeModal() {
      this.unsetGoogleAuthData();
      this.$emit('closeModal');
    },
    setLinkAccounts() {
      this.setLinkLoading({ bool: true });
      this.doLogin({
        email: this.email,
        password: this.password,
        linkAccount: true,
      });
    },
    showResetPassword() {
      this.$emit('resetPassword')
    }
  },
  computed: {
    ...mapGetters({
      linkLoading: 'authentication/linkLoading',
    }),
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
    currentRoute() {
      return this.$route.name;
    },
  },
};
</script>

<template>
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 7L0.803849 0.249999L11.1962 0.25L6 7Z" fill="#333333" />
  </svg>
</template>

<script>
export default {
  name: "ArrowDown2",

  data() {
    return {};
  },
};
</script>

import { searchApi } from "../api";

const appUrl = "campuses/";
const searchAll = "campuses/all";
const searchCounter = "campuses/counter";

export default {
  elasticMainSerach({ mainSearch, gradeId }) {
    if (gradeId) {
      return searchApi.post(`${appUrl}/`, {
        search_term: mainSearch,
        search_size: 20,
        search_operator: "and",
        search_partial: false,
        other_filters: [
          /*
          {
            "fieldname":"programs.grade.id",
            "fieldvalue": gradeId.toString(),
          }
          */
        ],
      });
    } else {
      return searchApi.post(`${appUrl}/`, {
        search_term: mainSearch,
        search_size: 20,
        search_operator: "and",
        search_partial: false,
        other_filters: [],
      });
    }
  },
  elasticSearchCampuses(other_filters) {
    return searchApi.post(`${searchAll}`, {
      search_size: 5000,
      search_operator: "OR",
      filter_match: [...other_filters],
      fields_required: ["location", "campus_code"],
    });
  },
  // eslint-disable-next-line camelcase
  elasticSchoolList(searchFrom, filter) {
    // eslint-disable-next-line camelcase
    const filter_match = filter ?? [];
    return searchApi.post(`${searchAll}/`, {
      search_size: 5000,
      fields_required: ["id", "campus_name"],
      search_from: searchFrom,
      filter_match,
    });
  },
  elasticCommuneList() {
    return searchApi.post(`${searchCounter}/`, {
      field_sum: "programs.vacancies.regular_vacancies",
      field_group: "commune",
      search_size: 5000,
    });
  },
};

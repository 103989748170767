import { apiSendMail } from "../api";

export default {
  sendMail(data) {
    return apiSendMail.post("/sendmail", data);
  },
  /*
  getMessages() {
    return apiSendMail.get('/getmessages');
  },
  */
  getMessages(/* uuid */) {
    return apiSendMail.get(`/search/chile/parent_uuid/${1 /* uuid */}`);
  },
};

<template>
  <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0237 29.1765C6.72791 29.1765 0 22.6392 0 14.5979C0 6.53723 6.72791 0 15.0237 0C23.3195 0 30.0474 6.53723 30.0474 14.5979C30.0275 22.6392 23.2996 29.1765 15.0237 29.1765ZM15.0237 1.34987C7.50192 1.34987 1.38924 7.2893 1.38924 14.5979C1.38924 21.9065 7.50192 27.8459 15.0237 27.8459C22.5455 27.8459 28.6581 21.9065 28.6581 14.5979C28.6383 7.2893 22.5256 1.34987 15.0237 1.34987Z"
      fill="#333333"
    />
    <path
      d="M20.4219 20.4023H19.0327C19.0327 18.2425 17.2267 16.4877 15.0039 16.4877C12.7811 16.4877 10.9751 18.2425 10.9751 20.4023H9.58582C9.58582 17.5098 12.0071 15.1378 15.0039 15.1378C18.0007 15.1378 20.4219 17.5098 20.4219 20.4023Z"
      fill="#333333"
    />
    <path
      d="M15.0237 14.4822C13.2177 14.4822 11.7292 13.0552 11.7292 11.281C11.7292 9.50693 13.1979 8.09921 15.0237 8.09921C16.8496 8.09921 18.3182 9.52622 18.3182 11.3003C18.3182 13.0744 16.8298 14.4822 15.0237 14.4822ZM15.0237 9.44908C13.9719 9.44908 13.1185 10.2783 13.1185 11.3003C13.1185 12.3224 13.9719 13.1516 15.0237 13.1516C16.0756 13.1516 16.929 12.3224 16.929 11.3003C16.929 10.2783 16.0558 9.44908 15.0237 9.44908Z"
      fill="#333333"
    />
    <path
      d="M26.1177 27.8073C23.4186 27.8073 21.2355 25.6861 21.2355 23.0635C21.2355 20.4409 23.4186 18.3196 26.1177 18.3196C28.8168 18.3196 30.9999 20.4409 30.9999 23.0635C30.9999 25.6861 28.797 27.8073 26.1177 27.8073Z"
      fill="#333333"
    />
    <path
      d="M26.4553 20.3253V22.755H28.8567V23.3721H26.4553V25.8211H25.7806V23.3721H23.3792V22.755H25.7806V20.3253H26.4553Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "AddStudent",
};
</script>

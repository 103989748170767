import api, { searchGradeTrackApi } from '../api';
const appUrl = '/';
const gradeTracksUrl = 'gradetrack/all';
export default {
  retrieveGenders() {
    return api.get(`${appUrl}registration/genders/`);
  },
  retrieveNatonialitys() {
    return api.get(`${appUrl}registration/nationality/`);
  },
  retrieveNationalidentification() {
    return api.get(`${appUrl}registration/nationalidentification/`);
  },
  retrieveGrades() {
    return api.get(`${appUrl}institutions/gradeslabel/`);
  },
  retrieveAgreements() {
    return api.get(`${appUrl}institutions/agreementslabels/`);
  },
  retrieveGradeTracks() {
    return searchGradeTrackApi.post(`${gradeTracksUrl}/`, {
      search_size: 10000,
    });
  },
  retrieveRegions() {
    return api.get(`${appUrl}places/departments/`);
  },
  retrieveMunicipalities() {
    return api.get(`${appUrl}places/municipalities/`);
  },
  retrieveRoadType() {
    return api.get(`${appUrl}registration/vias/`);
  },
  retrievePerformanceCategories() {
    return api.get(`${appUrl}institutions/qualitymeasurecateglevellabel/`);
  },
  retrieveMonthlyPayments() {
    return api.get(`${appUrl}institutions/paymentcategorylabel/`);
  },
  retriveDocumentTypes() {
    return api.get(`${appUrl}registration/nationalidentification/`);
  },
  retrieveRelationshipTypes() {
    return api.get(`${appUrl}registration/relationship_types/`);
  },
  retrieveDisabilities() {
    return api.get(`${appUrl}registration/disabilities/`);
  },
  retrieveArmedConflictTypes() {
    return api.get(`${appUrl}registration/armedConflict_types/`);
  },
};

<template>
  <svg width="49" height="49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)" fill="#fff">
      <path
        d="m48.145 36.9.054-.014.047-.012.052-.018c.015-.006.03-.01.046-.017l.048-.022.046-.023.044-.026.045-.027c.016-.01.03-.023.046-.034.013-.01.026-.018.038-.029.023-.018.045-.038.066-.06l.012-.01.011-.012.059-.065.03-.041.032-.043.03-.048.023-.041c.01-.016.017-.033.024-.05l.021-.044.018-.05.017-.048.013-.051c.004-.017.01-.033.013-.05l.009-.06.006-.043c.003-.035.005-.07.005-.106V13.143c0-.587-.475-1.063-1.063-1.063H14.465l-.028.002a1.103 1.103 0 0 0-.312.055 1.08 1.08 0 0 0-.436.28c-.006.008-.013.014-.02.021l-.01.014a1.057 1.057 0 0 0-.038.047 1.155 1.155 0 0 0-.058.085c-.01.014-.018.027-.025.04-.01.016-.017.033-.025.049l-.022.045-.019.049-.018.048-.014.05-.013.05-.009.049-.008.055-.004.046-.003.06V19.696a1.063 1.063 0 1 0 2.125 0v-4.194l9.447 8.365c-.049.035-.096.073-.14.117l-9.308 9.307v-6.983a1.062 1.062 0 1 0-2.125 0v1.298h-5.49a1.062 1.062 0 1 0 0 2.126h5.49v6.125c0 .587.476 1.063 1.063 1.063h33.473c.035 0 .07-.002.105-.006.016-.001.032-.004.048-.007.018-.002.037-.004.055-.008zm-1.27-3.608-9.307-9.307-.019-.017 6.997-5.936a1.063 1.063 0 0 0-1.375-1.62L31.215 26.554l-13.948-12.35h29.608v19.087zm-20.539-7.804c.076-.076.138-.16.186-.25l3.974 3.518a1.06 1.06 0 0 0 1.392.015l4.05-3.436c.037.053.079.105.127.153l9.307 9.306H17.029l9.307-9.306z"
      />
      <path
        d="M1.063 24.182H19.01a1.062 1.062 0 1 0 0-2.125H1.063a1.063 1.063 0 1 0 0 2.125zM2.922 18.326h8.059a1.063 1.063 0 1 0 0-2.125H2.922a1.062 1.062 0 1 0 0 2.125zM8.855 32.093H1.063a1.062 1.062 0 1 0 0 2.126h7.792a1.063 1.063 0 1 0 0-2.126z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h49v49H0z" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Email",
};
</script>

import authenticationService from "./authentication.service";
import institutionsService from "./institutions.services";
import simulationService from "./simulation.services";
import studentRegistrationService from "./studentRegistration.services";
import optionsService from "./options.services";
import elasticSearchsService from "./elasticSearchs.services";
import geoLocatorService from "./geoLocator.service";
import userRegistrationService from "./userRegistration.services";
import feedbackService from "./feedback.service";
import contactService from "./contact.service";
import prechecksService from "./prechecks.service";

const services = {
  authenticationService,
  institutionsService,
  simulationService,
  studentRegistrationService,
  optionsService,
  elasticSearchsService,
  geoLocatorService,
  userRegistrationService,
  feedbackService,
  contactService,
  prechecksService,
};

export default services;

const CONFIG = {};

/* Set api host from environment. Set in .env. */
// eslint-disable-next-line no-nested-ternary
CONFIG.baseURL = process.env.VUE_APP_API_HOST
  || 'https://staging-api.consiliumbots.com/explorer-backend/r/chile/';
CONFIG.userUrl = process.env.VUE_APP_API_USER_HOST
|| 'https://staging-api.consiliumbots.com/explorer-backend/r/chile/';
CONFIG.geolocatorBaseURL = process.env.VUE_APP_API_GEOLOCATOR_HOST
|| 'https://staging-api.consiliumbots.com/connecticut/';

CONFIG.feedbackBaseUrl =
  process.env.VUE_APP_URL_API_STAGING ||
  "https://staging-api.consiliumbots.com/chile-explorer-backend/";

CONFIG.contactForm = process.env.VUE_APP_CONTACT_FORM;

CONFIG.elasticSearchURL = process.env.VUE_APP_ELASTIC_SEARCH_URL || process.env.VUE_APP_API_HOST;

CONFIG.contactGoogleFom = process.env.VUE_APP_MIME_CONTACT_FORM;

CONFIG.baseConsiliumApiURL = process.env.VUE_APP_CONSILIUM_API_URL;

CONFIG.language = ["en", "es"];

CONFIG.gMapKey = process.env.VUE_APP_GMAP_KEY;

CONFIG.captchaKey = process.env.VUE_APP_CAPTCHA_KEY;

CONFIG.ganalytics = process.env.VUE_APP_ANALYTICS_KEY;

CONFIG.defaultLocation = {
  lat: -33.447487,
  lng: -70.673676,
};

CONFIG.showFilters = process.env.VUE_APP_SHOW_FILTERS === "True";
CONFIG.simulationOn = process.env.VUE_APP_SIMULATION_ON === "True";
CONFIG.guestOption = process.env.VUE_APP_GUEST_OPTION === "True";
CONFIG.maintenance = process.env.VUE_APP_MAINTENANCE_ON === "True";

CONFIG.distanceRadius = [];
if (process.env.VUE_APP_DISTANCE_RADIUS) {
  process.env.VUE_APP_DISTANCE_RADIUS.split(",").forEach((elem) =>
    CONFIG.distanceRadius.push(parseInt(elem, 10))
  );
} else {
  CONFIG.distanceRadius = [1, 2, 5, 20];
}

CONFIG.hotjarKey = process.env.VUE_APP_HOTJAR_KEY;
CONFIG.hotjarOn = process.env.VUE_APP_HOTJAR_ON === "True";
CONFIG.environment = process.env.VUE_APP_ENVIRONMENT;
CONFIG.maxDaysToRefreshAllSchools = process.env.VUE_APP_MAX_DAYS_TO_REFRESH_ALL_SCHOOLS || 1;
CONFIG.mixPanelEnv = process.env.VUE_APP_MIXPANEL_ENV_CONFIG || 0;
CONFIG.sentryKeyDsn = process.env.VUE_APP_SENTRY_KEY_DSN;
CONFIG.primerShow = process.env.VUE_APP_PRIMER_SHOW === "True";
CONFIG.primerApp = process.env.VUE_APP_PRIMER_URL;
CONFIG.feedbackApp = process.env.VUE_APP_FEEDBACK_URL;
CONFIG.cardInfoUrl = process.env.VUE_APP_CARD_URL_API || 0;
CONFIG.awsKey = process.env.VUE_APP_API_KEY_AWS || 0;
CONFIG.apigatewayKey =
  process.env.VUE_APP_APIGATEWAY_ON === "True" ? process.env.VUE_APP_APIGATEWAY_KEY : undefined;
CONFIG.searchEnv = process.env.VUE_APP_SEARCH_ENVIRONMENT || 0;
CONFIG.version = "v2.4.0";
CONFIG.feedback = process.env.VUE_APP_FEEDBACK;
CONFIG.feedbackType = process.env.VUE_APP_FEEDBACK_TYPE;

export default CONFIG;

<template>
  <svg width="108" height="23" viewBox="0 0 108 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.91126 17.8401C5.2839 18.489 5.79904 19.0059 6.44571 19.3803C7.09237 19.7601 7.82671 19.9501 8.64874 19.9501C9.3228 19.9501 9.9585 19.8497 10.5558 19.6441C11.1477 19.4437 11.6628 19.1694 12.1067 18.8265C12.5451 18.4836 12.8575 18.1196 13.0384 17.7294V20.3667C12.5999 20.8573 11.9752 21.2423 11.1641 21.5113C10.3531 21.7856 9.5146 21.9227 8.65422 21.9227C7.43213 21.9227 6.32513 21.6485 5.33871 21.1052C4.35228 20.5619 3.56861 19.8077 2.99867 18.8476C2.42326 17.8876 2.13281 16.8011 2.13281 15.5932C2.13281 14.3642 2.41778 13.2723 2.99867 12.3229C3.5741 11.3735 4.35228 10.6245 5.33871 10.0812C6.32513 9.53789 7.43213 9.26361 8.65422 9.26361C9.5146 9.26361 10.3476 9.40075 11.1641 9.67503C11.9752 9.94931 12.5999 10.3291 13.0384 10.8196V13.4569C12.8575 13.0508 12.5451 12.6816 12.1067 12.3493C11.6683 12.017 11.1532 11.748 10.5558 11.5475C9.96398 11.3471 9.32829 11.2416 8.64874 11.2416C7.82671 11.2416 7.09237 11.4262 6.44571 11.7954C5.79904 12.1646 5.2839 12.6763 4.91126 13.3356C4.5386 13.9949 4.3468 14.7492 4.3468 15.5932C4.3468 16.4372 4.53313 17.1914 4.91126 17.8401Z"
      fill="#E5E5E5"
    />
    <path
      d="M15.9547 12.3257C16.5302 11.3763 17.3083 10.6273 18.2947 10.084C19.2811 9.5407 20.3882 9.26642 21.6103 9.26642C22.8543 9.26642 23.9612 9.5407 24.9422 10.084C25.9177 10.6273 26.6958 11.3763 27.2713 12.3257C27.8467 13.2751 28.1316 14.367 28.1316 15.5959C28.1316 16.8091 27.8467 17.8957 27.2713 18.8503C26.6958 19.8104 25.9177 20.5593 24.9422 21.1079C23.9668 21.6512 22.8543 21.9255 21.6103 21.9255C20.3882 21.9255 19.2811 21.6512 18.2947 21.1079C17.3083 20.5646 16.5246 19.8104 15.9547 18.8503C15.3793 17.8903 15.0889 16.8039 15.0889 15.5959C15.0889 14.367 15.3793 13.2751 15.9547 12.3257ZM17.8728 17.8376C18.2455 18.4865 18.7606 19.0033 19.4073 19.3778C20.0539 19.7575 20.7882 19.9475 21.6103 19.9475C22.4323 19.9475 23.1666 19.7575 23.8133 19.3778C24.46 18.9981 24.9751 18.4865 25.3478 17.8376C25.7204 17.1888 25.9067 16.4399 25.9067 15.5959C25.9067 14.752 25.7204 13.9977 25.3478 13.3384C24.9751 12.6791 24.46 12.1674 23.8133 11.7982C23.1666 11.429 22.4323 11.2444 21.6103 11.2444C20.7882 11.2444 20.0539 11.429 19.4073 11.7982C18.7606 12.1674 18.2455 12.6791 17.8728 13.3384C17.5001 13.9977 17.3083 14.752 17.3083 15.5959C17.3083 16.4399 17.4947 17.1888 17.8728 17.8376Z"
      fill="#E5E5E5"
    />
    <path
      d="M38.267 12.0092C37.8121 11.4976 37.1436 11.2444 36.2668 11.2444C35.6256 11.2444 35.0665 11.371 34.5789 11.6242C34.0966 11.8773 33.7185 12.236 33.4555 12.6949C33.1923 13.1538 33.0553 13.6812 33.0553 14.2773V21.6617H30.8633V9.53015H33.0553V11.429C33.4774 10.6905 34.0035 10.1473 34.6337 9.79387C35.2638 9.44048 35.9928 9.26642 36.8147 9.26642C38.1464 9.26642 39.2041 9.69365 39.9768 10.5429C40.755 11.3973 41.1386 12.5525 41.1386 14.0135V21.6617H38.9466V14.2773C38.952 13.2751 38.7274 12.5208 38.267 12.0092Z"
      fill="#E5E5E5"
    />
    <path
      d="M46.3828 18.9742C46.7115 19.2907 47.0896 19.5386 47.5225 19.7233C47.95 19.9079 48.4213 19.9975 48.9364 19.9975C49.5558 19.9975 50.0435 19.8604 50.3997 19.5914C50.7559 19.3224 50.9367 18.9426 50.9367 18.4731C50.9367 18.0512 50.7943 17.6925 50.5147 17.4024C50.2298 17.1122 49.868 16.8644 49.4296 16.6638C48.9913 16.4635 48.5254 16.2736 48.0322 16.0943C47.4843 15.8832 46.9307 15.6301 46.3772 15.3453C45.8183 15.0551 45.3524 14.6754 44.9798 14.2112C44.6071 13.747 44.4208 13.1616 44.4208 12.4548C44.4208 11.7321 44.6181 11.1361 45.0071 10.6614C45.4018 10.1867 45.9115 9.83327 46.5417 9.60646C47.1719 9.37965 47.824 9.26361 48.4981 9.26361C49.1721 9.26361 49.8079 9.3691 50.4052 9.58008C50.997 9.79106 51.5231 10.0706 51.9834 10.424C52.4383 10.7774 52.7946 11.1783 53.052 11.6372L51.2984 12.7185C50.9532 12.2596 50.5203 11.8851 49.9941 11.5844C49.468 11.2838 48.8872 11.1361 48.224 11.1361C47.7692 11.1361 47.3746 11.231 47.0458 11.4262C46.7171 11.6214 46.5526 11.9167 46.5526 12.3229C46.5526 12.6394 46.6787 12.9189 46.9361 13.1668C47.1938 13.4147 47.5225 13.631 47.9227 13.8262C48.3227 14.0213 48.7446 14.2059 49.1831 14.38C49.912 14.6596 50.5805 14.9655 51.1834 15.2872C51.7861 15.6142 52.2684 16.0046 52.6192 16.4635C52.9754 16.9223 53.1562 17.5079 53.1562 18.2305C53.1562 19.2854 52.7561 20.1662 51.967 20.8678C51.1724 21.5693 50.1257 21.9227 48.8269 21.9227C47.9884 21.9227 47.2266 21.7751 46.5526 21.4849C45.8785 21.1948 45.3086 20.8204 44.8428 20.3667C44.3769 19.9079 44.0263 19.4437 43.7852 18.9689L45.5113 17.9404C45.7635 18.3149 46.0539 18.6577 46.3828 18.9742Z"
      fill="#E5E5E5"
    />
    <path
      d="M56.2028 3.48989C56.5043 3.21034 56.8549 3.06793 57.2551 3.06793C57.677 3.06793 58.0333 3.21034 58.3237 3.48989C58.6141 3.76944 58.762 4.11229 58.762 4.51844C58.762 4.90348 58.6141 5.24632 58.3237 5.53115C58.0333 5.82126 57.677 5.96894 57.2551 5.96894C56.8549 5.96894 56.4987 5.82126 56.2028 5.53115C55.9014 5.24105 55.748 4.90348 55.748 4.51844C55.748 4.11229 55.9014 3.76944 56.2028 3.48989ZM58.3511 9.52928V21.6608H56.159V9.52928H58.3511Z"
      fill="#E5E5E5"
    />
    <path d="M64.7916 1.09119V21.6622H62.5996V1.09119H64.7916Z" fill="#E5E5E5" />
    <path
      d="M69.0788 3.48989C69.3803 3.21034 69.7311 3.06793 70.1311 3.06793C70.553 3.06793 70.9092 3.21034 71.1997 3.48989C71.4902 3.76944 71.6382 4.11229 71.6382 4.51844C71.6382 4.90348 71.4902 5.24632 71.1997 5.53115C70.9092 5.81598 70.553 5.96894 70.1311 5.96894C69.7311 5.96894 69.3747 5.82126 69.0788 5.53115C68.7774 5.24105 68.624 4.90348 68.624 4.51844C68.624 4.11229 68.7774 3.76944 69.0788 3.48989ZM71.227 9.52928V21.6608H69.035V9.52928H71.227Z"
      fill="#E5E5E5"
    />
    <path
      d="M78.3589 19.1551C78.8137 19.6826 79.4823 19.9462 80.3592 19.9462C81.0003 19.9462 81.5594 19.8198 82.047 19.5665C82.5293 19.3134 82.9074 18.9547 83.1704 18.4958C83.4335 18.0368 83.5706 17.5094 83.5706 16.9134V9.52887H85.7626V21.6606H83.5706V19.7616C83.1485 20.4843 82.628 21.0223 82.0086 21.381C81.3894 21.7397 80.6551 21.9243 79.8166 21.9243C78.5014 21.9243 77.4492 21.4917 76.6655 20.6321C75.8819 19.7723 75.4873 18.6171 75.4873 17.1772V9.52887H77.6793V16.9134C77.6739 17.8786 77.9041 18.6276 78.3589 19.1551Z"
      fill="#E5E5E5"
    />
    <path
      d="M106.852 21.6642H104.66V14.2798C104.66 13.2407 104.463 12.4758 104.068 11.9853C103.674 11.4948 103.071 11.2469 102.249 11.2469C101.646 11.2469 101.126 11.3629 100.687 11.6003C100.249 11.8376 99.909 12.1857 99.6734 12.6446C99.4377 13.1035 99.3172 13.6468 99.3172 14.2798V21.6642H97.1252V14.2798C97.1252 13.2407 96.9279 12.4758 96.5333 11.9853C96.1388 11.4948 95.5359 11.2469 94.7139 11.2469C94.1111 11.2469 93.5904 11.3629 93.152 11.6003C92.7137 11.8376 92.3738 12.1857 92.1382 12.6446C91.9025 13.1035 91.782 13.6468 91.782 14.2798V21.6642H89.5898V9.53262H91.782V11.2996C92.1655 10.6139 92.6478 10.1023 93.2178 9.76997C93.7933 9.43767 94.4728 9.26888 95.2564 9.26888C96.1332 9.26888 96.8841 9.46932 97.5143 9.85963C98.1445 10.25 98.6048 10.8143 98.9007 11.5317C99.3391 10.7774 99.8871 10.2078 100.545 9.83327C101.202 9.45349 101.953 9.26361 102.792 9.26361C103.652 9.26361 104.381 9.44822 104.984 9.81744C105.586 10.1867 106.047 10.7299 106.37 11.442C106.688 12.1541 106.852 13.0086 106.852 14.016V21.6642Z"
      fill="#E5E5E5"
    />
  </svg>
</template>

<script>
export default {
  name: "ConsiliumWhite",
};
</script>

<template>
  <svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="11" fill="#F90E1F" />
    <path
      d="M8.71 13.945 7.45 12.684l1.981-1.982L7.45 8.72l1.27-1.271 1.983 1.981 1.981-1.981 1.262 1.261-1.982 1.982 1.992 1.992-1.272 1.27-1.991-1.99-1.982 1.981z"
      fill="#fff"
    />
  </svg>
</template>
<script>
export default {
  name: "CloseMapCard",
};
</script>

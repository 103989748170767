<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.75 0.9375C10.8818 0.9375 10.0858 1.21262 9.38416 1.75525C8.7115 2.27547 8.26366 2.93806 8 3.41987C7.73634 2.93803 7.2885 2.27547 6.61584 1.75525C5.91422 1.21262 5.11822 0.9375 4.25 0.9375C1.82713 0.9375 0 2.91928 0 5.54731C0 8.3865 2.27947 10.329 5.73028 13.2697C6.31628 13.7692 6.9805 14.3352 7.67088 14.9389C7.76188 15.0186 7.87875 15.0625 8 15.0625C8.12125 15.0625 8.23813 15.0186 8.32913 14.939C9.01956 14.3352 9.68375 13.7691 10.2701 13.2694C13.7205 10.329 16 8.3865 16 5.54731C16 2.91928 14.1729 0.9375 11.75 0.9375Z"
      fill="#F90E1F"
    />
  </svg>
</template>

<script>
export default {
  name: "HeartFilled",
};
</script>

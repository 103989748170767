<template>
  <svg width="52" height="35" viewBox="0 0 52 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M6.38394 26.9259C7.08579 26.9114 7.65478 27.0934 8.09091 27.4721C8.52364 27.8481 8.78907 28.3919 8.8872 29.1036C8.99358 29.875 8.80235 30.5321 8.3135 31.0749C7.82424 31.6146 7.11614 31.9484 6.1892 32.0762L5.56128 32.1628C4.95428 32.2465 4.4057 32.2125 3.91554 32.0607C3.42496 31.9059 3.02966 31.6466 2.72963 31.2827C2.42661 30.9192 2.23902 30.4759 2.16687 29.9526C2.07121 29.2589 2.18887 28.6759 2.51986 28.2037C2.84786 27.7319 3.34949 27.4144 4.02474 27.2512L4.14411 28.1169C3.63414 28.2634 3.27985 28.4783 3.08126 28.7616C2.88225 29.0419 2.81367 29.4064 2.87552 29.8549C2.95139 30.4051 3.2143 30.8091 3.66425 31.067C4.11379 31.3219 4.71382 31.3976 5.46434 31.2941L6.09674 31.2069C6.8054 31.1092 7.34863 30.8834 7.72643 30.5297C8.10422 30.1759 8.25642 29.7329 8.18303 29.2007C8.11706 28.7223 7.9589 28.3708 7.70857 28.1464C7.45483 27.9193 7.05308 27.801 6.50331 27.7915L6.38394 26.9259Z"
        fill="#454444"
      />
      <path
        d="M4.40143 23.3736C4.03076 22.9173 3.90595 22.398 4.02702 21.8158C4.23782 20.8021 4.91602 20.4097 6.06162 20.6387L9.23132 21.2978L9.06078 22.1179L5.88664 21.4579C5.54024 21.3889 5.268 21.4156 5.06992 21.5378C4.87246 21.657 4.73962 21.8807 4.6714 22.2088C4.61609 22.4747 4.63847 22.723 4.73854 22.9534C4.83861 23.1839 4.99223 23.3793 5.1994 23.5395L8.61736 24.2503L8.44682 25.0704L1.6375 23.6544L1.80804 22.8343L4.40143 23.3736Z"
        fill="#454444"
      />
      <path
        d="M11.1372 16.7824C11.0292 16.7836 10.8573 16.7438 10.6215 16.6628C10.8152 17.1897 10.794 17.6962 10.558 18.1823C10.347 18.6167 10.0514 18.9144 9.67117 19.0753C9.28957 19.2323 8.91007 19.2191 8.53266 19.0358C8.0738 18.8129 7.80251 18.4664 7.71881 17.9962C7.63371 17.522 7.7448 16.9685 8.05208 16.3359L8.40815 15.6028L8.06197 15.4347C7.7986 15.3067 7.55128 15.2839 7.32003 15.3662C7.08606 15.4472 6.89456 15.6411 6.74554 15.948C6.61498 16.2168 6.5734 16.4751 6.6208 16.7229C6.6682 16.9708 6.78829 17.1415 6.98106 17.2352L6.61312 17.9927C6.39319 17.8859 6.21899 17.7056 6.09053 17.452C5.96067 17.1942 5.90062 16.9033 5.9104 16.5792C5.92149 16.2524 6.00485 15.9288 6.16046 15.6084C6.40708 15.1007 6.72789 14.7649 7.12291 14.601C7.51521 14.4359 7.93664 14.456 8.3872 14.6614L10.4154 15.6466C10.82 15.8431 11.1668 15.9478 11.4558 15.9606L11.521 15.9923L11.1372 16.7824ZM9.95568 17.7538C10.0704 17.5176 10.1181 17.2639 10.0988 16.9928C10.0795 16.7217 9.99516 16.4911 9.84583 16.3011L8.94168 15.862L8.65484 16.4525C8.20645 17.3757 8.25242 17.9685 8.79273 18.2309C9.02895 18.3456 9.25183 18.3566 9.46136 18.2637C9.67089 18.1709 9.83567 18.0009 9.95568 17.7538Z"
        fill="#454444"
      />
      <path
        d="M10.6443 10.6407C10.5468 10.7245 10.4507 10.8231 10.3559 10.9367C10.0039 11.3584 9.94461 11.7945 10.1781 12.245L12.8476 14.4732L12.3109 15.1163L8.54985 11.977L9.0721 11.3513L9.5153 11.7035C9.35531 11.2122 9.43682 10.773 9.75985 10.386C9.8643 10.2609 9.95982 10.1794 10.0464 10.1416L10.6443 10.6407Z"
        fill="#454444"
      />
      <path
        d="M12.1963 6.73212L12.9012 7.68624L13.6368 7.14278L14.0216 7.66354L13.2859 8.20701L15.0912 10.6506C15.2078 10.8084 15.3284 10.9031 15.4532 10.9348C15.5761 10.9641 15.7165 10.9204 15.8743 10.8038C15.952 10.7465 16.0481 10.653 16.1625 10.5234L16.5661 11.0696C16.4247 11.2491 16.2703 11.4008 16.1027 11.5245C15.8017 11.7469 15.5074 11.8236 15.22 11.7545C14.9325 11.6854 14.665 11.4833 14.4175 11.1483L12.6122 8.70473L11.8948 9.23474L11.5101 8.71398L12.2275 8.18397L11.5226 7.22984L12.1963 6.73212Z"
        fill="#454444"
      />
      <path
        d="M19.8232 9.41077C19.2109 9.6679 18.6286 9.67673 18.0762 9.43725C17.5227 9.195 17.1045 8.73712 16.8217 8.06361L16.7621 7.92168C16.5739 7.4736 16.491 7.03858 16.5135 6.61662C16.5377 6.1907 16.6613 5.81467 16.8845 5.4885C17.1092 5.15839 17.4011 4.91794 17.7601 4.76717C18.3474 4.52056 18.885 4.52231 19.3731 4.77241C19.8612 5.02251 20.2566 5.50797 20.5593 6.22879L20.6943 6.55023L17.6343 7.8353C17.8324 8.27591 18.1132 8.58197 18.4766 8.75348C18.8416 8.92103 19.2161 8.92416 19.6002 8.76287C19.873 8.64833 20.0806 8.49566 20.2231 8.30486C20.3656 8.11406 20.4692 7.89702 20.534 7.65373L21.16 7.82299C21.0258 8.5636 20.5802 9.09286 19.8232 9.41077ZM18.0266 5.40171C17.7149 5.53262 17.5012 5.75659 17.3855 6.07363C17.2687 6.38788 17.2711 6.76008 17.3929 7.19023L19.6555 6.24002L19.631 6.18158C19.4439 5.79852 19.2107 5.53957 18.9315 5.40475C18.6511 5.26715 18.3495 5.26614 18.0266 5.40171Z"
        fill="#454444"
      />
      <path
        d="M24.4489 3.87942C24.3204 3.87795 24.1831 3.88853 24.0369 3.91118C23.494 3.99529 23.1614 4.28354 23.0392 4.77592L23.5716 8.21223L22.7438 8.34047L21.9938 3.49922L22.7991 3.37444L22.8992 3.93166C23.1037 3.45708 23.4549 3.18121 23.9531 3.10403C24.1142 3.07907 24.2397 3.08101 24.3297 3.10983L24.4489 3.87942Z"
        fill="#454444"
      />
      <path
        d="M31.666 5.66203C30.998 5.26764 30.5378 4.8772 30.2856 4.4907C30.037 4.10204 29.9702 3.68562 30.0852 3.24145C30.2154 2.73883 30.523 2.37571 31.0081 2.1521C31.4969 1.92632 32.0627 1.89667 32.7056 2.06315C33.1439 2.17666 33.5121 2.36243 33.8101 2.62046C34.111 2.87924 34.3164 3.18188 34.4262 3.52837C34.539 3.87562 34.5496 4.22604 34.458 4.57963L33.6121 4.36055C33.7119 3.97482 33.6675 3.64059 33.4788 3.35786C33.2909 3.07221 32.9733 2.87149 32.5262 2.75571C32.1113 2.64826 31.7631 2.65631 31.4816 2.77987C31.2039 2.90126 31.023 3.12414 30.939 3.44851C30.8716 3.70858 30.9241 3.95758 31.0963 4.19552C31.2723 4.43128 31.6054 4.69217 32.0957 4.97817C32.589 5.26493 32.9612 5.53749 33.2123 5.79585C33.4671 6.05205 33.6351 6.3185 33.7162 6.5952C33.8003 6.87265 33.8003 7.17356 33.7163 7.49793C33.5824 8.01516 33.2733 8.3779 32.7891 8.58614C32.3056 8.79147 31.7263 8.80672 31.0513 8.63192C30.613 8.51841 30.2254 8.32918 29.8887 8.06424C29.5527 7.79638 29.3188 7.48947 29.187 7.14352C29.0581 6.79833 29.0417 6.44018 29.1378 6.06906L29.9838 6.28813C29.8839 6.67386 29.9466 7.01594 30.1718 7.31435C30.4006 7.6106 30.7532 7.82039 31.2295 7.94374C31.6737 8.05876 32.0376 8.05634 32.3212 7.93646C32.6048 7.81657 32.7871 7.6003 32.8681 7.28762C32.9491 6.97495 32.9019 6.70549 32.7266 6.47924C32.5521 6.25008 32.1986 5.97768 31.666 5.66203Z"
        fill="#454444"
      />
      <path
        d="M35.9658 9.98983C36.2187 10.149 36.4879 10.2115 36.7734 10.1772C37.0589 10.1429 37.2843 10.0172 37.4494 9.80019L38.12 10.2223C37.9577 10.4518 37.7336 10.6264 37.4478 10.7462C37.1621 10.8659 36.8525 10.91 36.5192 10.8785C36.1885 10.8487 35.8826 10.7453 35.6016 10.5684C35.037 10.2131 34.7056 9.74232 34.6073 9.15615C34.5132 8.56904 34.672 7.9485 35.0836 7.29452L35.1584 7.17574C35.4124 6.77211 35.7124 6.45983 36.0584 6.23888C36.4043 6.01793 36.7699 5.90743 37.1552 5.90737C37.5429 5.90893 37.9259 6.0287 38.304 6.26668C38.7689 6.55933 39.067 6.94136 39.1983 7.41276C39.3321 7.88577 39.2694 8.35096 39.01 8.80832L38.3394 8.38623C38.4878 8.10513 38.5239 7.82112 38.4477 7.53419C38.3757 7.24632 38.2056 7.01796 37.9373 6.84912C37.5771 6.6224 37.2154 6.57661 36.8521 6.71176C36.493 6.84595 36.1591 7.15829 35.8504 7.64877L35.766 7.78289C35.4653 8.26059 35.3321 8.69036 35.3664 9.07218C35.4007 9.45401 35.6005 9.75989 35.9658 9.98983Z"
        fill="#454444"
      />
      <path
        d="M42.0139 9.98471C42.6016 9.96841 43.0895 10.1855 43.4777 10.6359C44.1535 11.4202 44.052 12.197 43.173 12.9665L40.7207 15.0798L40.1739 14.4453L42.6296 12.329C42.8952 12.0962 43.0398 11.864 43.0634 11.6325C43.089 11.4032 42.9924 11.1617 42.7737 10.9079C42.5963 10.7021 42.3858 10.5688 42.142 10.5079C41.8983 10.447 41.6498 10.4499 41.3966 10.5167L38.7522 12.7956L38.2054 12.1611L43.4735 7.62112L44.0203 8.25563L42.0139 9.98471Z"
        fill="#454444"
      />
      <path
        d="M43.9858 15.4232C44.4233 15.226 44.8552 15.1339 45.2816 15.1469C45.7092 15.1627 46.0868 15.2805 46.4144 15.5004C46.7432 15.7229 46.9963 16.031 47.1737 16.4245C47.4479 17.0326 47.4588 17.6187 47.2065 18.1827C46.9555 18.7495 46.4805 19.1904 45.7816 19.5055L45.7279 19.5297C45.2932 19.7258 44.8646 19.818 44.4422 19.8065C44.0238 19.7964 43.6482 19.6794 43.3154 19.4553C42.9839 19.2339 42.7275 18.9224 42.5464 18.5206C42.2735 17.9153 42.2625 17.3292 42.5136 16.7624C42.7658 16.1984 43.2387 15.76 43.9321 15.4474L43.9858 15.4232ZM44.2411 16.2319C43.7458 16.4552 43.3997 16.7486 43.2027 17.1123C43.007 17.4786 42.996 17.8544 43.1696 18.2397C43.3446 18.6276 43.6349 18.8676 44.0405 18.9595C44.4489 19.0502 44.9269 18.9721 45.4745 18.7252C45.9643 18.5044 46.3078 18.2088 46.505 17.8384C46.7062 17.4696 46.72 17.0926 46.5463 16.7073C46.3764 16.3303 46.0893 16.0939 45.6852 15.998C45.281 15.9021 44.7997 15.98 44.2411 16.2319Z"
        fill="#454444"
      />
      <path
        d="M46.3965 21.737C46.8746 21.6945 47.3129 21.7494 47.7113 21.9018C48.11 22.0572 48.428 22.2926 48.6652 22.6078C48.9026 22.9261 49.0405 23.3002 49.0788 23.7302C49.138 24.3947 48.9557 24.9519 48.5321 25.4017C48.1088 25.8546 47.5152 26.1151 46.7515 26.1831L46.6929 26.1883C46.2178 26.2306 45.7827 26.1769 45.3875 26.0272C44.9956 25.8803 44.6793 25.6463 44.4386 25.3253C44.1981 25.0073 44.0584 24.6288 44.0193 24.1898C43.9604 23.5283 44.1426 22.9712 44.5659 22.5183C44.9896 22.0684 45.5802 21.8097 46.3379 21.7423L46.3965 21.737ZM46.372 22.5847C45.8308 22.6329 45.4075 22.7964 45.1019 23.0751C44.7967 23.3569 44.6628 23.7082 44.7003 24.1292C44.7381 24.5531 44.9334 24.8752 45.2864 25.0953C45.6423 25.3151 46.1195 25.3984 46.7178 25.3451C47.253 25.2974 47.6746 25.1311 47.9826 24.8461C48.2939 24.5638 48.4307 24.2122 48.3933 23.7912C48.3566 23.3793 48.1631 23.0617 47.8129 22.8383C47.4627 22.6149 46.9824 22.5304 46.372 22.5847Z"
        fill="#454444"
      />
      <path
        d="M43.9292 28.9538L44.059 28.1263L50.9295 29.2043L50.7997 30.0318L43.9292 28.9538Z"
        fill="#454444"
      />
      <path
        d="M18.6601 20.72C18.5301 20.81 18.0201 20.89 18.3201 21.03C18.7301 21.22 19.0901 21.87 19.3501 22.44C19.5201 22.47 19.6601 22.52 19.7801 22.57C19.4501 21.8 18.7601 20.65 18.6601 20.72Z"
        fill="#424241"
      />
      <path
        d="M23.6902 24.39C23.6002 23.65 23.3202 23.04 22.8202 22.74C22.2902 22.42 21.8302 22.35 21.4402 22.4C20.7502 22.48 20.2702 22.94 20.2002 23.01L20.1102 23.1L20.0002 23.03C19.9202 22.98 19.3502 22.65 18.6502 22.73C18.2602 22.78 17.8302 22.95 17.3902 23.38C16.9802 23.79 16.8402 24.45 16.9302 25.2C17.0402 26.1 17.4702 27.11 18.0402 27.86C18.6102 28.62 19.3002 29.09 19.8602 29.01C19.9202 29 19.9802 28.99 20.0302 28.97C20.0702 28.94 20.1902 28.89 20.3402 28.87C20.4002 28.86 20.4702 28.86 20.5502 28.88C20.5502 28.88 20.5602 28.88 20.5702 28.88H20.5802H20.5902C20.6302 28.89 20.7102 28.9 20.8002 28.89C20.8802 28.88 20.9602 28.86 20.9902 28.84H21.0002H21.0102C21.0202 28.84 21.0302 28.83 21.0502 28.82C21.1202 28.78 21.2002 28.76 21.2702 28.75C21.4202 28.73 21.5402 28.77 21.5902 28.78C21.6502 28.78 21.7102 28.78 21.7602 28.78C22.3202 28.72 22.8802 28.1 23.2502 27.23C23.6102 26.38 23.8002 25.3 23.6902 24.39ZM18.4002 23.83C18.1202 23.91 17.9702 24.08 17.8702 24.26C17.7702 24.44 17.7502 24.63 17.7402 24.7C17.7402 24.72 17.7402 24.73 17.7402 24.73C17.7402 24.81 17.6802 24.87 17.6002 24.88C17.5902 24.88 17.5802 24.88 17.5802 24.88C17.4902 24.88 17.4202 24.8 17.4302 24.72C17.4302 24.71 17.4402 24.48 17.5502 24.22C17.6602 23.96 17.8902 23.65 18.3202 23.53C18.4002 23.51 18.4902 23.55 18.5202 23.64C18.5302 23.72 18.4802 23.81 18.4002 23.83Z"
        fill="#424241"
      />
      <path
        d="M21.6902 21.32C21.7502 20.48 22.3202 20.53 22.3202 20.53C22.3202 20.53 19.5702 19.45 19.9602 22.23C19.9602 22.23 20.3702 22.31 20.7902 22.24C20.9202 22.22 21.0602 22.18 21.1802 22.12C21.4502 21.99 21.6602 21.74 21.6902 21.32Z"
        fill="#424241"
      />
      <path
        d="M39.7102 19.66L39.1402 19.57V18.83V18.26L38.5802 18.2C36.9502 18.03 35.0702 17.3 33.2202 16.58C31.3702 15.87 29.5602 15.17 27.8902 15.16C27.3402 15.16 26.8002 15.24 26.2902 15.44C25.8302 15.29 25.3502 15.22 24.8602 15.22C23.1802 15.23 21.3602 15.91 19.4802 16.6C17.6102 17.3 15.7102 18.02 14.0402 18.19L13.4802 18.25V18.82V19.56L12.9102 19.65V34.71L23.8702 32.95C23.9702 33.54 24.4402 33.98 25.0202 33.98H27.6402C28.2102 33.98 28.6902 33.54 28.7902 32.95L39.7002 34.7V19.66H39.7102ZM14.7502 19.38C16.4802 19.1 18.2502 18.42 19.9302 17.79C21.7902 17.09 23.5502 16.48 24.8702 16.49C25.1402 16.49 25.3902 16.51 25.6302 16.56V30.48C25.5202 30.47 25.4002 30.47 25.2902 30.47C23.6502 30.48 21.7702 31.05 19.8102 31.65C18.1102 32.17 16.3502 32.72 14.7602 32.98V19.38H14.7502ZM30.4102 24.76L31.6002 25.53V17.32C31.9802 17.46 32.3702 17.61 32.7602 17.76C34.4202 18.4 36.1602 19.1 37.8702 19.38V32.98C36.2702 32.72 34.5202 32.18 32.8202 31.65C30.8602 31.05 28.9802 30.48 27.3402 30.47C27.2202 30.47 27.1102 30.47 27.0002 30.48V16.53C27.2702 16.46 27.5602 16.42 27.8802 16.42C28.1202 16.42 28.3802 16.44 28.6602 16.48V26.45L30.4102 24.76Z"
        fill="#424241"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="51.16" height="34.47" fill="white" transform="translate(0.810059 0.25)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "CharterSchool",
};
</script>

<template>
  <svg viewBox="0 0 49 46" xmlns="http://www.w3.org/2000/svg">
    <g style="mix-blend-mode: multiply">
      <path
        d="M39 23C39 30.1797 33.1797 36 26 36C18.8203 36 13 30.1797 13 23C13 15.8203 18.8203 10 26 10C33.1797 10 39 15.8203 39 23Z"
        fill="white"
        stroke="#BDBDBD"
        stroke-width="20"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "NoPriorityUnexplored",
};
</script>

<style scoped></style>

<template>
  <div
    v-ripple
    class="btn"
    :disabled="disabled"
    @click.stop.prevent="$emit('click')"
    v-on="on"
    v-bind="bind"
  >
    <span v-if="loading">
      <v-progress-circular class="mr-2" indeterminate size="15"></v-progress-circular>
      {{ $t("base.base_button.loading") }}
    </span>
    <slot v-else />
  </div>
  <!--<button
    class="btn"
    :class="customClass"
    :disabled="disabled"
    @click.stop.prevent="$emit('click')"
    v-on="on"
    v-bind="bind"
  >
    <span v-if="more && !selected" class="more-text">MORE</span>
    <v-icon v-else-if="selected" small color="white">mdi-close</v-icon>
    <slot v-else/>
  </button>-->
</template>
<script>
export default {
  name: "BaseButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    inverse: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    accent: {
      type: Boolean,
      default: false,
    },
    monospace: {
      type: Boolean,
      default: true,
    },
    text: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    noTextTransform: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    favorite: {
      type: Boolean,
      default: false,
    },
    more: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    bind: {
      type: Object,
      default: () => {},
    },
    on: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    customClass() {
      return [
        this.inverse ? "btn--inverse" : "",
        this.block ? "btn--block" : "",
        this.small ? "btn--small" : "",
        this.large ? "btn--large" : "",
        this.text ? "btn--text" : "",
        this.primary ? "btn--primary-01" : "",
        this.secondary ? "btn--secondary-01" : "",
        this.borderless ? "btn--borderless" : "",
        this.expanded ? "btn--expanded" : "",
        this.accent ? "btn--accent" : "",
        this.monospace ? "btn--monospace" : "",
        this.noTextTransform ? "btn--no-text-transform" : "",
        this.form ? "btn--form" : "",
        this.outlined ? "btn--outlined" : "",
        this.flat ? "btn--flat" : "",
        this.favorite ? "btn--favorite" : "",
        this.more ? "more-button ma-0 pa-0" : "",
        this.selected ? "more-button--selected" : "",
        this.color ? `btn--${this.color}` : "",
      ];
    },
  },
};
</script>

import { feedback } from "../api";

const recommendations = "/reports/recommendations_v2/";
const applications = "/reports/risk/simulate";
const results = '/reports/results/application';

export default {
  recommendations() {
    return feedback.get(`${recommendations}`);
  },
  // risk(uuid) {
  //   return feedback.get("/reports/risk/risk", {
  //     params: {
  //       applicant_uuid: uuid,
  //       year: "2022",
  //     },
  //   });
  // },
  applications(uuid, round) {
    return feedback.get(`${applications}/`, {
      params: {
        applicant_uuid: uuid,
        year: "2022",
        round,
      },
    });
  },

  results(uuid) {
    return feedback.get(`${results}/`, {
      params: {
        applicant_uuid: uuid,
        year: 2022,
        admission_system_label_id: 1,
      },
    });
  },
  // results() {
  //   return feedback.get(`${results}/`);
  // },
};

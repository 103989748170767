<template>
  <section class="grade-container pb-4">
    <h6
      v-if="mapFilter"
      class="grade-container__title"
      v-t="'user_register.register_grade.guest_title'"
    />
    <p
      v-if="!gradesOfInterest && !isInProfile"
      class="grade-container__subtitle"
      v-t="'shared.grade.subtitle'"
    />
    <p
      v-if="userRegister && !isInProfile && gradesOfInterest"
      class="grade-container__subtitle"
      v-t="'shared.grade.subtitle-grades-interest'"
    />
    <p v-if="isShowGrades" class="grade-container__title" v-t="'shared.grade.stage_title'" />
    <div
      v-if="isShowGrades"
      class="grade__checkbox pb-8"
      :style="selectedStage.index === -1 ? '' : 'border-bottom: 1px solid #2b5bff;'"
    >
      <template v-for="(label, index) in selectStageLabels()">
        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <label
          @click="setStage(label, index)"
          class="grade__checkbox__label mr-1"
          :class="{
            grade__checkbox__label__selected: selectedStage.name === label,
            'grade-desktop': !isMobile,
          }"
        >
          {{ label }}
        </label>
      </template>
    </div>
    <button
      v-if="!isShowGrades"
      @click="isShowGrades = !isShowGrades"
      class="confirm-applicant__student__btn"
    >
      {{ getTextButton }}
      <span class="text-red">*</span>
    </button>

    <p
      v-if="showTitle && selectedStage.index !== -1 && !noCurrentSchool"
      class="grade-container__title mt-5"
    >
      {{ getTitle }}
    </p>
    <template>
      <div v-if="selectedStage.name === 'Parvularia'" class="grade__checkbox mt-3">
        <!-- eslint-disable-next-line vue/no-template-key -->
        <template v-for="gradeName in preschoolLabels">
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <label
            class="grade__checkbox__label mr-1"
            :class="{
              'grade__checkbox__label__grade-selected': selectedGradesNames.includes(gradeName),
            }"
            @click="selectGrades(gradeName)"
          >
            {{ gradeName }}
          </label>
        </template>
      </div>
    </template>
    <template>
      <div v-if="selectedStage.name === 'Básica'" class="grade__checkbox mt-5">
        <!-- eslint-disable-next-line vue/no-template-key -->
        <template v-for="gradeName in elementarySchoolLabels">
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <label
            class="grade__checkbox__label mr-1"
            :class="{
              'grade__checkbox__label__grade-selected': selectedGradesNames.includes(gradeName),
            }"
            @click="selectGrades(gradeName)"
          >
            {{ gradeName }}
          </label>
        </template>
      </div>
    </template>
    <template>
      <div v-if="selectedStage.name === 'Media'" class="grade__checkbox mt-5">
        <!-- eslint-disable-next-line vue/no-template-key -->
        <template v-for="gradeName in highSchoolLabels">
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <label
            class="grade__checkbox__label mr-1"
            :class="{
              'grade__checkbox__label__grade-selected': selectedGradesNames.includes(gradeName),
            }"
            @click="selectGrades(gradeName)"
          >
            {{ gradeName }}
          </label>
        </template>
      </div>
    </template>
    <template>
      <div v-if="selectedStage.name === 'Adultos'" class="grade__checkbox mt-5">
        <!-- eslint-disable-next-line vue/no-template-key -->
        <template v-for="gradeName in adultSchoolLabels">
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <label
            class="grade__checkbox__label mr-1"
            :class="{
              'grade__checkbox__label__grade-selected': selectedGradesNames.includes(gradeName),
            }"
            @click="selectGrades(gradeName)"
          >
            {{ gradeName }}
          </label>
        </template>
      </div>
    </template>
    <template>
      <div v-if="selectedStage.name === 'Especial'" class="grade__checkbox mt-5">
        <!-- eslint-disable-next-line vue/no-template-key -->
        <template v-for="gradeName in specialitySchools">
          <!-- eslint-disable-next-line vue/require-v-for-key -->
          <label
            class="grade__checkbox__label mr-1"
            :class="{
              'grade__checkbox__label__grade-selected': selectedGradesNames.includes(gradeName),
            }"
            @click="selectGrades(gradeName)"
          >
            {{ gradeName }}
          </label>
        </template>
      </div>
    </template>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SelectGrade",
  data() {
    return {
      gradeLabels: [],
      isSelected: false,
      selectedGrades: [],
      selectedStage: {
        index: -1,
        name: "",
      },
      selectedGradesNames: [],
      isShowGrades: false,
      noCurrentSchool: false,
    };
  },
  props: {
    userRegister: {
      type: Boolean,
      default: false,
    },
    oneGrade: {
      type: Boolean,
      default: false,
    },
    userRegisterInDashboard: {
      type: Boolean,
      default: false,
    },
    confirmStudent: {
      type: Boolean,
      default: false,
    },
    mapFilter: {
      type: Boolean,
      default: false,
    },
    editCurrentStudent: {
      type: Boolean,
      default: false,
    },
    gradesOfStudent: {
      type: Number,
      default: null,
    },
    gradesGuest: {
      type: Array,
      default: () => [],
    },
    isInProfile: {
      type: Boolean,
      default: false,
    },
    gradesOfInterest: {
      type: Boolean,
      default: false,
    },
    cleanGrades: {
      type: Boolean,
      default: false,
    },
    student: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.formatGrades();
    this.setIsShowGrades();
  },
  mounted() {
    if (this.userRegister) {
      this.setInRegisterFlow(true);
    } else {
      this.setInRegisterFlow(false);
    }
    this.SetPreFormData();
  },
  computed: {
    ...mapGetters({
      grades: "options/grades",
      stages: "options/stages",
      stageLabels: "options/stageLabels",
      preschoolLabels: "options/preschoolLabels",
      elementarySchoolLabels: "options/elementarySchoolLabels",
      highSchoolLabels: "options/highSchoolLabels",
      adultSchoolLabels: "options/adultSchoolLabels",
      specialitySchools: "options/specialitySchools",
      isGuest: "authentication/isGuest",
      currentStudent: "authentication/currentStudent",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getTitle() {
      let title;

      if (this.userRegister && this.userRegisterInDashboard) {
        title = !this.gradesOfInterest
          ? this.$t("shared.grade.current_grade")
          : this.$t("shared.grade.interest_grade");
      } else {
        title = !this.gradesOfInterest
          ? this.$t("shared.grade.edit_current_grade")
          : this.$t("shared.grade.edit_interest_grade");
      }
      return title;
    },
    getTextButton() {
      const text = !this.gradesOfInterest
        ? this.$t("shared.grade.current_grade")
        : this.$t("shared.grade.interest_grade");

      return text;
    },
    showTitle() {
      const isShow = this.userRegisterInDashboard || this.gradesOfStudent !== null;

      return isShow;
    },
  },
  methods: {
    ...mapActions({
      setFastRegisterGrade: "userRegister/setFastRegisterLocation",
      setInRegisterFlow: "userRegister/setInRegisterFlow"
    }),
    selectStageLabels() {
      if (this.gradesOfInterest) {
        return this.stageLabels.slice(0, 5);
      }
      return this.stageLabels;
    },
    setIsShowGrades() {
      const oneGrade = this.gradesOfStudent !== null;

      if (this.userRegisterInDashboard) {
        this.isShowGrades = false;
      } else {
        this.isShowGrades = oneGrade || this.userRegister;
      }
    },
    selectGrades(gradeName) {
      if (this.oneGrade) {
        this.selectedGradesNames = [];
      }
      let newGradeLabels = [];
      let isAddingGrade;
      if (!this.selectedGradesNames.includes(gradeName)) {
        isAddingGrade = true;
        this.selectedGradesNames.push(gradeName);
      } else if (this.selectedGradesNames.includes(gradeName)) {
        isAddingGrade = false;
        if (this.oneGrade) {
          this.selectedGradesNames = "";
        } else {
          const newGradeNames = this.selectedGradesNames.filter((names) => names !== gradeName);
          this.selectedGradesNames = newGradeNames;
        }
      }
      if (gradeName === "Sala Cuna Menor" || gradeName === "Atención Temprana") {
        gradeName = "Sala Cuna Menor / Atención Temprana";
      }
      if (gradeName === "Nivel de Transición 1" || gradeName === "PreKinder") {
        gradeName = "Nivel de Transición 1 / PreKinder";
      }
      if (gradeName === "Nivel de Transición 2" || gradeName === "Kinder") {
        gradeName = "Nivel de Transición 2 / Kinder";
      }
      if (gradeName === "1ro Básico" || gradeName === "Nivel Básico 1 Adultos") {
        gradeName = "1ro Básico / Nivel Básico 1 Adultos";
      }
      if (gradeName === "5to Básico" || gradeName === "Nivel Básico 2 Adultos") {
        gradeName = "5to Básico / Nivel Básico 2 Adultos";
      }
      if (gradeName === "7mo Básico" || gradeName === "Nivel Básico 3 Adultos") {
        gradeName = "7mo Básico / Nivel Básico 3 Adultos";
      }
      if (gradeName === "1ro Medio" || gradeName === "1er Nivel Adultos") {
        gradeName = "1ro Medio / 1er Nivel Adultos";
      }
      if (gradeName === "3ro Medio" || gradeName === "2do Nivel Adultos") {
        gradeName = "3ro Medio / 2do Nivel Adultos";
      }
      if (gradeName === "4to Medio" || gradeName === "3er Nivel Adultos") {
        gradeName = "4to Medio / 3er Nivel Adultos";
      }
      if (this.oneGrade) {
        this.grades.forEach((grade) => {
          if (grade.grade_name === gradeName) {
            grade.selected = true;
          } else {
            grade.selected = false;
          }
          newGradeLabels.push(grade);
        });
      } else if (this.gradeLabels.length === 2) {
        this.gradeLabels[0].forEach((grade) => {
          if (!this.confirmStudent) {
            if (grade.name === gradeName) {
              grade.selected = true;
            }
          }
          newGradeLabels.push(grade);
        });
      } else {
        this.grades.forEach((grade) => {
          if (!this.confirmStudent) {
            if (grade.grade_name === gradeName) {
              if (isAddingGrade) {
                grade.selected = true;
              } else {
                grade.selected = false;
              }
            }
          } else {
            if (grade.grade_name === gradeName) {
              if (isAddingGrade) {
                grade.selected = true;
              } else {
                grade.selected = false;
              }
            }
          }
          newGradeLabels.push(grade);
        });
      }
      this.gradeLabels = newGradeLabels;
      const newGradesSelected = newGradeLabels.filter((grade) => grade.selected === true);
      let gradeIds = [];
      this.selectedGrades = [];
      newGradesSelected.forEach((grade) => {
        gradeIds.push(grade.id);
        this.selectedGrades.push(grade.id);
      });
      const stage = this.stages.find((stage) => stage.stage_name_es === this.selectedStage.name).id;
      if (this.userRegister || this.confirmStudent) {
        if (!this.editCurrentStudent && !this.isGuest) {
          this.$emit("gradeInfo", gradeIds, stage);
          this.$emit("selected-grade", gradeIds, stage);
        } else if (this.oneGrade) {
          this.$emit("selected-grade", gradeIds[0], stage);
        } else {
          this.$emit("selected-grade", gradeIds, stage);
        }
      } else {
        this.$emit("selected-grade", gradeIds, stage);
      }
    },
    SetPreFormData() {
      if (this.student) {
        if (this.student.stages.length > 0) {
          const stageName = this.stages.find(
            (stage) => stage.id === this.student.stages[0]
          ).stage_name;
          this.selectedStage = {
            index: this.student.stages[0] - 1,
            name: stageName,
          };
          this.isShowGrades = true;
        }
        let gradeName;
        if (this.gradesOfInterest) {
          if (this.student.grades.length > 0) {
            gradeName = this.grades.find((grade) => grade.id === this.student.grades[0]).grade_name;
          }
        } else {
          if (this.student.current_enrollment) {
            if (
              this.student.current_enrollment.grade_name !== null &&
              this.student.current_enrollment.grade_name !== undefined
            ) {
              gradeName = this.grades.find(
                (grade) => grade.id === this.student.current_enrollment.grade_label
              ).grade_name;
            }
          }
        }
        if (gradeName) {
          let gradeSelection;
          if (this.gradesOfInterest) {
            gradeSelection = this.student.grades[0];
          } else {
            gradeSelection = this.student.current_enrollment.grade_label;
          }
          const fromatedName = this.splitLevelName(
            gradeName,
            this.student.stages[0],
            gradeSelection
          );
          this.selectedGradesNames.push(fromatedName);
        }
      }
    },
    splitLevelName(gradeName, stage, grade) {
      if (stage === 1) {
        if (grade === 1) {
          return gradeName.split("/")[0].trim();
        }
        if (grade === 5 || grade === 6) {
          return gradeName.split("/")[1].trim();
        }
      }
      if (stage === 2) {
        if (grade === 7 || grade === 11 || grade === 13) {
          return gradeName.split("/")[0].trim();
        }
      }
      if (stage === 3) {
        if (grade === 15 || grade === 17 || grade === 18) {
          return gradeName.split("/")[0].trim();
        }
      }
      if (stage === 4) {
        if (grade === 1) {
          return gradeName.split("/")[1].trim();
        }
        if (grade === 7 || grade === 11 || grade === 13) {
          return gradeName.split("/")[0].trim();
        }
      }
      if (stage === 5) {
        if (grade === 7 || grade === 11 || grade === 13) {
          return gradeName.split("/")[1].trim();
        }
        if (grade === 15 || grade === 17 || grade === 18) {
          return gradeName.split("/")[1].trim();
        }
      }
      return gradeName.split("/")[0].trim();
    },
    formatGrades() {
      // eslint-disable-next-line no-restricted-syntax
      this.grades.forEach((grade) => {
        this.gradeLabels.push([
          {
            name: grade.grade_name_es,
            id: grade.id,
            selected: this.isSelectedCurrentGrade(grade.id),
          },
        ]);
      });
    },
    setStage(label, index) {
      if (label === "No Aplica") {
        this.noCurrentSchool = true;
        this.$emit("selected-grade", 5, 1);
      } else {
        this.noCurrentSchool = false;
      }
      this.selectedGradesNames = [];
      this.selectedStage = {
        index: index,
        name: label,
      };
    },
    isSelectedCurrentGrade(id) {
      if (this.isGuest) {
        const selectGradeGuest = this.gradesGuest.includes(id);
        // const grade = this.gradesOfStudent.some(id)
        return selectGradeGuest;
      }
      const isSelected = +id === +this.gradesOfStudent;
      return isSelected;
    },
  },
  watch: {
    cleanGrades: {
      handler(newVal) {
        if (newVal === true) {
          this.gradeLabels = [];
          this.isSelected = false;
          this.selectedGrades = [];
          this.selectedStage = {
            index: -1,
            name: "",
          };
          this.selectedGradesNames = [];
          this.isShowGrades = false;
        }
      },
    },
    student: {
      handler() {
        this.SetPreFormData();
      },
    },
  },
};
</script>

import moment from "moment";
import jwtDecode from "jwt-decode";
import services from "@/services";
import CONFIG from "../config";
/* eslint-disable no-shadow */
const getDefaultState = () => ({
  exitsInfoGuest: false,
  user: {
    username: "",
    email: "",
    firstLogin: null,
    token: "",
    registerComplete: false,
    legalguardian: {
      name: "",
      lastName: "",
      referenceLocation: {},
      uuid: "",
      isGuest: true,
      guestStudent: "",
      guestStudentUuid: "",
      guestToken: "",
      address: null,
      id: "",
    },
    guestLocation: {},
    guestLocationSet: false,
    guestLocationLastUpdated: 0,
    guestGrades: [],
    guestLevel: "",
    guestSexTypes: [],
    artificial: false,
    treatment: -1,
    guestStage: null,
  },
  isGuestClickFirstTime: {
    location: false,
    grade: false,
  },
  students: [],
  currentStudent: {},
  registerStatus: null,
  resetPasswordStatus: null,
  tokenStatus: null,
  environment: CONFIG.environment,
  // Vars to decide if can show/hide primer
  // primerShow => show primer
  primerShow: false,
  feedbackShow: false,
  // if user has visited primer
  primerVisited: false,
  wantCreateAccount: false,
  showMapModal: true,
  rerenderCount: 0,
  language: null,
  preCheckStatus: null,
  loading: false,
  loginLoading: false,
  linkLoading: false,
  resetStore: false,
  validCode: false,
  authError: false,
  gauth: null,
});

const state = {
  ...getDefaultState(),
};

const getters = {
  gauth: ({ gauth }) => gauth,
  loading: ({ loading }) => loading,
  loginLoading: ({ loginLoading }) => loginLoading,
  linkLoading: ({ linkLoading }) => linkLoading,
  currentStudent: ({ currentStudent }) => currentStudent,
  userEmail: ({ user }) => user.email,
  language: ({ language }) => language,
  userUsername: ({ user }) => user.username,
  legalGuardian: ({ user }) => user.legalguardian,
  legalGuardianUUID: ({ user }) => user.legalguardian.uuid,
  legalGuardianID: ({ user }) => user.legalguardian.id,
  legalGuardianAddress: ({ user }) => user.legalguardian.address,
  token: ({ user }) => user.token,
  guestToken: ({ user }) => user.legalguardian.guestToken,
  students: ({ students }) => students,
  guestStudentUUID: ({ user }) => user.legalguardian.guestStudentUuid,
  registerComplete: ({ user }) => user.registerComplete,
  resetPasswordStatus: ({ resetPasswordStatus }) => resetPasswordStatus,
  userFirstLogin: ({ user }) => user.firstLogin,
  tokenStatus: ({ tokenStatus }) => tokenStatus,
  referenceLocation: ({ user }) => user.legalguardian.referenceLocation,
  environment: ({ environment }) => environment,
  homeLocation: ({ currentStudent, user }) => {
    const isGuest = CONFIG.guestOption && user.legalguardian.isGuest;
    if (isGuest && user.guestLocationSet && user.guestLocation !== undefined) {
      return user.guestLocation.coordinate;
    }
    if (Object.keys(currentStudent).length !== 0) {
      if (currentStudent.address !== null) {
        if (
          currentStudent.address[0].address_lat !== null &&
          currentStudent.address[0].address_lon !== null
        ) {
          return {
            lat: parseFloat(currentStudent.address[0].address_lat),
            lng: parseFloat(currentStudent.address[0].address_lon),
          };
        }
      }
    }
    if (Object.keys(currentStudent).length === 0 || currentStudent.address === null) {
      if (user.legalguardian.address !== null) {
        return {
          lat: parseFloat(user.legalguardian.address[0].address_lat),
          lng: parseFloat(user.legalguardian.address[0].address_lon),
        };
      }
      return CONFIG.defaultLocation;
    }
    return CONFIG.defaultLocation;
  },
  isGuest: ({ user }) => CONFIG.guestOption && user.legalguardian.isGuest,
  wantCreateAccount: ({ wantCreateAccount }) => wantCreateAccount && CONFIG.guestOption,
  // eslint-disable-next-line
  guestGrades: ({ user }) => user.guestGrades,
  guestLevel: ({ user }) => user.guestLevel,
  guestStage: ({ user }) => user.guestStage,
  guestLocationSet: ({ user }) => user.guestLocationSet,
  guestLocationLastUpdated: ({ user }) => user.guestLocationLastUpdated,
  primerShow: ({ primerShow }) => CONFIG.primerShow && primerShow,
  canShowPrimer: ({ user }) => CONFIG.primerShow && user.artificial,
  artificial: ({ user }) => user.artificial,
  treatment: ({ user }) => user.treatment,
  guestLocation: ({ user }) => user.guestLocation,
  showMapModal: ({ showMapModal }) => showMapModal,
  feedbackShow: ({ feedbackShow }) => feedbackShow,
  guestSexTypes: ({ user }) => user.guestSexTypes,
  rerenderCount: ({ rerenderCount }) => rerenderCount,
  preCheckStatus: ({ preCheckStatus }) => preCheckStatus,
  email: ({ user }) => user.email,
  isGuestClickFirstTime: ({ isGuestClickFirstTime }) => isGuestClickFirstTime,
  resetStore: ({ resetStore }) => resetStore,
  exitsInfoGuest: () => true,
  validCode: ({ validCode }) => validCode,
};

const mutations = {
  setLoginLoading(state, { bool }) {
    state.loginLoading = bool;
  },
  setLinkLoading(state, { bool }) {
    state.linkLoading = bool;
  },
  setValidCode(state, { validCode }) {
    state.validCode = validCode;
  },
  offGuest(state) {
    state.user.legalguardian.isGuest = false;
    state.user.legalguardian.guestStudent = "";
    state.user.legalguardian.guestToken = "";
    state.user.legalguardian.uuid = "";
    state.user.legalguardian.id = "";
  },
  setUser(state, { username, token, artificial, treatment, registerComplete, email, firstLogin }) {
    state.user.username = username;
    state.user.token = token;
    state.user.artificial = artificial;
    state.user.treatment = treatment;
    state.user.registerComplete = registerComplete;
    state.user.email = email;
    state.user.firstLogin = firstLogin;
  },
  setUsername(state, { username }) {
    state.user.username = username;
    state.user.userUsername = username;
  },
  setUserToken(state, token) {
    state.user.token = token;
  },
  setGoogleAuthData(state, data) {
    state.gauth = data;
  },
  setCurrentStudent(state, { student }) {
    state.currentStudent = student;
  },
  setAuthError(state, { bool }) {
    state.authError = bool;
  },
  setAddress(state, { address }) {
    if (state.user.legalguardian.address === null) {
      state.user.legalguardian.address = [];
      state.user.legalguardian.address.push({ address_details: address });
    } else {
      state.user.legalguardian.address[0].address_details = address;
    }
  },
  setLegalGuardianLocation(state, location) {
    if (location.coordinates) {
      location = [location];
      location[0].address_lat = location[0].coordinates.lat;
      location[0].address_lon = location[0].coordinates.lng;
    }
    if (state.user.legalguardian.address === null) {
      state.user.legalguardian.address = location;
    } else {
      state.user.legalguardian.address[0].address_lat = location[0].address_lat;
      state.user.legalguardian.address[0].address_lon = location[0].address_lon;
      state.user.legalguardian.address[0].address_name = location[0].address_name;
      state.user.legalguardian.address[0].address_details = location[0].addressDetail;
    }
  },
  setLanguage(state, language) {
    state.language = language;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setPreCheckStatus(state, preCheckStatus) {
    state.preCheckStatus = preCheckStatus;
  },
  // deprecated!!
  setLegalGuardian(state, { legalGuardian }) {
    state.user.legalguardian = legalGuardian;
  },
  setLegalGuardianUUID(state, { legalGuardianUUID }) {
    state.user.legalguardian.uuid = legalGuardianUUID;
  },
  setLegalGuardianID(state, { legalGuardianID }) {
    state.user.legalguardian.id = legalGuardianID;
  },
  setReferenceLocation(state, { referenceLocation }) {
    state.user.legalguardian.referenceLocation = referenceLocation;
  },
  setStudents(state, { students }) {
    state.students = [...students];
  },
  setRegisterStatus(state, { registerStatus }) {
    state.registerStatus = registerStatus;
  },
  setResetPasswordStatus(state, { status }) {
    state.resetPasswordStatus = status;
  },
  setTokenStatus(state, { status }) {
    state.tokenStatus = status;
  },
  setEnvironment(state, { environment }) {
    state.environment = environment;
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
  setPrimerShow(state, { primerShow }) {
    state.primerShow = primerShow;
  },
  setFeedbackShow(state, { feedbackShow }) {
    state.feedbackShow = feedbackShow;
  },
  setPrimerVisited(state, { primerVisited }) {
    state.primerVisited = primerVisited;
  },
  setWantCreateAccount(state, { wantCreateAccount }) {
    state.wantCreateAccount = wantCreateAccount;
  },
  setGuestLocation(state, { location }) {
    state.user.guestLocation = location;
  },
  setGuestLocationSet(state, newVal) {
    state.user.guestLocationSet = newVal;
  },
  setGuestLocationLastUpdated(state, newVal) {
    state.user.guestLocationLastUpdated = newVal;
  },
  setGuestGrades(state, { grades }) {
    state.user.guestGrades = grades;
  },
  setGuestLevel(state, { level }) {
    state.user.guestLevel = level;
  },
  setGuestStage(state, { stage }) {
    state.user.guestStage = stage;
  },
  setMapModal(state, { show }) {
    state.showMapModal = show;
  },
  setGuestSexTypes(state, { sexTypes }) {
    state.user.guestSexTypes = [...sexTypes];
  },
  setLGIsGuestUser(state, { isGuest }) {
    state.user.legalguardian.isGuest = isGuest;
  },
  setGuestStudent(state, { guestStudent }) {
    state.user.legalguardian.guestStudent = guestStudent;
  },
  setGuestStudentUuid(state, { guestStudent }) {
    state.user.legalguardian.guestStudentUuid = guestStudent;
  },
  setLGGuestToken(state, { token }) {
    state.user.legalguardian.guestToken = token;
  },
  setRerenderCount(state, { rerenderCount }) {
    state.rerenderCount = rerenderCount;
  },
  setEmail(state, { email }) {
    state.user.email = email;
  },
  setIsGuestClickFirstTime(state, { property }) {
    state.isGuestClickFirstTime[property] = true;
  },
  resetStore(state) {
    Object.assign(state, getDefaultState());
  },
  setResetStore(state, { data }) {
    state.resetStore = data;
  },
  setFirstLogin(state, { bool }) {
    state.user.firstLogin = bool;
  },
  setExitsInfoGuest(state, { exitsInfoGuest }) {
    state.exitsInfoGuest = exitsInfoGuest;
  },
};

const actions = {
  resetStores({ commit, dispatch }) {
    dispatch("filters/resetStores", {}, { root: true });
    dispatch("newStudentRegister/resetStore", {}, { root: true });
    dispatch("options/resetStores", {}, { root: true });
    dispatch("institutions/reset", {}, { root: true });
    commit("resetStore");
  },
  setLoginLoading({ commit }, { bool }) {
    commit("setLoginLoading", { bool });
  },
  setLinkLoading({ commit }, { bool }) {
    commit("setLinkLoading", { bool });
  },
  setUsername({ commit }, { username }) {
    commit("setUsername", { username });
  },
  setAuthError({ commit }, { bool }) {
    commit("setAuthError", { bool });
  },
  setAddress({ commit }, { address }) {
    commit("setAddress", { address });
  },
  setIsGuestClickFirstTime({ commit }, { property }) {
    commit("setIsGuestClickFirstTime", { property });
  },
  // TODO DEPRECATED FUNC
  createGuest({ commit }, { location, grades, callback = undefined, errorCallback = undefined }) {
    const data = {
      coordinates: location.coordinate,
      locality: 117,

      // locality: location.regionId,
      completeAddress: location.municipalityName,
      grades,
    };
    services.authenticationService
      .createGuest(data)
      .then(({ data }) => {
        commit("setLegalGuardianUUID", { legalGuardianUUID: data.uuid });
        commit("setLegalGuardianID", { legalGuardianID: data.legal_id });
        commit("setLGIsGuestUser", { isGuest: true });
        commit("setGuestStudent", { guestStudent: data.guest_applicant_id });
        commit("setGuestStudentUuid", { guestStudent: data.guest_applicant });
        commit("setLGGuestToken", { token: data.token });
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.error(error);
        if (errorCallback) {
          errorCallback();
        }
      });
  },
  // TODO DEPRECATED FUNC
  updateGuest(
    { getters },
    { location = undefined, grades = undefined, callback = undefined, errorCallback = undefined }
  ) {
    const data = {};
    if (location) {
      data.coordinates = location.coordinate;
      data.locality = location.commune;
      data.completeAddress = location.communeName;
      data.upAddress = true;
    }
    if (grades) {
      data.grades = grades;
      data.upGrades = true;
    }
    // eslint-disable-next-line
    services.authenticationService
      .updateGuest(data, getters.legalGuardian.guestStudent)
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.error(error);
        if (errorCallback) {
          errorCallback();
        }
      });
  },
  getStudents(
    { commit, dispatch, rootGetters },
    { callback = undefined, /* selectFirst = true */ updateMarkers = false }
  ) {
    return services.authenticationService.retrieveStudents().then(({ data }) => {
      data.forEach((student) => {
        if (
          student.personalized_name &&
          student.identification_type &&
          (student.identification_type.id === 10 ||
            (student.identification_type.id !== 10 && student.identification_number)) &&
          student.first_name &&
          student.first_lastname &&
          student.other_lastname &&
          student.gender &&
          student.relationship &&
          student.birth_date &&
          student.grade &&
          (student.identification_type.nationality.id !== 1 ||
            (student.identification_type.nationality.id === 1 && student.birth_location)) &&
          student.same_location !== null
        ) {
          student.register_complete = true; // eslint-disable-line no-param-reassign
        } else {
          student.register_complete = false; // eslint-disable-line no-param-reassign
        }
        if (
          student.identification_type &&
          (student.identification_type.id === 10 ||
            (student.identification_type.id !== 10 && student.identification_number)) &&
          student.same_location !== null
        ) {
          student.can_apply = true; // eslint-disable-line no-param-reassign
        } else {
          student.can_apply = false; // eslint-disable-line no-param-reassign
        }
      });

      let homeLocation;
      if (data.length > 0) {
        if (data[0].address !== undefined && data[0].address !== null) {
          homeLocation = {
            lat: data[0].address[0].location.default_coordinate.lat,
            lng: data[0].address[0].location.default_coordinate.lon,
          };
        }
      } else {
        const userHomeLocation = rootGetters["authentication/legalGuardianAddress"];
        homeLocation = {
          lat: userHomeLocation[0].address_lat,
          lng: userHomeLocation[0].address_lon,
        };
      }
      commit("setStudents", { students: data });
      dispatch("userRegister/setHomeLocationFromAuth", homeLocation, { root: true });
      const currentStudent = rootGetters["authentication/currentStudent"];
      let filterCurrentStudent;
      if (currentStudent.uuid !== undefined) {
        const { uuid } = { ...currentStudent };
        filterCurrentStudent = data.find((student) => student.uuid === uuid);
      } else {
        [filterCurrentStudent] = data;
      }
      if (data.length > 0) {
        // let studentToSet;
        // if (selectFirst) {
        //   [studentToSet] = data;
        // } else {
        //   const lastIndex = data.length - 1;
        //   studentToSet = data[lastIndex];
        // }
        if (updateMarkers) {
          dispatch("setCurrentStudentWithoutCleanFiltersAndGetMarkers", {
            student: data,
            homeLocation,
          });
        } else {
          dispatch("setCurrentStudent", { student: filterCurrentStudent });
        }
      }
      commit("setLoading", false);
      if (callback) {
        callback(data);
      }
    });
  },
  /* eslint-disable camelcase */
  async doLoginWithUUID({ commit, dispatch }, { uuid, isFeedback = false }) {
    await services.authenticationService.loginWithUUID({ uuid }).then(({ data }) => {
      commit("setLGIsGuestUser", { isGuest: false });
      commit("setLoading", false);
      if (isFeedback) {
        commit("setUserToken", data.key);
        dispatch("getStudents", {
          callback: () => {},
        });
      }
      return uuid;
    });
  },
  verifyCode({ commit, rootGetters, dispatch }, { code, email, authType }) {
    services.authenticationService
      .verifyCode({
        email: authType === "email" ? email : null,
        username: null,
        code,
        authMethod: authType,
      })
      .then(({ data }) => {
        commit("setValidCode", { validCode: data.is_valid });
        if (data.is_valid) {
          const preCheckData = {
            origin: null,
            found: true,
            first_login: true,
            same_password: false,
            has_addresses: false,
            is_verified: true,
          };
          commit("setPreCheckStatus", preCheckData);
        }
        const token = rootGetters["authentication/token"];
        services.authenticationService.refreshToken({ token }).then((response) => {
          const token = response.data.token;
          commit("setUserToken", token);
          services.prechecksService.preChekLegalGuardian().then((response) => {
            if (!response.data.has_lg) {
              services.authenticationService
                .createLegalGuardian()
                .then((response) => {
                  dispatch("getPrecheckRoles").then(() => {
                    const headMasterRole = rootGetters["roles/headMasterRole"];
                    const firstLogin = rootGetters["roles/headMasterFirstLogin"];
                    if (headMasterRole !== null && firstLogin === true) {
                      dispatch("roles/setHeadMasterFirstLogin", { bool: true }, { root: true });
                    } else {
                      dispatch("roles/setHeadMasterFirstLogin", { bool: false }, { root: true });
                    }
                  });
                  const username = rootGetters["authentication/userUsername"];
                  dispatch("setIdentificationName", { username }).then(() => {
                    const legalGuardianUUID = response.data.uuid;
                    commit("setLegalGuardianUUID", { legalGuardianUUID });
                    dispatch("getUserDetails", { legalGuardianUUID });
                  });
                })
                .then(() => {
                  dispatch("getStudents", {
                    callback: () => {},
                  }).then(() => {
                    dispatch("institutions/retrieveExploredSchoolCard", {}, { root: true });
                    dispatch("institutions/retrieveExploredCampuses", {}, { root: true });
                    dispatch("institutions/retrieveFavorites", {}, { root: true });
                  });
                })
                .catch((error) => {
                  commit("setLoading", false);
                  console.error(error);
                })
                .then(() => {
                  dispatch("institutions/retrieveExploredSchoolCard", {}, { root: true });
                  dispatch("institutions/retrieveExploredCampuses", {}, { root: true });
                  dispatch("institutions/retrieveFavorites", {}, { root: true });
                });
            }
          });
        });
      })
      .catch(() => commit("setValidCode", false))
      .finally(() => {});
  },
  doLogin(
    { commit, dispatch, getters, rootGetters },
    { username, email, password, callback, captcha, linkAccount }
  ) {
    const payload = {
      password,
      captcha,
    };
    if (email) {
      commit("setEmail", { email });
      payload.email = email;
    }
    if (username) {
      payload.username = username;
    }
    /* eslint-disable camelcase */
    services.authenticationService.login(payload).then((response) => {
      const token = response.data.key;
      commit("setUserToken", token);
      commit("setLGIsGuestUser", { isGuest: false });
      services.prechecksService.preChekLegalGuardian().then((response) => {
        const preCheckResponse = {
          origin: "Google",
          found: response.data.has_lg,
          first_login: false,
          same_password: true,
          is_verified: true,
          has_addresses: response.data.has_lg_address,
          has_applicants: response.data.has_applicants,
        };
        const legalGuardianUUID = response.data.lg_uuid;
        if (linkAccount) {
          dispatch("linkAccounts", { preCheckResponse, legalGuardianUUID });
        } else {
          if (legalGuardianUUID !== "") {
            commit("offGuest");
            dispatch("getUserDetails", { legalGuardianUUID })
              .then(() => {
                dispatch("getPrecheckRoles").then(() => {
                  const headMasterRole = rootGetters["roles/headMasterRole"];
                  const firstLogin = rootGetters["roles/headMasterFirstLogin"];
                  if (headMasterRole !== null && firstLogin === true) {
                    dispatch("roles/setHeadMasterFirstLogin", { bool: true }, { root: true });
                  } else {
                    dispatch("roles/setHeadMasterFirstLogin", { bool: false }, { root: true });
                  }
                });
                if (response.data.has_applicants) {
                  dispatch("getStudents", {
                    callback: (studentsData) => {
                      if (callback) {
                        callback(studentsData);
                      }
                      if (getters.artificial) {
                        dispatch(
                          "filters/setLoginFilter",
                          {
                            login: true,
                          },
                          { root: true }
                        );
                        dispatch(
                          "institutions/setFilters",
                          {
                            filters: {
                              performance_categories: [],
                              monthly_payments: [],
                              distance_radius: [2],
                              has_pie: false,
                              has_sep: false,
                              center: studentsData[0].location.default_coordinate,
                              all_performance_categories: true,
                              all_monthly_payments: true,
                            },
                          },
                          { root: true }
                        );
                      }
                    },
                  }).then(() => {
                    dispatch("institutions/retrieveExploredSchoolCard", {}, { root: true });
                    dispatch("institutions/retrieveExploredCampuses", {}, { root: true });
                    dispatch("institutions/retrieveFavorites", {}, { root: true });
                    commit("setLinkLoading", { bool: false });
                    commit("setPreCheckStatus", preCheckResponse);
                    dispatch("institutions/retrieveExploredSchoolCard", {}, { root: true });
                    dispatch("institutions/retrieveExploredCampuses", {}, { root: true });
                    dispatch("institutions/retrieveFavorites", {}, { root: true });
                  });
                } else {
                  const preCheckResponse = {
                    origin: "Google",
                    found: response.data.has_lg,
                    first_login: false,
                    same_password: true,
                    is_verified: true,
                    has_addresses: response.data.has_lg_address,
                    has_applicants: response.data.has_applicants,
                  };
                  commit("setPreCheckStatus", preCheckResponse);
                }
              })
              .catch((error) => {
                console.error(error);
                return true;
              });
          } else {
            const preCheckResponse = {
              origin: "Google",
              found: response.data.has_lg,
              first_login: false,
              same_password: true,
              is_verified: true,
              has_addresses: response.data.has_lg_address,
              has_applicants: response.data.has_applicants,
            };
            if (linkAccount) {
              preCheckResponse.linkAccount = false;
            }
            if (preCheckResponse.has_applicants) {
              dispatch("getStudents", {
                callback: (studentsData) => {
                  if (callback) {
                    callback(data);
                  }
                  if (getters.artificial) {
                    dispatch(
                      "filters/setLoginFilter",
                      {
                        login: true,
                      },
                      { root: true }
                    );
                    dispatch(
                      "institutions/setFilters",
                      {
                        filters: {
                          performance_categories: [],
                          monthly_payments: [],
                          distance_radius: [2],
                          has_pie: false,
                          has_sep: false,
                          center: studentsData[0].location.default_coordinate,
                          all_performance_categories: true,
                          all_monthly_payments: true,
                        },
                      },
                      { root: true }
                    );
                  }
                },
              }).then(() => {
                commit("setLinkLoading", { bool: false });
                commit("setPreCheckStatus", preCheckResponse);
                dispatch("institutions/retrieveExploredSchoolCard", {}, { root: true });
                dispatch("institutions/retrieveExploredCampuses", {}, { root: true });
                dispatch("institutions/retrieveFavorites", {}, { root: true });
              });
            }
            services.authenticationService
              .createLegalGuardian()
              .then((response) => {
                dispatch("getPrecheckRoles").then(() => {
                  const headMasterRole = rootGetters["roles/headMasterRole"];
                  const firstLogin = rootGetters["roles/headMasterFirstLogin"];
                  if (headMasterRole !== null && firstLogin === true) {
                    dispatch("roles/setHeadMasterFirstLogin", { bool: true }, { root: true });
                  } else {
                    dispatch("roles/setHeadMasterFirstLogin", { bool: false }, { root: true });
                  }
                });
                const legalGuardianUUID = response.data.uuid;
                commit("setPreCheckStatus", preCheckResponse);
                commit("setLegalGuardianUUID", { legalGuardianUUID });
                commit("setLoading", false);
              })
              .catch((error) => {
                commit("setLoginLoading", { bool: false });
                commit("setLoading", false);
                console.error(error);
                return false;
              });
          }
        }
        return true;
      });
    });
  },
  setCurrentStudentWithUUID({ getters, dispatch }, { uuid, callback }) {
    const student = getters.students.find((student) => student.uuid === uuid);
    if (student) {
      dispatch("setCurrentStudent", { student });
    }
    if (callback) {
      callback();
    }
  },
  async setCurrentStudent({ commit, dispatch, getters }, { student }) {
    commit("setCurrentStudent", { student });
    let lat;
    let lng;
    if (getters.isGuest) {
      if (getters.guestLocation.coordinate !== undefined) {
        lat = getters.guestLocation.coordinate.lat;
        lng = getters.guestLocation.coordinate.lng;
      } else {
        lat = CONFIG.defaultLocation.lat;
        lng = CONFIG.defaultLocation.lng;
      }
    } else if (student) {
      if (student.address !== null) {
        if (student.address[0].location !== null) {
          lat = student.address[0].location.default_coordinate.lat;
          lng = student.address[0].location.default_coordinate.lon;
        }
        if (student.address[0].address_lat !== null && student.address[0].address_lon !== null) {
          lat = student.address[0].address_lat;
          lng = student.address[0].address_lon;
        }
      }
    } else if (getters.legalGuardianAddress.length > 0) {
      lat = parseFloat(getters.legalGuardianAddress[0].address_lat);
      lng = parseFloat(getters.legalGuardianAddress[0].address_lon);
    } else {
      lat = CONFIG.defaultLocation.lat;
      lng = CONFIG.defaultLocation.lng;
    }
    dispatch("institutions/setMapCenter", { lat, lng }, { root: true });
    await dispatch("institutions/cleanFilters", {}, { root: true });
  },
  async setCurrentStudentWithoutCleanFiltersAndGetMarkers({ commit }, { student }) {
    const firstStudent = student[0];
    commit("setCurrentStudent", { student: firstStudent });
    // await dispatch('institutions/setMapCenter', lat, lng,
    // { root: true });
    // dispatch('institutions/retrieveCampusesWithActualData', {}, { root: true });
  },
  setLegalGuardian({ commit }, { legalGuardian }) {
    commit("setLegalGuardian", { legalGuardian });
  },
  setReferenceLocation({ commit }, { referenceLocation }) {
    commit("setReferenceLocation", { referenceLocation });
  },
  setResetStore({ commit }, { data }) {
    commit("setResetStore", { data });
  },
  setLanguage({ commit }, language) {
    commit("setLanguage", language);
  },
  setLoading({ commit }, loading) {
    commit("setLoading", loading);
  },
  setIdentificationName({ commit }, { username }) {
    services.userRegistrationService.communRegisterStep1({ username }).then(() => {
      commit("setUsername", { username });
    });
  },
  doRegister({ commit, dispatch, rootGetters }, { registerData }) {
    const guestToken = rootGetters["authentication/guestToken"];
    if (guestToken) {
      services.authenticationService
        .register({
          username: null,
          email: registerData.email,
          password1: registerData.password1,
          password2: registerData.password2,
          token: guestToken,
        })
        .then((response) => {
          const userInfo = jwtDecode(response.data.key);
          const token = response.data.key;
          commit("setUserToken", token);
          dispatch("doLoginWithUUID", {
            uuid: userInfo.user_id,
          });
        })
        .catch((error) => {
          commit("setRegisterStatus", { registerStatus: error.message });
          commit("setLoading", false);
        });
    }
  },
  setLegalGuardianLocation({ commit }, address) {
    commit("setLegalGuardianLocation", address);
  },
  getUserDetails({ commit, dispatch, state, rootGetters }, { legalGuardianUUID, location }) {
    const token = rootGetters["authentication/token"];
    services.userRegistrationService
      .retrieveRegistrationData(legalGuardianUUID, token)
      .then((response) => {
        let setAddress = response.data.address;
        // const userHomeLocation = rootGetters['authentication/legalGuardianAddress'];
        if (setAddress !== null) {
          if (location !== undefined) {
            if (setAddress[0].address_details !== location) {
              setAddress[0].address_details = location;
            }
          } else {
            setAddress = response.data.address;
          }
          commit("setLegalGuardianLocation", setAddress);
        }
        dispatch(
          "userRegister/setLoadingAddress",
          {
            address: setAddress,
          },
          { root: true }
        );
        const { uuid, id } = response.data;
        const personalizedName = response.data.personalized_name;
        const firstLogin = response.data.first_login;
        // if user is artificial, show primer
        // if (false) {
        //  dispatch('showPrimer');
        // }
        const email = state.user.email;
        commit("setUser", {
          username: personalizedName,
          token,
          artificial: null,
          treatment: null,
          registerComplete: null,
          email,
          firstLogin,
        });
        commit("setUsername", { username: personalizedName });
        commit("setLegalGuardianUUID", { legalGuardianUUID: uuid });
        commit("setLegalGuardianID", { legalGuardianID: id });
        commit("setWantCreateAccount", { wantCreateAccount: false });
        dispatch("institutions/changeShowInfoMarkerStatus", { status: false }, { root: true });
        dispatch("cleanGuestLocationWithClosedModal");
        return response.data;
      });
  },
  getPrecheckData({ commit, dispatch }, { data, fastLogin }) {
    const email = data.registerData.email;
    commit("setUsername", { username: data.registerData.username });
    commit("setEmail", { email });
    commit("setLoading", true);
    const preCheck = data.preCheck;
    services.prechecksService
      .preChekUser({ preCheck })
      .then((response) => {
        if (response.status === 200) {
          commit("setPreCheckStatus", response.data);
          const preCheckData = response.data;
          const registerData = data.registerData;
          if (preCheckData.found && !preCheckData.is_verified) {
            dispatch("resendVerificationCode", {
              email: registerData.email,
              username: registerData.username,
              authMethod: "email",
            });
          }
          if (preCheckData.found && preCheckData.is_verified && fastLogin !== false) {
            dispatch("doLogin", {
              email: registerData.email,
              password: registerData.password1,
            });
          }
          commit("setLoading", false);
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          const registerData = data.registerData;
          const preCheckData = {
            origin: null,
            found: false,
            first_login: true,
            same_password: false,
            has_addresses: false,
            new_user: true,
            is_verified: false,
          };
          commit("setPreCheckStatus", preCheckData);
          dispatch("doRegister", { registerData }, preCheck);
        }
        console.error(error);
      })
      .finally((response) => response);
  },
  getPrecheckRoles({ dispatch, rootGetters }) {
    services.prechecksService
      .preChekRoles()
      .then((response) => {
        const roles = response.data;
        if (roles.length === 1) {
          const roleInfo = {
            id: roles[0].role.id,
            roleCode: roles[0].role.role_code,
            roleName: roles[0].role.role_name,
            roleOptions: roles[0].role.role_options,
          };
          const currentRole = {
            roleInfo,
            rolePermissions: roles[0].role.role_permissions,
          };
          if (roles[0].role.id === 2) {
            dispatch("roles/setLegalGuardianRoleInfo", { info: roleInfo }, { root: true }).then(
              () => {
                dispatch(
                  "roles/setLegalGuardianRolePermissions",
                  { info: roles[0].role.role_permissions },
                  { root: true }
                ).then(() => {
                  dispatch("roles/setCurrentRole", { info: currentRole }, { root: true });
                });
              }
            );
            dispatch("roles/setHeadMasterRoleInfo", { info: null }, { root: true }).then(() => {
              dispatch("roles/setHeadMasterRolePermissions", { info: null }, { root: true }).then(
                () => {}
              );
            });
          } else {
            dispatch("roles/setHeadMasterRoleInfo", { info: roleInfo }, { root: true }).then(() => {
              dispatch(
                "roles/setHeadMasterRolePermissions",
                { info: roles[0].role.role_permissions },
                { root: true }
              ).then(() => {
                dispatch("roles/setCurrentRole", { info: currentRole }, { root: true });
              });
            });
            dispatch("roles/setLegalGuardianRoleInfo", { info: null }, { root: true }).then(() => {
              dispatch(
                "roles/setLegalGuardianRolePermissions",
                { info: null },
                { root: true }
              ).then(() => {});
            });
          }
        } else {
          roles.forEach((roleData) => {
            if (roleData.role.id === 3) {
              const roleInfo = {
                id: roleData.role.id,
                roleCode: roleData.role.role_code,
                roleName: roleData.role.role_name,
                roleOptions: roleData.role.role_options,
              };
              dispatch("roles/setHeadMasterRoleInfo", { info: roleInfo }, { root: true }).then(
                () => {
                  dispatch(
                    "roles/setHeadMasterRolePermissions",
                    { info: roleData.role.role_permissions },
                    { root: true }
                  ).then(() => {
                    const headMasterRole = rootGetters["roles/headMasterRole"];
                    if (headMasterRole !== null) {
                      const currentRole = {
                        roleInfo,
                        rolePermissions: roleData.role.role_permissions,
                      };
                      dispatch("roles/setCurrentRole", { info: currentRole }, { root: true });
                    }
                  });
                }
              );
            } else if (roleData.role.id === 2) {
              const roleInfo = {
                id: roleData.role.id,
                roleCode: roleData.role.role_code,
                roleName: roleData.role.role_name,
                roleOptions: roleData.role.role_options,
              };
              dispatch("roles/setLegalGuardianRoleInfo", { info: roleInfo }, { root: true }).then(
                () => {
                  dispatch(
                    "roles/setLegalGuardianRolePermissions",
                    { info: roleData.role.role_permissions },
                    { root: true }
                  ).then(() => {
                    const headMasterRole = rootGetters["roles/headMasterRole"];
                    if (headMasterRole === null) {
                      const currentRole = {
                        roleInfo,
                        rolePermissions: roleData.role.role_permissions,
                      };
                      dispatch("roles/setCurrentRole", { info: currentRole }, { root: true });
                    }
                  });
                }
              );
            }
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally((response) => response);
  },
  retrieveResetPasswordToken({ commit }, { email, username, authMethod, callback }) {
    if (authMethod === "email") {
      services.authenticationService
        .resetPasswordToken({ email })
        .then(() => commit("setTokenStatus", { status: "OK" }))
        .catch(() => commit("setTokenStatus", { status: "ERROR" }))
        .finally(() => callback());
    } else {
      services.authenticationService
        .resetPasswordToken({ username })
        .then(() => {
          commit("setTokenStatus", { status: "OK" });
        })
        .catch(() => {
          commit("setTokenStatus", { status: "ERROR" });
        })
        .finally(() => callback());
    }
  },
  changePassword({ commit, dispatch }, { password1, password2, callback }) {
    commit("setLoading", true);
    services.authenticationService
      .changePassword({ password1, password2 })
      .then(() => {
        commit("setFirstLogin", { bool: false });
        commit("setResetPasswordStatus", { status: "OK" });
        dispatch("roles/setHeadMasterFirstLogin", { bool: false }, { root: true });
        commit("setLoading", false);
      })
      .catch(() => {
        commit("setResetPasswordStatus", { status: "ERROR" });
      })
      .finally(() => callback());
  },
  resetPassword({ commit }, { token, password, callback }) {
    services.authenticationService
      .resetPassword({
        token,
        password,
      })
      .then(() => commit("setResetPasswordStatus", { status: "OK" }))
      .catch(() => commit("setResetPasswordStatus", { status: "ERROR" }))
      .finally(() => callback());
  },
  resetHeadMasterPassword({ commit }) {
    commit("setResetPasswordStatus", { status: "LOGGED-RESET" });
  },
  resendVerificationCode({ dispatch }, { email, username, authMethod, callback }) {
    services.authenticationService
      .resendVerificationCode({ email, username, authMethod })
      .then((response) => {
        if (response.status === 200) {
          if (callback) callback();
        } else {
          dispatch("snackbar/error", response.data.detail, { root: true });
        }
      })
      .catch((response) => {
        dispatch("snackbar/error", response.response.data.detail, { root: true });
      });
  },
  logoutSuccess({ state, commit, dispatch }, { callback = undefined }) {
    services.authenticationService
      .logout()
      .then(() => {
        dispatch("userRegister/setInRegisterFlow", false, { root: true });
        const { primerVisited } = state;
        dispatch("resetStores");
        commit("setPrimerVisited", { primerVisited });
        return dispatch("institutions/reset", {}, { root: true });
      })
      .finally(() => {
        if (callback) {
          callback();
        }
      });
  },
  showPrimer({ state, commit }) {
    if (state.user.artificial) {
      commit("setPrimerShow", { primerShow: true });
    }
  },
  hidePrimer({ commit }) {
    commit("setPrimerShow", { primerShow: false });
  },
  showFeedback({ commit }) {
    commit("setFeedbackShow", { feedbackShow: true });
  },
  hideFeedback({ commit }) {
    commit("setFeedbackSho", { feedbackShow: false });
  },
  startFromPrimer({ dispatch, commit }) {
    dispatch("hidePrimer");
    commit("setPrimerVisited", { primerVisited: true });
  },
  unsetGoogleAuthData({ commit }) {
    commit("setGoogleAuthData", null);
  },
  linkAccounts({ dispatch, rootGetters, commit }, { preCheckResponse, legalGuardianUUID }) {
    const gauth = rootGetters["authentication/gauth"];
    services.authenticationService.linkAccounts({ gauth }).then(() => {
      const oldToken = rootGetters["authentication/token"];
      services.authenticationService.refreshToken({ token: oldToken }).then((response) => {
        const { token } = response.data;
        commit("setUserToken", token);
        dispatch("unsetGoogleAuthData", {});
        if (legalGuardianUUID !== "") {
          dispatch("getUserDetails", { legalGuardianUUID })
            .then(() => {
              dispatch("getPrecheckRoles").then(() => {
                const headMasterRole = rootGetters["roles/headMasterRole"];
                const firstLogin = rootGetters["roles/headMasterFirstLogin"];
                if (headMasterRole !== null && firstLogin === true) {
                  dispatch("roles/setHeadMasterFirstLogin", { bool: true }, { root: true });
                } else {
                  dispatch("roles/setHeadMasterFirstLogin", { bool: false }, { root: true });
                }
              });
              if (preCheckResponse.has_applicants) {
                dispatch("getStudents", {}).then(() => {
                  dispatch("institutions/retrieveExploredSchoolCard", {}, { root: true });
                  dispatch("institutions/retrieveExploredCampuses", {}, { root: true });
                  dispatch("institutions/retrieveFavorites", {}, { root: true });
                  commit("setLinkLoading", { bool: false });
                  commit("setPreCheckStatus", preCheckResponse);
                });
              } else {
                if (preCheckResponse) {
                }
                commit("setLinkLoading", { bool: false });
                commit("setPreCheckStatus", preCheckResponse);
              }
            })
            .catch((error) => {
              console.error(error);
              return true;
            });
        } else {
          services.authenticationService
            .createLegalGuardian()
            .then((response) => {
              dispatch("getPrecheckRoles").then(() => {
                const headMasterRole = rootGetters["roles/headMasterRole"];
                const firstLogin = rootGetters["roles/headMasterFirstLogin"];
                if (headMasterRole !== null && firstLogin === true) {
                  dispatch("roles/setHeadMasterFirstLogin", { bool: true }, { root: true });
                } else {
                  dispatch("roles/setHeadMasterFirstLogin", { bool: false }, { root: true });
                }
              });
              preCheckResponse.linkAccount = false;
              const legalGuardianUUID = response.data.uuid;
              commit("setPreCheckStatus", preCheckResponse);
              commit("setLegalGuardianUUID", { legalGuardianUUID });
              commit("setLoading", false);
            })
            .catch((error) => {
              commit("setLoginLoading", { bool: false });
              commit("setLoading", false);
              console.error(error);
              return false;
            });
        }
      });
    });
  },
  setWantCreateAccount({ commit }, { wantCreateAccount }) {
    commit("setWantCreateAccount", { wantCreateAccount });
  },
  setRerenderCount({ commit }, { rerenderCount }) {
    commit("setRerenderCount", { rerenderCount });
  },
  // eslint-disable-next-line
  setGuestData(
    { commit, dispatch },
    { location, grades, sexTypes = undefined, level, distance = null, stage }
  ) {
    if (location !== null) {
      dispatch("setGuestLocation", { location });
      dispatch("institutions/setMapCenter", location.coordinate, { root: true });
    }
    if (grades !== null) {
      commit("setGuestGrades", { grades });
    }
    if (sexTypes && sexTypes !== []) {
      commit("setGuestSexTypes", { sexTypes });
    }
    if (level !== null) {
      commit("setGuestLevel", { level });
    }
    if (stage !== null) {
      commit("setGuestStage", { stage });
    }
    dispatch("institutions/setDistanceRadius", { distanceRadius: distance }, { root: true });
    dispatch(
      "institutions/setFilters",
      {
        filters: {
          performance_categories: [],
          monthly_payments: [],
          distance_radius: [2],
          has_pie: false,
          has_sep: false,
          center: location.coordinate,
          all_performance_categories: true,
          all_monthly_payments: true,
        },
      },
      { root: true }
    );
  },
  setFirstLogin({ commit }, bool) {
    commit("setFirstLogin", { bool });
  },
  cleanGuestGrades({ commit }) {
    commit("setGuestGrades", []);
  },
  cleanGuestData({ commit }) {
    commit("setGuestLocation", { location: {} });
    commit("setGuestLocationSet", false);
    commit("setGuestGrades", { grades: [] });
    commit("setGuestLocationLastUpdated", 0);
  },
  deleteStudent({ dispatch, state }, { uuid }) {
    try {
      services.authenticationService.deleteStudent({ uuid }).then(() => {
        dispatch("getStudents", {});
      });
      const { students, currentStudent } = state;
      if (students.length > 0) {
        return currentStudent;
      }
      return null;
    } catch (error) {
      dispatch("snackbar/error", error, { root: true });
      return null;
    }
  },
  setMapModal({ commit }, { show }) {
    commit("setMapModal", { show });
  },
  setGuestLocation({ commit }, { location }) {
    commit("setGuestLocation", { location });
    commit("setGuestLocationSet", true);
    commit("setMapModal", { show: false });
    commit("setGuestLocationLastUpdated", moment());
  },
  cleanGuestLocationWithClosedModal({ commit }) {
    commit("setGuestLocation", {});
    commit("setGuestLocationSet", false);
    commit("setMapModal", { show: false });
  },
  setExitsInfoGuest({ commit }, { exitsInfoGuest }) {
    commit("setExitsInfoGuest", { exitsInfoGuest });
  },
  setFakeToken({ commit }) {
    services.authenticationService.fakeToken().then((response) => {
      commit("setLGGuestToken", { token: response.data.key });
    });
  },
  setGoogleAuth({ commit, dispatch, rootGetters }, { googleAuth }) {
    commit("setLoading", true);
    const guestToken = rootGetters["authentication/guestToken"];
    const authResponse = googleAuth.getAuthResponse();
    if (guestToken) {
      services.authenticationService
        .googleAuth({ authResponse, guestToken })
        .then((response) => {
          const userInfo = jwtDecode(response.data.key);
          const username = userInfo.first_name;
          const token = response.data.key;
          commit("setUserToken", token);
          services.prechecksService
            .preChekLegalGuardian()
            .then((response) => {
              const lgUuid = response.data.lg_uuid;
              if (!response.data.has_lg) {
                const preCheckResponse = {
                  origin: "Google",
                  found: true,
                  first_login: true,
                  same_password: true,
                  has_addresses: false,
                  has_applicants: false,
                  is_verified: true,
                };
                commit("setPreCheckStatus", preCheckResponse);
                services.authenticationService
                  .createLegalGuardian()
                  .then((response) => {
                    dispatch("setIdentificationName", { username }).then(() => {
                      const legalGuardianUUID = response.data.uuid;
                      commit("setLegalGuardianUUID", { legalGuardianUUID });
                      dispatch("doLoginWithUUID", {
                        uuid: userInfo.user_id,
                      }).then(() => {
                        dispatch("getPrecheckRoles").then(() => {
                          const headMasterRole = rootGetters["roles/headMasterRole"];
                          const firstLogin = rootGetters["roles/headMasterFirstLogin"];
                          if (headMasterRole !== null && firstLogin === true) {
                            dispatch(
                              "roles/setHeadMasterFirstLogin",
                              { bool: true },
                              { root: true }
                            );
                          } else {
                            dispatch(
                              "roles/setHeadMasterFirstLogin",
                              { bool: false },
                              { root: true }
                            );
                          }
                        });
                        commit("setLoading", false);
                        return true;
                      });
                    });
                  })
                  .catch((error) => {
                    commit("setLoading", false);
                    console.error(error);
                    return false;
                  });
              } else {
                const preCheckResponse = {
                  origin: "Google",
                  found: response.data.has_lg,
                  first_login: false,
                  same_password: true,
                  is_verified: true,
                  has_addresses: response.data.has_lg_address,
                  has_applicants: response.data.has_applicants,
                };
                commit("setLGIsGuestUser", { isGuest: false });
                commit("setLegalGuardianUUID", { legalGuardianUUID: lgUuid });
                dispatch("doLoginWithUUID", {
                  uuid: userInfo.user_id,
                }).then(() => {
                  dispatch("getPrecheckRoles").then(() => {
                    const headMasterRole = rootGetters["roles/headMasterRole"];
                    const firstLogin = rootGetters["roles/headMasterFirstLogin"];
                    if (headMasterRole !== null && firstLogin === true) {
                      dispatch("roles/setHeadMasterFirstLogin", { bool: true }, { root: true });
                    } else {
                      dispatch("roles/setHeadMasterFirstLogin", { bool: false }, { root: true });
                    }
                  });
                  if (preCheckResponse.has_addresses) {
                    dispatch("getUserDetails", { legalGuardianUUID: lgUuid }).then(() => {
                      dispatch("getStudents", {}).then(() => {
                        dispatch("institutions/retrieveExploredSchoolCard", {}, { root: true });
                        dispatch("institutions/retrieveExploredCampuses", {}, { root: true });
                        dispatch("institutions/retrieveFavorites", {}, { root: true });
                        commit("setPreCheckStatus", preCheckResponse);
                        commit("setLoading", false);
                        return false;
                      });
                    });
                  }
                  if (!preCheckResponse.has_addresses || !preCheckResponse.has_applicants) {
                    commit("setPreCheckStatus", preCheckResponse);
                    commit("setLoading", false);
                    return false;
                  }
                  return true;
                });
                return true;
              }
              return true;
            })
            .catch((error) => {
              commit("setLoading", false);
              console.error(error);
              return false;
            });
        })
        .catch((error) => {
          const preCheckResponse = {
            linkAccount: true,
          };
          commit("setPreCheckStatus", preCheckResponse);
          commit("setGoogleAuthData", authResponse);
          commit("setLoading", false);
          console.error(error);
          return false;
        });
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

import api from "../api";

const appUrl = "registration";

export default {
  patchStep1(data) {
    return api.patch(`${appUrl}/applicant/step_1/`, data);
  },
  patchStep2(data) {
    return api.patch(`${appUrl}/applicant/step_2/`, data);
  },
  patchStep3(data) {
    return api.patch(`${appUrl}/applicant/step_3/`, data);
  },
  patchStep4(data) {
    return api.patch(`${appUrl}/applicant/step_4/`, data);
  },
  patchStep5(data) {
    return api.patch(`${appUrl}/applicant/step_5/`, data);
  },
  patchStep5Pre(data) {
    const idNumber = data.identification_number;
    return api.get(`${appUrl}/applicant/step_5_pre/?indentification_number=${idNumber}`);
  },
  patchStep6(data) {
    return api.patch(`${appUrl}/applicant/step_6/`, data);
  },
  patchStep7(data) {
    return api.patch(`${appUrl}/applicant/step_7/`, data);
  },
  patchStep8(data) {
    return api.patch(`${appUrl}/applicant/step_8/`, data);
  },
  patchStep9_1(data) {
    return api.patch(`${appUrl}/applicant/step_9_1/`, data);
  },
  patchStep9_2(data) {
    return api.patch(`${appUrl}/applicant/step_9_2/`, data);
  },
  patchStep10(data) {
    return api.patch(`${appUrl}/applicant/step_10/`, data);
  },
  patchStep11(data) {
    return api.patch(`${appUrl}/applicant/step_11/`, data);
  },
  patchStep12(data) {
    return api.patch(`${appUrl}/applicant/step_12/`, data);
  },
  patchStep13(data) {
    return api.patch(`${appUrl}/applicant/step_13/`, data);
  },
  patchStep14(data) {
    return api.patch(`${appUrl}/applicant/step_14/`, data);
  },
  patchStep15(data) {
    return api.patch(`${appUrl}/applicant/step_15/`, data);
  },
  setSteps(data) {
    return api.patch(`${appUrl}/applicant/set_steps/`, data);
  },
  patchStep12Pre(nDocument) {
    return api.get(`${appUrl}/applicant/step_12_pre/?indentification_number=${nDocument}`);
  },
  setStudents(gradePayload) {
    return api.patch(`${appUrl}/applicant/f_step_1/`, {
      uuid: gradePayload.uuid,
      first_name: gradePayload.first_name,
      last_name: gradePayload.last_name,
      current_school: gradePayload.current_school,
      current_grade: gradePayload.current_grade,
      interest_grades: gradePayload.interest_grades,
      interest_stages: gradePayload.interest_stages,
    });
  },
  setStudentLocation({ location }) {
    return api.patch(`${appUrl}/applicant/c_step_3/`, {
      uuid: location.uuid,
      same_address: false,
      address_street: location.address_street,
      address_number: location.address_number,
      address_complement: null,
      zip_code: location.zipcode,
      address_name: null,
      location: location.location,
      grid: 0,
      address_details: location.address_details,
      address_lat: location.coordinates.lat,
      address_lon: location.coordinates.lng,
    });
  },
  /*
  ONLY INTERES GRADES FOR STUDENT
  setInterestedGrades(grades) {
    return api.patch(`${appUrl}/applicant/f_step_1/`, {
      uuid: grades.uuid,
      interest_grades: grades.interest_grades,
    });
  },
  */
};

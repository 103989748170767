/* eslint-disable no-shadow */
import services from "../services";

const getDefaultState = () => ({
  searchs: {
    mainSearch: "",
  },
  loading: false,
});

const state = {
  ...getDefaultState(),
};

const getters = {
  mainSearch: ({ searchs }) => searchs.mainSearch,
  loading: ({ loading }) => loading,
};

const mutations = {
  setMainSearch(state, { schools }) {
    state.searchs.mainSearch = schools;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const actions = {
  setMainSearch({ commit, rootGetters }, { mainSearch }) {
    commit("setLoading", true);
    const isGuest = rootGetters["authentication/isGuest"];
    const currentStudent = rootGetters["authentication/currentStudent"];
    let gradeId = null;
    if (isGuest) {
      const guestGrade = rootGetters["authentication/guestGrades"];
      if (guestGrade !== null && guestGrade !== undefined) {
        gradeId = guestGrade;
      }
    }
    if (currentStudent.grades !== undefined) {
      if (currentStudent.grades !== null) {
        gradeId = currentStudent.grades[0];
      }
    }
    services.elasticSearchsService
      .elasticMainSerach({ mainSearch, gradeId })
      .then((response) => {
        const schools = response.data.results;
        commit("setMainSearch", { schools });
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  cleanMainSearch({ commit }) {
    const schools = "";
    commit("setMainSearch", { schools });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

<template>
  <svg width="46" height="53" viewBox="0 0 46 53" fill="#11D3D9" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23 45.5C10.5761 45.5 0.5 35.4239 0.5 23C0.5 10.5761 10.5761 0.5 23 0.5C35.4239 0.5 45.5 10.5761 45.5 23C45.5 35.4239 35.4239 45.5 23 45.5ZM23 19.5C21.0239 19.5 19.5 21.0239 19.5 23C19.5 24.9761 21.0239 26.5 23 26.5C24.9761 26.5 26.5 24.9761 26.5 23C26.5 21.0239 24.9761 19.5 23 19.5Z"
      fill="#11D3D9"
      stroke="white"
    />
    <path
      d="M34.0002 52.3002C39.1364 52.3002 43.3002 48.1364 43.3002 43.0002C43.3002 37.8639 39.1364 33.7002 34.0002 33.7002C28.8639 33.7002 24.7002 37.8639 24.7002 43.0002C24.7002 48.1364 28.8639 52.3002 34.0002 52.3002Z"
      fill="#11D3D9"
    />
    <path
      d="M34 53C28.5 53 24 48.5 24 43C24 37.5 28.5 33 34 33C39.5 33 44 37.5 44 43C44 48.5 39.5 53 34 53ZM34 34.4C29.2 34.4 25.4 38.3 25.4 43C25.4 47.7 29.3 51.6 34 51.6C38.7 51.6 42.6 47.7 42.6 43C42.6 38.3 38.8 34.4 34 34.4Z"
      fill="#333333"
    />
    <path
      d="M34.473 41.6796L34.9595 40.9223C34.9595 40.9223 34.9595 40.9223 34.9595 41.0485L36.5405 40.6699C36.4189 40.165 36.2973 39.7864 35.9324 39.4078L38 36.3786L37.3919 36L35.3243 38.9029C35.0811 38.7767 34.8378 38.6505 34.473 38.5243V37.2621H33.2568V38.5243C32.0405 38.7767 31.1892 39.6602 31.1892 40.9223C31.1892 41.8058 31.7973 42.5631 32.6486 42.9417L32.2838 43.4466L30.8243 43.8252C30.8243 44.2039 30.9459 44.5825 31.1892 44.9612L29 48.4951L29.6081 49L31.7973 45.7184C32.1622 45.9709 32.6486 46.2233 33.2568 46.3495V47.6116H34.5946V46.3495C35.9324 46.0971 36.6622 45.0874 36.6622 44.0777C36.6622 42.9417 36.0541 42.0583 34.473 41.6796ZM33.5 41.4272C33.1351 41.301 32.8919 41.0485 32.8919 40.7961C32.8919 40.4175 33.2568 40.0388 33.8649 40.0388C34.2297 40.0388 34.3514 40.165 34.5946 40.165L33.7432 41.4272H33.5ZM33.9865 44.835C33.3784 44.835 33.0135 44.5825 32.7703 44.3301L33.5 43.3204L34.2297 43.4466C34.7162 43.5728 34.9595 43.8252 34.9595 44.0777C34.8378 44.4563 34.473 44.835 33.9865 44.835Z"
      fill="white"
      stroke="#333333"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      d="M12.0002 52.3998C17.1364 52.3998 21.3002 48.2361 21.3002 43.0998C21.3002 37.9636 17.1364 33.7998 12.0002 33.7998C6.86395 33.7998 2.7002 37.9636 2.7002 43.0998C2.7002 48.2361 6.86395 52.3998 12.0002 52.3998Z"
      fill="#11D3D9"
    />
    <path
      d="M9.1998 47.3H8.0998V40.6L6.5998 42.1L5.7998 41.3L8.6998 38.5L11.4998 41.3L10.6998 42.2L9.1998 40.7V47.3Z"
      fill="white"
      stroke="#333333"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      d="M15.6002 47.3H14.5002V40.6L13.0002 42.1L12.2002 41.3L15.1002 38.5L17.9002 41.3L17.1002 42.2L15.6002 40.7V47.3Z"
      fill="white"
      stroke="#333333"
      stroke-width="0.1"
      stroke-miterlimit="10"
    />
    <path
      d="M12 53C6.5 53 2 48.5 2 43C2 37.5 6.5 33 12 33C17.5 33 22 37.5 22 43C22 48.5 17.5 53 12 53ZM12 34.4C7.2 34.4 3.4 38.3 3.4 43C3.4 47.7 7.3 51.6 12 51.6C16.7 51.6 20.6 47.7 20.6 43C20.6 38.3 16.8 34.4 12 34.4Z"
      fill="#333333"
    />
  </svg>
</template>

<script>
export default {
  name: "ArtifitialSchoolRemarked",
};
</script>

<style></style>

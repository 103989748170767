import api, { searchApi } from "../api";

const appUrl = "/institutions";
const elasticUrl = "campuses/poly_filter";

export default {
  /** Gets all campuses (only for testing purpose)
   * @returns {Promise<AxiosResponse<any>>}
   */
  retrieveCampuses(params) {
    return searchApi.post(`${elasticUrl}`, params);
  },
  /** Add a new favorite program for the current student
   * @param campus_uuid
   * @param student_uuid
   * @returns {Promise<AxiosResponse<any>>}
   */

  retriveInstitutionsComuna() {
    return api.get(`${appUrl}/vacancies/comuna/`);
  },
  addFavorite({ programs, studentUuid }) {
    return api.post(`${appUrl}/favorite-campus/add_favorite/`, {
      programs,
      applicant_id: studentUuid,
    });
  },
  /** Remove the program defined by the uuid from favorites schools
   * @param uuid
   * @returns {Promise<AxiosResponse<any>>}
   */
  removeFavorites({ programId }) {
    return api.delete(`${appUrl}/favorite-campus/${programId}/`);
  },
  /** Swap two favorite schools
   * @param uuid1
   * @param uuid2
   * @returns {Promise<AxiosResponse<any>>}
   */
  swapFavorites({ program, auxRanking, applicant }) {
    return api.post(`${appUrl}/favorite-campus/swap_favorites/`, {
      program,
      rank: auxRanking,
      applicant,
    });
  },
  /** Retrieve the favorites schools of a student (FavoriteSchool class)
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  retrieveFavoriteSchools(params) {
    return api.get(`${appUrl}/favorite-campus/`, { params });
  },
  /**
   * Retrieve the detail of an school
   * @param uuid
   * @returns {Promise<AxiosResponse<any>>}
   */
  retrieveSchoolDetail({ uuid, studentUuid, grade, fromUrl }) {
    const urlModify = fromUrl ? "/campuses/by_code/" : "/campuses/";
    return api.get(`${appUrl}${urlModify}${uuid}/?applicant_uuid=${studentUuid}&grade=${grade}`);
  },
  retrieveCampusGrade(campusUuid, studentUuid, grade) {
    return api.get(`${appUrl}/campuses/${campusUuid}?applicant_uuid=${studentUuid}&grade=${grade}`);
  },
  retrieveLiteAllSchoolUUIDs() {
    return api.get(`${appUrl}/campuses_lite/`);
  },
  /**
   * Retrieve favorite campuses of student (Campus class)
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  retrieveFavoriteCampuses(params) {
    return api.get(`${appUrl}/favorite-campus/`, { params });
  },
  /**
   * Create ExploredCampus for student
   * @param student
   * @param campus
   * @returns {Promise<AxiosResponse<any>>}
   */
  exploreCampus({ currentStudent, campus }) {
    return api.post(`${appUrl}/explored-map-cards/`, {
      campus_uuid: campus,
      applicant: currentStudent.id,
    });
  },
  exploreSchoolCard({ currentStudent, campus }) {
    return api.post(`${appUrl}/explored-campuses/`, {
      campus_uuid: campus,
      applicant: currentStudent.id,
    });
  },
  /**
   * Returns the data of map card
   * @param uuid
   * @returns {Promise<AxiosResponse<any>>}
   */
  applyWithFavorites({ studentId }) {
    return api.post(`${appUrl}/application/`, {
      applicant: studentId,
    });
  },
  retrieveCampusMapCard({ uuid, params }) {
    return api.get(`${appUrl}/campus-map-card/${uuid}/`, { params });
  },
  retrieveSpecialityByGrade({ gradeId }) {
    return api.get(`${appUrl}/gradetracks/by_grade/${gradeId}`);
  },
  /**
   * Retrieve explored campuses for the current student
   * @param uuid
   */
  retrieveExploredCampuses({ uuid }) {
    return api.get(`${appUrl}/explored-campuses/filter_by_student/?applicant_uuid=${uuid}`);
  },
  retrieveExploredSchoolCards({ uuid }) {
    return api.get(`${appUrl}/explored-map-cards/filter_by_student/?applicant_uuid=${uuid}`);
  },
  /**
   * Retrieve favorite campuses of legal guardian (Campus class)
   * @returns {Promise<AxiosResponse<any>>}
   */

  retrieveAllFavoriteCampuses() {
    return api.get(`${appUrl}/favorite-campus/legal_guardian_favorites/`);
  },
  retrieveLegalGuardianApplications() {
    return api.get(`${appUrl}/application/`);
  },
  /** Gets all institutions
   * @returns {Promise<AxiosResponse<any>>}
   */
  retrieveInstitutions(included) {
    if (included !== null) {
      return api.get(`${appUrl}/institutions/?included=${included}`);
    }
    return api.get(`${appUrl}/institutions/`);
  },
};

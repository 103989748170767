<template>
  <div class="register-data-container">
    <div class="title">
      {{ $t("user_register.register_data.title") }}
    </div>
    <div class="sub_title">
      {{ $t("user_register.register_data.sub_title") }}
    </div>
    <button
      v-if="false"
      class="register-data-container__register_btn justify-space-between"
      :class="{ 'register-data-container__register_btn--selected': googleRegisterSelected }"
      depressed
      @click="showGoogle()"
    >
      <img src="@/assets/iconsBase/logos/google.svg" alt="google" />
      <div class="d-flex">
        <p
          class="register-data-container__register_btn__text"
          style="margin-right: 3px"
          :class="{
            'register-data-container__register_btn__text--selected': googleRegisterSelected,
          }"
        >
          {{ $t('views.login.register-with') }}
        </p>
        <span
          class="register-data-container__register_btn__text font-weight-bold"
          :class="{
            'register-data-container__register_btn__text--selected': googleRegisterSelected,
          }"
          v-t="'views.login.plain-google'"
        />
      </div>
    </button>
    <button
      class="register-data-container__register_btn justify-center pl-0 pr-0"
      :class="{
        'register-data-container__register_btn--selected': emailRegisterSelected,
      }"
      depressed
      @click="showForm()"
    >
      <div class="d-flex justify-center">
        <p
          class="register-data-container__register_btn__text"
          :class="{
            'register-data-container__register_btn__text--selected': emailRegisterSelected,
          }"
          style="margin-right: 3px"
        >
          {{ $t('views.login.register-with') }}
        </p>
        <span
          class="register-data-container__register_btn__text font-weight-bold"
          :class="{
            'register-data-container__register_btn__text--selected': emailRegisterSelected,
          }"
          v-t="'views.login.plain-email'"
        />
      </div>
    </button>
    <div v-if="emailRegisterSelected">
      <div class="email_container mt-4">
        <div class="email_title_container">
          <p class="required-field email_title">
            {{ $t("user_register.register_data.form.name") }}
          </p>
        </div>
        <input
          v-model="name"
          type="text"
          class="register__content__input__div__input email_input"
          :class="{
            'register__content__input__div__input--selected': name.length > 0,
            'email_input--mobile': mobile,
            'register__content__input__div__input--error': nameErrors.length > 0,
          }"
        />
      </div>
      <div class="mb-4">
        <p
          v-for="(error, index) in nameErrors"
          :key="index"
          class="text password_error_conditions password_error_conditions--error"
        >
          {{ error }}
        </p>
      </div>

      <div class="email_container">
        <div class="email_title_container">
          <p class="required-field email_title">
            {{ $t("user_register.register_data.form.email") }}
          </p>
        </div>
        <input
          v-model="email"
          type="text"
          class="register__content__input__div__input email_input"
          :class="{
            'register__content__input__div__input--selected': email.length > 0,
            'email_input--mobile': mobile,
            'register__content__input__div__input--error': emailErrors.length > 0,
          }"
        />
      </div>
      <div class="mb-4">
        <p
          v-for="(error, index) in emailErrors"
          :key="index"
          class="text password_error_conditions password_error_conditions--error"
        >
          {{ error }}
        </p>
      </div>
      <div class="password_container">
        <div class="password_title_container">
          <p class="required-field password_title">
            {{ $t("user_register.register_data.form.password") }}
          </p>
        </div>
        <div class="d-flex align-center">
          <input
            :type="showPassword ? 'text' : 'password'"
            class="register__content__input__div__input password_input"
            :class="{
              'register__content__input__div__input--selected': password.length > 0,
              'password_input--mobile': mobile,
              'register__content__input__div__input--error': passwordErrors.length > 0,
            }"
            v-model="password"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          />
          <v-btn
            @click="showPassword = !showPassword"
            class="password_btn"
            icon
            color="black"
            type="button"
          >
            <v-icon>
              {{ showPassword ? "mdi-eye" : "mdi-eye-off" }}
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="mb-4">
        <p
          v-for="(error, index) in passwordErrors"
          :key="index"
          class="text password_error_conditions password_error_conditions--error"
        >
          {{ error }}
        </p>
      </div>
      <div class="password_two_container">
        <div class="password_two_title_container">
          <p class="required-field password_two_title">
            {{ $t("user_register.register_data.form.password2") }}
          </p>
        </div>
        <div class="d-flex align-center">
          <input
            :type="showPassword2 ? 'text' : 'password'"
            class="register__content__input__div__input password_two_input"
            :class="{
              'register__content__input__div__input--selected': password2.length > 0,
              'password_two_input--mobile': mobile,
              'register__content__input__div__input--error': password2Errors.length > 0,
            }"
            v-model="password2"
            @input="$v.password2.$touch()"
            @blur="$v.password2.$touch()"
          />
          <v-btn
            @click="showPassword2 = !showPassword2"
            class="password_two_btn"
            icon
            color="black"
            type="button"
          >
            <v-icon>
              {{ showPassword2 ? "mdi-eye" : "mdi-eye-off" }}
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="mb-4">
        <p
          v-for="(error, index) in password2Errors"
          :key="index"
          class="text password_error_conditions password_error_conditions--error"
        >
          {{ error }}
        </p>
      </div>
      <div class="mb-4">
        <p
          v-for="(error, index) in passwordConditions"
          :key="index"
          class="text password_error_conditions"
          :class="{ 'password_error_conditions--error': error.active }"
        >
          {{ error.text }}
        </p>
      </div>
      <div class="btn_container mb-5">
        <v-btn icon @click="backMethod">
          <img width="8" src="@/assets/iconsNewHaven/user_register/icons/blue-back.svg" />
        </v-btn>
        <base-button
          class="btn-continue"
          :class="{ 'btn-continue--active': formCompleted() }"
          @click="continueMethod"
        >
          {{ $t("user_register.register_data.btn") }}
        </base-button>
      </div>
      <div></div>
      <div
        class="register-data-container__terms"
        :class="{ 'register-data-container__terms--mobile': mobile }"
      >
        <div>
          {{ $t('user_register.register_data.terms_info') }}
        </div>
        <div
          class="ml-1 font-weight-bold"
          v-t="'user_register.register_data.terms'"
          @click="openPdfTerms()"
        />
      </div>
    </div>
    <div v-if="!emailRegisterSelected" class="fill-width d-flex">
      <v-btn icon @click="backMethod">
        <img width="8" src="@/assets/iconsNewHaven/user_register/icons/blue-back.svg" />
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { minLength, required, email } from "vuelidate/lib/validators";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import utils from "@/utils/";

export default {
  name: "BasicInformation",
  components: {
    BaseButton,
  },
  data() {
    return {
      name: "",
      email: "",
      firstName: "",
      password: "",
      password2: "",
      showPassword: false,
      showPassword2: false,
      googleRegisterSelected: false,
      emailRegisterSelected: false,
    };
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email(emailToTest) {
        return email(emailToTest);
      },
    },
    password: {
      required,
      minLength(password) {
        return minLength(8)(password);
      },
      hasNumbers(password) {
        return utils.checkNotOnlyNumbers(password);
      },
      hasLowercaseLetter(password) {
        return utils.checkLowerCases(password);
      },
      hasUppercaseLetter(password) {
        return utils.checkUpperCases(password);
      },
    },
    password2: {
      required,
      equal(password2) {
        return password2 === this.password;
      },
    },
  },
  mounted() {
    this.setInRegisterFlow(true);
  },
  methods: {
    ...mapActions({
      doRegister: "authentication/doRegister",
      getPrecheckData: "authentication/getPrecheckData",
      setRegistryData: "userRegister/setRegisterData",
      error: "snackbar/error",
    }),
    async signInGoogle() {
      const googleAuth = await this.$gAuth.signIn();
      this.setGoogleAuth({
        googleAuth,
      }).then((response) => {
        if (response) {
          return this.$emit('setLocationModal');
        }
        return this.$emit('nextStep');
      });
    },
    async continueMethod() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error(this.$t("user_register.register_data.error.invalid_form"));
      } else {
        this.email = this.email.toLowerCase();
        await this.$recaptchaLoaded();
        const captcha = await this.$recaptcha('login');
        const personalInfo = {
          preCheck: {
            username: this.email,
            password: this.password2,
          },
          registerData: {
            username: this.name,
            email: this.email,
            password1: this.password,
            password2: this.password2,
            captcha,
            errorCallback: (error) => this.error(error),
            callback: () =>
              this.setRegistryData({
                registerData: {
                  email: this.email,
                  username: this.name,
                },
              }),
          },
        };
        this.getPrecheckData({ data: personalInfo, fastLogin: true }).then(() => {
          this.$emit("nextStep");
        });
      }
    },
    backMethod() {
      this.$emit("backStep");
    },
    openPdfTerms() {
      window.open(
        "https://chile-assets.s3.amazonaws.com/TERMINOS+DE+REFERENCIA+-+PRIVACIDAD+DE+DATOS+-+CHILE.pdf"
      );
    },
    showGoogle() {
      this.emailRegisterSelected = false;
      if (!this.googleRegisterSelected) {
        this.signInGoogle();
        this.googleRegisterSelected = true;
        return;
      }
      this.googleRegisterSelected = false;
      return;
    },
    showForm() {
      this.googleRegisterSelected = false;
      if (!this.emailRegisterSelected) {
        this.emailRegisterSelected = true;
        return;
      }
      this.emailRegisterSelected = false;
      return;
    },
    formCompleted() {
      if (this.name && this.email && this.password && this.password2) {
        return true;
      }
      return false;
    },
  },
  computed: {
    ...mapGetters({
      preCheckStatus: "authentication/preCheckStatus",
      legalGuardianUUID: "authentication/legalGuardianUUID",
    }),
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) {
        return errors;
      }
      if (!this.$v.name.required) {
        errors.push(this.$t("user_register.register_data.error.username.required"));
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) {
        return errors;
      }
      if (!this.$v.email.required) {
        errors.push(this.$t("user_register.register_data.error.email.required"));
      }
      if (!this.$v.email.email) {
        errors.push(this.$t("user_register.register_data.error.email.invalid"));
      }
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) {
        return errors;
      }
      if (!this.$v.password.required) {
        errors.push(this.$t("user_register.register_data.error.password.required"));
      }
      return errors;
    },
    password2Errors() {
      const errors = [];
      if (!this.$v.password2.$dirty) {
        return errors;
      }
      if (!this.$v.password2.required) {
        errors.push(this.$t("user_register.register_data.error.password.required"));
      }
      return errors;
    },
    passwordConditions() {
      const errors = [];
      errors.push({
        text: this.$t("user_register.register_data.error.password.min_length"),
        active: !this.$v.password.minLength && this.$v.password.$dirty,
      });
      errors.push({
        text: this.$t("user_register.register_data.error.password.has_number"),
        active: !this.$v.password.hasNumbers && this.$v.password.$dirty,
      });
      errors.push({
        text: this.$t("user_register.register_data.error.password.not_match"),
        active: !this.$v.password2.equal && this.$v.password2.$dirty,
      });
      let caseValidation = false;
      if (this.$v.password.hasUppercaseLetter && this.$v.password.hasLowercaseLetter) {
        caseValidation = true;
      }
      if (!this.$v.password.$dirty) {
        caseValidation = true;
      }
      errors.push({
        text: this.$t("user_register.register_data.error.password.has_lowercase"),
        active: !caseValidation,
      });
      return errors;
    },
  },
};
</script>

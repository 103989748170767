import Hotjar from "vue-hotjar";
import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import * as VueGoogleMaps from "vue2-google-maps";
import VueYoutube from "vue-youtube";
import { VueReCaptcha } from "vue-recaptcha-v3";
import Vuelidate from "vuelidate";
import VueMixpanel from "vue-mixpanel";
import VueGtag from "vue-gtag";
import Donut from "vue-css-donut-chart";
import vueVimeoPlayer from "vue-vimeo-player";
import VueMask from "v-mask";
import VueCalendly from "vue-calendly";
import VueGeolocation from "vue-browser-geolocation";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import "./styles/main.scss";
import CONFIG from "./config";
import "vue-css-donut-chart/dist/vcdonut.css";
import "./styles/tailwind.css";
// eslint-disable-next-line import/order
import VueCookies from 'vue-cookies';
import GAuth from 'vue-google-oauth2'


const gauthOption = {
  clientId: '535702573428-lfps296ghpfts60i9kea9gfaqmuah85h.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}

Vue.use(GAuth, gauthOption)
Vue.use(VueCookies);
Vue.use(VueGeolocation);
// set default config cookies
Vue.$cookies.set("_ga", "1d");
Vue.$cookies.set("mp_54a0868c0a0ad44ca6742f3ad6ddcf7f_mixpanel", "1d");
Vue.$cookies.set("_ga_3G0ZRBEB0W", "1d");

Vue.use(VueCookies);

Vue.use(VueCalendly);

Vue.use(vueVimeoPlayer);

Vue.use(Donut);

Vue.use(VueGtag, {
  config: { id: CONFIG.ganalytics },
});

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: CONFIG.gMapKey,
    libraries: ["geocoder", "places", "geometry"],
    language: "es-419",
  },
  installComponents: true,
});

Vue.use(Vuelidate);

Vue.use(VueYoutube);

Vue.use(VueReCaptcha, { siteKey: CONFIG.captchaKey });

const isProduction = CONFIG.hotjarOn;
Vue.use(Hotjar, {
  id: CONFIG.hotjarKey,
  isProduction,
});

Sentry.init({
  Vue,
  dsn: CONFIG.sentryKeyDsn,
  integrations: [new Integrations.BrowserTracing()],
  environment: CONFIG.environment,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Vue.use(VueMixpanel, {
  token: "54a0868c0a0ad44ca6742f3ad6ddcf7f",
  config: {
    debug: CONFIG.mixPanelEnv,
    ignore_dnt: true,
  },
});
router.setStore(store);

Vue.use(VueMask);

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

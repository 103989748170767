<template>
  <svg width="17" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      stroke="#333"
      d="M.5 21.333v-5.689M5.833 21.333V9.955M11.167 21.333V5.689M16.5 21.333V0"
    />
  </svg>
</template>

<script>
export default {
  name: "PerformanceLevelBars",
};
</script>

/* eslint-disable no-shadow */
import services from "../services";

const getDefaultState = () => ({
  uuid: "",
  // Flow chart
  currentStep: 1,
  currentSubSteps: [1, 1, 1], // identificacion, location, priority
  totalSubSteps: [9, 2, 7], // identificacion, location, priority
  totalSubStepsComplete: [0, 0, 0], // identification, location, priority
  // Identification
  nickname: "",
  grade: null,
  day: null,
  month: null,
  year: null,
  showIdNumberModal: null,
  idNumberResponse: null,
  userRelationship: {},
  otherRelationship: "",
  userDocumentMother: {},
  userDocumentFather: {},
  userNDocumentMother: "",
  userNDocumentFather: "",
  firstNameFather: "",
  secondNameFather: "",
  fatherLastNameFather: "",
  motherLastNameFather: "",
  firstNameMother: "",
  secondNameMother: "",
  fatherLastNameMother: "",
  motherLastNameMother: "",
  liveWithLegalguardian: null,
  document: {},
  nDocument: "",
  file: [],
  firstName: "",
  secondName: "",
  fatherLastName: "",
  motherLastName: "",
  gender: null,
  birthplace: {},
  // location
  sameAddress: null,
  address: {},
  // Priority
  publicInstitutionPriority: null,
  siblings: [],
  cdiOrIcbfPriority: null,
  victimOfArmedConflictPriority: null,
  victimOfArmedConflictCategory: null,
  disabilityPriority: null,
  disabilityCategory: null,
  disabilityFile: [],
  ethnicity: null,
  loading: false,
});

const state = {
  ...getDefaultState(),
};

const getters = {
  uuid: ({ uuid }) => uuid,
  showIdNumberModal: ({ showIdNumberModal }) => showIdNumberModal,
  idNumberResponse: ({ idNumberResponse }) => idNumberResponse,
  // Flow chart
  currentStep: ({ currentStep }) => currentStep,
  currentSubStep: ({ currentStep, currentSubSteps }) => currentSubSteps[currentStep - 1],
  currentSubSteps: ({ currentSubSteps }) => currentSubSteps,
  totalSubSteps: ({ totalSubSteps }) => totalSubSteps,
  totalSubStepsComplete: ({ totalSubStepsComplete }) => totalSubStepsComplete,
  // identification
  nickname: ({ nickname }) => nickname,
  grade: ({ grade }) => grade,
  day: ({ day }) => day,
  month: ({ month }) => month,
  year: ({ year }) => year,
  otherRelationship: ({ otherRelationship }) => otherRelationship,
  userRelationship: ({ userRelationship }) => userRelationship,
  userDocumentMother: ({ userDocumentMother }) => userDocumentMother,
  userDocumentFather: ({ userDocumentFather }) => userDocumentFather,
  userNDocumentMother: ({ userNDocumentMother }) => userNDocumentMother,
  userNDocumentFather: ({ userNDocumentFather }) => userNDocumentFather,
  firstNameFather: ({ firstNameFather }) => firstNameFather,
  secondNameFather: ({ secondNameFather }) => secondNameFather,
  fatherLastNameFather: ({ fatherLastNameFather }) => fatherLastNameFather,
  motherLastNameFather: ({ motherLastNameFather }) => motherLastNameFather,
  firstNameMother: ({ firstNameMother }) => firstNameMother,
  secondNameMother: ({ secondNameMother }) => secondNameMother,
  fatherLastNameMother: ({ fatherLastNameMother }) => fatherLastNameMother,
  motherLastNameMother: ({ motherLastNameMother }) => motherLastNameMother,
  liveWithLegalguardian: ({ liveWithLegalguardian }) => liveWithLegalguardian,
  document: ({ document }) => document,
  nDocument: ({ nDocument }) => nDocument,
  file: ({ file }) => file,
  firstName: ({ firstName }) => firstName,
  secondName: ({ secondName }) => secondName,
  fatherLastName: ({ fatherLastName }) => fatherLastName,
  motherLastName: ({ motherLastName }) => motherLastName,
  gender: ({ gender }) => gender,
  birthplace: ({ birthplace }) => birthplace,
  // location
  sameAddress: ({ sameAddress }) => sameAddress,
  address: ({ address }) => address,
  // priority
  publicInstitutionPriority: ({ publicInstitutionPriority }) => publicInstitutionPriority,
  siblings: ({ siblings }) => siblings,
  cdiOrIcbfPriority: ({ cdiOrIcbfPriority }) => cdiOrIcbfPriority,
  victimOfArmedConflictPriority: ({ victimOfArmedConflictPriority }) =>
    victimOfArmedConflictPriority,
  victimOfArmedConflictCategory: ({ victimOfArmedConflictCategory }) =>
    victimOfArmedConflictCategory,
  disabilityPriority: ({ disabilityPriority }) => disabilityPriority,
  disabilityCategory: ({ disabilityCategory }) => disabilityCategory,
  disabilityFile: ({ disabilityFile }) => disabilityFile,
  ethnicity: ({ ethnicity }) => ethnicity,
  loading: ({ loading }) => loading,
};

const mutations = {
  resetStore(state) {
    Object.assign(state, getDefaultState());
  },
  setUuid(state, uuid) {
    state.uuid = uuid;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setShowIdNumberModal(state, response) {
    state.showIdNumberModal = response;
  },
  setIdNumberResponse(state, data) {
    state.idNumberResponse = data;
  },
  // Flow chart
  setCurrentStep(state, step) {
    state.currentStep = step;
  },
  setCurrentSubSteps(state, value) {
    const { step, subStep } = value;
    state.currentSubSteps[step - 1] = subStep;
  },
  setTotalSubStepsComplete(state, value) {
    const { step, subStep } = value;
    state.totalSubStepsComplete[step - 1] = subStep;
  },
  // identification
  setDocument(state, document) {
    state.document = document;
  },
  setUserRelationship(state, relationship) {
    state.userRelationship = relationship;
  },
  setNickname(state, nickname) {
    state.nickname = nickname;
  },
  setGrade(state, grade) {
    state.grade = grade;
  },
  setDay(state, day) {
    state.day = day;
  },
  setMonth(state, month) {
    state.month = month;
  },
  setYear(state, year) {
    state.year = year;
  },
  setOtherRelationship(state, otherRelationship) {
    state.otherRelationship = otherRelationship;
  },
  setUserNDocumentMother(state, userNDocument) {
    state.userNDocumentMother = userNDocument;
  },
  setUserNDocumentFather(state, userNDocument) {
    state.userNDocumentFather = userNDocument;
  },
  setLiveWithLegalguardian(state, liveWithLegalguardian) {
    state.liveWithLegalguardian = liveWithLegalguardian;
  },
  setUserDocumentMother(state, userDocument) {
    state.userDocumentMother = userDocument;
  },
  setUserDocumentFather(state, userDocument) {
    state.userDocumentFather = userDocument;
  },
  setFirstNameFather(state, value) {
    state.firstNameFather = value;
  },
  setSecondNameFather(state, value) {
    state.secondNameFather = value;
  },
  setFatherLastNameFather(state, value) {
    state.fatherLastNameFather = value;
  },
  setMotherLastNameFather(state, value) {
    state.motherLastNameFather = value;
  },
  setFirstNameMother(state, value) {
    state.firstNameMother = value;
  },
  setSecondNameMother(state, value) {
    state.secondNameMother = value;
  },
  setFatherLastNameMother(state, value) {
    state.fatherLastNameMother = value;
  },
  setMotherLastNameMother(state, value) {
    state.motherLastNameMother = value;
  },
  setNDocument(state, nDocument) {
    state.nDocument = nDocument;
  },
  setFile(state, file) {
    state.file = file;
  },
  setFirstName(state, firstName) {
    state.firstName = firstName;
  },
  setSecondName(state, secondName) {
    state.secondName = secondName;
  },
  setFatherLastName(state, fatherLastName) {
    state.fatherLastName = fatherLastName;
  },
  setMotherLastName(state, motherLastName) {
    state.motherLastName = motherLastName;
  },
  setGender(state, gender) {
    state.gender = gender;
  },
  setBirthplace(state, birthplace) {
    state.birthplace = birthplace;
  },
  // location
  setSameAddress(state, sameAddress) {
    state.sameAddress = sameAddress;
  },
  setAddress(state, address) {
    state.address = address;
  },
  // priority
  setPublicInstitutionPriority(state, info) {
    state.publicInstitutionPriority = info;
  },
  setNewSibling(state, student) {
    state.siblings.push(student);
  },
  removeSibling(state, id) {
    state.siblings.splice(id, 1);
  },
  setCdiOrIcbfPriority(state, info) {
    state.cdiOrIcbfPriority = info;
  },
  setVictimOfArmedConflictPriority(state, info) {
    state.victimOfArmedConflictPriority = info;
  },
  setVictimOfArmedConflictCategory(state, info) {
    state.victimOfArmedConflictCategory = info;
  },
  setDisabilityPriority(state, info) {
    state.disabilityPriority = info;
  },
  setDisabilityCategory(state, info) {
    state.disabilityCategory = info;
  },
  setDisabilityFile(state, info) {
    state.disabilityFile = info;
  },
  setEthnicity(state, info) {
    state.ethnicity = info;
  },
  setStudentData(state, data) {
    state.uuid = data.uuid;
    state.currentStep = data.last_step_complete;
    state.currentSubSteps[data.last_step_complete - 1] = data.last_substep_complete;
    state.totalSubStepsComplete[data.last_step_complete - 1] = data.last_substep_complete - 1;
    if (data.last_step_complete > 1) {
      for (let i = 0; i < data.last_step_complete - 1; i += 1) {
        state.totalSubStepsComplete[i] = state.totalSubSteps[i];
      }
    }
    state.nickname = data.personalized_name;
    state.grade = data.grade;
    state.day = data.birth_date ? parseInt(data.birth_date.split("-")[2], 10) : null;
    state.month = data.birth_date ? parseInt(data.birth_date.split("-")[1], 10) : null;
    state.year = data.birth_date ? parseInt(data.birth_date.split("-")[0], 10) : null;
    state.userRelationship = data.relationship || {};
    state.otherRelationship = data.relationship_other || "";
    state.userDocumentMother = data.mother_identification_type || {};
    state.userDocumentFather = data.father_identification_type || {};
  },
};

const actions = {
  resetStore({ commit }, { callback = undefined }) {
    commit("resetStore");
    if (callback) {
      callback();
    }
  },
  setShowIdNumberModal({ commit }, data) {
    commit("setShowIdNumberModal", data);
  },
  setUuid({ commit }, uuid) {
    commit("setUuid", uuid);
  },
  // Flow chart
  setCurrentStep({ commit }, step) {
    commit("setCurrentStep", step);
  },
  setCurrentSubSteps({ commit }, { step, subStep }) {
    commit("setCurrentSubSteps", { step, subStep });
  },
  setTotalSubStepsComplete({ commit }, { step, subStep }) {
    commit("setTotalSubStepsComplete", { step, subStep });
  },
  // identification
  setDocument({ commit }, document) {
    commit("setDocument", document);
  },
  setUserRelationship({ commit }, relationship) {
    commit("setUserRelationship", relationship);
  },
  setNickname({ commit }, nickname) {
    commit("setNickname", nickname);
  },
  setGrade({ commit }, grade) {
    commit("setGrade", grade);
  },
  setDay({ commit }, day) {
    commit("setDay", day);
  },
  setMonth({ commit }, month) {
    commit("setMonth", month);
  },
  setYear({ commit }, year) {
    commit("setYear", year);
  },
  setOtherRelationship({ commit }, otherRelationship) {
    commit("setOtherRelationship", otherRelationship);
  },
  setUserNDocumentMother({ commit }, userNDocument) {
    commit("setUserNDocumentMother", userNDocument);
  },
  setUserNDocumentFather({ commit }, userNDocument) {
    commit("setUserNDocumentFather", userNDocument);
  },
  setLiveWithLegalguardian({ commit }, liveWithLegalguardian) {
    commit("setLiveWithLegalguardian", liveWithLegalguardian);
  },
  setUserDocumentMother({ commit }, userDocument) {
    commit("setUserDocumentMother", userDocument);
  },
  setUserDocumentFather({ commit }, userDocument) {
    commit("setUserDocumentFather", userDocument);
  },
  setFirstNameFather({ commit }, value) {
    commit("setFirstNameFather", value);
  },
  setSecondNameFather({ commit }, value) {
    commit("setSecondNameFather", value);
  },
  setFatherLastNameFather({ commit }, value) {
    commit("setFatherLastNameFather", value);
  },
  setMotherLastNameFather({ commit }, value) {
    commit("setMotherLastNameFather", value);
  },
  setFirstNameMother({ commit }, value) {
    commit("setFirstNameMother", value);
  },
  setSecondNameMother({ commit }, value) {
    commit("setSecondNameMother", value);
  },
  setFatherLastNameMother({ commit }, value) {
    commit("setFatherLastNameMother", value);
  },
  setMotherLastNameMother({ commit }, value) {
    commit("setMotherLastNameMother", value);
  },
  setNDocument({ commit }, nDocument) {
    commit("setNDocument", nDocument);
  },
  setFile({ commit }, file) {
    commit("setFile", file);
  },
  setFirstName({ commit }, firstName) {
    commit("setFirstName", firstName);
  },
  setSecondName({ commit }, secondName) {
    commit("setSecondName", secondName);
  },
  setFatherLastName({ commit }, fatherLastName) {
    commit("setFatherLastName", fatherLastName);
  },
  setMotherLastName({ commit }, motherLastName) {
    commit("setMotherLastName", motherLastName);
  },
  setGender({ commit }, gender) {
    commit("setGender", gender);
  },
  setBirthplace({ commit }, birthplace) {
    commit("setBirthplace", birthplace);
  },
  async setIdentificationStepOne({ commit, getters, dispatch }, data) {
    const { nickname } = data;
    commit("setNickname", nickname);
    const { uuid } = getters;
    const requestData = {
      personalized_name: nickname,
    };
    if (uuid !== "") {
      requestData.uuid = uuid;
    }

    await services.studentRegistrationService
      .patchStep1(requestData)
      .then((response) => {
        dispatch("setUuid", response.data.uuid);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async setIdentificationStepTwo({ commit, getters }, data) {
    const { relationship, other } = data;
    commit("setUserRelationship", relationship);
    commit("setOtherRelationship", other);
    const { uuid } = getters;
    const requestData = {
      relationship: relationship.id,
      relationship_other: other,
      uuid,
    };

    await services.studentRegistrationService.patchStep2(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setIdentificationStepThree({ commit, getters }, data) {
    const {
      nDocumentMother,
      nDocumentFather,
      documentMother,
      documentFather,
      firstNameFather,
      secondNameFather,
      fatherLastNameFather,
      motherLastNameFather,
      firstNameMother,
      secondNameMother,
      fatherLastNameMother,
      motherLastNameMother,
    } = data;
    commit("setUserNDocumentMother", nDocumentMother);
    commit("setUserNDocumentFather", nDocumentFather);
    commit("setUserDocumentMother", documentMother);
    commit("setUserDocumentFather", documentFather);
    commit("setFirstNameFather", firstNameFather);
    commit("setSecondNameFather", secondNameFather);
    commit("setFatherLastNameFather", fatherLastNameFather);
    commit("setMotherLastNameFather", motherLastNameFather);
    commit("setFirstNameMother", firstNameMother);
    commit("setSecondNameMother", secondNameMother);
    commit("setFatherLastNameMother", fatherLastNameMother);
    commit("setMotherLastNameMother", motherLastNameMother);
    const { uuid } = getters;
    const requestData = {
      mother_nationality: documentMother.nationality.id,
      mother_identification_type: documentMother.id,
      mother_identification_number: nDocumentMother,
      mother_firstname: firstNameMother,
      mother_other_name: secondNameMother,
      mother_first_lastname: fatherLastNameMother,
      mother_other_lastname: motherLastNameMother,
      father_nationality: documentFather.nationality.id,
      father_identification_type: documentFather.id,
      father_identification_number: nDocumentFather,
      father_firstname: firstNameFather,
      father_other_name: secondNameFather,
      father_first_lastname: fatherLastNameFather,
      father_other_lastname: motherLastNameFather,
      uuid,
    };

    await services.studentRegistrationService.patchStep3(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setIdentificationStepFour({ commit }, data) {
    const { liveWithLegalguardian } = data;
    commit("setLiveWithLegalguardian", liveWithLegalguardian);
  },
  async setIdentificationStepFive({ commit, getters }, data) {
    const { document } = data;
    commit("setDocument", document);
    const { uuid } = getters;
    const requestData = {
      nationality: document.nationality.id,
      identification_type: document.id,
      uuid,
    };

    await services.studentRegistrationService.patchStep4(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setIdentificationStepSix({ commit, getters }, data) {
    const { nDocument } = data;
    commit("setNDocument", nDocument);
    const { uuid } = getters;
    const requestData = {
      identification_number: nDocument,
      uuid,
    };
    services.studentRegistrationService.patchStep5(requestData).catch((error) => {
      console.error(error);
    });
    // await services.studentRegistrationService.patchStep5Pre(requestData).then((response) => {
    //   if (response.data.is_used === false) {
    //     commit('setShowIdNumberModal', false);
    //     services.studentRegistrationService.patchStep5(requestData)
    //       .catch((error) => {
    //         console.error(error);
    //       });
    //   } else {
    //     commit('setShowIdNumberModal', true);
    //     commit('setIdNumberResponse', response.data);
    //   }
    // })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  },
  async setIdentificationStepSeven({ getters }, data) {
    const { imageB64, imageName, imageNumber } = data;
    const { uuid } = getters;
    const requestData = {
      filename: imageName,
      data_b64: imageB64,
      uuid,
      image_number: imageNumber,
    };
    await services.studentRegistrationService.patchStep6(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setIdentificationStepEight({ commit, getters }, data) {
    const {
      day,
      month,
      year,
      firstName,
      secondName,
      fatherLastName,
      motherLastName,
      gender,
      address,
    } = data;
    commit("setDay", day);
    commit("setMonth", month);
    commit("setYear", year);
    commit("setFirstName", firstName);
    commit("setSecondName", secondName);
    commit("setFatherLastName", fatherLastName);
    commit("setMotherLastName", motherLastName);
    commit("setGender", gender);
    commit("setBirthplace", address);
    const { uuid } = getters;
    const requestData = {
      birth_day: day,
      birth_month: month,
      birth_year: year,
      first_name: firstName,
      other_name: secondName,
      first_lastname: fatherLastName,
      other_lastname: motherLastName,
      gender,
      birth_location: address.muni,
      uuid,
    };

    await services.studentRegistrationService.patchStep7(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setIdentificationStepNine({ commit, getters }, data) {
    const { grade } = data;
    commit("setGrade", grade);
    const { uuid } = getters;
    const requestData = {
      grade,
      uuid,
    };

    await services.studentRegistrationService.patchStep8(requestData).catch((error) => {
      console.error(error);
    });
  },
  // location
  setSameAddress({ commit }, sameAddress) {
    commit("setSameAddress", sameAddress);
  },
  setAddress({ commit }, address) {
    commit("setAddress", address);
  },
  async setLocationStepOne({ commit, getters, rootGetters }, { data, callback = undefined }) {
    const { opt } = data;
    commit("setSameAddress", opt);
    const { uuid } = getters;
    const roadTypes = rootGetters["options/roadTypes"];
    if (opt) {
      const requestData = {
        same_location: opt,
        uuid,
      };
      await services.studentRegistrationService
        .patchStep9_1(requestData)
        .then(() => {
          if (callback) {
            callback();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const geoSearch = data.address;
      await services.geoLocatorService
        .fromAddress({ geoSearch })
        .then((response) => {
          if ("lat" in response.data.location && "lon" in response.data.location) {
            const location = {
              lat: response.data.location.lat,
              lng: response.data.location.lon,
            };
            geoSearch.address_lat = location.lat;
            geoSearch.address_lon = location.lng;
            const vpTipoVia = roadTypes.find((elem) => elem.tipo_via === geoSearch.VP_tipo_via).id;
            const address = {
              main_address: geoSearch.main_address,
              address_details: geoSearch.address_details,
              uuid: geoSearch.uuid,
              department: geoSearch.department,
              municipality: geoSearch.municipality,
              vp_tipo_via: vpTipoVia,
              area_label: 1,
              vp_number: geoSearch.VP_numero,
              vp_letra: geoSearch.VP_letra,
              vp_prefijo: geoSearch.VP_prefijo,
              vp_cuadrante: geoSearch.VP_cuadrante,
              vg_numero_via: geoSearch.VG_numero_via,
              vg_letra: geoSearch.VG_letra,
              vg_prefijo: geoSearch.VG_prefijo,
              vg_placa: geoSearch.VG_placa,
              vg_cuadrante: geoSearch.VG_cuadrante,
              address_lat: geoSearch.address_lat,
              address_lon: geoSearch.address_lon,
            };
            services.studentRegistrationService
              .patchStep9_2(address)
              .then(({ data }) => {
                const { address } = data;
                commit("setAddress", address[0]);
                if (callback) {
                  callback({ found: true });
                }
              })
              .catch((error) => {
                console.error(error);
              });
          } else if (callback) {
            callback({ found: false });
          }
        })
        .catch((error) => {
          console.error(error);
          if (callback) {
            callback({ found: false });
          }
        })
        .finally();
    }
  },
  // priority
  setPublicInstitutionPriority({ commit }, priority) {
    commit("setPublicInstitutionPriority", priority);
  },
  setNewSibling({ commit }, student) {
    commit("setNewSibling", student);
  },
  removeSibling({ commit }, id) {
    commit("removeSibling", id);
  },
  setCdiOrIcbfPriority({ commit }, priority) {
    commit("setCdiOrIcbfPriority", priority);
  },
  setVictimOfArmedConflictPriority({ commit }, priority) {
    commit("setVictimOfArmedConflictPriority", priority);
  },
  setVictimOfArmedConflictCategory({ commit }, priority) {
    commit("setVictimOfArmedConflictCategory", priority);
  },
  setDisabilityPriority({ commit }, priority) {
    commit("setDisabilityPriority", priority);
  },
  setDisabilityCategory({ commit }, priority) {
    commit("setDisabilityCategory", priority);
  },
  setDisabilityFile({ commit }, priority) {
    commit("setDisabilityFile", priority);
  },
  setEthnicity({ commit }, priority) {
    commit("setEthnicity", priority);
  },
  async setPriorityStepOne({ commit, getters }, data) {
    const { opt } = data;
    commit("setPublicInstitutionPriority", opt);
    const { uuid } = getters;
    const requestData = {
      has_sibling_priority: opt,
      uuid,
    };

    await services.studentRegistrationService.patchStep10(requestData).catch((error) => {
      console.error(error);
    });
  },
  setPriorityStepTwo({ getters }, { callback = undefined }) {
    const { uuid } = getters;
    const { siblings } = getters;
    const requestData = {
      siblings,
      uuid,
    };
    services.studentRegistrationService
      .patchStep11(requestData)
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async setPriorityStepThree({ commit, getters }, data) {
    const { opt } = data;
    commit("setCdiOrIcbfPriority", opt);
    const { uuid } = getters;
    const requestData = {
      icbf: opt,
      uuid,
    };

    await services.studentRegistrationService.patchStep12(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setPriorityStepFour({ commit, getters }, data) {
    const { opt, category } = data;
    commit("setVictimOfArmedConflictPriority", opt);
    commit("setVictimOfArmedConflictCategory", category);
    const { uuid } = getters;
    const requestData = {
      has_armed_conflict_priority: opt,
      armed_conflict_type: category,
      uuid,
    };

    await services.studentRegistrationService.patchStep13(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setPriorityStepFive({ commit, getters }, data) {
    const { opt, disability } = data;
    commit("setDisabilityPriority", opt);
    commit("setDisabilityCategory", disability);
    const { uuid } = getters;
    const requestData = {
      disability,
      has_disability_priority: opt,
      uuid,
    };

    await services.studentRegistrationService.patchStep14(requestData).catch((error) => {
      console.error(error);
    });
  },
  async setPriorityStepSix({ getters }, data) {
    const { imageB64, imageName, imageNumber } = data;
    const { uuid } = getters;
    const requestData = {
      filename: imageName,
      data_b64: imageB64,
      uuid,
      image_number: imageNumber,
    };
    await services.studentRegistrationService.patchStep15(requestData).catch((error) => {
      console.error(error);
    });
  },
  setSteps({ getters }, { callback = undefined }) {
    const { uuid } = getters;
    const { currentSubSteps } = getters;
    const { totalSubSteps } = getters;
    const { totalSubStepsComplete } = getters;
    let step = 4;
    let subStep = 10;

    currentSubSteps.forEach((elem, i) => {
      if (elem < totalSubSteps[i]) {
        if (i + 1 < step && elem < subStep) {
          step = i + 1;
          subStep = elem;
        }
      } else if (totalSubStepsComplete[i] < totalSubSteps[i]) {
        if (i + 1 < step && elem < subStep) {
          step = i + 1;
          subStep = elem;
        }
      }
    });
    const requestData = {
      step,
      sub_step: subStep,
    };

    if (uuid !== "") {
      requestData.uuid = uuid;
    }

    return services.studentRegistrationService
      .setSteps(requestData)
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  setStudentData({ commit }, data) {
    commit("setStudentData", data);
  },
  getStudentData({ dispatch, rootGetters }) {
    dispatch("authentication/getStudents", {}, { root: true }).then(() => {
      const cornerBounds = rootGetters["institutions/cornerBounds"];
      dispatch(
        "institutions/retrieveCampuses",
        { callback: null, bounds: cornerBounds },
        { root: true }
      );
    });
  },
  async setStudents({ commit, dispatch }, studentsForm, newStudent) {
    let gradePayload;
    if (newStudent) {
      gradePayload = {
        first_name: studentsForm[0].firstName,
        last_name: studentsForm[0].lastName,
        current_school: studentsForm[0].currentSchool,
        current_grade: studentsForm[0].gradeId,
        interest_grades: studentsForm[0].gradeInterest,
        interest_stages: studentsForm[0].stagesInteres,
      };
    } else {
      gradePayload = {
        uuid: studentsForm[0].uuid,
        first_name: studentsForm[0].firstName,
        last_name: studentsForm[0].lastName,
        current_school: studentsForm[0].currentSchool,
        current_grade: studentsForm[0].gradeId,
        interest_grades: studentsForm[0].gradeInterest,
        interest_stages: [studentsForm[0].stagesInteres],
      };
    }
    commit("setLoading", true);
    services.studentRegistrationService.setStudents(gradePayload).then(() => {
      const callback = false;
      dispatch("getStudentData", callback);
      commit("setLoading", false);
    });
  },
  async setInterestedGrades({ commit, dispatch }, gradePayload) {
    commit("setLoading", true);
    services.studentRegistrationService.setStudents(gradePayload).then(() => {
      setTimeout(() => {
        const callback = false;
        dispatch("getStudentData", callback);
        commit("setLoading", false);
      }, 500);
    });
  },
  async setStudentLocation({ dispatch }, { location }) {
    services.studentRegistrationService
      .setStudentLocation({ location })
      .then(() => {
        dispatch("getStudentData");
      })
      .finally(() => {});
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
